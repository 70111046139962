import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Default configuration for dialog
const defaultConfig = {
	open: false,
	handleClose: false,
	btnCancel: false,
	btnConfirm: false,
	title: '',
	subtitle: '',
};

export const DialogTemplate = ({ content = null, config = defaultConfig }) => {
		// Desestructure config property
	const {
		open,
		handleClose,
		btnCancel = false,
		btnConfirm = false,
		btnConfirmAction = null,
		btnConfirmText = null,
		title,
		subtitle = '',
		maxWidth = 'sm',
		fullWidth= true,
	} = config;

	return (
		<div>
			<Dialog open={open} onClose={handleClose} maxWidth={maxWidth} fullWidth={fullWidth}>
				{/* Dialog title */}
				<DialogTitle>{title}</DialogTitle>

				{/* Dialog content */}
				<DialogContent>
					{/* Dialog subtitle (if any) */}
					{subtitle.length > 0 && (
						<DialogContentText className='mb-3'>
							{subtitle}
							<br />
						</DialogContentText>
					)}

					{/* Dialog content prop */}
					{content}
				</DialogContent>

				{/* Dialog buttons */}
				{(btnCancel || btnConfirm) && (
					<DialogActions>
						{/* Cancel button */}
						{btnCancel && (
							<Button onClick={handleClose} color='error'>
								Cancelar
							</Button>
						)}

						{/* Confirm button */}
						{btnConfirm && (
							<Button
								onClick={() => {
									if (btnConfirmAction) {
										btnConfirmAction();
									}
									handleClose();
								}}
							>
								{btnConfirmText ? btnConfirmText : 'Confirmar'}
							</Button>
						)}
					</DialogActions>
				)}
			</Dialog>
		</div>
	);
};
