import Swal from 'sweetalert2';
import { toastSweet } from './sweetAlert2';
import { styled } from '@mui/material/styles';
import { Switch } from '@mui/material';

// Club Puebla color
export const mainColor = '#0E4194';

// Wait N seconds and reload
export const waitAndReload = (seconds = 1.5) => {
	setTimeout(() => {
		document.location.reload();
	}, seconds * 1000);
};

// Create our number formatter.
export const formatterCurrency = (num) => {
	return `${new Intl.NumberFormat('es-MX', {
		style: 'currency',
		currency: 'MXN',
	}).format(num)} MXN`;
};

// Gretting by the moment of the day
export const getDayMoment = (name = null) => {
	if (name) {
		name = name.split(' ')[0];
	}
	let hour = new Date().getHours();
	if (hour < 12) return `Buenos días${name ? `, ${name}` : ''} 👋`;
	if (hour >= 12 && hour < 19)
		return `Buenas tardes${name ? `, ${name}` : ''} 👋`;
	if (hour >= 19) return `Buenas noches${name ? `, ${name}` : ''} 🌙`;
};

// Format first letter to be capitalized and the rest lowercase
export const capitalizeFirstLetter = (text) => {
	let words = text.split(' ');
	words = words.map((word) => {
		let letters = word.split('');
		const firstLetter = letters[0].toUpperCase();
		const rest = letters.slice(1, letters.length);
		return `${firstLetter}${rest.join('').toLowerCase()}`;
	});
	return words.join(' ');
};

export const getPromoterName = (prom = null) =>
	prom ? capitalizeFirstLetter(`${prom.name} ${prom.lastname}`) : '';

// Convert object to array
export const obj2Array = (obj) => {
	let array = [];
	for (const name in obj) {
		array.push({
			name: name,
			value: obj[name],
		});
	}
};

// Get profile by id
export const getProfileID = (id) => {
	switch (id) {
		case 'ADM':
			return 'Administrador';
		case 'EXE':
			return 'Ejecutivo';
		case 'OPE':
			return 'Operador';
	}
};

// Get profile by description
export const getProfileDescription = (text) => {
	switch (text) {
		case 'Administrador':
			return 'ADM';
		case 'Ejecutivo':
			return 'EXE';
		case 'Operador':
			return 'OPE';
	}
};

// Get department by id
export const getDepartmentID = (id) => {
	switch (id) {
		case 'SYS':
			return 'Sistemas';
		case 'DIR':
			return 'Dirección';
		case 'OPE':
			return 'Operaciones';
	}
};

// Get department by description
export const getDepartmentDescription = (text) => {
	switch (text) {
		case 'Sistemas':
			return 'SYS';
		case 'Dirección':
			return 'DIR';
		case 'Operaciones':
			return 'OPE';
	}
};

// Get position by id
export const getPositionID = (id) => {
	switch (id) {
		case 'SYS':
			return 'Gerente de sistemas';
		case 'MAN':
			return 'Gerente';
		case 'EXE':
			return 'Ejecutivo';
	}
};

// Get position by description
export const getPositionDescription = (text) => {
	switch (text) {
		case 'Gerente de sistemas':
			return 'SYS';
		case 'Gerente':
			return 'MAN';
		case 'Ejecutivo':
			return 'EXE';
	}
};

// Check is users' domain email is from 'tec.mx'
export const isFromTec = (email) => email.includes('tec');

// Handle clean firebase documents as an array of objects
export const cleanDocs = (docs) => {
	let res = [];
	docs.forEach((doc) => {
		res.push({ uid: doc.id, ...doc.data() });
	});
	return res;
};

// Handle warning
export const handleWarning = (msg = null) => {
	toastSweet('warning', msg ? msg : 'Algo salió mal. Intenta de nuevo.');
};

// Handle error
export const handleError = (error, msg = null) => {
	console.log(error);
	toastSweet('error', msg ? msg : 'Algo salió mal. Intenta de nuevo.');
};

// Handle success
export const handleSuccess = (msg = null) => {
	toastSweet('success', msg ? msg : 'Algo salió mal. Intenta de nuevo.');
};

// Handle success login
export const handleSuccessLogin = (displayName) => {
	toastSweet(
		'success',
		`¡Bienvenido${displayName ? `, ${displayName}` : ''}!`
	);
};

// Handle success sent email
export const handleSentEmail = () => {
	Swal.fire({
		title: 'Revisa tu bandeja',
		text: 'Te hemos enviado el correo. Verifica tu bandeja de entrada principal o en correos no deseados.',
		icon: 'success',
		confirmButtonColor: bluePuebla,
	});
};

// Handle reset password
export const handleResetPassword = async () => {
	return await Swal.fire({
		title: 'Reestablecer contraseña',
		input: 'email',
		inputLabel: 'Te enviaremos un correo.',
		confirmButtonColor: bluePuebla,
		confirmButtonText: 'Enviar',
		inputPlaceholder: 'Escribe tu correo electrónico',
	});
};

// Handle delete user
export const handleDeleteUserModal = async () => {
	return await Swal.fire({
		title: '¡Precaución!',
		text: '¿Estás seguro de eliminar permanentemente al usuario del sistema?',
		confirmButtonColor: '#0157a7',
		icon: 'warning',
		showCancelButton: true,
		cancelButtonColor: '#d33',
		confirmButtonText: 'Sí, eliminar',
	});
};

// Handle delete catalog
export const handleDeleteCatalogModal = async (text) => {
	if (text === undefined)
		text = '';
	return await Swal.fire({
		title: '¡Precaución!',
		text: '¿Estás seguro de eliminar permanentemente ' + text + ' del sistema?',
		confirmButtonColor: '#0157a7',
		icon: 'warning',
		showCancelButton: true,
		cancelButtonColor: '#d33',
		confirmButtonText: 'Sí, eliminar',
	});
};

// Handle delete user
export const handleConfirmModal = async (
	title = null,
	text = null,
	icon = null,
	confirmButtonText = null
) => {
	return await Swal.fire({
		title: title,
		text: text,
		confirmButtonColor: '#143f6c',
		icon: icon,
		showCancelButton: true,
		cancelButtonColor: '#d33',
		confirmButtonText: confirmButtonText,
	});
};

// Handle pseudo delete
export const handlePseudoDelete = () => {
	toastSweet('success', '¡Usuario eliminado exitosamente!');
};

// Switch
export const Android12Switch = styled(Switch)(({ theme }) => ({
	padding: 8,
	'& .MuiSwitch-track': {
		borderRadius: 22 / 2,
		'&:before, &:after': {
			content: '""',
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			width: 16,
			height: 16,
		},
		'&:before': {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main)
			)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
			left: 12,
		},
		'&:after': {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main)
			)}" d="M19,13H5V11H19V13Z" /></svg>')`,
			right: 12,
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: 'none',
		width: 16,
		height: 16,
		margin: 2,
	},
}));