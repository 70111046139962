import CurrencyFormat from 'react-currency-format';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import React from 'react';

export function ReturnCommissionDashboard({
    money,
    totalReturn,
    totalSocialCost,
    totalISN,
    totalISR,
    totalIva,
    totalBalance,
    totalCommission,
    proveAmount,
    TextField,
    perceptionsTemp,
    setPerceptionsTemp,
    Number,
    updateApplicationPerceptions,
    applicationTemp,
    dispatch,
    invoiceVar,
    setInvoiceVar,
    updateApplicationInvoice,
}) {
    return (
        <Box
            sx={{
                flexGrow: 1,
                mt: 2,
            }}
        >
            <Grid
                container
                spacing={2}
                sx={{
                    mt: 2,
                }}
            >
                <Grid
                    item
                    xs={3}
                >
                    <ItemTexts
                        title='Total Retorno'
                        text={money(totalReturn)}
                    />
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    <ItemTexts
                        title='Total Costo Social '
                        text={money(totalSocialCost)}
                    />
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    <ItemTexts
                        title='Total ISN '
                        text={money(totalISN)}
                    />
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    <ItemTexts
                        title='Total ISR'
                        text={money(totalISR)}
                    />
                </Grid>

                <Grid
                    item
                    xs={3}
                >
                    <ItemTexts
                        title='Total IVA'
                        text={money(totalIva)}
                    />
                </Grid>

                <Grid
                    item
                    xs={3}
                >
                    <ItemTexts
                        title='Total Saldo por Esquema'
                        text={money(totalBalance)}
                    />
                </Grid>

                <Grid
                    item
                    xs={3}
                >
                    <ItemTexts
                        title='Total Comisión por Esquema'
                        text={money(totalCommission)}
                    />
                </Grid>

                <Grid
                    item
                    xs={3}
                >
                    <ItemTexts
                        title={
                            proveAmount > 1
                                ? 'Solicitado de más'
                                : proveAmount < -1
                                ? 'Solicitado de menos'
                                : 'Solicitud'
                        }
                        text={
                            proveAmount > 1
                                ? `${money(proveAmount)}`
                                : proveAmount < -1
                                ? `${money(proveAmount)}`
                                : `${money(proveAmount)}`
                        }
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <CurrencyFormat
                        fullWidth
                        customInput={TextField}
                        label='(Exclusivo Nominas) Total Percepciones' // type='number'
                        thousandSeparator={true}
                        prefix={'$'}
                        allowNegative={false}
                        value={perceptionsTemp}
                        onChange={({ target }) => {
                            setPerceptionsTemp(
                                Number(target.value.replace(/[^0-9.-]+/g, ''))
                            );
                        }}
                        onBlurCapture={(element) => {
                            // console.log(element);
                            updateApplicationPerceptions(
                                applicationTemp,
                                dispatch,
                                perceptionsTemp
                            );
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <CurrencyFormat
                        fullWidth
                        customInput={TextField}
                        label='Factura' // type='number'
                        thousandSeparator={true}
                        prefix={'$'}
                        allowNegative={false}
                        value={invoiceVar}
                        onChange={({ target }) => {
                            setInvoiceVar(
                                Number(target.value.replace(/[^0-9.-]+/g, ''))
                            );
                        }}
                        onBlurCapture={(element) => {
                            // console.log(element);
                            updateApplicationInvoice(
                                applicationTemp,
                                invoiceVar,
                                dispatch
                            );
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}


export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);

