import React, { useEffect } from 'react';
import {
    Alert,
    Button,
    IconButton,
    Tooltip,
    Stack,
    Typography,
    Switch,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CurrencyFormat from 'react-currency-format';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';

import { money } from '../helpers/money';
import { useDispatch, useSelector } from 'react-redux';
import {
    updatePromoters,
    updateOperatingExpenses,
    updateAvailableKV,
} from '../actions/requests';
import { NewPromotoria } from '../components/applications/NewPromotoria';
import { NewPayerAmount } from '../components/applications/NewPayerAmount';
import { deletePromoter, updatePromoter } from '../actions/promoter';
import { handleError, handleSuccess } from '../helpers/utils';
import { PayerDialogReturnPromoters } from '../components/applications/PayerDialogReturnPromoters';
import {
    getPromoters,
    handleUpdateFieldApp,
    setCurrentApplication
} from '../actions/applications';
import { saveApplication } from '../helpers/SaveApplication';

export const PromotoriaYAbono = ({ applicationTemp = {} }) => {
    const columns = [
        {
            field: 'col9',
            headerName: 'Acciones',
            width: 180,
            // Add actions to each case
            renderCell: ({ row }) => (
                <>
                    <Tooltip title='Nuevo fondeo'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='new-funding'
                            color='primary'
                            onClick={() => {
                                setCurrentRow(row);
                                setOpenModal2(true);
                            }}
                        >
                            <AttachMoneyIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Ver fondeos'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='view-fundings'
                            color='primary'
                            onClick={() => {
                                setCurrentRow(row);
                                setTempArr(row?.fundings);
                                setOpenPayerDialogReturn(true);
                            }}
                        >
                            <PriceChangeIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Editar'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='edit'
                            color='primary'
                            onClick={() => {
                                setCurrentRow(row);
                                setIsEdit(true);
                                setOpenModal(true);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Eliminar'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='delete'
                            color='primary'
                            onClick={() => {
                                handleDeletePromoter(row.id);

                                handleDelete2();
                                handleValue();
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
        {
            field: 'name',
            headerName: 'Nombre',
            width: 200,
            type: 'singleSelect',
        },
        {
            field: 'percentage',
            headerName: 'Porcentaje',
            width: 100,
            type: 'number',

            valueFormatter: (params) => {
                let percentage = params.value;
                return `${percentage}%`;
            },
        },
        {
            field: 'base_commission',
            headerName: 'Base',
            width: 180,
        },
        {
            field: 'commission',
            headerName: 'Comisión',
            width: 170,

            valueFormatter: (params) => {
                let commission = params.value;
                return `${money(commission)}`;
            },
        },
        {
            field: 'is_safeguard',
            headerName: 'En resguardo',
            width: 120,
            renderCell: ({ row }) => (
                <>
                    <Tooltip title='En resguardo'>
                        <Switch 
                            checked={row.is_safeguard}
                            onChange={(e) => {
                                handleSwitchPromoter(row, e.target.checked);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Tooltip>
                </>
            ),
        },
    ];

    const dispatch = useDispatch();
    const availableKV = useSelector((state) => state.request.availableKV);
    const objectOperativeExpenses = useSelector(
        (state) => state.request.operatingExpenses
    );
    const [tempArr, setTempArr] = React.useState([]);
    const [currentRow, setCurrentRow] = React.useState(null);
    const [isEdit, setIsEdit] = React.useState(false);
    const [openPayerDialogReturn, setOpenPayerDialogReturn] =
        React.useState(false);
    const [promotersTemp, setPromoterTemp] = React.useState([]);

    const handlePayerDialogReturn = () => {
        setOpenPayerDialogReturn(false);
        setCurrentRow(null);
    };

    // Update when component unmount
    useEffect(() => {
        return () => {
            dispatch(saveApplication());
        };
    },[]);

    /* Update Promoters table */
	const handleSwitchPromoter = ( currentRow, isSafeguardValue ) => {
		updatePromoter(currentRow?.id, { is_safeguard: isSafeguardValue })
			.then(({ data }) => {
				// Update an item of the table
				// Find the index of the item to edit
				let tempObj = { ...promoters };
				const indexData = promoters.promotersArray.findIndex((item) => item.id === data.id);
				
				const tmpData = promoters.promotersArray.map((item, index) => {
					if (index === indexData) {
						return {
							...item,
                            is_safeguard: isSafeguardValue,
						};
					}
					return item;
				});


				tempObj.promotersArray = tmpData;
				dispatch(updatePromoters(tempObj));
				handleSuccess('Registro actualizado correctamente');
				handleUpdateTotals(tmpData);
			})
			.catch((error) => {
				handleError(
					error,
					'Ocurrió un error al intentar actualizar un registro'
				);
			});
	};

    // Update redux data
    const handleUpdateTotals = (array) => {
        // Total commissions
        const totalCommissions = array.reduce(
            (sum, { commission }) => sum + commission,
            0
        );

        // Total amounts
        let totalAmounts = 0;
        array.forEach((item) => {
            totalAmounts += item.fundings.reduce(
                (sum, { amount }) => sum + amount,
                0
            );
        });

        // Set current promoters in Redux
        dispatch(
            updatePromoters({
                promotersArray: array,
                commissionTotal: totalCommissions,
                amountTotal: totalAmounts,
            })
        );
    };
    // All selectors
    const promoters = useSelector((state) => state.request.promoters);
    
    const { application = null } = useSelector((state) => state.request);

    // All states
    const handleCloseDialog = () => {
        setOpenModal(false);
        setCurrentRow(null);
    };
    const handleCloseDialog2 = () => {
        setOpenModal2(false);
        setCurrentRow(null);
    };
    const [openModal, setOpenModal] = React.useState(false);
    const [openModal2, setOpenModal2] = React.useState(false);
    const [change, setChange] = React.useState(false);
    const [change2, setChange2] = React.useState(false);
    const [deleteTemp, setDelete] = React.useState(false);

    const [expensePromoter, setExpensePromoter] = React.useState('');

    /* Payer modal handler */
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleDelete = () => {
        setChange(!change);
    };
    const handleValue = () => {
        setDelete(!deleteTemp);
    };

    const handleDelete2 = () => {
        setChange2(!change2);
    };

    // Delete a user in table
    const handleDeletePromoter = (id) => {
        let promoterCopy = { ...promoters };

        let newArr = promoters.promotersArray.filter(
            (element) => element.id != id
        );

        promoterCopy.promotersArray = newArr;

        deletePromoter(id)
            .then(() => {
                handleSuccess('Registro eliminado correctamente');
                handleUpdateTotals(newArr);
            })
            .catch((error) => {
                handleError(
                    error,
                    'Ocurrió un error al intentar eliminar el registro'
                );
            });
    };

    const expensesPromoters = (value) => {
        handleUpdateFieldApp(
            application,
            'gastos_promotoria',
            expensePromoter !== '' ? Number(expensePromoter) : 0
        ).then(({ data }) => {
            dispatch(
                setCurrentApplication({
                    ...data,
                    fundings: application.fundings,
                    promoter: application.promoter,
                    executive: application.executive,
                })
            );

            const tempObj2 = { ...objectOperativeExpenses };
            tempObj2.gastosPromotoria = Number(expensePromoter);
            dispatch(updateOperatingExpenses(tempObj2));

            handleSuccess('Gasto registrado');
        });
    };

    const handleAvailableKV = (value) => {
        let tempObj = { ...availableKV };
        tempObj.availableKV = value;
        dispatch(updateAvailableKV(tempObj));
    };

    

    useEffect(() => {
        setTimeout(() => {
            handleUpdateFieldApp(
                application,
                'total_promoter',
                promoters.commissionTotal
            ).then(({ data }) => {
                dispatch(
                    setCurrentApplication({
                        ...data,
                        fundings: application.fundings,
                        promoter: application.promoter,
                        executive: application.executive,
                        bankDetected: application.bankDetected,
                    })
                );
            });
        }, 50);
    }, [promoters.commissionTotal]);


    useEffect(() => {
        setExpensePromoter(application?.gastos_promotoria);

        getPromoters().then(({ data }) => {
            const namesArray = data.results.map(
                (element) => `${element.name} ${element.lastname}`
            );
            setPromoterTemp(namesArray);
        });
    }, []);

    return (
        <div>
            {/* Payer return dialog */}
            <PayerDialogReturnPromoters
                currentRow={currentRow}
                fundings={tempArr}
                show={openPayerDialogReturn}
                handleClose={handlePayerDialogReturn}
                updateTotals={handleUpdateTotals}
                sectionTitle='Promotoría'
            />
            <Stack
                direction='row'
                alignItems='flex-end'
                justifyContent='space-between'
                style={{
                    border: '1px solid white',
                    padding: '5px',
                    width: '100%',
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: '10px' }}
                    >
                        <Grid
                            item
                            xs={9}
                        >
                            <Typography
                                variant='h5'
                                gutterBottom
                            >
                                Promotoría
                            </Typography>
                        </Grid>
                        
                        <Grid
                            item
                            xs={3}
                        >
                            <Button
                                variant='contained'
                                style={{ backgroundColor: '#143f6c' }}
                                onClick={() => {
                                    setIsEdit(false);
                                    setOpenModal(true);
                                }}
                            >
                                Nueva Promotoría
                            </Button>
                        </Grid>
                        <Box sx={{ flexGrow: 1, ml: 2, mb: 4 }}>
                            <Grid
                                container
                                spacing={2}
                                sx={{ mt: 2 }}
                            >
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <ItemTexts
                                        title='Total Comisiones'
                                        text={money(promoters.commissionTotal)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <ItemTexts
                                        title='Total Importes'
                                        text={money(promoters.amountTotal)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <CurrencyFormat
                                        customInput={TextField}
                                        variant='standard'
                                        label='Gasto'
                                        // type='number'

                                        thousandSeparator={true}
                                        prefix={'$'}
                                        allowNegative={false}
                                        value={expensePromoter}
                                        onChange={({ target }) => {
                                            
                                            setExpensePromoter(
                                                Number(
                                                    target.value.replace(
                                                        /[^0-9.-]+/g,
                                                        ''
                                                    )
                                                )
                                            );
                                        }}
                                        onBlurCapture={() => {
                                            expensesPromoters();
                                        }}
                                    />
                                    
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    {application.total_available_KV < 1000 ? (
                                        <Chip
                                            label='Se esta pagando de más'
                                            color='warning'
                                            avatar={
                                                <WarningIcon
                                                    style={{ color: 'white' }}
                                                />
                                            }
                                        />
                                    ) : (
                                        <Chip
                                            label='Disponible correcto'
                                            color='success'
                                            avatar={
                                                <CheckCircleIcon
                                                    style={{ color: 'white' }}
                                                />
                                            }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                        {openModal ? 
                            <NewPromotoria
                                show={openModal}
                                handleClose={handleCloseDialog}
                                handleChange={handleDelete2}
                                handleUpdateTotals={handleUpdateTotals}
                                sectionTitle='Promotoría'
                                promotersList={promotersTemp}
                                isEdit={isEdit}
                                currentRow={currentRow}
                            />
                        : null}       
                        <NewPayerAmount
                            show={openModal2}
                            handleClose={handleCloseDialog2}
                            currentRow={currentRow}
                            handleChange={handleDelete2}
                            sectionTitle='Promotoría'
                        />
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            marginBottom: '10px',
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            {promoters.promotersArray?.length > 0 ? (
                                <DataGrid
                                    sx={{
                                        '& .MuiDataGrid-cell': {
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            padding: '10px',
                                        },
                                    }}
                                    style={{
                                        height: '600px',
                                        margin: '10px 0',
                                        // width: '500px',
                                    }}
                                    density='compact'
                                    getRowHeight={() => 'auto'}
                                    rows={promoters.promotersArray}
                                    columns={columns}
                                    // autoPageSize
                                    components={{ Toolbar: GridToolbar }}
                                    localeText={
                                        esES.components.MuiDataGrid.defaultProps
                                            .localeText
                                    }
                                />
                            ) : (
                                <Alert severity='info'>
                                    No hay ninguna fila
                                </Alert>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
            <hr />
        </div>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);
