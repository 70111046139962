import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import {
    Alert,
    Box,
    Button,
    DialogActions,
    Paper,
    Table,
    TableBody,
    TableFooter,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from '@mui/material';
import { useState, useEffect } from 'react';
import {
    getDepositsByApplicationID,
    getReturnsAndCommissionsByApplicationID,
} from '../../actions/applications';
import moment from 'moment';
import { money } from '../../helpers/money';

import {
    balanceToConsiderate,
    checkCompanyFunding,
    checkCostCompany,
    checkExpenseName,
    checkImportFunding,
} from '../../helpers/ApplicationDetailCalculus';

export const SafeguardsDialog = ({
    open,
    handleClose,
    applicationId = null,
    handleResult,
}) => {
    const [safeguards, setSafeguards] = useState([]);
    const [tempTotalSafeguards, setTotalSafeguards] = useState([]);

    useEffect(() => {
        if (applicationId) {
            getReturnsAndCommissionsByApplicationID(applicationId).then(
                ({ data }) => {
                    let filteredSafeguards = data.results.filter(
                        (element) => element.scheme == 'RESGUARDO'
                    );

                    if (data.count > 0) {
                        const formattedData = filteredSafeguards.map(
                            (item) => ({
                                ...item,
                                created_at: moment(item.created_at).format(
                                    'DD/MM/YYYY'
                                ),
                                total_safeguard: money(item.balance),
                                folioItem: item.key,
                            })
                        );
                        const totalSafeguards = filteredSafeguards?.reduce(
                            (accumulator, object) => {
                                return accumulator + Number(object.balance);
                            },
                            0
                        );
                        setTotalSafeguards(totalSafeguards);
                        setSafeguards(formattedData);
                    } else {
                        setSafeguards([]);
                    }
                }
            );
        }
    }, [applicationId]);

    return (
        <Dialog
            maxWidth='lg'
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            {/* Content */}
            <Box sx={{ flexGrow: 1 }}>
                <div style={{ padding: '20px' }}>
                    <Text
                        variant='h6'
                        text='Resguardos'
                    />

                    {safeguards?.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table
                                sx={{ width: '100%' }}
                                aria-label='simple table'
                            >
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align='center'>
                                            #
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Fecha
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Folio
                                        </StyledTableCell>

                                        <StyledTableCell align='center'>
                                            importe de Resguardo
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {safeguards?.map((safeguard, index) => (
                                        <StyledTableRow
                                            key={safeguard.key}
                                            sx={styleLabel}
                                        >
                                            <ColumnItem
                                                align='center'
                                                text={index + 1}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={safeguard.created_at}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={safeguard.folioItem}
                                            />

                                            <ColumnItem
                                                align='center'
                                                text={safeguard.total_safeguard}
                                            />
                                        </StyledTableRow>
                                    ))}
                                    <StyledTableRow>
                                        <StyledTableCell align='center'></StyledTableCell>
                                        <StyledTableCell align='center'></StyledTableCell>

                                        <StyledTableCell align='center'>
                                            Total
                                        </StyledTableCell>

                                        <StyledTableCell align='center'>
                                            {money(tempTotalSafeguards)}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                                
                            </Table>
                        </TableContainer>
                    ) : (
                        <Alert severity='info'>No hay Resguardos</Alert>
                    )}
                </div>
            </Box>
            <DialogActions>
                <Button onClick={() => handleClose()}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

const ColumnItem = ({ align, text }) => (
    <StyledTableCell align={align}>{text}</StyledTableCell>
);

const styleLabel = {
    '&:last-child td, &:last-child th': { border: 0 },
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction='up'
            ref={ref}
            {...props}
        />
    );
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#01579b',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const Text = ({ variant, text, bold = true }) => (
    <Typography
        variant={variant}
        gutterBottom
    >
        {bold ? <b>{text}</b> : text}
    </Typography>
);
