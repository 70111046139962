import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Grid, IconButton, Typography } from '@mui/material';
import { money } from '../../helpers/money';
import { useSelector, useDispatch } from 'react-redux';
import { deleteFunding } from '../../actions/funding';
import {
    handleError,
    handleSuccess,
    handleConfirmModal,
} from '../../helpers/utils';
import { getPromoterInfo } from '../../actions/promoter';
import { getDebtInfo } from '../../actions/debt';
import { getAdministrativeCostInfo } from '../../actions/administrativeCosts';
import { getAdministrativeCostWithdrawInfo } from '../../actions/administrativeCostsWithdraw';


const arrBases = [
    { label: 'TOTAL DEPOSITO' },
    { label: 'SUBTOTAL DEPOSITO' },
    { label: 'RETORNO' },
    { label: 'COMISION TOTAL' },
    { label: 'COMISION - BANCARIA' },
    { label: 'COMISION - BANCARIA - COSTO EFECTIVO CLIENTE' },
    { label: 'COMISION - COSTO EFECTIVO CLIENTE' },
    { label: 'COMISION - COSTOS OPERATIVOS' },
    { label: 'COMISION - COSTO SC' },
    { label: 'COMISION - 2% EDEN RED' },
    { label: 'COMISION - 2% EDEN RED - BANCARIA' },
    { label: 'COMISION - 2% EDEN RED - COM. CONTACTO - BANCARIA' },
    { label: 'COMISION - PROMOTORIA 1 - BANCARIA' },
    { label: 'COMISION - PROMOTORIA 2 - BANCARIA' },
    { label: 'COMISION - PROMOTORIA 3 - BANCARIA' },
    { label: 'COMISION - COSTO SC - PROMOTORIA 1' },
    { label: 'RETORNO + 10% SUBTOTAL' },
];

// Error message
const errorMsg =
    'Ocurrió un error al intentar eliminar el fondeo. Intenta más tarde.';

export const PayerDialogReturnPromoters = ({
    currentRow = null, // Current return and commission register
    fundings = [],
    show = false,
    handleClose = null,
    updateTotals = null,
    sectionTitle = '',
}) => {
    // Dispatch
    const dispatch = useDispatch();
    // Redux state

    

    const { promotersArray: rows = [] } = useSelector(
        (state) => state.request.promoters
    );
    const tempBanks = useSelector((state) => state.catalogs.banks);

    const { creditArray: rows_credit = [] } = useSelector(
        (state) => state.request.creditToDebt
    );
    const { administrativeCostsArray: rows_admin = [] } = useSelector(
        (state) => state.request.administrativeCosts
    );

    const { administrativeCostsWithdrawArray: rows_admin_withdraw = [] } =
        useSelector((state) => state.request.administrativeCostsWithdraw);

    // Handle delete funding
    const handleDeleteFunding = (id) => {
        console.log(id);
        // Perform action delete
        handleConfirmModal(
            'Precaución',
            '¿Seguro que deseas borrar el fondeo?',
            'warning',
            'Sí, borrar'
        ).then(({ isConfirmed }) => {
            if (isConfirmed) {
                deleteFundingAction(id);
            }
        });
    };

    // Delete funding from DB and update state
    const deleteFundingAction = async (id) => {
        try {
            // Delete funding (DB)
            await deleteFunding(id);

            // Get updated register (DB)
            let updatedRegister = null;
            let updatedRows = [];

            if (sectionTitle === 'Promotoría') {
                updatedRegister = (await getPromoterInfo(currentRow?.id)).data;
                // Update specific row
                updatedRows = rows.map((row) =>
                    row?.id === currentRow?.id ? updatedRegister : row
                );
            } else if (sectionTitle === 'Abono a Deuda') {
                updatedRegister = (await getDebtInfo(currentRow?.id)).data;
                // Update specific row
                updatedRows = rows_credit.map((row) =>
                    row?.id === currentRow?.id ? updatedRegister : row
                );
            } else if (sectionTitle === 'Gastos Administrativos') {
                updatedRegister = (
                    await getAdministrativeCostInfo(currentRow?.id)
                ).data;
                // Update specific row
                updatedRows = rows_admin.map((row) =>
                    row?.id === currentRow?.id ? updatedRegister : row
                );
            } else if (sectionTitle === 'Gastos Administrativos Retiro') {
                updatedRegister = (
                    await getAdministrativeCostWithdrawInfo(currentRow?.id)
                ).data;

                // Update specific row
                updatedRows = rows_admin_withdraw.map((row) =>
                    row?.id === currentRow?.id ? updatedRegister : row
                );
            }

            console.log('updated rows', updatedRows);
            // Update totals
            updateTotals(updatedRows);

            // Feedback success
            handleSuccess('Registro eliminado correctamente');

            // Close modal
            handleClose();
        } catch (error) {
            // Feedback error
            handleError(error, errorMsg);
        }
    };

    const searchBank = (fundingTemp) => {
        // While loading all banks names renders loading

        //if the names are not loaded yet, render a loaded string
        if (
            typeof tempBanks?.find((item) => item?.id === fundingTemp)?.name ===
            'undefined'
        ) {
            return 'Cargando...';
        }
        //else return the name
        return tempBanks?.find((item) => item?.id === fundingTemp)?.name;
    };

    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={show}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            {/* Title */}
            <DialogTitle>Información Fondeo</DialogTitle>

            {/* Content */}
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    style={{
                        width: '100%',
                        marginBottom: '10px',
                    }}
                >
                    {fundings.length > 0 ? (
                        fundings.map((funding) => (
                            <React.Fragment key={funding?.id}>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <ItemTexts
                                        title='Pagadora'
                                        text={funding.payer}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                >
                                    <ItemTexts
                                        title='Banco'
                                        text={searchBank(funding?.bank)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <ItemTexts
                                        title='Importe'
                                        text={money(funding.amount)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                >
                                    <IconButton
                                        style={{ color: '#143f6c' }}
                                        aria-label='delete'
                                        color='primary'
                                        onClick={() => {
                                            handleDeleteFunding(funding?.id);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </React.Fragment>
                        ))
                    ) : (
                        <Grid
                            item
                            xs={12}
                        >
                            <Alert severity='info'>
                                No hay ningun elemento
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color='primary'
                    onClick={handleClose}
                >
                    Ok
                </Button>
                {/* <Button color='error' onClick={handleClose}>
					Cancelar
				</Button> */}
                {/* <Button
                    onClick={() => {
                        setPayerAmountValues();
                        handleChange();
                        handleClose();
                        setValuesZero();
                    }}
                    autoFocus
                >
                    Crear
                </Button> */}
            </DialogActions>
        </Dialog>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);
