import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Grid, TextField, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CurrencyInput from 'react-currency-input-field';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const areasNames = ['Area 1', 'Area 2', 'Area 3', 'Area 4', 'Area 5'];

export const CustomCurrencyInput = ({ label, value, onValueChange, ...props }) => {
	return (
		<TextField
			label={label}
			variant="outlined"
			fullWidth
			InputProps={{
				inputComponent: CurrencyInput,
				inputProps: {
					prefix: '$',
					allowNegativeValue: false,
					onValueChange: (value, name, values) => {
						onValueChange(value);
					}
				},
			}}
			value={value}
			{...props}
		/>
	);
};

export const MultipleSelectCheckmarks = ({ state, setState }) => {
	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		setState(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value?.split(',') : value
		);
	};

	return (
		<div>
			<FormControl sx={{ m: 1, width: '100%' }}>
				<InputLabel id='demo-multiple-checkbox-label'>Áreas</InputLabel>
				<Select
					labelId='demo-multiple-checkbox-label'
					id='demo-multiple-checkbox'
					multiple
					value={state}
					onChange={handleChange}
					input={<OutlinedInput label='Áreas' />}
					renderValue={(selected) => selected?.join(', ')}
					MenuProps={MenuProps}
				>
					{areasNames?.map((name) => (
						<MenuItem key={name} value={name}>
							<Checkbox checked={state?.indexOf(name) > -1} />
							<ListItemText primary={name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

// Icon component
export const Icon = ({ name, sidebar = true }) => (
	<i
		className={`bi bi-${name}`}
		style={sidebar ? { marginRight: '10px' } : { margin: '0 -7px' }}
	/>
);

// Search Bar
export const SearchBar = ({ searchValue, setSearchValue, handleSearch }) => {
	return (
		<Grid container spacing={2} direction='row'>
			<Grid item sm={8} md={8} lg={4}>
				<TextField
					id='search-field'
					type='text'
					placeholder='Buscar'
					variant='outlined'
					size='small'
					fullWidth
					onChange={(e) => setSearchValue(e.target.value)}
					onKeyDown={(ev) => {
						if (ev.key === 'Enter') {
							handleSearch(searchValue);
						}
					}}
					value={searchValue}
					inputProps={{ maxLength: 255 }}
				/>
			</Grid>
			<Grid item sm={4} md={4} lg={2}>
				<Button
					variant='outlined'
					startIcon={<SearchIcon />}
					onClick={() => {
						handleSearch(searchValue);
					}}
				>
					Buscar
				</Button>
			</Grid>
		</Grid>
	);
};

/*
	Recives:
	const [searchValues, setSearchValues] = useState([
		{key: 'key', value: '', placeholder: 'Buscar por Clave'},
		{key: 'client_name', value: '', placeholder: 'Buscar por Cliente'},
		...
	]);
*/
// Multi Search Bar
export const MultiSearchBar = ({
	searchValues = [],
	setSearchValues,
	handleSearch,
}) => {
	return (
		<Grid container spacing={1} direction='row'>
			{searchValues
				? searchValues.map((obj, index) => (
						<Grid item key={index} sm={4} md={4} lg={2}>
							<TextField
								id='search-field'
								type='text'
								placeholder={obj.placeholder}
								variant='outlined'
								size='small'
								fullWidth
								onChange={(e) => {
									const updatedValues = [...searchValues];
									obj.value = e.target.value;
									updatedValues[index] = obj;
									setSearchValues(updatedValues);
								}}
								onKeyDown={(ev) => {
									if (ev.key === 'Enter') {
										handleSearch(searchValues);
									}
								}}
								value={obj.value}
								inputProps={{ maxLength: 255 }}
							/>
						</Grid>
				  ))
				: null}
			<Grid item sm={4} md={4} lg={2}>
				<Button
					variant='outlined'
					startIcon={<SearchIcon />}
					onClick={() => {
						handleSearch(searchValues);
					}}
				>
					Buscar
				</Button>
			</Grid>
		</Grid>
	);
};

// Date Range with button
// ranges param must be an object thant contains: startDate, setStartDate, endDate, setEndDate
export const DateRangeWithButton = ({ ranges, handleGetResults }) => {
	const SelectComponent = (params, label) => (
		<TextField
			fullWidth
			required
			margin='normal'
			{...params}
			label={label}
		/>
	);

	const gridButtonStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	};

	return (
		<>
			{/* Start Date */}
			<Grid item xs={4}>
				<DesktopDatePicker
					fullWidth
					label='Desde'
					inputFormat='dd/MM/yyyy'
					value={ranges.startDate}
					onChange={(val) => {
						ranges.setStartDate(val);
					}}
					renderInput={(params) => SelectComponent(params, 'Desde')}
				/>
			</Grid>
			{/* End Date */}
			<Grid item xs={4}>
				<DesktopDatePicker
					fullWidth
					label='Hasta'
					inputFormat='dd/MM/yyyy'
					value={ranges.endDate}
					onChange={(val) => {
						ranges.setEndDate(val);
					}}
					renderInput={(params) => SelectComponent(params, 'Hasta')}
				/>
			</Grid>
			<Grid item xs={2} style={gridButtonStyle}>
				<Button
					variant='contained'
					style={{ backgroundColor: '#143f6c' }}
					onClick={handleGetResults}
				>
					Obtener
				</Button>
			</Grid>
		</>
	);
};
