import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { NavBar } from '../components/dashboard/navbar/NavBar';
import { SideBar } from '../components/dashboard/sidebar/SideBar';

export const PrivateRoute = ({ isAuthenticated }) => {
	return isAuthenticated ? (
		<div style={dashboardStyle}>
			<SideBar />
			<div style={contentStyle}>
				<NavBar />
				<Outlet />
			</div>
		</div>
	) : (
		<Navigate to='/auth/inicio-de-sesion' />
	);
};

const contentStyle = {
	width: '100%',
};

const dashboardStyle = {
	display: 'flex',
	flexDirection: 'row',
};
