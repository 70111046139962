import React, { useState } from 'react';

import { TextField, Button, Typography, Grid } from '@mui/material';

import { handleError, handleSuccess, handleWarning } from '../../helpers/utils';
import { registerTransfer, updateTransfer } from '../../actions/catalogs';

// Default configuration for a new form
const defaultConfig = {
	title: 'Añadir Traspaso',
	handleClose: false,
	addOneItem: false,
	updateOneItem: false,
};

// Create/Edit Transfer Form
export const NewTransferForm = ({ data = null, config = defaultConfig }) => {
	// Desestructure config property
	const {
		title = 'Añadir Traspaso',
		handleClose,
		addOneItem,
		updateOneItem,
	} = config;

	const [description, setDescription] = data
		? useState(data.description)
		: useState('');
	const [concept, setConcept] = data ? useState(data.concept) : useState('');
	const [cost, setCost] = data ? useState(data.cost) : useState('');
	const [expense, setExpense] = data ? useState(data.expense) : useState('');

	// Function to create a Transfer
	function handleCreate(value) {
		// Validate that a concept exits
		if (value.concept !== '' && value.cost !== '' && value.expense !== '') {
			registerTransfer(value)
				.then(({ data }) => {
					handleSuccess('Traspaso creado correctamente');
					addOneItem(data);
					handleClose();
				})
				.catch((error) => {
					handleError(error, 'No se creó el traspaso');
				});
		} else {
			handleWarning('Faltan Campos');
		}
	}

	// Function to update a Transfer
	function handleUpdate(value) {
		// Validate that a concept exits
		if (value.concept !== '' && value.cost !== '' && value.expense !== '') {
			updateTransfer(data.id, value)
				.then(({ data }) => {
					handleSuccess('Traspaso actualizado correctamente');
					updateOneItem(data);
					handleClose();
				})
				.catch((error) => {
					handleError(error, 'No se actualizó el traspaso');
				});
		} else {
			handleWarning('Faltan Campos');
		}
	}

	return (
		<div
			style={{
				padding: '20px',
				margin: 6,
			}}
		>
			<Typography variant='h4' gutterBottom>
				{title}
			</Typography>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={4}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Concepto'
						onChange={(e) => setConcept(e.target.value)}
						value={concept}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Costo'
						onChange={(e) => setCost(e.target.value)}
						value={cost}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Gasto'
						onChange={(e) => setExpense(e.target.value)}
						value={expense}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Descripción'
						onChange={(e) => setDescription(e.target.value)}
						value={description}
						multiline
						rows={4}
						inputProps={{ maxLength: 500 }}
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<Button
						variant='contained'
						fullWidth
						color='error'
						sx={{ mt: 2 }}
						onClick={handleClose}
					>
						Cancelar
					</Button>
				</Grid>
				<Grid item xs={6}>
					{data ? (
						<Button
							variant='contained'
							fullWidth
							style={{ backgroundColor: '#143f6c' }}
							sx={{ mt: 2 }}
							onClick={() => {
								handleUpdate({
									concept: concept,
									cost: cost,
									expense: expense,
									description: description,
								});
							}}
						>
							Actualizar
						</Button>
					) : (
						<Button
							variant='contained'
							fullWidth
							style={{ backgroundColor: '#143f6c' }}
							sx={{ mt: 2 }}
							onClick={() => {
								handleCreate({
									concept: concept,
									cost: cost,
									expense: expense,
									description: description,
								});
							}}
						>
							Registrar
						</Button>
					)}
				</Grid>
			</Grid>
		</div>
	);
};
