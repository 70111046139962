import React, { useEffect, useState } from 'react';
import {
	Alert,
	Button,
	IconButton,
	Tooltip,
	Stack,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {
	capitalizeFirstLetter,
	handleDeleteUserModal,
	handleError,
	handleSuccess,
} from '../../helpers/utils';
import { NewApplicationForm } from '../applications/NewApplicationForm';
import { useDispatch } from 'react-redux';
import { formatDateTimeMX } from '../../helpers/hours';
import {
	deleteApplication,
	deleteApplicationFake,
	getApplication,
	getApplicationInfo,
	getPaginatedApplications,
	getPromoters,
} from '../../actions/applications';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { setCurrentTab } from '../../actions/requests';
import { money } from '../../helpers/money';
import { SafeguardsDialog } from '../results/SafeguardsDialog';
import { MultiSearchBar, SearchBar } from '../utils/UtilsComponent';
import { PDFFromTable } from './PDFFromTable';

// Users table
export const ApplicationsTable = () => {
	// Hook for navigation
	const navigate = useNavigate();

	// Dispatch
	const dispatch = useDispatch();

	// Promoters
	const [promoters, setPromoters] = useState([]);

	// Rows
	const [rows, setRows] = useState([]);

	// Current user id
	const [currentApplication, setCurrentApplication] = useState(null);
	const [editOrCreate, setEditOrCreate] = useState(null);
	// Off Canvas
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleOpen = () => setShow(true);
	const [localPage, setLocalPage] = useState(1);
	const [preventLoad, setPreventLoad] = useState(false);
	const [numberRows, setNumberRows] = useState(0);

	// Open safeguards dialog
	const [openSafeguardsDialog, setopenSafeguardsDialog] = useState(false);
	const handleopenSafeguardsDialog = () => setopenSafeguardsDialog(true);
	const handleCloseSafeguardsDialog = () => setopenSafeguardsDialog(false);

	// safe current id
	const [currentApplicationId, setCurrentApplicationId] = useState(null);

	// Search
	const [searchValues, setSearchValues] = useState([
		{ key: 'key', value: '', placeholder: 'Buscar por Clave' },
		{ key: 'client_name', value: '', placeholder: 'Buscar por Cliente' },
	]);
	const [isSearchPage, setIsSearchPage] = useState(false);

	const checkIfExistPromoter = (item) => {
		if (item.folio === 'SOLICITUD DE RETIRO') {
			return '-';
		} else {
			return capitalizeFirstLetter(
				`${item?.promoter?.name} ${item?.promoter?.lastname}`
			);
		}
	};

	const checkIfClientExist = (item) => {
		if (item.folio === 'SOLICITUD DE RETIRO') {
			return '-';
		} else {
			return item?.client?.name;
		}
	};

	// paginateRows listen to the page number in order to call api paginated object
	const paginateRows = async () => {
		const element = await getPaginatedApplications(localPage);
		setNumberRows(element.data.count);
		if (element.data.results.length === 0) return [];

		const res = element.data.results.map((item) => {
			return {
				id: item.id,
				keyCol: item.key,
				promoterCol: checkIfExistPromoter(item),
				folio: item.folio,
				executiveCol: `${item.executive.first_name} ${item.executive.last_name}`,
				updatedCol: formatDateTimeMX(item.updated_at),
				createdCol: formatDateTimeMX(item.created_datetime),
				availableCol: money(item.total_available_KV),
				depositsCol: money(item.total_imports),
				commissionSafeguard: item.commission_safeguard,
				client: checkIfClientExist(item),
				isFundingDone:
					item.toggle_fundings == false ? 'No Fondeado' : 'Fondeado',
				total_safeguards: item.id,
			};
		});

		setRows(res);
	};

	// Listen to the page number to call paginateRows
	useEffect(() => {
		// Generate rows
		if (preventLoad) paginateRows();
	}, [localPage]);

	useEffect(() => {
		// Generate rows
		paginateRows();

		// Get promoters
		getPromoters().then(({ data }) => {
			setPromoters(data.results);
		});

		// Reset tab index
		localStorage.setItem('currentTab', 0);
		dispatch(setCurrentTab(0));
		setPreventLoad(true);
	}, []);

	// Handle delete user with modal confirmation
	const handleDeleteApplication = (id) => {
		handleDeleteUserModal()
			.then(({ isConfirmed }) => {
				if (isConfirmed) {
					deleteApplication(id)
						.then(() => {
							handleSuccess('Solicitud eliminado correctamente.');
							paginateRows();
						})
						.catch((error) => {
							handleError(
								error,
								'No se pudo eliminar la solicitud.'
							);
						});
				}
			})
			.catch((error) => handleError(error));
	};

	// Function to Filter the catalogs
	const handleSearch = async (values, searchPage = 1) => {
		// Validate if values is an empty string and perform the search
		if (values[0].value === '' && values[1].value === '') {
			setLocalPage(1);
			paginateRows();
			setIsSearchPage(false);
		} else {
			setIsSearchPage(true);
			const query = `?ordering=-creating_at${
				values[0].value !== ''
					? `&key__contains=${values[0].value}`
					: ''
			}
			${
				values[1].value !== '' ? `&client_name=${values[1].value}` : ''
			}&page=${searchPage}&count=12`;

			const element = await getApplication(query);
			setNumberRows(element.data.count);

			const res = element.data.results.map((item) => {
				return {
					id: item.id,
					keyCol: item.key,
					promoterCol: checkIfExistPromoter(item),
					folio: item.folio,
					executiveCol: `${item.executive.first_name} ${item.executive.last_name}`,
					updatedCol: formatDateTimeMX(item.updated_at),
					createdCol: formatDateTimeMX(item.created_datetime),
					availableCol: money(item.total_available_KV),
					depositsCol: money(item.total_imports),
					commissionSafeguard: item.commission_safeguard,
					client: checkIfClientExist(item),
					isFundingDone:
						item.toggle_fundings == false
							? 'No Fondeado'
							: 'Fondeado',
					total_safeguards: item.id,
				};
			});

			setRows(res);
		}
	};

	// Define columns
	const columns = [
		{
			field: 'col7',
			headerName: 'Acciones',
			width: 150,
			// Add actions to each case
			renderCell: ({ row }) => (
				<>
					{row.commissionSafeguard
					?
						<Tooltip title='Obtener PDF'>
							<IconButton
								style={{ color: '#143f6c' }}
								aria-label='profile'
								color='primary'
								onClick={() => {
									PDFFromTable(row?.id)
								}}
							>
								<PictureAsPdfIcon />
							</IconButton>
						</Tooltip>
					:
						<Tooltip title='Ver detalle'>
							<IconButton
								style={{ color: '#143f6c' }}
								aria-label='profile'
								color='primary'
								onClick={() => {
									const path = `/dashboard/application/${row.id}`;
									navigate(path);
									localStorage.setItem('lastPathKv', path);
								}}
							>
								<InfoIcon />
							</IconButton>
						</Tooltip>
					}
					<Tooltip title='Editar'>
						<IconButton
							style={{ color: '#143f6c' }}
							aria-label='edit'
							color='primary'
							onClick={() => {
								getApplicationInfo(row.id).then(({ data }) => {
									setEditOrCreate('Edit');
									setCurrentApplication(data);
									handleOpen();
								});
							}}
						>
							<EditIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title='Eliminar'>
						<IconButton
							style={{ color: '#143f6c' }}
							aria-label='delete'
							color='primary'
							onClick={() => handleDeleteApplication(row.id)}
						>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</>
			),
		},
		{ field: 'keyCol', headerName: 'Clave', width: 100 },
		{ field: 'client', headerName: 'Cliente', width: 210 },
		{
			field: 'total_safeguards',
			headerName: 'Resguardos',

			width: 200,
			renderCell: ({ row }) => (
				<Tooltip title='Total de los depósitos'>
					<div style={rowDialogStyle}>
						<Button
							variant='text'
							style={buttonDialogStyle}
							onClick={() => {
								setCurrentApplicationId(row.id);
								handleopenSafeguardsDialog();
							}}
						>
							Ver
						</Button>
					</div>
				</Tooltip>
			),
		},
		{ field: 'depositsCol', headerName: 'Total Depositos', width: 170 },
		{ field: 'createdCol', headerName: 'Creado', width: 170 },
		{ field: 'folio', headerName: 'Folio', width: 170 },
		{ field: 'promoterCol', headerName: 'Promotor', width: 210 },
		{ field: 'availableCol', headerName: 'Disponible KV', width: 170 },
		{ field: 'executiveCol', headerName: 'Ejecutivo', width: 210 },
		{
			field: 'isFundingDone',
			headerName: 'Fondeado / No fondeado',
			width: 170,
		},
		{ field: 'updatedCol', headerName: 'Actualizado', width: 170 },
	];

	return (
		<div
			style={{
				padding: '20px',
			}}
		>
			<SafeguardsDialog
				open={openSafeguardsDialog}
				handleClose={handleCloseSafeguardsDialog}
				applicationId={currentApplicationId}
			/>
			<Stack
				direction='row'
				alignItems='flex-end'
				justifyContent='space-between'
				style={{
					border: '1px solid white',
					padding: '20px 20px 0 0',
					margin: '0 0 40px 0',
					width: '100%',
				}}
			>
				<Typography variant='h4' gutterBottom>
					Solicitud de fondeo
				</Typography>
				<Button
					variant='contained'
					style={{ backgroundColor: '#143f6c' }}
					onClick={() => {
						setEditOrCreate('Create');
						setCurrentApplication(null);
						handleOpen();
					}}
				>
					Nueva solicitud
				</Button>
			</Stack>
			<MultiSearchBar
				searchValues={searchValues}
				setSearchValues={setSearchValues}
				handleSearch={handleSearch}
			/>
			<hr />
			<NewApplicationForm
				data={currentApplication}
				editOrCreate={editOrCreate}
				setPromoter={setCurrentApplication}
				promoters={promoters}
				handleClose={handleClose}
				refresh={paginateRows}
				show={show}
			/>
			{/* Datagrid table */}
			{rows.length > 0 ? (
				<DataGrid
					autoHeight
					rows={rows}
					columns={columns}
					rowCount={numberRows}
					rowsPerPageOptions={[12]}
					components={{ Toolbar: GridToolbar }}
					localeText={
						esES.components.MuiDataGrid.defaultProps.localeText
					}
					pagination
					page={localPage - 1}
					pageSize={12}
					paginationMode='server'
					onPageChange={(newPage) => {
						setLocalPage(newPage + 1);
					}}
				/>
			) : (
				<Alert severity='info'>No hay ninguna solicitud</Alert>
			)}
		</div>
	);
};

const rowDialogStyle = {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	margin: '0 auto',
};

const stackStyle = {
	padding: '20px 20px 0 0',
	margin: '0 0 40px 0',
	width: '100%',
};

const divStyle = {
	padding: '20px',
};

const gridStyle = {
	width: '70%',
	display: 'flex',
	justifyContent: 'flex-end',
};

const gridButtonStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
};

const datagridTableStyle = { margin: '20px 0' };

const columnStyleCell = {
	headerClassName: 'column-header-pilu',
	headerAlign: 'center',
};

const Cell = ({ subtitle, text = '', lastBreaks = true }) => (
	<>
		<b>{subtitle}:</b>
		<br />
		{text ? (text === 'No lleva' ? '' : text) : 'No se asignó'}
		{lastBreaks && <br />}
	</>
);

const buttonDialogStyle = {
	// backgroundColor: '#143f6c',
	margin: '0 0 0 10px',
};
