import React, { useEffect, useState } from 'react';
import {
	Alert,
	Button,
	Tooltip,
	Stack,
	Typography,
	Grid,
	TextField,
	IconButton,
    CircularProgress,
} from '@mui/material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { money } from '../../helpers/hours';
import { getExcel, getResults } from '../../actions/applications';
import { LocalizationProvider } from '@mui/x-date-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import { getStateByCode } from '../../helpers/lists';
import { DepositsDialog } from './DepostisDialog';
import { ReturnsAndComissionsDialog } from './ReturnsAndComissionsDialog';
import { DateRangeWithButton } from '../utils/UtilsComponent';
import DownloadIcon from '@mui/icons-material/Download';
import { Android12Switch, handleError, handleSuccess } from '../../helpers/utils';
import { deleteCommissionSafeguard, registerCommissionSafeguard } from '../../actions/returnAndCommissions';
import { useSelector } from 'react-redux';

const checkImportFunding = (item) => {
	if (item?.company_type == 'KV') {
		return '-';
	} else if (item?.company_type == 'KV + Grupo') {
		return '-';
	} else if (item?.company_type == 'Grupo') {
		return formatterCurrency(item?.import_funding);
	}
};

// Results table
export const ResultsTable = () => {
	// Date range state
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const ranges = { startDate, setStartDate, endDate, setEndDate };
    const { id: userId } = useSelector((state) => state.user);

	// Rows state
	const [rows, setRows] = useState(null);

	// Current application
	const [currentApplicationId, setCurrentApplicationId] = useState(null);
	const [numberApplications, setNumberApplications] = useState(null);
	const [numberFundingApplications, setNumberFundingApplications] =
		useState(null);
	const [totalAvailableKV, setTotalAvailableKV] = useState(null);
	const [totalDeposits, setTotalDeposits] = useState(null);
	const [totalWithdraw, setTotalWithdraw] = useState(null);

	// Deposits dialog
	const [openDepositsDialog, setOpenDepositsDialog] = useState(false);
	const handleOpenDepositsDialog = () => setOpenDepositsDialog(true);
	const handleCloseDepositsDialog = () => setOpenDepositsDialog(false);

	// Returns and commissions dialog
	const [openReturnsAndComissionsDialog, setOpenReturnsAndComissionsDialog] =
		useState(false);
	const handleOpenReturmsAndCommissionsDialog = () =>
		setOpenReturnsAndComissionsDialog(true);
	const handleCloseReturmsAndCommissionsDialog = () =>
		setOpenReturnsAndComissionsDialog(false);

	// Generate rows
	const generateRows = async (formattedStartDate, formattedEndDate) => {
        setLoading(true);
		// Get results by range of date from API
		const { data } = await getResults(formattedStartDate, formattedEndDate);

		// If results are empty set an empty array
		if (data?.results?.length === 0 || !data?.ok) setRows([]);

		const genericPromoter = {
			name: '-',
			commission: '-',
			percentage: '-',
			base_commission: '-',
		};

		// Map data results
		const res = data?.results?.map((item) => {
			const originalPromoterName = item.promoter.name;
			const originalPromoter = item.promoters.find(
				({ name }) => name === originalPromoterName
			) || { name: originalPromoterName };

			originalPromoter.commission = money(
				originalPromoter?.commission || 0
			);
			originalPromoter.percentage = `${
				originalPromoter?.percentage || 0
			}%`;
			originalPromoter.base_commission = `${
				originalPromoter?.base_commission || '-'
			}`;
			const promoters = item.promoters
				.filter(({ name }) => name !== originalPromoterName)
				.map((item) => ({
					...item,
					commission: money(item.commission),
				}));
			return {
				...item,
				promoter: originalPromoter,
				promoters,
				subpromoter1:
					promoters[0] !== undefined ? promoters[0] : genericPromoter,
				subpromoter2:
					promoters[1] !== undefined ? promoters[1] : genericPromoter,
				subpromoter3:
					promoters[2] !== undefined ? promoters[2] : genericPromoter,
				site: getStateByCode(item.site),
				total_imports: money(item.total_imports),
				total_return: money(item.total_return),
				total_commission: money(item.total_commission),
				total_promoter: money(item.total_promoter),
				total_commission_scheme: money(item.total_commission_scheme),
				total_operative_expense: money(item.total_operative_expense),
				commision_paid: money(item.commision_paid),
				comission_kv: money(item.comission_kv),
			};
		});

		const numberOfFundedApplications = res?.reduce(function (acc, obj) {
			if (obj.toggle_fundings == true) {
				return acc + 1;
			} else {
				return acc;
			}
		}, 0);

		const totalWithdraw = res?.reduce(function (acc, obj) {
			if (obj.folio == 'SOLICITUD DE RETIRO') {
				return acc + Number(obj.total_administrative_withdraw);
			} else {
				return Number(acc);
			}
		}, 0);

		const totalAvailableKVTemp = res?.reduce(function (acc, obj) {
            if (obj.commission_safeguard && obj.commission_safeguard.id) {
                return acc;
            } else {
                return acc + Number(obj.comission_kv.replace(/[^0-9.-]+/g, ''));
            }
        }, 0);

		const totalDeposits = res?.reduce(function (acc, obj) {
			return acc + Number(obj.total_imports.replace(/[^0-9.-]+/g, ''));
		}, 0);

		setNumberApplications(res?.length);
		setNumberFundingApplications(numberOfFundedApplications);
		setTotalAvailableKV(totalAvailableKVTemp - totalWithdraw);
		setTotalDeposits(totalDeposits);
		setTotalWithdraw(totalWithdraw);

		// Set mapped rows
		setRows(res);
		setLoading(false);
	};

	// Handle get results
	const handleGetResults = () => {
		// Date format to YYYY-MM-DD
		const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
		const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

		// Generate rows from formatted dates
		generateRows(formattedStartDate, formattedEndDate);
	};

	// Handle download excel
	const handleDownloadExcel = () => {
		// Date format to YYYY-MM-DD
		const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
		const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

		// Get Excel with format dates and filename
		getExcel(
			'results-of-the-day',
			'application',
			formattedStartDate,
			formattedEndDate,
			true,
			`tablaDeResumen_${formattedStartDate}-${formattedEndDate}`
		);
	};

	// Function to format is_safeguard
	const formatIsSafeguardText = (value) => {
		return value !== undefined && value !== null
			? value
				? 'SI'
				: 'NO'
			: '-';
	};

    // Handle commission safeguards switch
    const handleSwitchCommissionSafeguard = (sol) => {
        setLoading(true);
        if(sol?.commission_safeguard?.id){
            // If there's a commission safeguard delete it
            deleteCommissionSafeguard(sol?.commission_safeguard?.id)
                .then(() => {
                    // Display a success message upon successful deletion
                    handleSuccess('Retorno de comisión eliminado correctamente');
                    handleGetResults();
                })
                .catch((error) => {
                    // Handle any errors that may occur during the deletion process
                    handleError(
                        error,
                        'Ocurrió un error al intentar eliminar el registro'
                    );
                })
                .finally(() => setLoading(false));
        }else{
            // If there's no commission safeguard create it
            const total_safeguard = Number(sol?.comission_kv?.replace(/[^0-9.-]+/g,""));
            // Register the return and commission information
            registerCommissionSafeguard({
                in_use: false,
                total_safeguard: total_safeguard,
                client_name: sol?.client,
                application: sol?.id,
                created_by: userId,
            })
                .then(({ data }) => {
                    // Handle success by displaying a success message
                    handleSuccess('El retorno se creó correctamente.');
                    handleGetResults();
                })
                .catch((error) => {
                    // Handle error by displaying an error message
                    handleError(error, 'No se pudo registrar el retorno.');
                })
                .finally(() => setLoading(false));
        }
    };

	// Columns
	const columns = [
		{
			field: 'key',
			headerName: 'No. de operación',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>{row?.key}</span>
				</div>
			),
		},
		{
			field: 'executive',
			headerName: 'Ejecutivo',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>
						{row?.executive?.name}
					</span>
				</div>
			),
			valueGetter: ({ row }) => `${row?.executive?.name}`,
		},
		{
			field: 'site',
			headerName: 'Sede',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>{row?.site}</span>
				</div>
			),
		},
		{
			field: 'promoter',
			headerName: 'Promotor',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>
						<Cell subtitle='Nombre' text={row?.promoter?.name} />
						<Cell
							subtitle='Base de comisión'
							text={row?.promoter?.base_commission}
						/>
						<Cell
							subtitle='Comisión'
							text={row?.promoter?.commission}
						/>
						<Cell
							subtitle='Porcentaje'
							text={row?.promoter?.percentage}
						/>
					</span>
				</div>
			),
			valueGetter: ({ row }) =>
				`Nombre: ${row?.promoter?.name}, Base de comisión: ${row?.promoter?.base_commission}, Comisión: ${row?.promoter?.commission}, Porcentaje:${row?.promoter?.percentage}`,
		},
		{
			field: 'created_datetime',
			headerName: 'Fecha',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>{row.created_datetime}</span>
				</div>
			),
		},
		{
			field: 'client',
			headerName: 'Cliente',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>{row.client}</span>
				</div>
			),
		},
        {
            field: 'commission_safeguard',
            headerName: 'Resguardo de comisión KV',
            ...columnStyleCell,
            width: 200,
            renderCell: ({ row }) => (
                <Android12Switch
                    checked={
                        row?.commission_safeguard?.id >= 0
                    }
                    size={
                        'large'
                    }
                    disabled={loading}
                    onClick={() => handleSwitchCommissionSafeguard(row)}
                />
            )
            ,
        },
        {
            field: 'total_commission_safeguard',
            headerName: 'Total resguardo de comisión KV',
            ...columnStyleCell,
            width: 200,
            renderCell: ({ row }) => (
                <div style={rowDialogStyle}>
					<span className='table-cell-trucate'>
						{!isNaN(row?.commission_safeguard?.total_safeguard) ? money(row?.commission_safeguard?.total_safeguard) : '$0.00'}
					</span>
				</div>
            )
            ,
        },
		{
			field: 'total_imports',
			headerName: 'Total importes',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<Tooltip title='Total de los depósitos'>
					<div style={rowDialogStyle}>
						<span className='table-cell-trucate'>
							{row.total_imports}
						</span>
						<Button
							variant='text'
							style={buttonDialogStyle}
							onClick={() => {
								setCurrentApplicationId(row.id);
								handleOpenDepositsDialog();
							}}
						>
							Ver
						</Button>
					</div>
				</Tooltip>
			),
		},
		{
			field: 'total_return',
			headerName: 'Saldo de la operación',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<Tooltip title='Total de los retornos'>
					<div style={rowDialogStyle}>
						<span className='table-cell-trucate'>
							{row.total_return}
						</span>
						<Button
							variant='text'
							style={buttonDialogStyle}
							onClick={() => {
								setCurrentApplicationId(row.id);
								handleOpenReturmsAndCommissionsDialog();
							}}
						>
							Ver
						</Button>
					</div>
				</Tooltip>
			),
		},
		{
			field: 'total_commission_scheme',
			headerName: 'Total comisión por esquema',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>
						{row.total_commission_scheme}
					</span>
				</div>
			),
		},
		{
			field: 'commision_paid',
			headerName: 'Comisión pagada',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<Tooltip title='"Total de comisión por esquema" * 1.5'>
					<div style={rowDialogStyle}>
						<span className='table-cell-trucate'>
							{row.commision_paid}
						</span>
					</div>
				</Tooltip>
			),
		},
		{
			field: 'total_operative_expense',
			headerName: 'Costo operativo',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<Tooltip title='Total de los gastos operativos'>
					<div style={rowDialogStyle}>
						<span className='table-cell-trucate'>
							{row.total_operative_expense}
						</span>
					</div>
				</Tooltip>
			),
		},
		{
			field: 'total_promoter',
			headerName: 'Total comisión promotoría',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<Tooltip title='Total de la promotoría'>
					<div style={rowDialogStyle}>
						<span className='table-cell-trucate'>
							{row.total_promoter}
						</span>
					</div>
				</Tooltip>
			),
		},
		{
			field: 'subpromoter1',
			headerName: 'Subpromotor 1',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>
						<Cell
							subtitle='Nombre'
							text={row?.subpromoter1?.name}
						/>
						<Cell
							subtitle='Base de comisión'
							text={row?.subpromoter1?.base_commission}
						/>
						<Cell
							subtitle='Comisión'
							text={row?.subpromoter1?.commission}
						/>
						<Cell
							subtitle='Porcentaje'
							text={row?.subpromoter1?.percentage}
						/>
						<Cell
							subtitle='Es resguardo'
							text={formatIsSafeguardText(
								row?.subpromoter1?.is_safeguard
							)}
						/>
					</span>
				</div>
			),
			valueGetter: ({ row }) =>
				`Nombre: ${row?.subpromoter1?.name}, Base de comisión: ${
					row?.subpromoter1?.base_commission
				}, Comisión: ${row?.subpromoter1?.commission}, Porcentaje:${
					row?.subpromoter1?.percentage
				}, Es resguardo: ${formatIsSafeguardText(
					row?.subpromoter1?.is_safeguard
				)}`,
		},
		{
			field: 'subpromoter2',
			headerName: 'Subpromotor 2',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>
						<Cell
							subtitle='Nombre'
							text={row?.subpromoter2?.name}
						/>
						<Cell
							subtitle='Base de comisión'
							text={row?.subpromoter2?.base_commission}
						/>
						<Cell
							subtitle='Comisión'
							text={row?.subpromoter2?.commission}
						/>
						<Cell
							subtitle='Porcentaje'
							text={row?.subpromoter2?.percentage}
						/>
						<Cell
							subtitle='Es resguardo'
							text={formatIsSafeguardText(
								row?.subpromoter2?.is_safeguard
							)}
						/>
					</span>
				</div>
			),
			valueGetter: ({ row }) =>
				`Nombre: ${row?.subpromoter2?.name}, Base de comisión: ${
					row?.subpromoter2?.base_commission
				}, Comisión: ${row?.subpromoter2?.commission}, Porcentaje:${
					row?.subpromoter2?.percentage
				}, Es resguardo: ${formatIsSafeguardText(
					row?.subpromoter2?.is_safeguard
				)}`,
		},
		{
			field: 'subpromoter3',
			headerName: 'Subpromotor 3',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>
						<Cell
							subtitle='Nombre'
							text={row?.subpromoter3?.name}
						/>
						<Cell
							subtitle='Base de comisión'
							text={row?.subpromoter3?.base_commission}
						/>
						<Cell
							subtitle='Comisión'
							text={row?.subpromoter3?.commission}
						/>
						<Cell
							subtitle='Porcentaje'
							text={row?.subpromoter3?.percentage}
						/>
						<Cell
							subtitle='Es resguardo'
							text={formatIsSafeguardText(
								row?.subpromoter3?.is_safeguard
							)}
						/>
					</span>
				</div>
			),
			valueGetter: ({ row }) =>
				`Nombre: ${row?.subpromoter3?.name}, Base de comisión: ${
					row?.subpromoter3?.base_commission
				}, Comisión: ${row?.subpromoter3?.commission}, Porcentaje:${
					row?.subpromoter3?.percentage
				}, , Es resguardo: ${formatIsSafeguardText(
					row?.subpromoter3?.is_safeguard
				)}`,
		},
		{
			field: 'comission_kv',
			headerName: 'Comisión KV',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>
						{row?.commission_safeguard?.id ? '$0.00' : row.comission_kv}
					</span>
				</div>
			),
		},
	];

	useEffect(() => {
		handleGetResults();
	}, []);

	return (
		<div style={divStyle}>
			<DepositsDialog
				open={openDepositsDialog}
				handleClose={handleCloseDepositsDialog}
				applicationId={currentApplicationId}
			/>
			<ReturnsAndComissionsDialog
				open={openReturnsAndComissionsDialog}
				handleClose={handleCloseReturmsAndCommissionsDialog}
				applicationId={currentApplicationId}
			/>
			<Stack
				direction='row'
				alignItems='flex-end'
				justifyContent='space-between'
				style={stackStyle}
			>
				<Typography variant='h4' gutterBottom>
					Tabla de Resumen
				</Typography>
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					adapterLocale={es}
					utils={DateFnsUtils}
				>
					{/* Date Range Search */}
					<Grid
						container
						spacing={1}
						style={gridStyle}
						direction='row'
						display='flex'
						justifyContent='end'
						alignItems='center'
					>
						<IconButton
							onClick={handleDownloadExcel}
							sx={{ mt: 2 }}
						>
							<DownloadIcon />
						</IconButton>
						<DateRangeWithButton
							ranges={ranges}
							handleGetResults={handleGetResults}
							gridButtonStyle={gridButtonStyle}
						/>
					</Grid>
				</LocalizationProvider>
			</Stack>

			<Stack
				direction='row'
				alignItems='flex-end'
				justifyContent='space-between'
				style={stackStyle}
			>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<ItemTexts
							title='Total del disponible'
							text={money(totalAvailableKV)}
						/>
					</Grid>
					<Grid item xs={3}>
						<ItemTexts
							title='No. de solicitudes'
							text={numberApplications}
						/>
					</Grid>

					<Grid item xs={3}>
						<ItemTexts
							title='No. de solicitudes fondeadas'
							text={numberFundingApplications}
						/>
					</Grid>
					<Grid item xs={3}>
						<ItemTexts
							title='Total de depósitos'
							text={money(totalDeposits)}
						/>
					</Grid>

					<Grid item xs={3}>
						<ItemTexts
							title='Total de Retiros'
							text={money(totalWithdraw)}
						/>
					</Grid>
				</Grid>
			</Stack>
			<hr />

			<Typography variant='h6'>Solicitudes: {rows?.length ?? '-'}</Typography>
            <div style={datagridTableStyle}>
                {/* Datagrid table */}
                {rows?.length > 0 ? (
                    <DataGrid
                        autoHeight
                        // style={{ height: '600px' }}
                        components={{ Toolbar: GridToolbar }}
                        rows={rows}
                        getRowHeight={() => 'auto'}
                        columns={columns}
                        loading={loading}
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                    />
                ) : (
                    <>
                        {rows === null ? (
                            <Alert severity='success'>
                                Busca todas las solicitudes de un día
                            </Alert>
                        ) : (
                            <Alert severity='info'>
                                No hay ninguna solicitud
                            </Alert>
                        )}
                    </>
                )}
            </div>
		</div>
	);
};

const SelectComponent = (params, label) => (
	<TextField fullWidth required margin='normal' {...params} label={label} />
);

const rowDialogStyle = {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	margin: '0 auto',
};

const stackStyle = {
	padding: '20px 20px 0 0',
	margin: '0 0 40px 0',
	width: '100%',
};

const divStyle = {
	padding: '20px',
};

const gridStyle = {
	width: '70%',
	display: 'flex',
	justifyContent: 'flex-end',
};

const gridButtonStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
};

const datagridTableStyle = { margin: '20px 0' };

const columnStyleCell = {
	headerClassName: 'column-header-pilu',
	headerAlign: 'center',
};

const Cell = ({ subtitle, text = '', lastBreaks = true }) => (
	<>
		<b>{subtitle}:</b>
		<br />
		{text ? (text === 'No lleva' ? '' : text) : 'No se asignó'}
		{lastBreaks && <br />}
	</>
);

const buttonDialogStyle = {
	// backgroundColor: '#143f6c',
	margin: '0 0 0 10px',
};

export const ItemTexts = ({ title = '', text = '' }) => (
	<>
		<Typography variant='p'>
			<b>{title}</b>
		</Typography>
		<Typography variant='h6'>{text}</Typography>
	</>
);
