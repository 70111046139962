import axios from 'axios';
import { getToken } from './auth';

// Get promoter info by id
export const getPromoterInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/promoter-model/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get all promoters
export const getPromoters = async (idApplication) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/promoter-model/?application=${idApplication}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register promoter
export const registerPromoter = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/application/promoter-model/create/',
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};



// Update promoter
export const updatePromoter = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/application/promoter-model/update/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete promoter
export const deletePromoter = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/application/promoter-model/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

export const registerDebt = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/application/debt/create/',
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};
