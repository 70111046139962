import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../utils/UtilsComponent';
import { UserMenu } from './UserMenu';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { setShowSideBar, setSideBarStatus } from '../../../actions/sidebar';
import { UserMenu2 } from './UserMenu2';

const whichPage = () => {
    const href = document.location.href;
    if (href.includes('user')) {
        return 'users';
    } else if (href.includes('test')) {
        return 'tests';
    } else if (href.includes('catalog')) {
        return 'catalogs';
    } else if (href.includes('application')) {
        return 'applications';
    }

    return 'dashboard';
};
// Sidebar component
export const SideBar = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [navActive, setNavActive] = useState(whichPage());
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);

    const { sidebar = true } = useSelector((state) => state);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Change current active link
    const handleActiveLink = (id) => {
        setNavActive(id);
    };

    // Sidebar item component
    const SideBarItem = ({ id, path, title, icon, navigate }) => (
        <li
            className='nav-item'
            key={id}
            onClick={() => {
                handleClose();
                navigate(path);
                localStorage.setItem('lastPathKv', path);
                handleActiveLink(id);
            }}
        >
            <Tooltip
                title={sidebar ? '' : title}
                placement='right-end'
            >
                <div
                    style={{
                        cursor: 'pointer',
                        width: sidebar ? '100%' : '35px',
                    }}
                    className={`nav-link ${id === navActive && 'active'}`}
                >
                    <Icon
                        name={icon}
                        sidebar={sidebar}
                    />
                    {sidebar ? title : ''}
                </div>
            </Tooltip>
        </li>
    );

    return (
        <div
            className='d-flex flex-column flex-shrink-0 p-3 bg-light'
            style={{
                width: sidebar ? '250px' : '60px',
                height: '100vh',
            }}
        >
            {/* SIDEBAR TITLE */}
            <div className='d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none'>
                {/* Img Logo */}
                {sidebar ? (
                    <img
                        style={{
                            m: 1,
                            width: '96%',
                            height: '96%',
                        }}
                        src={'/static/logo-kv.png'}
                        alt='avatar'
                    />
                ) : (
                    <img
                        style={{
                            m: 1,
                            width: '100%',
                        }}
                        src={'/static/KVLOGO.png'}
                        alt='logo-sm'
                    />
                )}
            </div>

            {/* SEPARATOR */}
            <hr />

            {/* MENU LIST */}
            <ul className='nav nav-pills flex-column mb-auto'>
                {/* Home */}
                <SideBarItem
                    path='/'
                    id='dashboard'
                    title='Dashboard'
                    icon='speedometer2'
                    navigate={navigate}
                />

                {/* Users */}
                <SideBarItem
                    id='users'
                    path='/dashboard/users'
                    title='Usuarios'
                    icon='people'
                    navigate={navigate}
                />

                {/* Applications */}
                <SideBarItem
                    id='applications'
                    path='/dashboard/applications'
                    title='Solicitudes'
                    icon='file-earmark-bar-graph'
                    navigate={navigate}
                />

                {/* <SideBarItem
                    id='test'
                    path='/dashboard/test'
                    title='Test'
                    icon='file-earmark-bar-graph'
                    navigate={navigate}
                    
                /> */}

                {/* Results */}
                <SideBarItem
                    id='results'
                    path='/dashboard/results'
                    title='Resultados'
                    icon='graph-up-arrow'
                    navigate={navigate}
                />

                <SideBarItem
                    id='resultsFundings'
                    path='/dashboard/fundingsResults'
                    title='Fondeo Resultados'
                    icon='piggy-bank'
                    navigate={navigate}
                />

                <SideBarItem
                    id='promotersResults'
                    path='/dashboard/promotersResults'
                    title='Promotores'
                    icon='person-bounding-box'
                    navigate={navigate}
                />

                <SideBarItem
                    id='safeguards'
                    path='/dashboard/safeguardsResults'
                    title='Resguardos'
                    icon='wallet2'
                    navigate={navigate}
                />

                <SideBarItem
                    id='payers'
                    path='/dashboard/payersResults'
                    title='Pagadoras'
                    icon='cash-stack'
                    navigate={navigate}
                />

                {/* Catalogs */}
                {sidebar == true ? (
                    <li
                        className='nav-item'
                        onClick={handleShow}
                        aria-controls='example-collapse-text'
                        aria-expanded={open}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className={`nav-link ${
                                'catalogs' === navActive && 'active'
                            }`}
                        >
                            <Icon name='journals' />
                            Catálogos
                        </div>
                    </li>
                ) : (
                    <li
                        className='nav-item'
                        onClick={() => {
                            const newStatus = !sidebar;
                            // Change sidebar status (redux)
                            dispatch(setShowSideBar(newStatus));
                            // Change sidebar status (local storage)
                            setSideBarStatus(newStatus);
                            handleShow();
                        }}
                        aria-controls='example-collapse-text'
                        aria-expanded={open}
                    >
                        <div
                            style={{ cursor: 'pointer', padding: '0px 8px' }}
                            className={`nav-link ${
                                'catalogs' === navActive && 'active'
                            }`}
                        >
                            <Icon name='journals' />
                        </div>
                    </li>
                )}
            </ul>
            <hr />
            {sidebar && (
                <>
                    {/* USER MENU */}
                    <UserMenu2 />
                    {/* CATALOGS OFF CANVAS */}
                    <>
                        <Offcanvas
                            show={show}
                            onHide={handleClose}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>Catálogos</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                Seleccione un catálogo para visualizar, agregar,
                                editar o eliminar elementos.
                                <hr />
                                <ul className='nav nav-pills flex-column mb-auto'>
                                    <SideBarItem
                                        id='catalogs-clients'
                                        path='/dashboard/catalogs/clients'
                                        title='Clientes'
                                        icon='people'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-companies'
                                        path='/dashboard/catalogs/companies'
                                        title='Empresas'
                                        icon='building'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-cons-companies'
                                        path='/dashboard/catalogs/conscompanies'
                                        title='Empresas Contra'
                                        icon='building'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-payers'
                                        path='/dashboard/catalogs/payers'
                                        title='Pagadoras'
                                        icon='building'
                                        navigate={navigate}
                                    />

                                    <SideBarItem
                                        id='catalogs-promoters'
                                        path='/dashboard/catalogs/promoters'
                                        title='Promotores'
                                        icon='person-bounding-box'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-origin-resource'
                                        path='/dashboard/catalogs/origin-source'
                                        title='Origen recurso'
                                        icon='wrench'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-destination-resource'
                                        path='/dashboard/catalogs/destination-source'
                                        title='Destino recurso'
                                        icon='geo-alt'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-application'
                                        path='/dashboard/catalogs/cat-applications'
                                        title='Aplicación'
                                        icon='menu-button-wide'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-base-commissions'
                                        path='/dashboard/catalogs/base-commissions'
                                        title='Comisiones base'
                                        icon='cash-coin'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-schemes'
                                        path='/dashboard/catalogs/schemes'
                                        title='Esquemas'
                                        icon='bounding-box'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-promotery'
                                        path='/dashboard/catalogs/promotery'
                                        title='Promotoría'
                                        icon='person-lines-fill'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-banks'
                                        path='/dashboard/catalogs/bank'
                                        title='Bancos'
                                        icon='bank'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-debt-repayment'
                                        path='/dashboard/catalogs/debt-repayments'
                                        title='Abono a deuda'
                                        icon='credit-card-2-front'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-bank-commissions'
                                        path='/dashboard/catalogs/bank-commissions'
                                        title='Comisiones bancarias'
                                        icon='safe'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-transfers'
                                        path='/dashboard/catalogs/transfers'
                                        title='Traspasos'
                                        icon='arrow-left-right'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-prepaid-cards'
                                        path='/dashboard/catalogs/prepaidcard-commission'
                                        title='Tarjetas prepago'
                                        icon='credit-card'
                                        navigate={navigate}
                                    />
                                    <SideBarItem
                                        id='catalogs-cost-scheme'
                                        path='/dashboard/catalogs/scheme-cost'
                                        title='Costo esquema'
                                        icon='currency-dollar'
                                        navigate={navigate}
                                    />
                                </ul>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </>
                </>
            )}
        </div>
    );
};
