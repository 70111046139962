export const STATES = [
	{ code: 'NOA', name: 'No asignado' },
	{ code: 'AGS', name: 'Aguascalientes' },
	{ code: 'BCN', name: 'Baja California' },
	{ code: 'CS', name: 'Baja California Sur' },
	{ code: 'CAM', name: 'Campeche' },
	{ code: 'CHP', name: 'Chiapas' },
	{ code: 'CHI', name: 'Chihuahua' },
	{ code: 'COA', name: 'Coahuila' },
	{ code: 'COL', name: 'Colima' },
	{ code: 'DUR', name: 'Durango' },
	{ code: 'GTO', name: 'Guanajuato' },
	{ code: 'GRO', name: 'Guerrero' },
	{ code: 'HGO', name: 'Hidalgo' },
	{ code: 'JAL', name: 'Jalisco' },
	{ code: 'MEX', name: 'CDMX' },
	{ code: 'MIC', name: 'Michoacán' },
	{ code: 'MOR', name: 'Morelos' },
	{ code: 'NAY', name: 'Nayarit' },
	{ code: 'NLE', name: 'Nuevo León' },
	{ code: 'OAX', name: 'Oaxaca' },
	{ code: 'PUE', name: 'Puebla' },
	{ code: 'QRO', name: 'Querétaro' },
	{ code: 'ROO', name: 'Quintana Roo' },
	{ code: 'SLP', name: 'San Luis Potosí' },
	{ code: 'SIN', name: 'Sinaloa' },
	{ code: 'SON', name: 'Sonora' },
	{ code: 'TAB', name: 'Tabasco' },
	{ code: 'TAM', name: 'Tamaulipas' },
	{ code: 'TLX', name: 'Tlaxcala' },
	{ code: 'VER', name: 'Veracruz' },
	{ code: 'YUC', name: 'Yucatán' },
	{ code: 'ZAC', name: 'Zacatecas' },
];

export const EXPENSES_CONCEPTS = [
	"ARRENDAMIENTO",
	"CAJA CHICA CMDX",
	"CAJA CHICA PUEBLA",
	"COSTOS SOCIALES",
	"DEUDA",
	"GASTOS ADMINISTRATIVOS",
	"GASTOS ADMINISTRATIVOS DOMICILIOS",
	"GASTOS DIRECCION",
	"GASTOS JURIDICOS",
	"GASTOS SOFOM - SAPI",
	"IMPUESTOS ESTATALES (ISN)",
	"IMPUESTOS FEDERALES",
	"MANTENIMIENTO VEHICULOS",
	"NOMINA",
	"RENTAS", 
	"SERVICIOS APOYO EXTERNO",
	"SISTEMAS",
	"TELEFONOS",
	"COMISIONES BANCARIAS",
	"VIATICOS",
	"COMISIONES T. PREPAGO",
	"CAJA CHICA METEPEC",
	"CAJA CHICA DOMICILIOS",
	"CAPACITACION",
	"MULTAS",
	"PROMOTORIAS",
	"AGUINALDOS",
	"COMISION COMPRA EFECTIVO",
	"SEGUROS VEHICULOS",
	"TRASLADO EFECTIVO",
	"FINIQUITOS",
	"PRESTAMO",
	"NO APLICA",
	"HONORARIO CUOTAS SINDICATO"
];

export const getStateByCode = (code) => {
	return STATES.find((item) => item.code === code)?.name || 'No asignado';
};
