import {
    getDepositInfo,
    registerDeposit,
    setCurrentApplication,
    updateApplication,
    updateDeposit,
} from '../../actions/applications';
import { getWithdrawTotal, setListOfSafeguards } from '../../actions/requests';
import {
    deleteSafeguards,
    registerSafeguardTotal,
} from '../../actions/returnAndCommissions';
import { calculateAndLoadDeposits } from '../../containers/ApplicationDetail';
import { handleError, handleSuccess } from '../utils';
import { createNewDepositObj } from './CreateNewDepositObj';

export const handleSubmitDeposit = ({
    handleSubmitVariables,
    objectForCreateDepositObj,
    getCommission,
    dispatch,
    handleCloseAndReset,
    handleOperationSafeguards,
    checkIfIsWithdraw = false
}) => {
    const {
        bank,
        application,
        applicationTemp,
        clientTemp,
        deposits,
        folio,
        data,
        withdrawInput,
        safeguardsTotal,
        tempSafeguards,
    } = handleSubmitVariables;

    if (bank == 'STP' || bank == 'KUSPIT') {
        // Update the application to include detected bank
        updateApplication(application.id, {
            ...applicationTemp,
            bank_detected: bank,
        }).then(({ data }) => {
            dispatch(
                setCurrentApplication({
                    ...applicationTemp,
                    bank_detected: bank,
                })
            );
        });
    }

    // Delete the list of safeguards when the folio is 'RETIRO DE RESGUARDO'
    if (folio == 'RETIRO DE RESGUARDO') {
        let clientNumber = null;
        if (clientTemp.id == null) {
            clientNumber = clientTemp;
        } else {
            clientNumber = clientTemp.id;
        }

        deleteSafeguards({
            safeguardsList: tempSafeguards.map((element) => element.id),
            clientNumber: clientNumber,
        }).then(({ data }) => {
            console.log('deleteSafeguards', data);
            handleOperationSafeguards(data.results);
            dispatch(setListOfSafeguards(data.results));
        });
    }

    // Create a new deposit object based on the provided conditions
    const newDepositDB = createNewDepositObj(
        objectForCreateDepositObj,
        getCommission,
        checkIfIsWithdraw,
    );

    try {
        if (data) {
            // Update existing deposit
            updateDeposit(data.id, newDepositDB)
                .then(async ({ data: updatedDeposit }) => {
                    // Find the index of the updated deposit
                    const indexDeposit = deposits.findIndex(
                        (item) => item.id === updatedDeposit.id
                    );

                    // Fetch updated deposit information
                    const updatedDepositInfo = (
                        await getDepositInfo(updatedDeposit.id)
                    ).data;

                    // Update the deposits array with the updated deposit information
                    const tmpDeposits = deposits.map((item, index) => {
                        if (index === indexDeposit) {
                            return updatedDepositInfo;
                        }
                        return item;
                    });

                    // Update the Redux state and recalculate deposit totals
                    calculateAndLoadDeposits(dispatch, tmpDeposits);

                    // Show success feedback
                    handleSuccess('Depósito actualizada correctamente.');

                    // Close and reset the form
                    handleCloseAndReset();
                })
                .catch((error) => {
                    // Handle errors during deposit update
                    handleError(error, 'No se pudo actualizar el depósito.');
                });
        } else {
            // Register a new deposit
            registerDeposit(newDepositDB)
                .then(async ({ data }) => {
                    // Show success feedback
                    handleSuccess('El depósito se creó correctamente.');
                    // Fetch complete information of the newly registered deposit
                    const newDeposit = (await getDepositInfo(data.id)).data;

                    // Calculate deposit totals and update the Redux state
                    calculateAndLoadDeposits(dispatch, [
                        ...deposits,
                        newDeposit,
                    ]);

                    dispatch(getWithdrawTotal(safeguardsTotal - withdrawInput));

                    // Close and reset the form
                    handleCloseAndReset();
                })
                .catch((error) => {
                    // Handle errors during deposit registration
                    handleError(error, 'No se pudo registrar el depósito.');
                });
        }
    } catch (error) {
        // Handle general errors during deposit submission
        handleError(error, 'Ocurrió un error al intentar agregar el depósito');
    }
};
