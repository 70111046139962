import React from 'react';
import { Alert, Box, Tab, Tabs, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UserProfileTab } from '../components/users/UserProfileTab';
import { getUserInfo, setUser } from '../actions/user';

export const UserProfile = () => {
	const { idUser = null } = useParams();
	const [value, setTab] = useState(0);
	const [user, setUser] = useState(null);

	// Get user data by id given as param in URL
	useEffect(() => {
		getUserInfo(idUser).then(({ data }) => {
			setUser(data);
		});
	}, []);

	// Change tabs
	const handleChange = (_, newValue) => {
		setTab(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			{user ? (
				<>
					<Stack
						direction='column'
						alignItems='flex-start'
						justifyContent='space-between'
						mb={1}
						style={{
							border: '1px solid white',
							padding: '20px 0 0 20px',
						}}
					>
						<Typography variant='h4' gutterBottom>
							{`${user.first_name} ${user.last_name}`}
						</Typography>
						<Typography variant='h5' gutterBottom>
							ID: {user.id}
						</Typography>
					</Stack>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label='basic tabs example'
						>
							<Tab label='Perfil' {...a11yProps(0)} />
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						<UserProfileTab user={user} />
					</TabPanel>
				</>
			) : (
				<div style={{ padding: '40px' }}>
					<Alert severity='warning'>
						No existe el usuario con ID: {idUser}
					</Alert>
				</div>
			)}
		</Box>
	);
};

const a11yProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
};

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
};
