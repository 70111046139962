import React from 'react';
import {
	deleteBank,
	getBankInfo,
	getBanks,
	registerBank,
	searchBank,
	updateBank,
} from '../../actions/catalogs';
import { SimpleCatalogTable } from './SimpleCatalogTable';

// Banks Table
export const Bank = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getBanks,
		getCatalogInfo: getBankInfo,
		deleteCatalog: deleteBank,
		updateCatalog: updateBank,
		registerCatalog: registerBank,
		searchCatalog: searchBank,
		catalogSingular: 'Banco',
		catalogPlural: 'Banco',
		pronounSingular: 'el',
		pronoun: 'o',
		conceptName: 'name',
		conceptNameLabel: 'Nombre',
	};

	return <SimpleCatalogTable config={configuration} />;
};