import React from 'react';
import { 
	deleteBankCommission,
	getBankCommissions
} from '../../actions/catalogs';
import { BankCommissionsTable } from './BankCommissionsTable';

// Bank Commissions Table
export const BankCommission = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getBankCommissions,
		deleteCatalog: deleteBankCommission,
		catalogSingular: 'Comisión bancaria',
		catalogPlural: 'Comisiones Bancarias',
		pronounSingular: 'la',
		pronoun: 'a',
	};

	// Configuration for the Details Table
	const detailGridConfig = {
		gridItems : [
		{xsWidth: 4, title: 'CONCEPTO', code: 'concept'}, 
		{xsWidth: 4, title: 'PRIMERA COMISIÓN', code: 'first_commission'},
		{xsWidth: 4, title: 'SEGUNDA COMISIÓN', code: 'second_commission'},
		{xsWidth: 12, title: 'Descripcion', code: 'description'},
	  ]
	};

	return (
		<BankCommissionsTable config={configuration} detailGridConfig={detailGridConfig} />
	);
};
