import * as React from 'react';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { getSafeguards } from '../../actions/returnAndCommissions';
import { money } from '../../helpers/money';
import { useSelector } from 'react-redux';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};





export default function SelectSafeguards({funcSafeguards, listOfSafeguards}) {    
    const [arraySafeguards, setArraySafeguards] = React.useState([]);


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setArraySafeguards(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );


        funcSafeguards(value === 'string' ? value.split(',') : value)
    };

    return (
        <div>
            <FormControl fullWidth sx={{marginTop: '15px'}}>
                <InputLabel id='demo-multiple-chip-label'>
                    Resguardos
                </InputLabel>
                <Select
                    labelId='demo-multiple-chip-label'
                    id='demo-multiple-chip'
                    multiple
                    value={arraySafeguards}
                    onChange={handleChange}
                    input={
                        <OutlinedInput
                            id='select-multiple-chip'
                            label='Chip'
                        />
                    }
                    renderValue={(selected) => (
                        <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                        >
                            {selected.map((value) => (
                                <Chip
                                    key={value.balance}
                                    label={value.balance}
                                />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {listOfSafeguards.map((safeguard) => (
                        <MenuItem
                            key={safeguard.id}
                            value={safeguard}
                        >
                            Saldo: {money(safeguard.balance)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
