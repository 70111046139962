import React, { useEffect, useState, useRef } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import {
    Alert,
    Button,
    IconButton,
    Tooltip,
    Stack,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { NewDialog } from '../components/applications/NewDialog';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import SaveIcon from '@mui/icons-material/Save';
import { money } from '../helpers/money';
import { useDispatch, useSelector } from 'react-redux';
import { updatePerceptionsInvoice } from '../actions/requests';
import { PayerReturnDialog } from '../components/applications/PayerDialogReturn';
import { EditReturn } from '../components/applications/EditReturn';
import {
    handleUpdateFieldApp,
    setCurrentApplication,
    updateApplication,
} from '../actions/applications';
import {
    calculateAllPayersOnReturnAndCommission,
    calculateCommissionBySingleScheme,
    calculateReturnValue,
    calculateTotalBalance,
    calculateTotalCommissions,
    calculateTotalISN,
    calculateTotalISR,
    calculateTotalIVA,
    calculateTotalReturns,
    calculateTotalSocialCost,
} from '../helpers/ReturnCommissionCalc';
import CurrencyFormat from 'react-currency-format';
import {
    addRow,
    handleDeleteReturnAndCommission,
    saveReturnAndCommission,
    updateApplicationInvoice,
    updateApplicationPerceptions,
    updateTotals,
} from '../helpers/ReturnAndCommissionFunctions/ReturnAndCommissionFunctions';
import { ReturnCommissionDashboard } from './Components/ReturnCommissionDashboard';
import { baseObj } from '../helpers/ReturnAndCommissionFunctions/ReturnAndCommissionFunctions';
import { CalculateBalanceReturn } from '../helpers/ReturnAndCommissionFunctions/CalculateBalanceReturn';
import { saveApplication } from '../helpers/SaveApplication';

export const ReturnAndComission = ({ application = null }) => {
    // Dispatch
    const dispatch = useDispatch();
    const rows = useSelector((state) => state.request.returnAndCommission);
    const depositsTotal = useSelector((state) => state.request.depositTotal);
    const schemes = useSelector((state) => state.catalogs.scheme);
    const proveAmount = useSelector((state) => state.request.requestTotal);
    const totalOperativeExpenses = useSelector(
        (state) => state.request.operatingExpensesTotal
    );
    const bankCommissions = useSelector(
        (state) => state.request.operatingExpenses.comisionesBancarias
    );
    const totalInvoice = useSelector(
        (state) => state.request.application.invoice
    );
    const applicationInvoice = useSelector(
        (state) => state.request.application.folio
    );
    const totalDeposits = useSelector((state) => state.request.depositTotal);
    const totalPerceptions = useSelector(
        (state) => state.request.application.total_perceptions
    );
    const { effectiveCost } = useSelector(
        (state) => state.request.operatingExpenses
    );
    const applicationTemp = useSelector((state) => state.request.application);
    /* Modal New Return */
    const [openModal, setOpenModal] = React.useState(false);
    const {
        costoFID,
        costoSC,
        costoSindicato,
        comisionSpei,
        comisionEdenredKV,
        comisionEdenredCorpo,
        comisionFacil,
    } = useSelector((state) => state.request.operatingExpenses);
    const handleCloseDialog = () => setOpenModal(false);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleOpen3 = () => setShow3(true);

    // Current user id
    const [returnValue, setReturnValue] = useState(0);
    const [comisionValue, setComisionValue] = useState('0');
    const [Esquema, setEsquema] = React.useState('');
    const [BaseComision, setBaseComision] = React.useState('');
    const [percent, setPercent] = React.useState(0);
    const [factura, setFactura] = React.useState(0);
    const [percepciones, setPercepciones] = React.useState(0);
    const [isn, setIsn] = React.useState(0);
    const [isr, setIsr] = React.useState(0);
    const [iva, setIva] = React.useState(0);
    const [socialCost, setSocialCost] = React.useState(0);
    const [totalBalance, setTotalBalance] = React.useState(0);
    const [totalCommission, setTotalCommission] = React.useState(0);
    const [totalReturn, setTotalReturn] = React.useState(0);
    const [totalISN, setTotalISN] = useState(0);
    const [totalISR, setTotalISR] = useState(0);
    const [totalSocialCost, setTotalSocialCost] = useState(0);
    const [totalIva, setTotalIva] = useState(0);
    const [companyName, setCompanyName] = useState('');
    const [totalPagadoras, setTotalPagadoras] = useState(0);

    // Update when component unmount
    useEffect(() => {
        return () => {
            dispatch(saveApplication());
        };
    },[]);

    //Edit Retorno
    const [EditRetorno, setEditRetorno] = useState(false);
    const totalRef = useRef(0);
    const [currentRow, setCurrentRow] = useState(0);
    const [perceptionsTemp, setPerceptionsTemp] = useState(0);
    const [invoiceVar, setInvoiceVar] = useState(0);

    // Handle PayerDialogReturn
    const [openPayerDialogReturn, setOpenPayerDialogReturn] =
        React.useState(false);
    const handlePayerDialogReturn = () => setOpenPayerDialogReturn(false);
    const handleDeleteReturn = () => {
        setChange(!change);
    };
    const [tempArr, setTempArr] = React.useState([]);

    const setValuesZero = () => {
        setReturnValue(0);
        setIsn(0);
        setIsr(0);
        setPercent(0);
        setEsquema(0);
        setBaseComision('');
        setSocialCost(0);
    };

    // Define columns
    const columns = [
        {
            field: 'col9',
            headerName: 'Acciones',
            width: 200,
            // Add actions to each case
            renderCell: ({ row }) => (
                <>
                    <Tooltip title='Nuevo fondeo'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='new-funding'
                            color='primary'
                            onClick={() => {
                                setCurrentRow(row);
                                handleOpen3();
                            }}
                        >
                            <AttachMoneyIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Ver fondeos'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='info'
                            color='primary'
                            onClick={() => {
                                setCurrentRow(row);
                                setTempArr(row?.fundings);
                                setOpenPayerDialogReturn(true);
                            }}
                        >
                            <PriceChangeIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Editar Retorno'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='info'
                            color='primary'
                            onClick={() => {
                                setCurrentRow(row);
                                setEditRetorno(!EditRetorno);

                                //mandar numero de fila
                                // setTempArr(row?.fundings);
                                // setOpenPayerDialogReturn(true);
                            }}
                        >
                            <ModeEditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Eliminar'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='delete'
                            color='primary'
                            onClick={() => {
                                // TODO import this function
                                handleDeleteReturnAndCommission(
                                    row?.id,
                                    dispatch,
                                    rows,
                                    setTotalPagadoras
                                );
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
        {
            field: 'key',
            headerName: 'Folio',
            width: 90,
            renderCell: ({ row }) => (
                <Tooltip title={row.key}>
                    <span className='table-cell-trucate'>{row.key}</span>
                </Tooltip>
            ),
        },
        {
            field: 'scheme',
            headerName: 'Esquema',
            width: 140,
            type: 'singleSelect',
        },
        {
            field: 'balance',
            headerName: 'Saldo por esquema',
            width: 150,
            type: 'number',

            valueFormatter: (params) => {
                const balance = params.value;
                return money(balance);
            },
        },
        {
            field: 'percentage',
            headerName: 'Porcentaje',
            width: 100,
            type: 'number',

            valueFormatter: (params) => {
                const percent = params.value;
                return `${percent}%`;
            },
        },
        {
            field: 'base_commission',
            headerName: 'Base de Comisión',
            type: 'singleSelect',
            width: 170,
        },
        {
            field: 'commission',
            headerName: 'Comisión por esquema',
            width: 170,

            valueFormatter: (params) => {
                const commission = params.value;
                return money(commission);
            },
        },
        {
            field: 'return_value',
            headerName: 'Retorno',
            width: 140,
            type: 'number',

            valueFormatter: (params) => {
                const return_value = params.value;
                return money(return_value);
            },
        },

        {
            field: 'isn',
            headerName: 'ISN',
            width: 150,
            type: 'number',

            valueFormatter: (params) => {
                const ISN = params.value;
                return money(ISN);
            },
        },

        {
            field: 'isr',
            headerName: 'ISR ',
            width: 170,
            type: 'number',

            valueFormatter: (params) => {
                const ISR = params.value;
                return money(ISR);
            },
        },
        {
            field: 'iva',
            headerName: 'IVA',
            width: 150,
            type: 'number',

            valueFormatter: (params) => {
                const IVA = params.value;
                return money(IVA);
            },
        },
        {
            field: 'social_cost',
            headerName: 'Costo Social',
            width: 150,
            type: 'number',

            valueFormatter: (params) => {
                const socialCostTemp = params.value;
                return money(socialCostTemp);
            },
        },
    ];

    /**
     * useEffect to calculate and update the commission value based on input parameters.
     *
     * This effect calculates the commission value using the provided input parameters
     * such as 'return_value', 'percent', 'isn', 'isr', 'BaseComision', 'factura',
     * 'percepciones', 'socialCost', and 'iva'. It then sets the calculated commission
     * value using the 'setComisionValue' function.
     *
     */
    useEffect(() => {
        if(BaseComision !== 'COSTOS'){
            // Convert input parameters to numbers if they are not already
            const commissionValuesCalculus = {
                return_value: Number(returnValue),
                ISN: Number(isn),
                ISR: Number(isr),
                IVA: Number(iva),
                socialCostTemp: Number(socialCost),
                percentT: Number(percent) / 100,
                facturaTemp: Number(totalInvoice),
                percepcionesTemp: Number(totalPerceptions),
                depositTotal: Number(depositsTotal),
            };
            // Calculate the commission value using the provided parameters
            const totalOfOneCommission = Number(
                calculateCommissionBySingleScheme(
                    commissionValuesCalculus,
                    BaseComision
                )
            );
    
            // Set the calculated commission value using the 'setComisionValue' function
            setComisionValue(totalOfOneCommission);
    
            // Set the calculated return value using
            setReturnValue(
                calculateReturnValue(commissionValuesCalculus, BaseComision)
            );
        }
    }, [
        returnValue,
        isn,
        isr,
        iva,
        socialCost,
        percent,
        factura,
        percepciones,
        BaseComision,
    ]);

    useEffect(() => {
        // This useEffect calculates the total for Return, ISN, ISR, IVA, Social Cost
        const tempBalance = calculateTotalBalance(rows);
        const tempReturn = calculateTotalReturns(rows);
        const tempCommission = calculateTotalCommissions(rows);
        const tempISN = calculateTotalISN(rows);
        const tempISR = calculateTotalISR(rows);
        const tempSocialCost = calculateTotalSocialCost(rows);
        const tempIva = calculateTotalIVA(rows);
        const tempTotalPagadoras =
            calculateAllPayersOnReturnAndCommission(rows);

        setTotalPagadoras(tempTotalPagadoras);
        setTotalCommission(tempCommission);
        setTotalReturn(tempReturn);
        setTotalBalance(tempBalance);
        setTotalISN(tempISN);
        setTotalISR(tempISR);
        setTotalSocialCost(tempSocialCost);
        setTotalIva(tempIva);
    }, [rows]);

    // Solicitado de Más formula
    useEffect(() => {
        const variablesForBalance = {
            totalPagadoras,
            totalReturn,
            totalSocialCost,
            totalISR,
            totalISN,
            totalIva,
            costoFID,
            costoSC,
            costoSindicato,
            comisionSpei,
            comisionEdenredKV,
            comisionEdenredCorpo,
            comisionFacil,
        };
        CalculateBalanceReturn(variablesForBalance, dispatch);
    }, [
        totalPagadoras,
        totalReturn,
        totalISN,
        totalISR,
        totalOperativeExpenses,
        bankCommissions,
        effectiveCost,
    ]);

    // On first render loads this data from redux, invoice and perceptions
    useEffect(() => {
        setInvoiceVar(applicationTemp.invoice);
        setPerceptionsTemp(applicationTemp.total_perceptions);
        dispatch(updatePerceptionsInvoice({ ...applicationTemp }));
    }, []);

    // Updates the variable total_return on DB and Redux
    useEffect(() => {
        updateApplication(application.id, {
            ...applicationTemp,
            gastos_promotoria: applicationTemp.gastos_promotoria,
            total_return: totalReturn,
            total_commission_scheme: totalCommission,
        }).then(({ data }) => {
            dispatch(
                setCurrentApplication({
                    ...data,
                    gastosPromotoria: applicationTemp.gastos_promotoria,
                    fundings: applicationTemp.fundings,
                    promoter: application.promoter,
                    executive: application.executive,
                    client: application.client,
                    bankDetected: applicationTemp.bankDetected,
                })
            );
        });
    }, [totalReturn]);

    // RESET FORM initializing variables on default values
    const handleResetForm = () => {
        setReturnValue(0);
        setComisionValue(0);
        setEsquema('');
        setBaseComision('');
        setPercent(0);
        setFactura(0);
        setPercepciones(0);
        setIsn(0);
        setIsr(0);
        setSocialCost(0);
        setIva(0);
    };

    const calculateBalance = () => {
        // Convert input parameters to numbers if they are not already

        const commissionValuesCalculus = {
            return_value: Number(returnValue),
            ISN: Number(isn),
            ISR: Number(isr),
            IVA: Number(iva),
            socialCostTemp: Number(socialCost),
            percentT: Number(percent) / 100,
            facturaTemp: Number(totalInvoice),
            percepcionesTemp: Number(totalPerceptions),
            depositTotal: Number(depositsTotal),
        };

        // Calculate the commission value using the provided parameters
        let totalOfOneCommission = Number(
            calculateCommissionBySingleScheme(
                commissionValuesCalculus,
                BaseComision
            )
        );

        // Calculate the total balance by summing up the individual amounts
        let totalBalance =
            commissionValuesCalculus.return_value +
            commissionValuesCalculus.ISN +
            commissionValuesCalculus.ISR +
            commissionValuesCalculus.socialCostTemp +
            commissionValuesCalculus.IVA +
            Number(totalOfOneCommission);

        // Update the 'totalRef' ref with the calculated total balance
        totalRef.current = totalBalance;

        // Return the calculated total balance
        return totalBalance;
    };

    return (
        <div>
            <Stack
                direction='row'
                alignItems='flex-end'
                justifyContent='space-between'
                style={{
                    border: '1px solid white',
                    padding: '5px',
                    // margin: '0 0 40px 0',
                    width: '100%',
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={10}
                        >
                            <Typography
                                variant='h5'
                                gutterBottom
                            >
                                Retorno y comisión Cliente
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                        >
                            <Button
                                variant='contained'
                                style={{ backgroundColor: '#143f6c' }}
                                onClick={() => {
                                    setValuesZero();
                                    setOpenModal(true);
                                }}
                                fullWidth
                            >
                                Nuevo Retorno
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>

            <ReturnCommissionDashboard
                money={money}
                totalReturn={totalReturn}
                totalSocialCost={totalSocialCost}
                totalISN={totalISN}
                totalISR={totalISR}
                totalIva={totalIva}
                totalBalance={totalBalance}
                totalCommission={totalCommission}
                proveAmount={proveAmount}
                TextField={TextField}
                perceptionsTemp={perceptionsTemp}
                setPerceptionsTemp={setPerceptionsTemp}
                Number={Number}
                updateApplicationPerceptions={updateApplicationPerceptions}
                applicationTemp={applicationTemp}
                dispatch={dispatch}
                invoiceVar={invoiceVar}
                setInvoiceVar={setInvoiceVar}
                updateApplicationInvoice={updateApplicationInvoice}
            />

            {/* New funding form */}
            <NewDialog
                show={show3}
                handleClose={handleClose3}
                row={currentRow}
            />

            <Dialog
                fullWidth
                maxWidth='lg'
                open={openModal}
                onClose={() => {
                    handleCloseDialog();
                    handleResetForm();
                }}
            >
                <DialogTitle>Nuevo retorno y comisión</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Complete el formulario para agregar un nuevo retorno y
                        comisión
                    </DialogContentText>

                    <hr />

                    <Box>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={3}
                            >
                                <Autocomplete
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    disablePortal
                                    id='combo-box-demo'
                                    options={
                                        schemes?.map(
                                            (element) => element.concept
                                        ) || []
                                    }
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='Esquemas'
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        setEsquema(value);
                                    }}
                                />
                            </Grid>

                            {Esquema !== 'RESGUARDO' ? (
                                <>
                                    <Grid
                                        item
                                        xs={3}
                                    >
                                        <TextField
                                            required
                                            fullWidth
                                            label='Porcentaje'
                                            id='outlined-start-adornment'
                                            placeholder='0'
                                            type='number'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        %
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={(e) => {
                                                setPercent(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                    >
                                        <Autocomplete
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.id === value.id}
                                            disablePortal
                                            id='combo-box-demo'
                                            options={baseObj}
                                            sx={{ width: '100%' }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label='Base de Comisión'
                                                />
                                            )}
                                            onChange={(_, value) =>
                                                setBaseComision(value.label)
                                            }
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <></>
                            )}

                            {/* Return */}
                            <Grid
                                item
                                xs={3}
                            >
                                <CurrencyFormat
                                    customInput={TextField}
                                    label='Retorno'
                                    fullWidth
                                    placeholder={String(returnValue)}
                                    value={returnValue}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    allowNegative={false}
                                    disabled={BaseComision === 'SUBTOTAL + IVA'}
                                    onChange={({ target }) => {
                                        // Removes any character that is not a digit or period
                                        const sanitizedValue =
                                            target.value.replace(
                                                /[^0-9.]+/g,
                                                ''
                                            );
                                        // Convert the value to a number
                                        const numericValue =
                                            Number(sanitizedValue);

                                        setReturnValue(numericValue);
                                    }}
                                    inputProps={{ 
                                        readOnly: BaseComision === 'SUBTOTAL + IVA', 
                                        disabled: BaseComision === 'SUBTOTAL + IVA' 
                                    }}
                                />
                            </Grid>

                            {Esquema !== 'RESGUARDO' &&
                            BaseComision !== 'SUBTOTAL + IVA' ? (
                                <>
                                    <Grid
                                        item
                                        xs={3}
                                    >
                                        <CurrencyFormat
                                            customInput={TextField}
                                            label='ISN'
                                            fullWidth
                                            placeholder={String(isn)}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            allowNegative={false}
                                            onChange={({ target }) => {
                                                setIsn(
                                                    Number(
                                                        target.value.replace(
                                                            /[^0-9.-]+/g,
                                                            ''
                                                        )
                                                    )
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                    >
                                        <CurrencyFormat
                                            customInput={TextField}
                                            label='ISR'
                                            fullWidth
                                            placeholder={String(isr)}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            allowNegative={false}
                                            onChange={({ target }) => {
                                                setIsr(
                                                    Number(
                                                        target.value.replace(
                                                            /[^0-9.-]+/g,
                                                            ''
                                                        )
                                                    )
                                                );
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={3}
                                    >
                                        <CurrencyFormat
                                            customInput={TextField}
                                            label='Costo Social'
                                            fullWidth
                                            placeholder={String(socialCost)}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            allowNegative={false}
                                            onChange={({ target }) => {
                                                setSocialCost(
                                                    Number(
                                                        target.value.replace(
                                                            /[^0-9.-]+/g,
                                                            ''
                                                        )
                                                    )
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                    >
                                        <CurrencyFormat
                                            customInput={TextField}
                                            label='IVA'
                                            fullWidth
                                            placeholder={String(iva)}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            allowNegative={false}
                                            onChange={({ target }) => {
                                                setIva(
                                                    Number(
                                                        target.value.replace(
                                                            /[^0-9.-]+/g,
                                                            ''
                                                        )
                                                    )
                                                );
                                            }}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <></>
                            )}

                            <Grid
                                container
                                spacing={2}
                                sx={{ mt: 2 }}
                                style={{ padding: '10px' }}
                            >
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <ItemTexts
                                        title='Saldo por Esquema'
                                        text={money(calculateBalance())}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <ItemTexts
                                        title='Comisión por Esquema'
                                        text={money(comisionValue)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleCloseDialog();
                            handleResetForm();
                        }}
                        color='error'
                    >
                        Cancelar
                    </Button>
                    <Button
                        // disabled={Esquema === null && BaseComision === null}
                        onClick={() => {
                            addRow(
                                {
                                    tempBalance: Number(totalRef.current),
                                    tempPercent: Number(percent),
                                    tempReturn: Number(returnValue),
                                    tempCommission: Number(comisionValue),
                                    tempISN: Number(isn),
                                    tempISR: Number(isr),
                                    tempSocialCost: Number(socialCost),
                                    tempIVA: Number(iva),
                                },
                                dispatch,
                                handleResetForm,
                                Esquema,
                                rows,
                                BaseComision,
                                application,
                                applicationTemp,
                                companyName,
                                handleCloseDialog
                            );
                            setOpenModal(false);
                            setValuesZero();
                            handleResetForm();
                        }}
                    >
                        Agregar
                    </Button>
                </DialogActions>
            </Dialog>

            <EditReturn
                openModal={EditRetorno}
                onClose={() => {
                    setEditRetorno(false);
                }}
                rowSelected={currentRow}
            />

            {/* Payer return dialog */}
            <PayerReturnDialog
                currentRow={currentRow}
                fundings={tempArr}
                show={openPayerDialogReturn}
                handleClose={handlePayerDialogReturn}
                handleChange={handleDeleteReturn}
                updateTotals={() => {
                    updateTotals(rows, setTotalPagadoras);
                }}
            />

            <hr />

            {/* Datagrid table */}
            {rows.length > 0 ? (
                <DataGrid
                    sx={{
                        '& .MuiDataGrid-cell': {
                            display: 'flex',
                            alignItems: 'flex-start',
                            padding: '10px',
                        },
                    }}
                    style={{ height: '600px', margin: '10px 0 0 0' }}
                    density='compact'
                    getRowHeight={() => 'auto'}
                    rows={rows}
                    columns={columns}
                    // autoPageSize
                    components={{ Toolbar: GridToolbar }}
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                />
            ) : (
                <Alert severity='info'>No hay ninguna fila</Alert>
            )}
        </div>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);
