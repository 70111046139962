import React, { useEffect } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import {
    getPositionID,
    capitalizeFirstLetter,
    getDepartmentID,
    getProfileID,
    handleSuccess,
    handleConfirmModal,
} from '../../helpers/utils';

import { useDispatch, useSelector } from 'react-redux';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getPDFInternalRequest } from '../../actions/pdfs';
import { getStateByCode } from '../../helpers/lists';
import { saveApplication } from '../../helpers/SaveApplication';

export const ApplicationDetailTab = ({ application }) => {
    const applicationTemp = useSelector((state) => state.request.application);
    const depositsTemp = useSelector((state) => state.request.deposits);
    const depositTotal = useSelector((state) => state.request.depositTotal);
    const availableKV = useSelector((state) => state.request.availableKV);
    const returnAndCommission = useSelector(
        (state) => state.request.returnAndCommission
    );
    const creditToDebtExpenses = useSelector(
        (state) => state.request.creditToDebt.totalExpenses
    );
    const operatingExpenses = useSelector(
        (state) => state.request.operatingExpenses
    );
    const decOperatingExpenses = useSelector(
        (state) => state.request.decOperatingExpenses
    );
    const promotersTemp = useSelector((state) => state.request.promoters);
    const creditToDebt = useSelector((state) => state.request.creditToDebt);
    const opTotal = useSelector(
        (state) => state.request.operatingExpensesTotal
    );

    const { administrativeCostsArray = [] } = useSelector(
        (state) => state.request.administrativeCosts
    );

    const dispatch = useDispatch();
    
    // Update when component unmount
    useEffect(() => {
        return () => {
            dispatch(saveApplication());
        };
    },[]);

    /**
     * Calculate the total operative expenses based on specific conditions and expense categories.
     *
     * @returns {number} The calculated total operative expenses.
     */
    const calculateTotalOP = () => {
        // Initialize a temporary object to store expense categories.
        let tempObj;

        // Check if there is no 'RESGUARDO' scheme in the 'returnAndCommission' array.
        if (
            returnAndCommission.find(
                (element) => element.scheme == 'RESGUARDO'
            ) == undefined
        ) {
            tempObj = {
                comisionesConvencionales:
                    operatingExpenses.comisionesConvencionales,
                costoTraslado: operatingExpenses.costoTraslado,
                comisionesBancarias: operatingExpenses.comisionesBancarias,
                secondBankCommission: operatingExpenses.secondBankCommission,
                costoProvedor4ST: operatingExpenses.costoProvedor4ST,
                costoEfectivo: operatingExpenses.costoEfectivo,
                costoFID: operatingExpenses.costoFID,
                costoExtra: operatingExpenses.costoExtra,
                costoSC: operatingExpenses.costoSC,
                costoSindicato: operatingExpenses.costoSindicato,
                comisionSpei: operatingExpenses.comisionSpei,
                comisionEdenredKV: operatingExpenses.comisionEdenredKV,
                comisionEdenredCorpo: operatingExpenses.comisionEdenredCorpo,
                comisionFacil: operatingExpenses.comisionFacil,
            };
        } else {
            // Use alternative expense categories if 'RESGUARDO' scheme is present.
            tempObj = {
                comisionesConvencionales:
                    operatingExpenses.comisionesConvencionales,
                costoTraslado: operatingExpenses.costoTraslado,
                comisionesBancarias: operatingExpenses.bankCommissionSafeguard,
                secondBankCommission:
                    operatingExpenses.secondBankCommissionSafeguard,
                costoProvedor4ST: operatingExpenses.corporativeCostSafeguard,
                costoEfectivo: operatingExpenses.costoEfectivo,
                costoFID: operatingExpenses.costoFID,
                costoExtra: operatingExpenses.costoExtra,
                costoSC: operatingExpenses.costoSC,
                costoSindicato: operatingExpenses.costoSindicato,
                comisionSpei: operatingExpenses.comisionSpei,
                comisionEdenredKV: operatingExpenses.comisionEdenredKV,
                comisionEdenredCorpo: operatingExpenses.comisionEdenredCorpo,
                comisionFacil: operatingExpenses.comisionFacil,
            };
        }

        // Calculate the total expenses by summing up valid numeric values in 'tempObj'.
        let totalExpenses = Object.values(tempObj).reduce((a, b) => {
            if (!isNaN(b)) {
                return a + Number(b);
            }

            return a;
        }, 0);

        // Convert 'creditToDebtExpenses' to a numeric value.
        let creditToDebtExpensesTemp = Number(creditToDebtExpenses);

        // Calculate the overall total by adding up various expense components.
        let total =
            totalExpenses +
            creditToDebtExpensesTemp +
            applicationTemp.gastos_abono_a_deuda +
            applicationTemp.gastos_promotoria;

        // Return the final calculated total operative expenses.
        return total;
    };

    /**
     * Generate an object containing various calculated totals and data related to financial transactions and expenses.
     *
     * @returns {object} An object containing financial and transaction-related data.
     */
    const makeObject = () => {
        // Calculate the total commission for the 'corporative' scheme from 'depositsTemp'.
        let totalCommissionCorporative = depositsTemp.reduce(
            (accumulator, object) => {
                return (
                    accumulator + Number(object?.commission_corporative || 0)
                );
            },
            0
        );

        // Calculate the total import funding from 'depositsTemp'.
        let totalImportFunding = depositsTemp.reduce((accumulator, object) => {
            return accumulator + Number(object?.import_funding || 0);
        }, 0);

        // Calculate the total operative expenses using a separate function 'calculateTotalOP'.
        let totalExpenses = calculateTotalOP();

        // Calculate various sums from the 'returnAndCommission' array.
        const tempBalance = returnAndCommission.reduce(
            (accumulator, object) => {
                return accumulator + Number(object?.balance || 0);
            },
            0
        );
        const tempReturn = returnAndCommission.reduce((accumulator, object) => {
            return accumulator + Number(object?.return_value || 0);
        }, 0);
        const tempCommission = returnAndCommission.reduce(
            (accumulator, object) => {
                return accumulator + Number(object?.commission || 0);
            },
            0
        );
        const tempISN = returnAndCommission.reduce((accumulator, object) => {
            return accumulator + Number(object?.isn || 0);
        }, 0);
        const tempISR = returnAndCommission.reduce((accumulator, object) => {
            return accumulator + Number(object?.isr || 0);
        }, 0);

        const tempSocialCost = returnAndCommission.reduce((accumulator, object) => {
            return accumulator + Number(object?.social_cost || 0);
        }, 0);

        const tempIva = returnAndCommission.reduce((accumulator, object) => {
            return accumulator + Number(object?.iva || 0);
        }, 0);

        // Create an array 'arrayOfPayers' containing all 'fundings' from 'returnAndCommission'.
        let arrayOfPayers = [];
        returnAndCommission.forEach((element) => {
            element?.fundings.forEach((element) => arrayOfPayers.push(element));
        });

        // Calculate the total amount for all payers in 'arrayOfPayers'.
        const amountPayers = arrayOfPayers.reduce((accumulator2, object) => {
            return accumulator2 + Number(object?.amount);
        }, 0);

        // Modify 'depositsTemp' by scaling 'expense' and 'cost' properties by 100.
        let depositsMod = depositsTemp.map((element) => {
            return {
                ...element,
                client_name: `${element.client.name} - ${element.company_type}`,
                expense: element.expense * 100,
                cost: element.cost * 100,
            };
        });

        // Calculate the total commission for promoters and credit-to-debt transactions.
        const promoterCommissionTotal = promotersTemp.promotersArray.reduce(
            (accumulator2, object) => {
                return accumulator2 + Number(object?.commission);
            },
            0
        );
        const creditCommissionTotal = creditToDebt.creditArray.reduce(
            (accumulator2, object) => {
                return accumulator2 + Number(object?.commission);
            },
            0
        );

        // Initialize 'totalAmounts' to calculate total funding amounts for promoters.
        let totalAmounts = 0;
        const totalFundingsPromoters = promotersTemp.promotersArray.forEach(
            (item) => {
                totalAmounts += item.fundings.reduce(
                    (sum, { amount }) => sum + amount,
                    0
                );
            }
        );

        const totalFundingAvailableKV = applicationTemp.fundings.reduce(
            (accumulator2, object) => {
                return accumulator2 + Number(object?.amount);
            },
            0
        );
        
        const totalAdministrativeExpenses = administrativeCostsArray.reduce((acc, expense) => acc + expense.manual_import, 0);

        // Return an object containing all the calculated totals and data.
        return {
            administrative_expenses: administrativeCostsArray,
            total_administrative_expenses: totalAdministrativeExpenses,
            executive: applicationTemp.executive.first_name,
            application_number: applicationTemp.key,
            type_application: applicationTemp.folio,
            available_fundings: applicationTemp.fundings,
            total_fundings_available_kv: totalFundingAvailableKV,
            date: applicationTemp.created_datetime.substr(0, 10),
            total_operative_expenses: applicationTemp.total_operative_expenses,
            total_promoter_commission: promotersTemp.commissionTotal,
            total_promoter_balance: promotersTemp.amountTotal,
            total_available_final: applicationTemp.total_available_KV,
            total_deposits: depositTotal,
            total_balance: tempBalance,
            total_commission_scheme: tempCommission,
            total_return: tempReturn,
            total_isn: tempISN,
            total_isr: tempISR,
            total_social_cost: tempSocialCost,
            total_iva: tempIva,
            total_payers: amountPayers,
            total_perceptions: applicationTemp.total_perceptions,
            invoice: applicationTemp.invoice,
            total_promoters: promoterCommissionTotal,
            total_payers_promoters: totalFundingsPromoters,
            total_commissions_credit: creditCommissionTotal,
            total_expenses: opTotal,
            total_commission_corporative: totalCommissionCorporative,
            total_import_funding: totalImportFunding,
            total_credit_to_debt_commission: creditToDebt.commissionTotal,
            total_credit_to_debt_amount: creditToDebt.amountTotal,
            deposits: depositsMod,
            return_commission: returnAndCommission.map(item => {
                if (item.scheme === "RESGUARDO") {
                    return { ...item, base_commission: "RESGUARDO" };
                }
                return item;
            }),
            operative_expense: operatingExpenses,
            dec_operating_expenses: decOperatingExpenses,
            promoters: promotersTemp,
            credit_to_debt: creditToDebt,
            available_kv_observations: availableKV.observations,
        };
    };

    /**
     * Create a PDF document representing financial and transaction-related data.
     *
     * @returns {void}
     */
    const createObjectToPDF = () => {
        // Generate a PDF representation of financial and transaction-related data using the 'getPDFInternalRequest' function.
        getPDFInternalRequest(makeObject())
            .then((response) => {
                try {
                    // Create a URL for the PDF content.
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );

                    // Create a link element to download the PDF.
                    const link = document.createElement('a');
                    link.href = url;

                    // Set the 'download' attribute for the link with the PDF file name.
                    link.setAttribute('download', `${applicationTemp.key}.pdf`);

                    // Append the link to the document body.
                    document.body.appendChild(link);

                    // Simulate a click on the link to initiate the download.
                    link.click();

                    // Display a success message after generating the report.
                    handleSuccess('Reporte generado');
                } catch (error) {
                    // Handle any errors that occur during the process and display an error message.
                    handleError(error, 'Error en reporte');
                }
            })
            .catch((error) => console.log(error));
    };

    return (
        <Grid
            container
            spacing={2}
            style={{ border: '1px solid white' }}
        >
            {application && (
                <>
                    <Button
                        variant='text'
                        color='primary'
                        onClick={() => {
                            createObjectToPDF();
                        }}
                    >
                        <PictureAsPdfIcon />
                    </Button>
                    <Grid
                        item
                        xs={12}
                    >
                        <Grid
                            container
                            spacing={2}
                            style={{ border: '1px solid white' }}
                        >
                            <GridItem
                                xs={3}
                                title='Ejecutivo'
                                text={`${application.executive.first_name} ${application.executive.last_name}`}
                            />

                            {application?.folio !== 'SOLICITUD DE RETIRO' ? (
                                <GridItem
                                    xs={3}
                                    title='Perfil'
                                    text={getProfileID(
                                        application?.executive?.profile
                                    )}
                                />
                            ) : (
                                <></>
                            )}
                            {application?.folio !== 'SOLICITUD DE RETIRO' ? (
                                <GridItem
                                    xs={3}
                                    title='Departamento'
                                    text={getDepartmentID(
                                        application?.executive?.department
                                    )}
                                />
                            ) : (
                                <></>
                            )}
                            {application.folio !== 'SOLICITUD DE RETIRO' ? (
                                <GridItem
                                    xs={3}
                                    title='Posición'
                                    text={getPositionID(
                                        application?.executive?.position
                                    )}
                                />
                            ) : (
                                <></>
                            )}
                            {application.folio !== 'SOLICITUD DE RETIRO' ? (
                                <GridItem
                                    xs={3}
                                    title='Cliente'
                                    text={
                                        application?.client?.name ||
                                        'No asignado'
                                    }
                                />
                            ) : (
                                <></>
                            )}
                            <GridItem
                                xs={3}
                                title='Sede'
                                text={getStateByCode(application.headquarter)}
                            />
                            {application.folio !== 'SOLICITUD DE RETIRO' ? (
                                <GridItem
                                    xs={3}
                                    title='Promotor'
                                    text={capitalizeFirstLetter(
                                        `${application.promoter.name} ${application.promoter.lastname}`
                                    )}
                                />
                            ) : (
                                <></>
                            )}
                            {application.folio !== 'SOLICITUD DE RETIRO' ? (
                                <GridItem
                                    xs={3}
                                    title='Clave de promotor'
                                    text={application.promoter.key}
                                />
                            ) : (
                                <></>
                            )}
                            {application.folio !== 'SOLICITUD DE RETIRO' ? (
                                <>
                                    <GridItem
                                        xs={3}
                                        title='Comisión 1'
                                        text={`${
                                            application.promoter.commission_1 *
                                            100
                                        }%`}
                                    />
                                    <GridItem
                                        xs={3}
                                        title='Comisión 2'
                                        text={`${
                                            application.promoter.commission_2 *
                                            100
                                        }%`}
                                    />
                                    <GridItem
                                        xs={3}
                                        title='Comisión 3'
                                        text={`${
                                            application.promoter.commission_3 *
                                            100
                                        }%`}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

const GridItem = ({ xs, title, text }) => (
    <Grid
        item
        xs={xs}
        style={{ margin: '10px 0' }}
    >
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </Grid>
);
