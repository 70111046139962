
export const ExpenseCostAmountCalculation = (
    { application, safeguardsTotal, costKVGroup, deposit, expenseKVGroup },
    setCostAmountKVGroup,
    setExpenseAmountKVGroup
) => {
    if (application.folio === 'RETIRO DE RESGUARDO') {
        // Calculate the 'costAmountKVGroup' based on 'safeguardsTotal' for 'RETIRO DE RESGUARDO'.
        setCostAmountKVGroup(
            (Number(safeguardsTotal) * Number(costKVGroup)) / 100
        );
        setExpenseAmountKVGroup(
            (Number(safeguardsTotal) * Number(expenseKVGroup)) / 100
        );
    } else {
        // Calculate the 'costAmountKVGroup' based on 'deposit' for other application types.
        setCostAmountKVGroup((Number(deposit) * Number(costKVGroup)) / 100);
        setExpenseAmountKVGroup(
            (Number(deposit) * Number(expenseKVGroup)) / 100
        );
    }
};
