import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { ApplicationDetailTab } from './ApplicationDetailTab';
import { AdministrativeCosts } from '../../containers/AdministrativeCosts';
import { AdministrativeCostsWithdraw } from '../../containers/AdministrativeCostsWithdraw';
import { ReturnAndComission } from '../../containers/ReturnAndComission';

export function WithdrawalApplicationTabs({
    currentTabLocal,
    handleChange,
    applicationTemp,
    application,
    handleGetDeposits,
    handleGetReturnCommissions,
}) {
    return (
        <>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
            >
                <Tabs
                    value={currentTabLocal}
                    onChange={handleChange}
                    aria-label='basic tabs example'
                    variant='scrollable'
                    scrollButtons='auto'
                >
                    <Tab
                        label='Detalle'
                        {...a11yProps(0)}
                    />
                    
                    <Tab
                        label='Gastos Administrativos'
                        {...a11yProps(1)}
                    />
                    <Tab
                        label='Retorno y comisión Cliente'
                        {...a11yProps(2)}
                    />
                    
                </Tabs>
            </Box>
            <TabPanel
                value={currentTabLocal}
                index={0}
            >
                <ApplicationDetailTab application={application} />
            </TabPanel>
            
            <TabPanel
                value={currentTabLocal}
                index={1}
            >
                <AdministrativeCostsWithdraw applicationTemp={application} />
            </TabPanel>

            <TabPanel
                value={currentTabLocal}
                index={2}
            >
                <ReturnAndComission
                    application={application}
                    handleGetReturnCommissions={handleGetReturnCommissions}
                />
            </TabPanel>
            
        </>
    );
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};


const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};
