import React from 'react';
import {
    Alert,
    Button,
    IconButton,
    Tooltip,
    Stack,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch, useSelector } from 'react-redux';
import { handleError, handleSuccess } from '../helpers/utils';

import {
    setCurrentApplication,
    updateApplication,
} from '../actions/applications';
import { calculateAvailableKV } from '../helpers/totalAvailableKV';
import {
    deleteAdministrativeCost,
    setAdministrativeCostsAction,
} from '../actions/administrativeCosts';
import { NewAdministrativeCostsWithdraw } from '../components/applications/NewAdministrativeCostsWithdraw';
import { money } from '../helpers/money';
import { PayerDialogReturnPromoters } from '../components/applications/PayerDialogReturnPromoters';
import { NewPayerAmountAdministrativeExpenses } from '../components/applications/NewPayerAmountAdministrativeExpenses';
import {
    deleteAdministrativeCostWithdraw,
    setAdministrativeCostsWithdrawAction,
} from '../actions/administrativeCostsWithdraw';

export const AdministrativeCostsWithdraw = ({ applicationTemp = {} }) => {
    const [currentRow, setCurrentRow] = React.useState(null);
    const { application = null } = useSelector((state) => state.request);

    const creditToDebt = useSelector((state) => state.request.creditToDebt);
    const applicationInvoice = useSelector((state) => state.request.application.folio)
    const totalDeposits = useSelector((state) => state.request.depositTotal)
    const { administrativeCostsWithdrawArray = [], totalImports = 0 , totalCommissions = 0} =
        useSelector((state) => state.request.administrativeCostsWithdraw);
    const administrativeCostsWithdraw = useSelector(
        (state) => state.request.administrativeCostsWithdraw
    );
    const [openPayerDialogReturn, setOpenPayerDialogReturn] =
        React.useState(false);
    const handlePayerDialogReturn = () => setOpenPayerDialogReturn(false);
    const [tempArr, setTempArr] = React.useState([]);
    const handleCloseDialog = () => setOpenModal(false);
    const handleCloseDialog2 = () => setOpenModal2(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [openModal2, setOpenModal2] = React.useState(false);
    const [change2, setChange2] = React.useState(false);
    const [deleteTemp, setDelete] = React.useState(false);

    /* Payer modal handler */
    const handleValue = () => {
        setDelete(!deleteTemp);
    };

    const handleDelete2 = () => {
        setChange2(!change2);
    };
    const dispatch = useDispatch();

    const columns = [
        {
            field: 'col9',
            headerName: 'Acciones',
            width: 150,
            // Add actions to each case
            renderCell: ({ row }) => (
                <>
                    <Tooltip title='Nuevo fondeo'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='new-funding'
                            color='primary'
                            onClick={() => {
                                setCurrentRow(row);
                                setOpenModal2(true);
                            }}
                        >
                            <AttachMoneyIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Ver fondeos'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='view-fundings'
                            color='primary'
                            onClick={() => {
                                setCurrentRow(row);
                                setTempArr(row?.fundings);
                                setOpenPayerDialogReturn(true);
                            }}
                        >
                            <PriceChangeIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Eliminar'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='delete'
                            color='primary'
                            onClick={() => {
                                handleDeletePromoter(row.id);

                                handleDelete2();
                                handleValue();
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
        {
            field: 'campus',
            headerName: 'Sede',
            width: 200,
            type: 'singleSelect',
        },
        {
            field: 'payer',
            headerName: 'Pagadora',
            width: 200,
        },
        {
            field: 'concept',
            headerName: 'Concepto',
            width: 180,
        },
        {
            field: 'amount',
            headerName: 'Importe',
            width: 170,
            renderCell: ({ row }) => <>{money(row.amount)}</>,
        },
        {
            field: 'bank',
            headerName: 'Banco',
            width: 170,
        },
        {
            field: 'bank_commission',
            headerName: 'Comisión Bancaria',
            width: 170,
            renderCell: ({ row }) => <>{money(row.bank_commission)}</>,
        },
        {
            field: 'comments',
            headerName: 'Comentarios',
            width: 170,
        },
    ];

    /**
     * Update Redux data related to administrative costs and total imports.
     *
     * This function calculates the total imports from an array of objects and updates Redux store data related to administrative costs and the current application.
     *
     * @param {Array} array - An array of objects containing information about manual imports.
     */
    const handleUpdateTotals = (array) => {
        // Calculate the total imports from the array
        const newTotalImports = array.reduce(
            (sum, { amount }) => sum + amount,
            0
        );

        const newTotalCommissions = array.reduce(
            (sum, { bank_commission }) => sum + Number(bank_commission),
            0
        );

        // Updates totals on database
        updateApplication(application.id, {
            ...application,
            total_administrative_withdraw: newTotalImports,
        });

        // Update Redux store data
        dispatch(
            setAdministrativeCostsWithdrawAction({
                ...administrativeCostsWithdraw,
                totalImports: newTotalImports,
                totalCommissions: newTotalCommissions,
                administrativeCostsWithdrawArray: array,
            })
        );

        dispatch(
            setCurrentApplication({
                ...application,
                total_administrative_expenses: newTotalImports,
                fundings: application.fundings,
                promoter: application.promoter,
                executive: application.executive,
            })
        );
    };

    /**
     * Delete a user from the administrative costs table.
     *
     * This function removes a user with the specified ID from the administrative costs array, updates Redux store data, and triggers a delete request to the API. It handles success and error feedback messages accordingly.
     *
     * @param {number} id - The ID of the user to be deleted.
     */
    const handleDeletePromoter = (id) => {
        // Filter the administrativeCostsArray to remove the user with the specified ID
        let newArr = administrativeCostsWithdrawArray.filter(
            (element) => element.id != id
        );

        // Send a delete request to the API to delete the user
        deleteAdministrativeCostWithdraw(id)
            .then(() => {
                // Handle success: Display a success message and update totals
                handleSuccess('Registro eliminado correctamente');
                handleUpdateTotals(newArr);
            })
            .catch((error) => {
                // Handle error: Display an error message
                handleError(
                    error,
                    'Ocurrió un error al intentar eliminar el registro'
                );
            });
    };

    /**
     * Save administrative expenses and update total available KV balance.
     *
     * This function updates the administrative expenses of an application, recalculates the total available KV balance, and triggers an update request to the API. It handles success feedback and updates relevant data in the Redux store.
     */
    const saveAdministrativeExpenses = () => {
        const applicationVariables = {
            totalCommissionScheme: application.total_commission_scheme,
            totalOperativeExpenses: application.total_operative_expenses,
            totalPromoter: application.total_promoter,
            totalCreditToDebt: application.total_credit_to_debt,
            totalAdministrativeExpenses: application.total_administrative_expenses,
            totalDeposits: totalDeposits,
        }


        // Update the application data with the recalculated total available KV balance
        updateApplication(application.id, {
            ...application,
            total_available_KV: Number(
                calculateAvailableKV(
                    applicationVariables,
                    application.account_kv,
                    applicationInvoice
                ).toFixed(2)
            ),
        })
            .then(({ data }) => {
                // Update the Redux store with the updated application data
                dispatch(
                    setCurrentApplication({
                        ...data,
                        fundings: application.fundings,
                        promoter: application.promoter,
                        executive: application.executive,
                    })
                );

                // Display a success message
                handleSuccess('ABONO A DEUDA GUARDADO');
            })
            .catch((error) => {
                // Handle error: Display an error message
                handleError(
                    error,
                    'Ocurrió un error al intentar guardar los gastos administrativos'
                );
            });
    };

    return (
        <div>
            {/* Payer return dialog */}
            <PayerDialogReturnPromoters
                currentRow={currentRow}
                fundings={tempArr}
                show={openPayerDialogReturn}
                handleClose={handlePayerDialogReturn}
                updateTotals={handleUpdateTotals}
                sectionTitle='Gastos Administrativos Retiro'
            />
            {/* Creates a new Administrative Cost */}
            <NewAdministrativeCostsWithdraw
                show={openModal}
                handleClose={handleCloseDialog}
                handleChange={handleDelete2}
                handleUpdateTotals={handleUpdateTotals}
                sectionTitle='Abono a Deuda'
            />
            {/* Creates a new Payer */}
            <NewPayerAmountAdministrativeExpenses
                show={openModal2}
                handleClose={handleCloseDialog2}
                currentRow={currentRow}
                currentRows={creditToDebt}
                handleChange={handleDelete2}
                sectionTitle='Abono a Deuda'
            />

            <Stack
                direction='row'
                alignItems='flex-end'
                justifyContent='space-between'
                style={{
                    border: '1px solid white',
                    padding: '5px',
                    width: '100%',
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: '10px' }}
                    >
                        <Grid
                            item
                            xs={8}
                        >
                            <Typography
                                variant='h5'
                                gutterBottom
                            >
                                Gastos Administrativos Retiro
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                        >
                            {' '}
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    saveAdministrativeExpenses();
                                }}
                            >
                                <SaveIcon /> Guardar
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                        >
                            <Button
                                variant='contained'
                                style={{ backgroundColor: '#143f6c' }}
                                onClick={() => {
                                    setOpenModal(true);
                                }}
                            >
                                Nuevo Gasto
                            </Button>
                        </Grid>
                        <Box sx={{ flexGrow: 1, ml: 2, mb: 4 }}>
                            <Grid
                                container
                                spacing={2}
                                sx={{ mt: 2 }}
                            >
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <ItemTexts
                                        title='Total Importes'
                                        text={money(totalImports)}
                                    />
                                </Grid>
                                
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            marginBottom: '10px',
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            {administrativeCostsWithdrawArray?.length > 0 ? (
                                <DataGrid
                                    sx={{
                                        '& .MuiDataGrid-cell': {
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            padding: '10px',
                                        },
                                    }}
                                    style={{
                                        height: '600px',
                                        margin: '10px 0',
                                    }}
                                    density='compact'
                                    getRowHeight={() => 'auto'}
                                    rows={administrativeCostsWithdrawArray}
                                    columns={columns}
                                    components={{ Toolbar: GridToolbar }}
                                    localeText={
                                        esES.components.MuiDataGrid.defaultProps
                                            .localeText
                                    }
                                />
                            ) : (
                                <Alert severity='info'>
                                    No hay ninguna fila
                                </Alert>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
            <hr />
        </div>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);
