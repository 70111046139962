export const types = {
	// Auth
	login: '[Auth] Login user',
	logout: '[Auth] Logout user',

	// User info
	setUser: '[User] Set info',
	cleanUser: '[User] Clean info',

	// Catalog info
	setCatalogs: '[Catalog] Set info',
	cleanCatalogs: '[Catalog] Clean info',

	// Deposits info
	setDeposits: '[Deposit] Set',
	addDeposit: '[Deposit] Add',
	cleanDeposits: '[Deposit] Clean ',
	updateDeposit: '[Deposit] Update',
	deleteDeposit: '[Deposit] Delete',
	setDepositTotal: '[Deposit] Total amount',
	setCommissionTotal: '[Commission] Total amount',
	setCommissionCorporative: '[Commission] Set Corporative commission',
	setCommissionBank: '[Commission] Set Bank commission',

	//List of safeguards
	setListOfSafeGuards: '[List of Safeguards] Set',


	//Get withdraw:
	getWithdrawTotal: '[Get totalWithdraw per client] Get',

	// total Import Fundings info
	setTotalImportFunding: '[Import Fundings] Set',

	// Administrative Costs:
	setAdministrativeCosts: '[Administrative Costs] Set',

	// Administrative Costs Withdraw:
	setAdministrativeCostsWithdraw: '[Administrative Costs Withdraw] Set',

	// Request And Commission info
	requestEvaluation: '[Return And Commission] update Total requestEvaluation',
	updatedReturn: '[Return And Commission] update returnAndCommission',

	// Operating Expenses
	updateOperatingExpenses: '[Operating Expenses] update',
	updateOperatingExpensesTotal: '[Operating Expenses] update total',
	updateOperatingExpensesTotal2: '[Operating Expenses] update total2',
	updateDecOperatingExpenses: '[Operating Expenses] update decitions',

	//Promoter
	updatePromoters: '[Promoter] updated promoters',

	// Credit To Debt
	updateCreditToDebt: '[Credit To Debt] update Credit',

	// Reset request to initial state
	resetRequest: '[Request] Reset to initial state',

	// Sidebar state
	setShowSideBar: '[Sidebar] Set state',
	resetSideBar: '[Sidebar] Reset state',

	// Current application
	currentApplication: '[Application] Current value',

	// Current tab
	currentTab: '[Application] Current tab',

	// Commission Scheme total
	totalCommissionSchemeTotal: '[Application] Commission scheme (Return)',

	//Update the available KV
	updateAvailableKV: '[AvailableKV] Update',

	//Update Perceptions or invoice
	updatePerceptionsInvoice: '[Perceptions or Invoice] update',

	setScheme: '[Get Scheme] get',
};
