import React from 'react';
import { Typography, Grid, Box, Alert, Stack } from '@mui/material';
import { formatDateTimeMX } from '../../helpers/hours';

export const SimpleCatalogDetail = ({
	data,
	conceptName,
	conceptNameLabel,
}) => {
	return (
		<Box sx={{ width: '100%' }}>
			{data ? (
				<>
					<Stack
						direction='column'
						alignItems='flex-start'
						justifyContent='space-between'
						mb={1}
						style={{
							border: '1px solid white',
							padding: '20px 0 0 20px',
						}}
					>
						<Typography variant='h4' gutterBottom>
							Clave: {data.key}
						</Typography>
						<Typography variant='p' gutterBottom>
							<b>Actualizado:</b> {formatDateTimeMX(data.updated_at)}
						</Typography>
						<Typography variant='p' gutterBottom>
							<b>Creado:</b> {formatDateTimeMX(data.created_at)}
						</Typography>
					</Stack>

					<Grid
						container
						spacing={2}
						style={{ border: '1px solid white' }}
						margin={1}
					>
						<Grid item xs={12}>
							<Grid container spacing={2} style={{ border: '1px solid white' }}>
								<GridItem
									xs={6}
									title={conceptNameLabel}
									text={`${data[conceptName]}`}
								/>
								<GridItem
									xs={6}
									title='Descripción'
									text={data.description === '' ? '-' : `${data.description}`}
								/>
							</Grid>
						</Grid>
					</Grid>
				</>
			) : (
				<div style={{ padding: '40px' }}>
					<Alert severity='warning'>No se encontraron detalles</Alert>
				</div>
			)}
		</Box>
	);
};

const GridItem = ({ xs, title, text }) => (
	<Grid item xs={xs} style={{ margin: '10px 0' }}>
		<Typography variant='p'>
			<b>{title}</b>
		</Typography>
		<Typography variant='h6'>{text}</Typography>
	</Grid>
);
