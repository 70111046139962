import React from 'react';
import { Stack } from '@mui/system';
import { Typography, Button, Grid } from '@mui/material';
import { saveApplication } from '../../helpers/SaveApplication';
import { useDispatch } from 'react-redux';

export function StackApplication({ formatDateTimeMX, application }) {
    const dispatch = useDispatch();
    return (
        <Stack
            direction='column'
            alignItems='flex-start'
            justifyContent='space-between'
            mb={1}
            style={{
                border: '1px solid white',
                padding: '20px 0 0 20px',
            }}
        >
            <Grid
                container
                spacing={0}
            >
                <Grid
                    item
                    xs={10}
                >
                    <Typography
                        variant='h4'
                        gutterBottom
                    >
                        Clave de solicitud: {application.key}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            dispatch(saveApplication());
                        }}
                    >
                        Actualizar
                    </Button>
                </Grid>
            </Grid>

            <Typography
                variant='p'
                gutterBottom
            >
                <b>Actualizado:</b> {formatDateTimeMX(application.updated_at)}
            </Typography>
            <Typography
                variant='p'
                gutterBottom
            >
                <b>Creado:</b> {formatDateTimeMX(application.created_datetime)}
            </Typography>
            <Typography
                variant='p'
                gutterBottom
            >
                <b>Folio:</b> {application.folio}
            </Typography>
        </Stack>
    );
}
