import React from 'react';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
	toast: true,
	position: 'bottom-end',
	showConfirmButton: false,
	timer: 2500,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer);
		toast.addEventListener('mouseleave', Swal.resumeTimer);
	},
});

export const toastSweet = (icon, message) => {
	Toast.fire({
		icon: icon,
		title: message,
	});
};

export const confirmModal = (icon, title, text, buttonText) => {
	return Swal.fire({
		icon: icon,
		title: title,
		text: text,
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		confirmButtonText: buttonText,
		cancelButtonColor: '#d33',
		cancelButtonText: 'Cancelar',
	});
};

export const inputResetPassword = async () => {
	return await Swal.fire({
		title: 'Reestablecer contraseña',
		inputLabel: 'Te enviaremos un correo para que cambies tu contraseña',
		inputPlaceholder: 'Escribe tu correo electrónico',
		confirmButtonText: 'Enviar correo',
		focusConfirm: false,
		confirmButtonColor: '#183b65',
		preConfirm: async () => {
			const email = document.getElementById('email').value;

			if (email.length > 0) {
				return { email };
			} else {
				console.log('Faltan datos');
			}
		},
	});
};
