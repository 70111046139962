import axios from 'axios';
import { getToken } from './auth';
import { types } from '../types/types';


//Set Administrative Costs on RX
export const setAdministrativeCostsAction = (data) => ({
	type: types.setAdministrativeCosts,
	payload: data,
});

// Get Administrative Cost info by id
export const getAdministrativeCostInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/administrative-costs/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get all AdministrativeCost
export const getAdministrativeCost = async (idApplication) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/administrative-costs/?application=${idApplication}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Administrative Cost
export const registerAdministrativeCost = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/application/administrative-costs/create',
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update Administrative Cost
export const updateAdministrativeCost= async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/application/administrative-costs/update/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete Adminsitrative Cost
export const deleteAdministrativeCost = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/application/administrative-costs/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};
