import { setCurrentApplication, updateApplication } from "../actions/applications";
import { calculateAvailableKV } from "./totalAvailableKV";
import { handleSuccess } from "./utils";

/**
 * Action to save an application.
 * @returns {function} A function that can be used as a Redux action.
 */
export const saveApplication = () => {
    return (dispatch, getState) => {
        // Get the current state from the Redux store.
        const { application, depositTotal } = getState().request;

        // Extract relevant variables from the application.
        const applicationVariables = {
            totalCommissionScheme: application.total_commission_scheme,
            totalOperativeExpenses: application.total_operative_expenses,
            totalPromoter: application.total_promoter,
            totalCreditToDebt: application.total_credit_to_debt,
            totalAdministrativeExpenses: application.total_administrative_expenses,
            totalDeposits: depositTotal,
        };

        // Update the application by calling the `updateApplication` function.
        updateApplication(application.id, {
            ...application,
            total_imports: depositTotal,
            total_available_KV: Number(
                calculateAvailableKV(
                    applicationVariables,
                    application.account_kv,
                    application.folio,
                    depositTotal
                ).toFixed(2)
            ),
        }).then(({ data }) => {
            // Dispatch an action to set the current application in the Redux state.
            dispatch(
                setCurrentApplication({
                    ...data,
                    fundings: application.fundings,
                    promoter: application.promoter,
                    executive: application.executive,
                })
            );

            // Display a success message.
            handleSuccess('SOLICITUD GUARDADA');
        });
    };
};
