import React from 'react';
import {
	getBaseCommissions,
	getBaseCommissionInfo,
	deleteBaseCommission,
	updateBaseCommission,
	registerBaseCommission,
	searchBaseCommission,
} from '../../actions/catalogs';
import { SimpleCatalogTable } from './SimpleCatalogTable';

// Base Commissions Table
export const BaseCommission = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getBaseCommissions,
		getCatalogInfo: getBaseCommissionInfo,
		deleteCatalog: deleteBaseCommission,
		updateCatalog: updateBaseCommission,
		registerCatalog: registerBaseCommission,
		searchCatalog: searchBaseCommission,
		catalogSingular: 'Comisión Base',
		catalogPlural: 'Comisiones Base',
		pronounSingular: 'la',
		pronoun: 'a',
	};

	return <SimpleCatalogTable config={configuration} />;
};
