import axios from 'axios';

export const getPDFInternalRequest = async (objectApplication) => {
	return await axios({
		method: 'post',
		// url: "https://30c3dfd.online-server.cloud/api/KV/intenal_request",
		url: 'https://30c3dfd.online-server.cloud/api/KV/intenal_request/',
		responseType: 'blob',
		data: objectApplication,
	});
};
