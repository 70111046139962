import React from 'react';
import {
    Autocomplete,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { handleWarning } from '../../helpers/utils';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { money } from '../../helpers/money';
import CurrencyFormat from 'react-currency-format';
import { getPayers } from '../../actions/catalogs';
import { calculateCommissionCorporative } from '../../helpers/Deposits/DepositsFunctions';
import { validateForm } from '../../helpers/Deposits/ValidateForm';
import { handleSubmitDeposit } from '../../helpers/Deposits/HandleSubmit';
import { getSafeguardTotal } from '../../actions/returnAndCommissions';
import { ExpenseCostAmountCalculation } from '../../helpers/Deposits/ExpenseCostAmountCalculation';
import { KVFormComponent } from './DepositsComponents/KVFormComponent';
import { KVGroupComponent } from './DepositsComponents/KVGroupComponent';
import { BancarizadaFormComponent } from './DepositsComponents/BancarizadaFormComponent';
import SelectSafeguards from '../Select/SelectSafeguards';
const typeCompanyTemp = ['KV', 'KV + Grupo', 'Grupo', 'Grupo 2', 'Bancarizada'];
const typeCompanyTempFolio2 = ['Bancarizada'];

export const NewWithdrawForm = ({
    handleClose,
    clients = [],
    banks = [],
    bankCommissions = [],
    companies = [],
    transfers = [],
    data = null,
    application = null,
    showWithdraw = false,
    handleOperationSafeguards = () => {},
}) => {
    // Dispatch
    const dispatch = useDispatch();

    // Deposits
    const { deposits = [] } = useSelector((state) => state.request);
    const nameClient = useSelector(
        (state) => state.request.application.client.name
    );
    const { listOfSafeguardsPerClient = [] } = useSelector(
        (state) => state.request
    );

    // Form
    const [date, setDate] = useState(null);
    const [client, setClient] = useState(null);
    const [company, setCompany] = useState(null);
    const [bank, setBank] = useState(null);
    const [typeCompany, setTypeCompany] = useState(null);
    const [deposit, setDeposit] = useState(0.0);
    // const [deposit2, setDeposit2] = useState(0.0);
    const [costAmount, setCostAmount] = useState('');
    const [cost, setCost] = useState('');
    const [expenseAmount, setExpenseAmount] = useState('');
    const [conCompany, setConCompany] = useState('');
    const [transfer, setTransfer] = useState(null);
    const [substractionImport, setSubstractionImport] = useState(0);
    const [safeguardResults, setSafeguardResults] = useState(0);
    const [tempSafeguards, setTempSafeguards] = useState(null);

    //Form KV + Group
    const [costKVGroup, setCostKVGroup] = useState(''); //Porcentaje de Costo
    const [costAmountKVGroup, setCostAmountKVGroup] = useState(0); //Importe Costo
    const [expenseNameKVGroup, setExpenseNameKVGroup] = useState(0); //Empresa gasto
    const [costNameKVGroup, setCostNameKVGroup] = useState(''); //Empresa gasto
    const [expenseKVGroup, setExpenseKVGroup] = useState(''); //Porcentaje de gasto
    const [expenseAmountKVGroup, setExpenseAmountKVGroup] = useState(''); //importe de gasto
    const [, setCostName] = useState(null);
    const [, setExpense] = useState(null);
    const [, setExpenseName] = useState(null);
    const [hasCorporateCommissions, setHasCorporateCommissions] =
        useState(true);

    const [payersName, setPayersName] = useState([]);

    //Form Group
    const [commisionCorporative, setCommisionCorporative] = useState(0);
    const [importFunding, setImportFunding] = useState(0);
    const [doubleCommission, setDoubleCommission] = useState(0);

    const [corporativeFunding, setCorporativeFunding] = useState(
        companies[0]?.name
    );

    const [withdrawInput, setWithdrawInput] = useState(0.0);

    const [safeguardsTotal, setSafeguardsTotal] = useState(0);
    const [checkIfIsCorp, setCheckIfIsCorp] = useState(false);

    const clientTemp = useSelector((state) => state.request.application.client);

    const folio = 'RETIRO DE RESGUARDO';
    const applicationTemp = useSelector((state) => state.request.application);

    // Reset form
    const resetForm = () => {
        setDate(null);
        setClient(null);
        setCompany(null);
        setBank(null);
        setDeposit('');
        setCostName('');
        setCostAmount('');
        setCost('');
        setExpense('');
        setExpenseName('');
        setExpenseAmount('');
        setConCompany('');
        setTransfer(null);
        setCostKVGroup('');
        setCostAmountKVGroup('');
        setExpenseNameKVGroup('');
        setCostNameKVGroup('');
        setExpenseKVGroup('');
        setExpenseAmountKVGroup('');
        setTypeCompany('');
        setCorporativeFunding('');
        setSafeguardsTotal(0);
        setHasCorporateCommissions(true);
        setWithdrawInput(0);
    };

    const resetFormTemp = () => {
        setCostKVGroup('');
        setCostAmountKVGroup('');
        setExpenseNameKVGroup('');
        setExpenseKVGroup('');
        setExpenseAmountKVGroup('');
        setCostNameKVGroup('');
    };

    /**
     * Calculate the commission amount based on the selected bank and deposit amount.
     *
     * @returns {number} The calculated commission amount.
     */
    const getCommission = () => {
        // Initialize the commission variable to 0.
        let commission = 0;

        // Find the corresponding bank commission rate from 'bankCommissions' based on the selected 'bank'.
        const bankCommission =
            bankCommissions.find((item) => item.concept === bank) || null;

        // If a bank commission rate is found, set the 'commission' variable accordingly.
        if (bankCommission) {
            commission = bankCommission.first_commission / 100;
        }

        // Calculate the commission amount based on the selected 'bank' and 'deposit' amount.
        if (bank == 'STP') {
            // For 'STP' bank, apply a fixed commission rate and tax factor.
            return deposit * 0.002 * 1.16;
        } else if (bank == 'ASP INTEGRA') {
            // For 'ASP INTEGRA' bank, apply a fixed commission rate.
            return deposit * 0.002;
        } else if (bank == 'KUSPIT') {
            // For 'KUSPIT' bank, apply a fixed commission rate.
            return deposit * 0.0025;
        }

        // Return the final commission amount calculated based on the commission rate and deposit.
        return commission * deposit;
    };

    /**
     * This function creates and returns a deposit object based on various conditions, which can be used for saving deposit data.
     *
     * @returns {object} - A deposit object with properties based on the provided conditions.
     */

    const handleCloseAndReset = () => {
        resetForm();
        handleClose();
    };

    /**
     * This function handles the submission of deposit data. It either updates an existing deposit or registers a new one based on the conditions.
     */
    const handleSubmit = () => {
        const handleSubmitVariables = {
            bank,
            application,
            applicationTemp,
            clientTemp,
            tempSafeguards,
            deposits,
            folio,
            data,
            safeguardResults,
            withdrawInput,
            safeguardsTotal,
        };

        const objectForCreateDepositObj = {
            typeCompany,
            safeguardsTotal,
            costKVGroup,
            expenseKVGroup,
            expenseNameKVGroup,
            costNameKVGroup,
            costAmountKVGroup,
            expenseAmountKVGroup: typeof(expenseAmountKVGroup) === 'string' ? expenseAmountKVGroup?.replace(/[^\d.-]/g, '') : expenseAmountKVGroup,
            application,
            clients,
            companies,
            conCompany,
            banks,
            transfers,
            commisionCorporative,
            corporativeFunding,
            importFunding,
            doubleCommission,
            date,
            folio,
            deposit,
            client,
            company,
            bank,
            transfer,
            withdrawInput,
        };

        handleSubmitDeposit({
            handleSubmitVariables,
            objectForCreateDepositObj,
            getCommission,
            dispatch,
            handleCloseAndReset,
            handleOperationSafeguards,
            checkIfIsWithdraw: true,
        });
    };

    // Handle check button
    const handleCheck = (event) => {
        setHasCorporateCommissions(event.target.checked);
    };

    // Returns all data from selected deposit to edit
    useEffect(() => {
        setDate(data ? data.date : null);
        setClient(data ? data.client.name : null);
        setCompany(data ? data?.company?.name : null);
        setBank(data ? data?.bank?.name : null);
        setDeposit(data ? data.deposit : '');
        setCostName(data ? data?.cons_company?.cost_name : '');
        setCostAmount(data ? data.cost_amount * data.deposit : '');
        setCost(data ? data.cost : '');
        setExpense(data ? data?.cons_company?.expense : '');
        setExpenseName(data ? data?.cons_company?.expense_name : '');
        setExpenseAmount(data ? data.expense_amount * data.deposit : '');
        setConCompany(data ? data.cons_company : '');
        setTransfer(data ? data?.transfer?.concept : null);
        setTypeCompany(data ? data?.company_type : '');
        setCorporativeFunding(data ? data?.company_funding : '');
        setCostNameKVGroup(data ? data?.cost_name : '');
        setCostKVGroup(data ? data?.cost : '');
        setCostAmountKVGroup(data ? data?.cost_amount : '');
        setExpenseNameKVGroup(data ? data?.expense_name : '');
        setExpenseKVGroup(data ? data?.expense : '');
        setExpenseAmountKVGroup(data ? data?.expense_amount : '');
    }, [data]);

    /**
     * Validate the form data to ensure it meets specific criteria before submission.
     *
     * @returns {boolean} A boolean indicating whether the form data is valid (true) or not (false).
     */
    const isValidForm = () => {
        return validateForm(
            folio,
            client,
            typeCompany,
            deposit,
            costAmount,
            expenseAmount,
            cost,
            conCompany,
            date
        );
    };

    useEffect(() => {
        const listOfVariablesForCommission = {
            application,
            safeguardsTotal,
            typeCompany,
            hasCorporateCommissions,
            bank,
            deposit,
            checkIfIsCorp,
            withdrawInput,
        };
        calculateCommissionCorporative({
            listOfVariablesForCommission,
            setCommisionCorporative,
            setSubstractionImport,
            getCommission,
            setImportFunding,
            setDoubleCommission,
            checkIfIsWithdraw: true,
        });
    }, [
        withdrawInput,
        deposit,
        typeCompany,
        bank,
        safeguardsTotal,
        hasCorporateCommissions,
        checkIfIsCorp,
    ]);

    const setAmountSafeguards = (listOfSafeguards) => {
        setTempSafeguards(listOfSafeguards);
        const totalSafeguards = listOfSafeguards.reduce(
            (accumulator, safeguard) => {
                return accumulator + safeguard.balance;
            },
            0
        );
        setSafeguardsTotal(totalSafeguards);
    };

    useEffect(() => {
        getPayers()
            .then(({ data }) => {
                // Extract only the names of the payers from the data.
                const onlyNamesOfPayers = data.results.map(
                    (element) => element.name
                );
                // Update the component's state with the payer names.
                setPayersName(['-', ...onlyNamesOfPayers]);
            })
            .catch((error) => {
                // Handle any errors that may occur during the data retrieval.
                console.error('Error fetching payers:', error);
            });

        if (nameClient) {
            getSafeguardTotal(nameClient).then(({ data }) => {
                setSafeguardResults(data.results);
            });
        }
    }, []);

    const verifyIfCorp = (nameSelected) => {
        setCheckIfIsCorp(nameSelected.split('-')[0] == '[CORP] ');
    };

    return (
        <React.Fragment>
            <Dialog
                maxWidth='lg'
                open={showWithdraw}
                onClose={handleClose}
            >
                <DialogTitle>
                    Retiro de resguardo para la solicitud:{' '}
                    <b>{application?.key}</b>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Complete el formulario para crear un nuevo retiro de
                        resguardo
                    </DialogContentText>
                    <Box
                        noValidate
                        component='form'
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content',
                        }}
                    >
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={es}
                            utils={DateFnsUtils}
                        >
                            <hr />
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={4}
                                >
                                    {/* Date */}
                                    <DesktopDatePicker
                                        fullWidth
                                        label='Fecha'
                                        inputFormat='dd/MM/yyyy'
                                        value={date}
                                        onChange={(val) => {
                                            setDate(val);
                                        }}
                                        renderInput={(params) =>
                                            SelectComponent(params, 'Fecha')
                                        }
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={4}
                                >
                                    {/* Client */}
                                    <Autocomplete
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        required
                                        // style={{ marginTop: '20px' }}
                                        fullWidth
                                        value={client}
                                        onChange={(_, value) => {
                                            setClient(value);
                                        }}
                                        options={clients.map(
                                            (item) => item.name
                                        )}
                                        renderInput={(params) =>
                                            SelectComponent(params, 'Cliente')
                                        }
                                    />
                                </Grid>

                                {typeCompany === 'Bancarizada' ? (
                                    <></>
                                ) : (
                                    <BancarizadaFormComponent
                                        company={company}
                                        setCompany={setCompany}
                                        verifyIfCorp={verifyIfCorp}
                                        SelectComponent={SelectComponent}
                                        companies={companies}
                                        corporativeFunding={corporativeFunding}
                                        setCorporativeFunding={
                                            setCorporativeFunding
                                        }
                                    />
                                )}

                                <Grid
                                    item
                                    xs={4}
                                >
                                    <Autocomplete
                                        isOptionEqualToValue={(option, value) =>
                                            option?.id === value?.id
                                        }
                                        required
                                        // style={{ marginTop: '20px' }}
                                        fullWidth
                                        value={bank}
                                        onChange={(_, value) => {
                                            setBank(value);
                                        }}
                                        options={banks.map((item) => item.name)}
                                        renderInput={(params) =>
                                            SelectComponent(
                                                params,
                                                application.folio ==
                                                    'SOLICITUD CONVENCIONAL' ||
                                                    application.folio ==
                                                        'COMISIONES DIRECTAS'
                                                    ? 'Banco'
                                                    : application.folio ==
                                                      'RETIRO DE RESGUARDO'
                                                    ? 'Banco'
                                                    : 'Caja'
                                            )
                                        }
                                    />
                                </Grid>

                                {folio == 'RETIRO DE RESGUARDO' ? (
                                    <>
                                        <Grid
                                            item
                                            xs={4}
                                        >
                                            <SelectSafeguards
                                                funcSafeguards={
                                                    setAmountSafeguards
                                                }
                                                listOfSafeguards={
                                                    listOfSafeguardsPerClient
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                        >
                                            <ItemTexts
                                                title='Monto Resguardos'
                                                text={money(safeguardsTotal)}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <></>
                                )}

                                <Grid
                                    item
                                    xs={4}
                                >
                                    {/* Cost */}

                                    <Autocomplete
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        required
                                        // style={{ marginTop: '20px' }}
                                        fullWidth
                                        value={typeCompany}
                                        onChange={(_, value) => {
                                            resetFormTemp();
                                            setTypeCompany(value);
                                        }}
                                        // Depends on type of the folio to get the options for select
                                        options={
                                            application.folio ==
                                                'SOLICITUD CONVENCIONAL' ||
                                            application.folio ==
                                                'RETIRO DE RESGUARDO' ||
                                            application.folio ==
                                                'COMISIONES DIRECTAS'
                                                ? typeCompanyTemp
                                                : typeCompanyTempFolio2
                                        }
                                        renderInput={(params) =>
                                            SelectComponent(
                                                params,
                                                'Tipo de Empresa'
                                            )
                                        }
                                    />
                                </Grid>

                                {folio === 'RETIRO DE RESGUARDO' ? (
                                    <></>
                                ) : (
                                    <Grid
                                        item
                                        xs={4}
                                    >
                                        <CurrencyFormat
                                            customInput={TextField}
                                            fullWidth
                                            label='Importe'
                                            value={deposit}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            allowNegative={false}
                                            margin='normal'
                                            onChange={({ target }) => {
                                                setDeposit(
                                                    Number(
                                                        target.value.replace(
                                                            /[^0-9.-]+/g,
                                                            ''
                                                        )
                                                    )
                                                );
                                            }}
                                        />
                                    </Grid>
                                )}

                                {typeCompany == '' ? (
                                    <></>
                                ) : typeCompany == 'KV + Grupo' ||
                                  typeCompany == 'KV' ? (
                                    <KVFormComponent
                                        costNameKVGroup={costNameKVGroup}
                                        setCostNameKVGroup={setCostNameKVGroup}
                                        payersName={payersName}
                                        SelectComponent={SelectComponent}
                                        costKVGroup={costKVGroup}
                                        setCostKVGroup={setCostKVGroup}
                                        TextField={TextField}
                                        costAmountKVGroup={costAmountKVGroup}
                                        setCostAmountKVGroup={
                                            setCostAmountKVGroup
                                        }
                                        Number={Number}
                                        setExpenseAmountKVGroup={
                                            setExpenseAmountKVGroup
                                        }
                                        expenseNameKVGroup={expenseNameKVGroup}
                                        setExpenseNameKVGroup={
                                            setExpenseNameKVGroup
                                        }
                                        expenseKVGroup={expenseKVGroup}
                                        setExpenseKVGroup={setExpenseKVGroup}
                                        expenseAmountKVGroup={
                                            expenseAmountKVGroup
                                        }
                                        bank={bank}
                                        substractionImport={substractionImport}
                                        hasCorporateCommissions={
                                            hasCorporateCommissions
                                        }
                                        handleCheck={handleCheck}
                                        commisionCorporative={
                                            commisionCorporative
                                        }
                                        corporativeFunding={corporativeFunding}
                                        setCorporativeFunding={
                                            setCorporativeFunding
                                        }
                                        importFunding={importFunding}
                                        companies={companies}
                                    />
                                ) : typeCompany == 'Grupo' ||
                                  typeCompany == 'Grupo 2' ? (
                                    <KVGroupComponent
                                        TextField={TextField}
                                        commisionCorporative={
                                            commisionCorporative
                                        }
                                        corporativeFunding={corporativeFunding}
                                        setCorporativeFunding={
                                            setCorporativeFunding
                                        }
                                        SelectComponent={SelectComponent}
                                        importFunding={importFunding}
                                        companies={companies}
                                    />
                                ) : typeCompany == 'Bancarizada' ? (
                                    <>
                                        <Grid
                                            item
                                            xs={4}
                                        >
                                            <Autocomplete
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => option.id === value.id}
                                                required // style={{ marginTop: '20px' }}
                                                fullWidth
                                                value={corporativeFunding}
                                                onChange={(_, value) => {
                                                    setCorporativeFunding(
                                                        value
                                                    );
                                                }}
                                                options={companies.map(
                                                    (item) => item.name
                                                )}
                                                renderInput={(params) =>
                                                    SelectComponent(
                                                        params,
                                                        'Empresa Fondeo Corporativo'
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </LocalizationProvider>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseAndReset}
                        color='error'
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            if (isValidForm()) {
                                handleSubmit();
                            } else {
                                handleWarning(
                                    'Hay campos incompletos en el formulario'
                                );
                            }
                        }}
                    >
                        {data ? 'Actualizar' : 'Crear'}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

const SelectComponent = (params, label) => (
    <TextField
        fullWidth
        required
        margin='normal'
        {...params}
        label={label}
    />
);

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography
            variant='p'
            sx={{ marginLeft: '10px' }}
        >
            <b>{title}</b>
        </Typography>
        <Typography
            variant='h6'
            sx={{ marginLeft: '10px' }}
        >
            {text}
        </Typography>
    </>
);
