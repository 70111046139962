import React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { AuthComponent } from '../components/auth/AuthComponent';
import { CircularProgress } from '@mui/material';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { Dashboard } from '../components/dashboard/Dashboard';
import { Users } from '../containers/Users';
import { UserProfile } from '../containers/UserProfile';
import { useDispatch, useSelector } from 'react-redux';
import { Teams } from '../containers/Teams';
import { checkAuth } from '../actions/auth';
import { setUser } from '../actions/user';
import { Applications } from '../containers/Applications';
import { ApplicationDetail } from '../containers/ApplicationDetail';
import { setSideBarStatus } from '../actions/sidebar';
import { Results } from '../containers/Results';
import { DebtRepayment } from '../containers/Catalogs/DebtRepayment';
import { CatApplication } from '../containers/Catalogs/CatApplication';
import { BaseCommission } from '../containers/Catalogs/BaseCommission';
import { Scheme } from '../containers/Catalogs/Scheme';
import { Promotery } from '../containers/Catalogs/Promotery';
import { Bank } from '../containers/Catalogs/Bank';
import { OriginSource } from '../containers/Catalogs/OriginSource';
import { DestinationSource } from '../containers/Catalogs/DestinationSource';
import { Company } from '../containers/Catalogs/Company';
import { ConsCompany } from '../containers/Catalogs/ConsCompany';
import { PrepaidCardCommission } from '../containers/Catalogs/PrepaidCardCommission';
import { BankCommission } from '../containers/Catalogs/BankCommission';
import { SchemeCost } from '../containers/Catalogs/SchemeCost';
import { Transfer } from '../containers/Catalogs/Transfer';
import { Client } from '../containers/Catalogs/Client';
import { Promoter } from '../containers/Catalogs/Promoter';
import { FundingsResults } from '../containers/FundingsResults';
import { SafeguardsResults } from '../containers/SafeguardsResults';
import { PayersResults } from '../containers/PayersResults';
import { Payers } from '../containers/Catalogs/Payers';
import { PromotersResults } from '../containers/PromotersResults';
import Test from '../containers/Test';

export const AppRouter = () => {
    const [checking, setsChecking] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { token } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // Start "checking"
        setsChecking(true);
        // Check authentication
        checkAuth(token)
            .then((user) => {
                // Finish "checking"
                setsChecking(false);
                if (user) {
                    // Set user info
                    dispatch(setUser(user));
                    // Set loggin to true
                    setIsLoggedIn(true);
                    // Set sidebar status
                    setSideBarStatus();
                    // Redirect last saved path
                    const lastPath = localStorage.getItem('lastPathKv');
                    if (lastPath) navigate(lastPath);
                } else {
                    setIsLoggedIn(false);
                }
            })
            .catch((error) => {
                // Finish "checking"
                setsChecking(false);
                // Handle error
                console.log(error, 'No se pudo verificar autenticación.');
            });
    }, [token]);

    if (checking) {
        return (
            <center style={{ padding: '20%' }}>
                <CircularProgress />
            </center>
        );
    }

    return (
        <Routes>
            {/* Private */}
            <Route
                exact
                path='/'
                element={<PrivateRoute isAuthenticated={isLoggedIn} />}
            >
                <Route
                    exact
                    path='/'
                    element={<Dashboard />}
                />
                {/* <Route
                    exact
                    path='/dashboard/test'
                    element={<StepperContainer />}
                /> */}
                <Route
                    exact
                    path='/dashboard/users'
                    element={<Users />}
                />
                <Route
                    exact
                    path='/dashboard/user/:idUser'
                    element={<UserProfile />}
                />
                <Route
                    exact
                    path='/dashboard/applications'
                    element={<Applications />}
                />

                <Route
                    exact
                    path='/dashboard/test'
                    element={<Test />}
                />
                <Route
                    exact
                    path='/dashboard/results'
                    element={<Results />}
                />
                <Route
                    exact
                    path='/dashboard/fundingsResults'
                    element={<FundingsResults />}
                />
                <Route
                    exact
                    path='/dashboard/promotersResults'
                    element={<PromotersResults />}
                />
                <Route
                    exact
                    path='/dashboard/safeguardsResults'
                    element={<SafeguardsResults />}
                />
                <Route
                    exact
                    path='/dashboard/payersResults'
                    element={<PayersResults />}
                />

                <Route
                    exact
                    path='/dashboard/application/:idApplication'
                    element={<ApplicationDetail />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs'
                    element={<Teams />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/companies'
                    element={<Company />}
                />

                <Route
                    exact
                    path='/dashboard/catalogs/payers'
                    element={<Payers />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/debt-repayments'
                    element={<DebtRepayment />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/cat-applications'
                    element={<CatApplication />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/bank'
                    element={<Bank />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/clients'
                    element={<Client />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/base-commissions'
                    element={<BaseCommission />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/bank-commissions'
                    element={<BankCommission />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/prepaidcard-commission'
                    element={<PrepaidCardCommission />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/scheme-cost'
                    element={<SchemeCost />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/conscompanies'
                    element={<ConsCompany />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/schemes'
                    element={<Scheme />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/origin-source'
                    element={<OriginSource />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/destination-source'
                    element={<DestinationSource />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/promoters'
                    element={<Promoter />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/promotery'
                    element={<Promotery />}
                />
                <Route
                    exact
                    path='/dashboard/catalogs/transfers'
                    element={<Transfer />}
                />
            </Route>
            {/* Authentication */}
            <Route
                exact
                path='/auth'
                element={<PublicRoute isAuthenticated={isLoggedIn} />}
            >
                <Route
                    exact
                    path='/auth/inicio-de-sesion'
                    element={<AuthComponent />}
                />
                <Route
                    path='*'
                    element={<Navigate to='/' />}
                />
            </Route>
        </Routes>
    );
};
