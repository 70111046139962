import moment from 'moment';

export const createNewDepositObj = (
	objectForCreateDepositObj,
	getCommission,
	checkIfIsWithdraw
) => {
	const {
		typeCompany,
		costKVGroup,
		expenseKVGroup,
		expenseNameKVGroup,
		costNameKVGroup,
		costAmountKVGroup,
		expenseAmountKVGroup,
		application,
		clients,
		companies,
		conCompany,
		banks,
		transfers,
		commisionCorporative,
		corporativeFunding,
		importFunding,
		doubleCommission,
		date,
		folio,
		deposit,
		client,
		company,
		bank,
		transfer,
		safeguardsTotal,
	} = objectForCreateDepositObj;
	console.log(objectForCreateDepositObj)
	const commission = getCommission();
	if (checkIfIsWithdraw === true) {
		if (typeCompany == 'KV') {
			return {
				date: moment(date).format('YYYY-MM-DD'),
				deposit: safeguardsTotal,
				cost: parseFloat(costKVGroup),
				expense: parseFloat(expenseKVGroup),
				company_type: `Resguardo ${typeCompany}`,
				expense_name: expenseNameKVGroup,
				cost_name: costNameKVGroup,
				cost_amount: parseFloat(costAmountKVGroup) ?? 0,
				expense_amount: parseFloat(expenseAmountKVGroup) ?? 0,
				application: application?.id,
				client: clients.find((item) => item.name === client)?.id,
				company: companies.find((item) => item.name === company)?.id,
				cons_company: conCompany?.id,
				bank: banks.find((item) => item.name === bank)?.id,
				transfer: transfers?.find((item) => item.concept === transfer)
					?.id,
				commission,
				commission_corporative: commisionCorporative,
				company_funding: corporativeFunding,
				import_funding: importFunding,
				double_bank_commission: doubleCommission,
			};
		} else if (typeCompany == 'KV + Grupo') {
			return {
				date: moment(date).format('YYYY-MM-DD'),
				deposit: safeguardsTotal,
				cost: parseFloat(costKVGroup),
				expense: parseFloat(expenseKVGroup),
				company_type: `Resguardo ${typeCompany}`,
				expense_name: expenseNameKVGroup,
				cost_name: costNameKVGroup,
				cost_amount: parseFloat(costAmountKVGroup) ?? 0,
				expense_amount: parseFloat(expenseAmountKVGroup) ?? 0,
				application: application?.id,
				client: clients.find((item) => item.name === client)?.id,
				company: companies.find((item) => item.name === company)?.id,
				cons_company: conCompany?.id,
				bank: banks.find((item) => item.name === bank)?.id,
				transfer: transfers?.find((item) => item.concept === transfer)
					?.id,
				commission: commission,
				commission_corporative: commisionCorporative,
				company_funding: corporativeFunding,
				import_funding: importFunding,
				double_bank_commission: doubleCommission,
			};
		} else if (typeCompany == 'Grupo' || typeCompany == 'Grupo 2') {
			
			return {
				date: moment(date).format('YYYY-MM-DD'),
				deposit: safeguardsTotal,
				cost: 0,
				expense: 0,
				company_type: `Resguardo ${typeCompany}`,
				expense_name: '-',
				cost_amount: 0,
				expense_amount: 0,
				application: application?.id,
				client: clients.find((item) => item.name === client)?.id,
				company: companies?.find((item) => item.name === company)?.id,
				cons_company: conCompany?.id,
				bank: banks.find((item) => item.name === bank)?.id,
				transfer: 1,
				commission: commission,
				commission_corporative: commisionCorporative,
				company_funding: corporativeFunding,
				import_funding: importFunding,
				double_bank_commission: doubleCommission,
			};
		} else if (typeCompany == 'Bancarizada') {
			return {
				date: moment(date).format('YYYY-MM-DD'),
				deposit: safeguardsTotal,
				cost: 0,
				expense: 0,
				company_type: `Resguardo ${typeCompany}`,
				expense_name: '-',
				cost_amount: 0,
				expense_amount: 0,
				application: application?.id,
				client: clients.find((item) => item.name === client)?.id,
				company: null,
				cons_company: conCompany?.id,
				bank: banks.find((item) => item.name === bank)?.id,
				transfer: 1,
				commission: 0,
				commission_corporative: 0,
				company_funding: 0,
				import_funding: 0,
				double_bank_commission: 0,
			};
		}
	} else {
		if (typeCompany == 'KV') {
			return {
				date: moment(date).format('YYYY-MM-DD'),
				deposit,
				cost: parseFloat(costKVGroup),
				expense: parseFloat(expenseKVGroup),
				company_type: typeCompany,
				expense_name:
					expenseNameKVGroup == '-' ? '' : expenseNameKVGroup,
				cost_name: costNameKVGroup == '-' ? '' : costNameKVGroup,
				cost_amount: parseFloat(costAmountKVGroup) ?? 0,
				expense_amount: parseFloat(expenseAmountKVGroup) ?? 0,
				application: application?.id,
				client: clients.find((item) => item.name === client)?.id,
				company: companies.find((item) => item.name === company)?.id,
				cons_company: conCompany?.id,
				bank: banks.find((item) => item.name === bank)?.id,
				transfer: transfers?.find((item) => item.concept === transfer)
					?.id,
				commission: commission,
				commission_corporative: commisionCorporative,
				company_funding: corporativeFunding,
				import_funding: importFunding,
				double_bank_commission: doubleCommission,
			};
		} else if (typeCompany == 'KV + Grupo') {
			return {
				date: moment(date).format('YYYY-MM-DD'),
				deposit: deposit,
				cost: parseFloat(costKVGroup),
				expense: parseFloat(expenseKVGroup),
				company_type: typeCompany,
				expense_name: expenseNameKVGroup,
				cost_name: costNameKVGroup,
				cost_amount: parseFloat(costAmountKVGroup) ?? 0,
				expense_amount: parseFloat(expenseAmountKVGroup) ?? 0,
				application: application?.id,
				client: clients.find((item) => item.name === client)?.id,
				company: companies.find((item) => item.name === company)?.id,
				cons_company: conCompany?.id,
				bank: banks.find((item) => item.name === bank)?.id,
				transfer: transfers?.find((item) => item.concept === transfer)
					?.id,
				commission,
				commission_corporative: commisionCorporative,
				company_funding: corporativeFunding,
				import_funding: importFunding,
				double_bank_commission: doubleCommission,
			};
		} else if (typeCompany == 'Grupo' || typeCompany == 'Grupo 2') {
			return {
				date: moment(date).format('YYYY-MM-DD'),
				deposit: deposit,
				cost: 0,
				expense: 0,
				company_type: typeCompany,
				expense_name: '-',
				cost_amount: 0,
				expense_amount: 0,
				application: application?.id,
				client: clients.find((item) => item.name === client)?.id,
				company: companies?.find((item) => item.name === company)?.id,
				cons_company: conCompany?.id,
				bank: banks.find((item) => item.name === bank)?.id,
				transfer: 1,
				commission: commission,
				commission_corporative: commisionCorporative,
				company_funding: corporativeFunding,
				import_funding: importFunding,
				double_bank_commission: doubleCommission,
			};
		} else if (typeCompany == 'Bancarizada') {
			return {
				date: moment(date).format('YYYY-MM-DD'),
				deposit: deposit,
				cost: 0,
				expense: 0,
				company_type: typeCompany,
				expense_name: '-',
				cost_amount: 0,
				expense_amount: 0,
				application: application?.id,
				client: clients.find((item) => item.name === client)?.id,
				company: companies.find((item) => item.name === company)?.id,
				cons_company: conCompany?.id,
				bank: banks.find((item) => item.name === bank)?.id,
				transfer: 1,
				commission: 0,
				commission_corporative: 0,
				company_funding: corporativeFunding,
				import_funding: 0,
				double_bank_commission: 0,
			};
		}
	}
};
