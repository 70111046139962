import React, { useEffect, useState } from 'react';
import {
	Alert,
	Button,
	IconButton,
	Tooltip,
	Stack,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';

import {
	handleDeleteCatalogModal,
	handleError,
	handleSuccess,
} from '../../helpers/utils';
import { formatDateTimeMX } from '../../helpers/hours';
import { DialogTemplate } from '../../components/dialogs/DialogTemplate';
import { CatalogDetail } from './CatalogDetail';
import { NewTransferForm } from './NewTransferForm';
import { searchTransfer } from '../../actions/catalogs';
import { SearchBar } from '../../components/utils/UtilsComponent';

// Transfers Table
export const TransfersTable = ({
	config = defaultConfig,
	detailGridConfig,
}) => {
	// Desestructure config property
	const {
		getCatalogs,
		deleteCatalog,
		catalogSingular,
		catalogPlural,
		pronounSingular = 'el',
		pronoun = 'o',
	} = config;

	// Search
	const [searchValue, setSearchValue] = useState('');

	// Function to Filter the catalogs
	function handleSearch(value) {
		if (value === '') {
			generateRows();
		} else {
			searchTransfer(value)
				.then(({ data }) => {
					const res = data.results.map((item) => {
						return {
							id: item?.id,
							col1: item?.key,
							col2: item?.concept,
							col3: item?.cost,
							col4: item?.expense,
							col5: item.description === '' ? '-' : item?.description,
							col6: formatDateTimeMX(item?.updated_at),
							col7: formatDateTimeMX(item?.created_at),
							...item,
						};
					});

					setRows(res);
				})
				.catch((error) => {
					handleError(error, 'Algo salio mal realizando la busqueda');
				});
		}
	}

	// Dialog Create/Edit
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setOpen(false);
		setHasData(null);
	};
	// Handle Data for editing
	const [hasData, setHasData] = useState(null);

	// Dialog Details
	const [openDetails, setOpenDetails] = useState(false);
	const handleOpenDetails = () => setOpenDetails(true);
	const handleCloseDetails = () => {
		setOpenDetails(false);
		setHasData(null);
	};

	// Rows
	const [rows, setRows] = useState([]);

	// Generate rows by array
	const generateRows = async () => {
		// Get all the data from the DB
		const { data } = await getCatalogs();

		if (data.results.length === 0) return [];

		const res = data.results.map((item) => {
			return {
				id: item?.id,
				col1: item?.key,
				col2: item?.concept,
				col3: item?.cost,
				col4: item?.expense,
				col5: item.description === '' ? '-' : item?.description,
				col6: formatDateTimeMX(item?.updated_at),
				col7: formatDateTimeMX(item?.created_at),
				...item,
			};
		});

		setRows(res);
	};

	useEffect(() => {
		// Generate rows
		generateRows();
	}, []);

	// Add an item to the rows
	const addOneItem = async (data) => {
		// Update the array with the information of the item
		const tmpDeposits = [
			...rows,
			{
				id: data?.id,
				col1: data?.key,
				col2: data?.concept,
				col3: data?.cost,
				col4: data?.expense,
				col5: data.description === '' ? '-' : data?.description,
				col6: formatDateTimeMX(data?.updated_at),
				col7: formatDateTimeMX(data?.created_at),
				...data,
			},
		];
		setRows(tmpDeposits);
	};

	// Update an item of the table
	const updateOneItem = async (data) => {
		// Find the index of the item to edit
		const indexDeposit = rows.findIndex((item) => item.id === data.id);

		const tmpDeposits = rows.map((item, index) => {
			if (index === indexDeposit) {
				return {
					id: data?.id,
					col1: data?.key,
					col2: data?.concept,
					col3: data?.cost,
					col4: data?.expense,
					col5: data.description === '' ? '-' : data?.description,
					col6: formatDateTimeMX(data?.updated_at),
					col7: formatDateTimeMX(data?.created_at),
					...data,
				};
			}
			return item;
		});

		setRows(tmpDeposits);
	};

	// Delete an item of the table
	const deleteRow = (rowId) => {
		const newRows = rows.filter((item) => item.id !== rowId);
		setRows(newRows);
	};

	// Handle delete with modal confirmation
	const handleDelete = (catalog) => {
		handleDeleteCatalogModal(pronounSingular + ' ' + catalogSingular)
			.then(({ isConfirmed }) => {
				if (isConfirmed) {
					deleteCatalog(catalog.id)
						.then(() => {
							handleSuccess(
								catalogSingular + ' eliminad' + pronoun + ' correctamente'
							);
							deleteRow(catalog.id);
						})
						.catch((error) => {
							handleError(
								error,
								'No se pudo eliminar' + pronounSingular + ' ' + catalogSingular
							);
						});
				}
			})
			.catch((error) => handleError(error));
	};

	// Define columns
	const columns = [
		{ field: 'col1', headerName: 'Clave', width: 100 },
		{ field: 'col2', headerName: 'Concepto', width: 200 },
		{ field: 'col3', headerName: 'Costo', width: 150 },
		{ field: 'col4', headerName: 'Gasto', width: 150 },
		{ field: 'col5', headerName: 'Descripcion', width: 210 },
		{ field: 'col6', headerName: 'Actualizado', width: 170 },
		{ field: 'col7', headerName: 'Creado', width: 170 },
		{
			field: 'col8',
			headerName: 'Acciones',
			width: 150,
			// Add actions to each case
			renderCell: ({ row }) => (
				<>
					<Tooltip title='Ver detalle'>
						<IconButton
							style={{ color: '#143f6c' }}
							aria-label='profile'
							color='primary'
							onClick={() => {
								setHasData(row);
								handleOpenDetails();
							}}
						>
							<InfoIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title='Editar'>
						<IconButton
							style={{ color: '#143f6c' }}
							aria-label='edit'
							color='primary'
							onClick={() => {
								setHasData(row);
								handleOpen();
							}}
						>
							<EditIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title='Eliminar'>
						<IconButton
							style={{ color: '#143f6c' }}
							aria-label='delete'
							color='primary'
							onClick={() => handleDelete(row)}
						>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</>
			),
		},
	];

	return (
		<div
			style={{
				padding: '20px',
			}}
		>
			<DialogTemplate
				config={{
					open: open,
					handleClose: handleClose,
					btnCancel: false,
					btnConfirm: false,
					title: '',
					maxWidth: 'xl',
				}}
				content={
					hasData ? (
						<NewTransferForm
							data={hasData}
							config={{
								title: 'Editar Traspaso',
								handleClose: handleClose,
								updateOneItem: updateOneItem,
							}}
						/>
					) : (
						<NewTransferForm
							config={{
								title: 'Añadir Traspaso',
								handleClose: handleClose,
								addOneItem: addOneItem,
							}}
						/>
					)
				}
			/>
			<DialogTemplate
				config={{
					open: openDetails,
					handleClose: handleCloseDetails,
					btnCancel: false,
					btnConfirm: true,
					title: '',
					maxWidth: 'lg',
				}}
				content={<CatalogDetail data={hasData} config={detailGridConfig} />}
			/>
			<Stack
				direction='row'
				alignItems='flex-end'
				justifyContent='space-between'
				style={{
					border: '1px solid white',
					padding: '20px 20px 0 0',
					margin: '0 0 40px 0',
					width: '100%',
				}}
			>
				<Typography variant='h4' gutterBottom>
					{catalogPlural}
				</Typography>
				<Button
					variant='contained'
					style={{ backgroundColor: '#143f6c' }}
					onClick={() => {
						handleOpen();
					}}
				>
					Añadir {catalogSingular}
				</Button>
			</Stack>
			<SearchBar
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				handleSearch={handleSearch}
			/>
			<hr />
			{/* Datagrid table */}
			{rows.length > 0 ? (
				<DataGrid
					autoHeight
					rows={rows}
					columns={columns}
					pageSize={20}
					components={{ Toolbar: GridToolbar }}
					localeText={esES.components.MuiDataGrid.defaultProps.localeText}
				/>
			) : (
				<Alert severity='info'>No hay {catalogPlural}</Alert>
			)}
		</div>
	);
};
