import { types } from '../types/types';

// Set all values for Request Evaluation on Operative Expenses
export const setRequestEvaluation = (data) => {
	return {
		type: types.requestEvaluation,
		payload: data,
	};
};

// Set current tab
export const setCurrentTab = (data) => {
	return {
		type: types.currentTab,
		payload: data,
	};
};

// Set commission total
export const setTotalCommissionSchemeTotal = (data) => {
	return {
		type: types.totalCommissionSchemeTotal,
		payload: data,
	};
};

// Get current tab from local storage
export const getCurrentTab = () =>
	parseInt(localStorage.getItem('currentTab')) || 0;

// Update the Return and Commission object
export const refreshReturnAction = (data) => {
	return {
		type: types.updatedReturn,
		payload: data,
	};
};

// Reset request to initial state
export const resetRequest = () => {
	return {
		type: types.resetRequest,
	};
};

// Set deposits total
export const setDepositsTotal = (data) => {
	return {
		type: types.setDepositTotal,
		payload: data,
	};
};

// Set commissions total
export const setCommissionsTotal = (data) => {
	return {
		type: types.setCommissionTotal,
		payload: data,
	};
};

// Set commissions total
export const setCommissionsCorporative = (data) => {
	return {
		type: types.setCommissionCorporative,
		payload: data,
	};
};

// Set commissions total
export const setCommissionsBank = (data) => {
	return {
		type: types.setCommissionBank,
		payload: data,
	};
};

// Set (replace) deposits
export const setDepositsRX = (data) => {
	return {
		type: types.setDeposits,
		payload: data,
	};
};

// Set list of safeguards total
export const setListOfSafeguards = (data) => {
	return {
		type: types.setListOfSafeGuards,
		payload: data,
	};
};


export const getWithdrawTotal = (data) => {
	return {
		type: types.getWithdrawTotal,
		payload: data,
	};
};

export const setTotalImportFundingRX = (data) => {
	return {
		type: types.setTotalImportFunding,
		payload: data,
	};
};

// Add a new deposit
export const addDepositRX = (data) => {
	return {
		type: types.addDeposit,
		payload: data,
	};
};

// Update a specific deposit
export const updateDepositRX = (data) => {
	return {
		type: types.updateDeposit,
		payload: data,
	};
};

// Delete deposit
export const deleteDepositRX = (id) => {
	return {
		type: types.deleteDeposit,
		payload: { id },
	};
};

// Clean all deposits
export const cleanDeposits = () => {
	return { type: types.cleanDeposits };
};

// Update Operating Expenses
export const updateOperatingExpenses = (data) => {
	return {
		type: types.updateOperatingExpenses,
		payload: data,
	};
};

// Update Operating Expenses total
export const updateOperatingExpensesTotal = (data) => {
	return {
		type: types.updateOperatingExpensesTotal,
		payload: data,
	};
};

export const updateOperatingExpensesTotal2 = (data) => {
	return {
		type: types.updateOperatingExpensesTotal,
		payload: data,
	};
};

// Update decitions object for Operating Expenses
export const updateDecOperatingExpenses = (data) => {
	return {
		type: types.updateDecOperatingExpenses,
		payload: data,
	};
};

export const updateDecitionsOperatingExpenses = (data) => {
	return {
		type: types.updateDecOperatingExpenses,
		payload: data,
	};
};
// Update CreditToDebt
export const updateCreditToDebt = (data) => {
	return {
		type: types.updateCreditToDebt,
		payload: data,
	};
};

// Update Promoters Object
export const updatePromoters = (data) => {
	return {
		type: types.updatePromoters,
		payload: data,
	};
};

export const updateAvailableKV = (data) => {
	return {
		type: types.updateAvailableKV,
		payload: data,
	};
};

export const updatePerceptionsInvoice = (data) => {
	return {
		type: types.updatePerceptionsInvoice,
		payload: data,
	};
}
