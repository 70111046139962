import React from 'react';
import {
	deleteDebtRepayment,
	getDebtRepaymentInfo,
	getDebtRepayments,
	registerDebtRepayment,
	searchDebtRepayment,
	updateDebtRepayment,
} from '../../actions/catalogs';
import { SimpleCatalogTable } from './SimpleCatalogTable';

// Debt Repayments Table
export const DebtRepayment = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getDebtRepayments,
		getCatalogInfo: getDebtRepaymentInfo,
		deleteCatalog: deleteDebtRepayment,
		updateCatalog: updateDebtRepayment,
		registerCatalog: registerDebtRepayment,
		searchCatalog: searchDebtRepayment,
		catalogSingular: 'Abono a deuda',
		catalogPlural: 'Abonos a deuda',
		pronounSingular: 'el',
		pronoun: 'o',
	};

	return <SimpleCatalogTable config={configuration} />;
};