import { setRequestEvaluation } from '../../actions/requests';

export const CalculateBalanceReturn = (variablesForBalance, dispatch) => {
    const {
        totalPagadoras,
        totalReturn,
        totalSocialCost,
        totalISR,
        totalISN,
        totalIva,
        costoFID,
        costoSC,
        costoSindicato,
        comisionSpei,
        comisionEdenredKV,
        comisionEdenredCorpo,
        comisionFacil,
    } = variablesForBalance;

    if (totalPagadoras === 0) {
        // If zero, set the Request Evaluation to zero
        dispatch(setRequestEvaluation(0));
    } else {
        // Calculate the Request Evaluation using the formula
        // Formula: (Total Pagadoras) - (Total Return) - (Total Social Cost) - (Total ISR) - (Total ISN) - (Total IVA)
        const requestEvaluation =
            Number(totalPagadoras) -
            Number(totalReturn) -
            Number(totalSocialCost) -
            Number(totalISR) -
            Number(totalISN) -
            Number(totalIva) -
            Number(costoFID)-
            Number(costoSC)-
            Number(costoSindicato)-
            Number(comisionSpei)-
            Number(comisionEdenredKV)-
            Number(comisionEdenredCorpo)-
            Number(comisionFacil);

        // Dispatch the calculated Request Evaluation to the application state
        dispatch(setRequestEvaluation(requestEvaluation));
    }
};
