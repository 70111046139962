import React, { useEffect, useState } from 'react';
import {
    Alert,
    Button,
    Tooltip,
    Stack,
    Typography,
    Grid,
    TextField,
} from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import { money } from '../helpers/money';
import {
    getApplicationInfo,
    getResults,
    handleUpdateFieldApp,
} from '../actions/applications';
import { getStateByCode } from '../helpers/lists';
import { DepositsFundingDialog } from '../components/results/DepositsFundingDialog';
import { DialogFundingsResume } from '../components/applications/DialogFundingsResume';
import { useSelector } from 'react-redux';
import { handleSuccess } from '../helpers/utils';
import { DateRangeWithButton } from '../components/utils/UtilsComponent';


// Results table
export const FundingsResults = () => {
	// Date range state
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const ranges = { startDate, setStartDate, endDate, setEndDate };

	// Rows state
	const [rows, setRows] = useState(null);

	// Current application
	const [currentApplicationId, setCurrentApplicationId] = useState(null);

	// Deposits dialog
	const [openDepositsDialog, setOpenDepositsDialog] = useState(false);
	const handleOpenDepositsDialog = () => setOpenDepositsDialog(true);
	const handleCloseDepositsDialog = () => setOpenDepositsDialog(false);

	//Fundings Dialog
	const [openFundingsDialog, setOpenFundingsDialog] = useState(null);
	const handleFundingsDialog = () => setOpenFundingsDialog(true);
	const handleCloseFundingDialog = () => setOpenFundingsDialog(false);

	const toggleFundingsFunc = (applicationId, toggleFundings) => {
		getApplicationInfo(applicationId).then(({ data }) => {
			handleUpdateFieldApp(data, 'toggle_fundings', !toggleFundings).then(
				({ data }) => {
					let tempArr = rows.map((element) => {
						if (element.id === applicationId) {
							return {
								...element,
								toggle_fundings: !toggleFundings,
							};
						} else {
							return element;
						}
					});
					setRows(tempArr);
					let stateFunding =
						!toggleFundings == true ? 'fondeado' : 'no fondeado';
					handleSuccess(`Cambió estado a ${stateFunding}`);
				}
			);
		});
	};

	// Generate rows
	const generateRows = async (formattedStartDate, formattedEndDate) => {
		// Get results by date from API
		// TODO date
		const { data } = await getResults(formattedStartDate, formattedEndDate);
		// const { data } = await getFundingsResults(formattedStartDate);
		// If results are empty set an empty array
		if (data?.results?.length === 0 || !data?.ok) setRows([]);

		const genericPromoter = {
			name: '-',
			commission: '-',
			percentage: '-',
			base_commission: '-',
		};

		// Map data results
		const res = data?.results.map((item) => {
			const originalPromoterName = item.promoter.name;
			const originalPromoter = item.promoters.find(
				({ name }) => name === originalPromoterName
			) || { name: originalPromoterName };

			originalPromoter.commission = money(
				originalPromoter?.commission || 0
			);
			originalPromoter.percentage = `${
				originalPromoter?.percentage || 0
			}%`;
			originalPromoter.base_commission = `${
				originalPromoter?.base_commission || '-'
			}`;
			const promoters = item.promoters
				.filter(({ name }) => name !== originalPromoterName)
				.map((item) => ({
					...item,
					commission: money(item.commission),
				}));
			return {
				...item,
				promoter: originalPromoter,
				promoters,
				subpromoter1:
					promoters[0] !== undefined ? promoters[0] : genericPromoter,
				subpromoter2:
					promoters[1] !== undefined ? promoters[1] : genericPromoter,
				subpromoter3:
					promoters[2] !== undefined ? promoters[2] : genericPromoter,
				site: getStateByCode(item.site),
				total_imports: money(item.total_imports),
				total_return: money(item.total_return),
				total_commission: money(item.total_commission),
				total_promoter: money(item.total_promoter),
				total_commission_scheme: money(item.total_commission_scheme),
				total_operative_expense: money(item.total_operative_expense),
				commision_paid: money(item.commision_paid),
				comission_kv: money(item.comission_kv),
				toggle_fundings: item.toggle_fundings,
			};
		});
		// Set mapped rows
		setRows(res);
	};

	// Handle get results
	const handleGetResults = () => {
		// Date format to YYYY-MM-DD
		const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
		const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

		// Generate rows from formatted dates
		generateRows(formattedStartDate, formattedEndDate);
	};

	// Columns
	const columns = [
		{
			field: 'key',
			headerName: 'No. de operación',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>{row?.key}</span>
				</div>
			),
		},
		{
			field: 'executive',
			headerName: 'Ejecutivo',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>
						{row?.executive?.name}
					</span>
				</div>
			),
		},
		{
			field: 'total_imports',
			headerName: 'Depositos',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<Tooltip title='Total de los depósitos'>
					<div style={rowDialogStyle}>
						<span className='table-cell-trucate'>
							{row.total_imports}
						</span>

						<Button
							variant='text'
							style={buttonDialogStyle}
							onClick={() => {
								setCurrentApplicationId(row.id);
								handleOpenDepositsDialog();
							}}
						>
							Ver
						</Button>
					</div>
				</Tooltip>
			),
		},

		{
			field: 'created_at',
			headerName: 'Fecha',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>{row.created_at}</span>
				</div>
			),
		},
		{
			field: 'client',
			headerName: 'Cliente',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>{row.client}</span>
				</div>
			),
		},
		{
			field: 'comission_kv',
			headerName: 'Disponible KV',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<span className='table-cell-trucate'>
						{row.comission_kv}
					</span>
				</div>
			),
		},

		{
			field: 'fundings',
			headerName: 'Fondeos',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					<Button
						variant='text'
						style={buttonDialogStyle}
						onClick={() => {
							setCurrentApplicationId(row.id);
							handleFundingsDialog();
						}}
					>
						Ver
					</Button>
				</div>
			),
		},
		{
			field: 'toggleFunding',
			headerName: 'Fondeado/No Fondeado',
			...columnStyleCell,
			width: 200,
			renderCell: ({ row }) => (
				<div style={rowDialogStyle}>
					{row.toggle_fundings == false ? (
						<Button
							variant='contained'
							color={'error'}
							onClick={() => {
								toggleFundingsFunc(row.id, row.toggle_fundings);
							}}
						>
							No fondeado
						</Button>
					) : (
						<Button
							variant='contained'
							color={'success'}
							onClick={() => {
								toggleFundingsFunc(row.id, row.toggle_fundings);
							}}
						>
							fondeado
						</Button>
					)}
				</div>
			),
		},
	];


    useEffect(() => {
        handleGetResults();
    }, []);

    return (
        <div style={divStyle}>
            <DepositsFundingDialog
                open={openDepositsDialog}
                handleClose={handleCloseDepositsDialog}
                applicationId={currentApplicationId}
            />
            <DialogFundingsResume
                show={openFundingsDialog}
                handleClose={handleCloseFundingDialog}
                applicationId={currentApplicationId}
            />

            <Stack
                direction='row'
                alignItems='flex-end'
                justifyContent='space-between'
                style={stackStyle}
            >
                <Typography
                    variant='h4'
                    gutterBottom
                >
                    Tabla de Fondeos
                </Typography>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={es}
                    utils={DateFnsUtils}
                >
                    {/* Date Range Search */}
                    <Grid
                        container
                        spacing={2}
                        style={gridStyle}
                    >
                        <DateRangeWithButton
                            ranges={ranges}
                            handleGetResults={handleGetResults}
                            gridButtonStyle={gridButtonStyle}
                        />
                    </Grid>
                </LocalizationProvider>
            </Stack>
            <hr />

            <Typography variant='h6'>Solicitudes de día</Typography>

            <div style={datagridTableStyle}>
                {/* Datagrid table */}
                {rows?.length > 0 ? (
                    <DataGrid
                        autoHeight
                        // style={{ height: '600px' }}
                        rowHeight={50}
                        rows={rows}
                        columns={columns}
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                    />
                ) : (
                    <>
                        {rows === null ? (
                            <Alert severity='success'>
                                Busca todas las solicitudes de un día
                            </Alert>
                        ) : (
                            <Alert severity='info'>
                                No hay ninguna solicitud
                            </Alert>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};


const SelectComponent = (params, label) => (
    <TextField
        fullWidth
        required
        margin='normal'
        {...params}
        label={label}
    />
);


const rowDialogStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
};

const stackStyle = {
    padding: '20px 20px 0 0',
    margin: '0 0 40px 0',
    width: '100%',
};

const divStyle = {
    padding: '20px',
};

const gridStyle = {
    width: '70%',
    display: 'flex',
    justifyContent: 'flex-end',
};

const gridButtonStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const datagridTableStyle = { margin: '20px 0' };

const columnStyleCell = {
    headerClassName: 'column-header-pilu',
    headerAlign: 'center',
};


const Cell = ({ subtitle, text = '', lastBreaks = true }) => (
    <>
        <b>{subtitle}:</b>
        <br />
        {text ? (text === 'No lleva' ? '' : text) : 'No se asignó'}
        {lastBreaks && <br />}
    </>
);

const buttonDialogStyle = {
    // backgroundColor: '#143f6c',
    margin: '0 0 0 10px',
};
