export const calculateCommissionCorporative = ({
    listOfVariablesForCommission,
    setCommisionCorporative,
    setSubstractionImport,
    getCommission,
    setImportFunding,
    setDoubleCommission,
    checkIfIsWithdraw = false,
}) => {
    calculateCommissionDeposits({
        listOfVariablesForCommission,
        setSubstractionImport,
        setCommisionCorporative,
        setImportFunding,
        setDoubleCommission,
        checkIfIsWithdraw,
    });
};



const calculateCommissionDeposits = ({
    listOfVariablesForCommission,
    setSubstractionImport,
    setCommisionCorporative,
    setImportFunding,
    setDoubleCommission,
    checkIfIsWithdraw = false,
}) => {
    const {
        bank,
        typeCompany,
        checkIfIsCorp,
        deposit,
        hasCorporateCommissions,
        safeguardsTotal,
        application,
    } = listOfVariablesForCommission;

    const listOfBanks = ['STP', 'ASP INTEGRA', 'KUSPIT'];

    // Checks the type of bank and then sets a default percentage for group 2
    const bankCommission = getCommissionBank({
        deposit,
        bank,
        safeguardsTotal,
        application,
        checkIfIsWithdraw,
    });

    // New total
    const NewTotal = getDepositMinusBankCommision({
        deposit,
        safeguardsTotal,
        bankCommission,
        application,
        checkIfIsWithdraw,
    });

    //
    const commissionCorporative = getCommissionCorporative({
        NewTotal,
        deposit,
        typeCompany,
        checkIfIsCorp,
        hasCorporateCommissions,
        application,
        safeguardsTotal,
        checkIfIsWithdraw
    });

    const subtractionImportTemp = checkIfIsWithdraw
        ? safeguardsTotal - getCommissionBank(safeguardsTotal, bank)
        : deposit - getCommissionBank(deposit, bank);

    if (listOfBanks.includes(bank)) {
        setSubstractionImport(subtractionImportTemp);
    }

    const funding = getFunding({
        checkIfIsCorp,
        commissionCorporative,
        NewTotal,
        deposit,
        typeCompany,
        hasCorporateCommissions,
        subtractionImportTemp,
        application,
        safeguardsTotal,
    });

    const doubleCommission = getDoubleCommissionPerDeposit(bank, NewTotal);

    setDoubleCommission(doubleCommission);
    setImportFunding(funding.toFixed(2));
    setCommisionCorporative(commissionCorporative.toFixed(2));
};

const getBankCommissionPercentage = (bank) => {
    // Checks the type of bank and then sets a default percentage for group 2
    return bank == 'ASP INTEGRA' ? 0.002 : 0.0025;
};

const getDepositMinusBankCommissionSafeguard = (
    bank,
    safeguardsTotal,
    bankCommissionPercentage
) => {
    return bank == 'STP'
        ? safeguardsTotal - safeguardsTotal * 0.002 * 1.16
        : safeguardsTotal - safeguardsTotal * bankCommissionPercentage;
};

const getTotalCommissionCorpGroup = ({ safeguardsTotal }) => {
    return ((safeguardsTotal / 1.16) * (1.5 / 100)).toFixed(3);
};

const getTotalCommissionCorpGroup2 = ({ bankCommission }) => {
    return ((bankCommission / 1.16) * (1.5 / 100)).toFixed(3);
};

const getTotalCommissionCorpKV = ({
    safeguardsTotal,
    hasCorporateCommissions,
}) => {
    return hasCorporateCommissions
        ? ((safeguardsTotal / 1.16) * (1.5 / 100)).toFixed(3)
        : 0;
};

const getDepositMinusBankCommision = ({
    deposit,
    safeguardsTotal,
    bankCommission,
    application,
    checkIfIsWithdraw,
}) => {
    if (checkIfIsWithdraw) {
        return safeguardsTotal - bankCommission;
    } else {
        return deposit - bankCommission;
    }
};

const getCommissionBank = ({
    deposit,
    bank,
    safeguardsTotal,
    application,
    checkIfIsWithdraw,
}) => {

    const depositVariables = checkIfIsWithdraw ? safeguardsTotal : deposit;
    // Calculate the commission amount based on the selected 'bank' and 'deposit' amount.
    if (bank == 'STP') {
        // For 'STP' bank, apply a fixed commission rate and tax factor.
        return depositVariables * 0.002 * 1.16;
    } else if (bank == 'ASP INTEGRA') {
        // For 'ASP INTEGRA' bank, apply a fixed commission rate.
        return depositVariables * 0.002;
    } else if (bank == 'KUSPIT') {
        // For 'KUSPIT' bank, apply a fixed commission rate.
        return depositVariables * 0.0025;
    } else {
        return 0;
    }
};

const getCommissionCorporative = ({
    NewTotal,
    deposit,
    typeCompany,
    checkIfIsCorp,
    hasCorporateCommissions,
    application,
    safeguardsTotal,
    checkIfIsWithdraw,
}) => {
    const listTypesCompanies = ['KV', 'KV + Grupo', 'Grupo'];
    const depositVariable = checkIfIsWithdraw
        ? Number(safeguardsTotal)
        : deposit;
    //If corporative, use deposit as variable, else newTotal
    const variableForCommission = checkIfIsCorp ? depositVariable : NewTotal;

    if (listTypesCompanies.includes(typeCompany)) {
        return hasCorporateCommissions
            ? (variableForCommission / 1.16) * (1.5 / 100)
            : 0;
    } else {
        return hasCorporateCommissions
            ? (((variableForCommission - (variableForCommission * 0.25) / 100) /
                  1.16) *
                  1.5) /
                  100
            : 0;
    }
};

const getFunding = ({
    checkIfIsCorp,
    commissionCorporative,
    NewTotal,
    deposit,
    typeCompany,
    hasCorporateCommissions,
    subtractionImportTemp,
    application,
    safeguardsTotal,
    checkIfIsWithdraw
}) => {
    const depositVariable = checkIfIsWithdraw ? safeguardsTotal : deposit;

    const listTypesCompanies = ['KV', 'KV + Grupo', 'Grupo'];
    if (hasCorporateCommissions) {
        if (checkIfIsCorp) {
            if (listTypesCompanies.includes(typeCompany)) {
                return depositVariable - commissionCorporative;
            } else {
                return (
                    depositVariable -
                    (depositVariable * 0.25) / 100 -
                    commissionCorporative
                );
            }
        } else {
            if (listTypesCompanies.includes(typeCompany)) {
                return NewTotal - commissionCorporative;
            } else {
                return (
                    NewTotal - (NewTotal * 0.25) / 100 - commissionCorporative
                );
            }
        }
    } else {
        return subtractionImportTemp;
    }
};

const getDoubleCommissionPerDeposit = (bank, newTotal) => {
    if (bank == 'STP') {
        // For 'STP' bank, apply a fixed commission rate and tax factor.
        return newTotal * 0.002 * 1.16;
    } else if (bank == 'ASP INTEGRA') {
        // For 'ASP INTEGRA' bank, apply a fixed commission rate.
        return newTotal * 0.002;
    } else if (bank == 'KUSPIT') {
        // For 'KUSPIT' bank, apply a fixed commission rate.
        return newTotal * 0.0025;
    } else {
        return 0;
    }
};
