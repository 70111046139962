import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateAvailableKV,
    updatePerceptionsInvoice,
} from '../../actions/requests';
import { registerFunding } from '../../actions/funding';
import { updateApplication } from '../../actions/applications';
import { handleError, handleSuccess } from '../../helpers/utils';
import { getPayers } from '../../actions/catalogs';
import CurrencyFormat from 'react-currency-format';

const arrBases = [
    { label: 'TOTAL DEPOSITO' },
    { label: 'SUBTOTAL DEPOSITO' },
    { label: 'RETORNO' },
    { label: 'COMISION TOTAL' },
    { label: 'COMISION - BANCARIA' },
    { label: 'COMISION - BANCARIA - COSTO EFECTIVO CLIENTE' },
    { label: 'COMISION - COSTO EFECTIVO CLIENTE' },
    { label: 'COMISION - COSTOS OPERATIVOS' },
    { label: 'COMISION - COSTO SC' },
    { label: 'COMISION - 2% EDEN RED' },
    { label: 'COMISION - 2% EDEN RED - BANCARIA' },
    { label: 'COMISION - 2% EDEN RED - COM. CONTACTO - BANCARIA' },
    { label: 'COMISION - PROMOTORIA 1 - BANCARIA' },
    { label: 'COMISION - PROMOTORIA 2 - BANCARIA' },
    { label: 'COMISION - PROMOTORIA 3 - BANCARIA' },
    { label: 'COMISION - COSTO SC - PROMOTORIA 1' },
    { label: 'RETORNO + 10% SUBTOTAL' },
];

const arrPayers = [
    'Sukse',
    'Evor',
    'Mosep',
    'Kaylan',
    'Massuet',
    'Vireo',
    'Draxter',
    'Invarat',
    'Suare',
    'Henoceos',
    'Lexi',
    'Efectivo',
];

export const PayersDialog = ({
    show = false,
    handleClose,
    idPass = null,
    //handle how to manage Data
    handleChange,
    typeFunding = '',
    concept,
}) => {
    const dispatch = useDispatch();
    const availableKVTemp = useSelector((state) => state.request.availableKV);
    const rows = useSelector((state) => state.request.returnAndCommission);
    const applicationTemp = useSelector((state) => state.request.application);
    const [payers, setPayers] = useState('');
    const [payer, setPayer] = React.useState('');
    const [amount, setAmount] = React.useState(0);
    const [bank, setBank] = useState('');
    const [payersName, setPayersName] = useState(null);
    const { banks = [] } = useSelector((state) => state.catalogs);
    /* Reset values on Dialog on close or on */
    const setValuesZero = () => {
        setPayers('');
        setAmount(0);
        setBank('');
    };

    function setPayerAmountValues() {
        const tempObj = JSON.parse(JSON.stringify(availableKVTemp));
        tempObj.arrayPayers.push({
            payer: payer,
            amount: amount,
        });
        dispatch(updateAvailableKV({ ...tempObj }));
    }

    function updateFundings(value, applicationTemp) {
        const fixedObjectRegister = {
            ...value,
            application_id: applicationTemp.id,
            application_tag: applicationTemp.key,
            client:
                applicationTemp.client.id == null
                    ? applicationTemp.client
                    : applicationTemp.client.id,
            concept: concept,
            bank: banks.find((item) => item.name === bank).id,
        };
        registerFunding(fixedObjectRegister).then((response) => {
            updateApplicationFunding(response.data, applicationTemp, value);
        });
    }

    function updateApplicationFunding(newFundingUpdated, applicationTemp) {
        updateApplication(applicationTemp.id, {
            ...applicationTemp,
            fundings: [...applicationTemp.fundings, newFundingUpdated],
        })
            .then(({ data }) => {
                // Actualizar en Redux
                handleSuccess('Pagadora creada correctamente');
                dispatch(
                    updatePerceptionsInvoice({
                        ...data,
                        promoter: applicationTemp.promoter,
                        executive: applicationTemp.executive,
                        client: applicationTemp.client,
                        fundings: [
                            ...applicationTemp.fundings,
                            newFundingUpdated,
                        ],
                    })
                );
            })
            .catch((error) => {
                handleError(error, 'No se creó la pagadora');
            });
    }

    useEffect(() => {
        getPayers().then(({ data }) => {
            setPayersName(data.results.map((element) => element.name));
        });
    }, []);

    return (
        <Dialog
            maxWidth='lg'
            open={show}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle>Nuevo Pagadora - Importe:</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Complete el formulario para crear un nuevo Pagadora -
                    Importe
                </DialogContentText>
                <hr />

                <Grid
                    container
                    spacing={2}
                    style={{
                        marginBottom: '10px',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Autocomplete
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            required
                            // style={{ marginTop: '20px' }}
                            fullWidth
                            value={payers}
                            onChange={(_, value) => {
                                setPayers(value);
                            }}
                            options={payersName}
                            renderInput={(params) =>
                                SelectComponent(params, 'Pagadoras')
                            }
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <Autocomplete
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            required
                            // style={{ marginTop: '20px' }}
                            fullWidth
                            value={bank}
                            onChange={(_, value) => {
                                setBank(value);
                            }}
                            options={banks.map((item) => item.name)}
                            renderInput={(params) =>
                                SelectComponent(params, 'Banco')
                            }
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <CurrencyFormat
                            customInput={TextField}
                            label='Retorno'
                            fullWidth
                            placeholder={String(amount)}
                            thousandSeparator={true}
                            prefix={'$'}
                            allowNegative={false}
                            onChange={({ target }) => {
                                setAmount(
                                    Number(
                                        target.value.replace(/[^0-9.-]+/g, '')
                                    )
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color='error'
                    onClick={() => {
                        handleClose();
                        setValuesZero();
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        updateFundings(
                            {
                                payer: payers,
                                bank: bank,
                                amount: amount,
                                ope: typeFunding,
                            },
                            applicationTemp
                        );

                        handleClose();
                        setValuesZero();
                    }}
                    autoFocus
                >
                    Crear
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);

const SelectComponent = (params, label) => (
    <TextField
        fullWidth
        required
        margin='normal'
        {...params}
        label={label}
    />
);
