import { configureStore } from '@reduxjs/toolkit';
// import { areaReducer } from '../reducers/areaReducer';
import { authReducer } from '../reducers/authReducer';
import { catalogReducer } from '../reducers/catalogReducer';
import { userReducer } from '../reducers/userReducer';
import { requestReducer2 } from '../reducers/requestReducer2';
import { sidebarReducer } from '../reducers/sidebarReducer';

// Combine reducers
const reducer = {
	auth: authReducer,
	user: userReducer,
	catalogs: catalogReducer,
	request: requestReducer2,
	sidebar: sidebarReducer,
};

// Set store with reducers and thunk middleware
const store = configureStore({
	reducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export default store;
