import React, { useState, useEffect } from 'react';
import {
    Alert,
    Stack,
    Typography,
    Grid,
    TextField,
    Tooltip,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    styled,
    tableCellClasses,
} from '@mui/material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import {
    getResults,
    getReturnsAndCommissionsByApplicationID,
} from '../actions/applications';
import { getFundings, getFundingsSums } from '../actions/funding';
import { useSelector } from 'react-redux';
import { SafeguardsDialog } from '../components/results/SafeguardsDialog';
import { getBanks } from '../actions/catalogs';
import { money } from '../helpers/money';
import { DateRangeWithButton } from '../components/utils/UtilsComponent';
import { ReturnsAndFundingsResults } from '../components/results/ReturnsAndFundingsResults';

// Results table
export const PayersResults = () => {
    const columns = [
        {
            field: 'payer',
            headerName: 'Pagador',
            ...columnStyleCell,
            width: 400,
            renderCell: ({ row }) => (
                <div style={rowDialogStyle}>{row?.payer}</div>
            ),
        },
        {
            field: 'bank',
            headerName: 'Banco',
            ...columnStyleCell,
            width: 400,
            renderCell: ({ row }) => (
                <div style={rowDialogStyle}>
                    <span className='table-cell-trucate'>{row?.bank}</span>
                </div>
            ),
        },

        {
            field: 'sum',
            headerName: 'Monto',
            ...columnStyleCell,
            width: 400,
            renderCell: ({ row }) => (
                <Tooltip title='Ver Montos'>
                    <div style={rowDialogStyle}>
                        <span className='table-cell-trucate'>{row.sum}</span>
                        <Button
                            variant='text'
                            style={buttonDialogStyle}
                            onClick={() => {
                                setCurrentApplicationId(row.id);
                                setCurrentRowInfo({
                                    payer: row.payer,
                                    bank: row.bank,
                                });
                                handleOpenReturnsAndFundingsDialog();
                            }}
                        >
                            Ver
                        </Button>
                    </div>
                </Tooltip>
            ),
        },
    ];
    // Date range state
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const ranges = { startDate, setStartDate, endDate, setEndDate };

    // Rows state
    const [rows, setRows] = useState(null);

    // Current application
    const [currentApplicationId, setCurrentApplicationId] = useState(null);
    const { application = null } = useSelector((state) => state.request);
    const [tempFundings, setTempFundings] = useState(null);
    const [currentRowInfo, setCurrentRowInfo] = useState(null);

    // Deposits dialog
    const [openDepositsDialog, setOpenReturnsAndfundings] = useState(false);
    const handleOpenReturnsAndFundingsDialog = () =>
        setOpenReturnsAndfundings(true);
    const handleCloseReturnsAndFundingsDialog = () =>
        setOpenReturnsAndfundings(false);

    const getTotalSafeguardPerRow = async (applicationId) => {
        const result = await getReturnsAndCommissionsByApplicationID(
            applicationId
        );
        let filteredSafeguards = result?.data?.results.filter(
            (element) => element.scheme == 'RESGUARDO'
        );
        const totalSafeguards = filteredSafeguards?.reduce(
            (accumulator, object) => {
                return accumulator + Number(object.balance);
            },
            0
        );

        return totalSafeguards;
    };

    // Generate rows
    const generateRows = async (formattedStartDate, formattedEndDate) => {
        // Get results by date from API
        // const { data } = await getResults(formattedStartDate);
        const { data } = await getResults(formattedStartDate, formattedEndDate);
        // If results are empty set an empty array
        if (data?.results?.length === 0 || !data?.ok) setRows([]);

        const genericPromoter = {
            name: '-',
            commission: '-',
            percentage: '-',
            base_commission: '-',
        };

        // Map data results
        const res = data?.results.map((item) => {
            return {
                ...item,
            };
        });

        // Set mapped rows
        setRows(res);
    };

    const translateBanks = (banksTemp, bankComp) => {
        return banksTemp?.find((item) => item?.id === bankComp)?.name;
    };

    // Handle get results
    const handleGetResults = () => {
        // Date format to YYYY-MM-DD
        const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
        const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

        // Generate rows from formatted dates
        generateRows(formattedStartDate, formattedEndDate);
    };

    useEffect(() => {
        // First loads all banks data
        getBanks().then(({ data }) => {
            const banksList = data.results;

            // Then loads all fundings
            getFundingsSums().then(({ data }) => {
                const fixedPayersTranslatedBanks = data?.results?.map(
                    (element, id) => {
                        return {
                            id: id,
                            sum: money(element.sum),
                            bank: translateBanks(banksList, element.bank),
                            payer: element.payer,
                        };
                    }
                );
                setTempFundings(fixedPayersTranslatedBanks);

                
            });

            
        });

        handleGetResults();
    }, []);

    return (
        <div style={divStyle}>
            <ReturnsAndFundingsResults
                open={openDepositsDialog}
                handleClose={handleCloseReturnsAndFundingsDialog}
                applicationId={currentApplicationId}
                rowInfo={currentRowInfo}
            />

            <Stack
                direction='row'
                alignItems='flex-end'
                justifyContent='space-between'
                style={stackStyle}
            >
                <Typography
                    variant='h4'
                    gutterBottom
                >
                    Tabla de Pagadoras
                </Typography>
            </Stack>
            <hr />

            <Typography
                variant='h6'
                sx={{ marginBottom: '20px' }}
            >
                Solicitudes de día
            </Typography>

            {tempFundings?.length > 0 ? (
                <DataGrid
                    autoHeight
                    rows={tempFundings}
                    columns={columns}
                    // autoPageSize
                    components={{ Toolbar: GridToolbar }}
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                />
            ) : (
                <Alert severity='info'>No hay ninguna pagadora registrada</Alert>
            )}

            
        </div>
    );
};

const SelectComponent = (params, label) => (
    <TextField
        fullWidth
        required
        margin='normal'
        {...params}
        label={label}
    />
);

const rowDialogStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
};

const stackStyle = {
    padding: '20px 20px 0 0',
    margin: '0 0 40px 0',
    width: '100%',
};

const divStyle = {
    padding: '20px',
};

const gridStyle = {
    width: '70%',
    display: 'flex',
    justifyContent: 'flex-end',
};

const gridButtonStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const datagridTableStyle = { margin: '20px 0' };

const columnStyleCell = {
    headerClassName: 'column-header-pilu',
    headerAlign: 'center',
};

const Cell = ({ subtitle, text = '', lastBreaks = true }) => (
    <>
        <b>{subtitle}:</b>
        <br />
        {text ? (text === 'No lleva' ? '' : text) : 'No se asignó'}
        {lastBreaks && <br />}
    </>
);

const buttonDialogStyle = {
    // backgroundColor: '#143f6c',
    margin: '0 0 0 10px',
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#01579b',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const styleLabel = {
    '&:last-child td, &:last-child th': { border: 0 },
};

const ColumnItem = ({ align, text }) => (
    <StyledTableCell align={align}>{text}</StyledTableCell>
);
