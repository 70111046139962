import React, { useState, useEffect, useRef } from 'react';
import {
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { refreshReturnAction } from '../../actions/requests';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { money } from '../../helpers/money';
import { handleError, handleSuccess } from '../../helpers/utils';

import { useDispatch, useSelector } from 'react-redux';
import { updateReturnAndCommission } from '../../actions/returnAndCommissions';
import CurrencyFormat from 'react-currency-format';
import { calculateCommissionBySingleScheme, calculatesSubtotalIVA } from '../../helpers/ReturnCommissionCalc';

export const EditReturn = ({
    openModal = false,
    onClose,
    rowSelected = null,
}) => {
    const dispatch = useDispatch();
    const [returnValue, setReturnValue] = useState(0);
    const [comisionValue, setComisionValue] = useState(0);
    const [Esquema, setEsquema] = React.useState('');
    const [BaseComision, setBaseComision] = React.useState('');
    const [percent, setPercent] = React.useState(0);
    const totalRef = useRef(0);
    const [isn, setIsn] = React.useState(0);
    const [isr, setIsr] = React.useState(0);
    const [iva, setIva] = React.useState(0);
    const [socialCost, setSocialCost] = React.useState(0);
    
    const totalInvoice = useSelector((state) => state.request.application.invoice);
    const totalPerceptions = useSelector((state) => state.request.application.total_perceptions);
    const schemes = useSelector((state) => state.catalogs.scheme);
    const application = useSelector((state) => state.request.application);
    const rows = useSelector((state) => state.request.returnAndCommission);
    const depositsTotal = useSelector((state) => state.request.depositTotal);

    const editRow = () => {
        const tempScheme = Esquema;
        const tempBalance = Number(totalRef.current);
        const tempBase = BaseComision;
        const tempPercent = Number(percent);
        const tempReturn = Number(returnValue);
        const tempCommission = Number(comisionValue);
        const tempISN = Number(isn);
        const tempISR = Number(isr);
        const tempIVA = Number(iva);
        const tempSocialCost = Number(socialCost);
        let lastItem = 0;
        if (rows.length != 0) {
            lastItem = rows[rows.length - 1]?.id + 1;
        }

        const newArray = rows.map((obj) =>
            obj.id === rowSelected.id
                ? {
                      key: rowSelected.key,
                      id: rowSelected.id,
                      scheme: tempScheme,
                      balance: tempBalance,
                      base_commission: tempBase,
                      percentage: tempPercent,
                      return_value: tempReturn,
                      commission: tempCommission,
                      isn: tempISN,
                      isr: tempISR,
                      social_cost: tempSocialCost,
                      iva: tempIVA,
                      application: application?.id,
                      fundings: rowSelected.fundings.map(
                          (element) => element.id
                      ),
                  }
                : obj
        );

        updateReturnAndCommission(rowSelected.id, {
            scheme: tempScheme,
            balance: tempBalance,
            base_commission: tempBase,
            percentage: tempPercent,
            return_value: tempReturn,
            commission: tempCommission,
            isn: tempISN,
            isr: tempISR,
            social_cost: tempSocialCost,
            iva: tempIVA,
            application: application?.id,
            fundings: rowSelected.fundings.map((element) => element.id),
        })
            .then(({ data }) => {
                // Feedback
                handleSuccess('El retorno se editó correctamente.');
                // Add new instance to redux
                dispatch(refreshReturnAction(newArray));
                // Close dialog
            })
            .catch((error) => {
                handleError(error, 'No se pudo editar el retorno.');
            });
    };

    //when first rendering the values are undefined until rowSelected is fully updated
    useEffect(() => {
        setReturnValue(rowSelected.return_value);
        setPercent(rowSelected.percentage);
        setBaseComision(rowSelected.base_commission);
        setEsquema(rowSelected.scheme);
        setIsn(rowSelected.isn);
        setIsr(rowSelected.isr);
        setSocialCost(rowSelected.social_cost);
        setIva(rowSelected.iva);
    }, [rowSelected]);

    const calculateBalance = () => {
        // Convert input parameters to numbers if they are not already

        const commissionValuesCalculus = {
            return_value: Number(returnValue),
            ISN: Number(isn),
            ISR: Number(isr),
            IVA: Number(iva),
            socialCostTemp: Number(socialCost),
            percentT: Number(percent) / 100,
            depositTotal: Number(depositsTotal),
            facturaTemp: Number(totalInvoice),
            percepcionesTemp: Number(totalPerceptions)
        };

        // Calculate the commission value using the provided parameters
        const totalOfOneCommission = Number(
            calculateCommissionBySingleScheme(
                commissionValuesCalculus,
                BaseComision
            )
        );

        // Calculate the total balance by summing up the individual amounts
        const totalBalance =
            commissionValuesCalculus.return_value +
            commissionValuesCalculus.ISN +
            commissionValuesCalculus.ISR +
            commissionValuesCalculus.socialCostTemp +
            commissionValuesCalculus.IVA +
            Number(totalOfOneCommission);

        // Update the 'totalRef' ref with the calculated total balance
        totalRef.current = totalBalance;

        // Return the calculated total balance
        return totalBalance;
    };

    useEffect(() => {
        // Convert input parameters to numbers if they are not already
        const commissionValuesCalculus = {
            return_value: Number(returnValue),
            ISN: Number(isn),
            ISR: Number(isr),
            IVA: Number(iva),
            socialCostTemp: Number(socialCost),
            percentT: Number(percent) / 100,
            depositTotal: Number(depositsTotal),
            facturaTemp: Number(totalInvoice),
            percepcionesTemp: Number(totalPerceptions)
        };
        // Calculate the commission value using the provided parameters
        const totalOfOneCommission = Number(
            calculateCommissionBySingleScheme(
                commissionValuesCalculus,
                BaseComision
            )
        );

        // Set the calculated commission value using the 'setComisionValue' function
        setComisionValue(totalOfOneCommission);
    }, [
        returnValue,
        isn,
        isr,
        iva,
        socialCost,
        percent,
        BaseComision,
    ]);

    return (
        <Dialog
            fullWidth
            maxWidth='lg'
            open={openModal}
            onClose={onClose}
        >
            <DialogTitle>Editar retorno y comisión</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Complete el formulario para editar un nuevo retorno y
                    comisión
                </DialogContentText>

                <hr />

                <Box>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={3}
                        >
                            <Autocomplete
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                disablePortal
                                id='combo-box-demo'
                                options={
                                    schemes?.map(
                                        (element) => element.concept
                                    ) || []
                                }
                                sx={{ width: '100%' }}
                                defaultValue={rowSelected.scheme}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Esquemas'
                                    />
                                )}
                                onChange={(event, value) => {
                                    setEsquema(value);
                                }}
                            />
                        </Grid>

                        {BaseComision !== 'SUBTOTAL + IVA' ? (
                            <Grid
                                item
                                xs={3}
                            >
                                <CurrencyFormat
                                    customInput={TextField}
                                    label='Retorno'
                                    fullWidth
                                    value={returnValue ?? 0}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    allowNegative={false}
                                    onChange={({ target }) => {
                                        setReturnValue(
                                            target.value.replace(
                                                /[^0-9.-]+/g,
                                                ''
                                            )
                                        );
                                    }}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {Esquema !== 'RESGUARDO' &&
                        BaseComision !== 'SUBTOTAL + IVA' ? (
                            <>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <CurrencyFormat
                                        customInput={TextField}
                                        label='Costo Social ISN '
                                        fullWidth
                                        value={isn ?? 0}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        allowNegative={false}
                                        onChange={({ target }) => {
                                            setIsn(
                                                target.value.replace(
                                                    /[^0-9.-]+/g,
                                                    ''
                                                )
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <CurrencyFormat
                                        customInput={TextField}
                                        label='ISR'
                                        fullWidth
                                        value={isr ?? 0}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        allowNegative={false}
                                        onChange={({ target }) => {
                                            setIsr(
                                                target.value.replace(
                                                    /[^0-9.-]+/g,
                                                    ''
                                                )
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <CurrencyFormat
                                        customInput={TextField}
                                        label='Costo Social'
                                        fullWidth
                                        value={socialCost ?? 0}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        allowNegative={false}
                                        onChange={({ target }) => {
                                            setSocialCost(
                                                target.value.replace(
                                                    /[^0-9.-]+/g,
                                                    ''
                                                )
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <CurrencyFormat
                                        customInput={TextField}
                                        label='IVA'
                                        fullWidth
                                        value={iva ?? 0}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        allowNegative={false}
                                        onChange={({ target }) => {
                                            setIva(
                                                target.value.replace(
                                                    /[^0-9.-]+/g,
                                                    ''
                                                )
                                            );
                                        }}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <></>
                        )}

                        <Grid
                            container
                            spacing={2}
                            sx={{ mt: 2 }}
                            style={{ padding: '10px' }}
                        >
                            <Grid
                                item
                                xs={3}
                            >
                                <ItemTexts
                                    title='Saldo por Esquema'
                                    text={money(calculateBalance())}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={3}
                            >
                                <ItemTexts
                                    title='Comisión por Esquema'
                                    text={money(comisionValue)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color='error'
                >
                    Cancelar
                </Button>
                <Button
                    // disabled={Esquema === null && BaseComision === null}
                    onClick={() => {
                        editRow();
                        onClose();
                    }}
                >
                    Editar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);
