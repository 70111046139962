import { getCurrentTab } from '../actions/requests';
import { types } from '../types/types';

const initialRequest = {
    currentTab: getCurrentTab(),
    application: null,
    requestTotal: 0,
    depositTotal: 0,
    commissionTotal: 0,
    commissionCorporative: 0,
    commissionBank: 0,
    totalCommissionSchemeTotal: 0,
    operatingExpensesTotal: 0,
    operatingExpensesTotal2: 0,
    isFolioOne: true,
    deposits: [],
    totalImportFunding: 0,
    returnAndCommission: [],
    listOfSafeguardsPerClient: [],
    withdrawTotal: 0,
    operatingExpenses: {
        comisionesBancarias: 0,
        secondBankCommission: 0,
        doubleBankCommission: 0,
        comisionesConvencionales: 0,
        costoProvedor4ST: 0,
        costoEfectivo: 0,
        comisionesExtra: 0,
        costoTraslado: 0,
        porcentajeTraslado: 0,
        costoExtra: 0,
        costoFID: 0,
        costoSC: 0,
        costoSindicato: 0,
        comisionSpei: 0,
        comisionEdenredKV: 0,
        comisionEdenredCorpo: 0,
        comisionFacil: 0,
        gastosPromotoria: 0,
        gastosAAbonoDeuda: 0,
        amountFidPayer: 0,
        amountEffectivePayer: 0,
        amountScPayer: 0,
        amountSindicatePayer: 0,
        amountSpeiPayer: 0,
        amountEdenredPayer: 0,
        AmountEdenredCorpoPayer: 0,
        amountFacilPayer: 0,
        payerFid: '',
        payerSC: '',
        payerEffective: '',
        payerSindicate: '',
        payerSpei: '',
        payerEdenred: '',
        payerEdenredCorpo: '',
        payerFacil: '',
        bankCommissionSafeguard: 0,
        secondBankCommissionSafeguard: 0,
        corporativeCostSafeguard: 0,
    },
    decOperatingExpenses: {
        comisionesBancarias: false,
        secondBankCommission: false,
        doubleBankCommission: false,
        costoProvedor4ST: false,
        costoEfectivo: false,
        costoFID: false,
        costoSC: false,
        costoSindicato: false,
        comisionSpei: 0,
        comisionesExtra: false,
        cuentaKV: false,
    },
    promoters: {
        promotersArray: [],
        commissionTotal: 0,
        amountTotal: 0,
    },
    administrativeCosts: {
        administrativeCostsArray: [],
        promotersArray: [],
    },
    administrativeCostsWithdraw: {
        administrativeCostsWithdrawArray: [],
        promotersWithdrawArray: [],
    },
    creditToDebt: {
        creditArray: [],
        commissionTotal: 0,
        totalExpenses: 0,
        amountTotal: 0,
        availableKV: 0,
        totalPayer: 0,
        totalImport: 0,
    },
    availableKV: {
        observations: '',
        fundings: [],
        availableKV: 0,
    },
};

export const requestReducer2 = (state = initialRequest, action) => {
    switch (action.type) {
        // Set current application
        case types.currentApplication:
            return {
                ...state,
                application: action.payload,
            };

        // Set deposit total
        case types.setDepositTotal:
            return {
                ...state,
                depositTotal: action.payload,
            };

        // Set commissions total
        case types.setCommissionTotal:
            return {
                ...state,
                commissionTotal: action.payload,
            };

        // Set commissions total
        case types.setCommissionCorporative:
            return {
                ...state,
                commissionCorporative: action.payload,
            };

        // Set commissions total
        case types.setCommissionBank:
            return {
                ...state,
                commissionBank: action.payload,
            };

        // Change all the deposits
        case types.setDeposits:
            return {
                ...state,
                deposits: action.payload,
            };

        case types.setTotalImportFunding:
            return {
                ...state,
                totalImportFunding: action.payload,
            };

        // Set Administrative Costs
        case types.setAdministrativeCosts:
            return {
                ...state,
                administrativeCosts: action.payload,
            };

        // Set Administrative Costs
        case types.setListOfSafeGuards:
            return {
                ...state,
                listOfSafeguardsPerClient: action.payload,
            };

        case types.setAdministrativeCostsWithdraw:
            return {
                ...state,
                administrativeCostsWithdraw: action.payload,
            };

        // Add new deposit
        case types.addDeposit:
            return {
                ...state,
                deposits: [...state.deposits, action.payload],
            };

        // Update deposit by ID
        case types.updateDeposit:
            let updatedDeposits = state.deposits;
            const indexTarget = updatedDeposits.findIndex(
                (item) => item.id === action.payload.id
            );
            updatedDeposits[indexTarget] = action.payload;
            return {
                ...state,
                deposits: updatedDeposits,
            };

        // Delete new deposit by ID
        case types.deleteDeposit:
            return {
                ...state,
                deposits: state.deposits.filter(
                    (deposit) => deposit.id !== action.payload.id
                ),
            };

        // Clean deposits
        case types.addDeposit:
            return {
                ...state,
                deposits: [],
            };

        // Update Return And Commission Object
        case types.updatedReturn:
            return {
                ...state,
                returnAndCommission: action.payload,
            };

        // Update requestTotal from operative expenses
        case types.requestEvaluation:
            return {
                ...state,
                requestTotal: action.payload,
            };

        // Update Operating Expenses object
        case types.updateOperatingExpenses:
            return {
                ...state,
                operatingExpenses: action.payload,
            };

        // Update the total commission scheme return
        case types.totalCommissionSchemeTotal:
            return {
                ...state,
                totalCommissionSchemeTotal: action.payload,
            };

        // Update the total of Operating Expenses
        case types.updateOperatingExpensesTotal:
            return {
                ...state,
                operatingExpensesTotal: action.payload,
            };

        // Update the total of Operating Expenses
        case types.updateOperatingExpensesTotal2:
            return {
                ...state,
                operatingExpensesTotal2: action.payload,
            };

        // Update all decition selects from operating expenses
        case types.updateDecOperatingExpenses:
            return {
                ...state,
                decOperatingExpenses: action.payload,
            };

        // Update the Credit to Debt object
        case types.updateCreditToDebt:
            return {
                ...state,
                creditToDebt: action.payload,
            };

        // Update the Promoters object
        case types.updatePromoters:
            return {
                ...state,
                promoters: action.payload,
            };

        // Update available KV
        case types.updateAvailableKV:
            return {
                ...state,
                availableKV: action.payload,
            };

        // Reset request
        case types.resetRequest:
            return initialRequest;

        // Set current tab
        case types.currentTab:
            return {
                ...state,
                currentTab: action.payload,
            };

        case types.updatePerceptionsInvoice:
            return {
                ...state,
                application: action.payload,
            };

        case types.getWithdrawTotal:
            return {
                ...state,
                withdrawTotal: action.payload,
            };

        default:
            return state;
    }
};
