import React, { useEffect } from 'react';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { UsersTable } from '../components/users/UsersTable';
import { Stack } from '@mui/system';
import { DialogTemplate } from '../components/dialogs/DialogTemplate';
import { NewUserForm } from '../components/auth/NewUserForm';
import { getUsers, getUsersAdministrator, getUsersExecutive, getUsersOperator } from '../actions/user';

// Define rolls
const rolls = ['Administrador', 'Ejecutivo', 'Operador'];

export const Users = () => {
    const [value, setTab] = React.useState(0);
    const [count, setCount] = useState(0);
    const [profile , setProfile] = useState(null)

    // Dialog
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (_, newValue) => {
        setTab(newValue);
    };

    // Users
    const [users, setUsers] = useState([]);

    /**
     * This function fetches user data based on the provided 'value' parameter, which determines the type of users to retrieve.
     * The 'manageData' object contains different functions for fetching users based on their roles:
     *   - 0: Fetches administrators
     *   - 1: Fetches executives
     *   - 2: Fetches operators
     *
     * The selected function is executed based on the 'value' parameter, and the retrieved user data is set in the component's state using the 'setUsers' function.
     */
    const getData = () => {
        // Define an object that maps 'value' to the corresponding user retrieval function.
        const manageData = {
            0: () => {
                // Fetch administrators and set the data in the component's state.
                getUsers("ADM")
                    .then(({ data }) => {
                        console.log(data)
                        setUsers(data.results);
                        setCount(data.count);
                        setProfile("ADM")
                    })
                    .catch((error) => {
                        console.error('Error fetching administrators:', error);
                    });
            },
            1: () => {
                // Fetch executives and set the data in the component's state.
                getUsers("EXE")
                    .then(({ data }) => {
                        setUsers(data.results);
                        setCount(data.count);
                        setProfile("EXE")

                    })
                    .catch((error) => {
                        console.error('Error fetching executives:', error);
                    });
            },
            2: () => {
                // Fetch operators and set the data in the component's state.
                getUsers("OPE")
                    .then(({ data }) => {
                        setUsers(data.results);
                        setCount(data.count);
                        setProfile("OPE")

                    })
                    .catch((error) => {
                        console.error('Error fetching operators:', error);
                    });
            },
        };

        // Execute the appropriate function based on the provided 'value'.
        manageData[value]();
    };

    useEffect(() => {
        getData();
    }, [value]);

    // Panel component for each tab
    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role='tabpanel'
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    };

    return (
        <Box sx={{ width: '100%' }}>
            <DialogTemplate
                config={{
                    open: open,
                    handleClose: handleClose,
                    btnCancel: false,
                    btnConfirm: false,
                    title: 'Nuevo registro de usuario',
                    subtitle: 'Registro de nuevo usuario.',
                }}
                content={
                    <NewUserForm
                        handleClose={handleClose}
                        refresh={getData}
                    />
                }
            />
            <Stack
                direction='column'
                alignItems='flex-start'
                justifyContent='space-between'
                mb={1}
                style={{ border: '1px solid white', padding: '20px 0 0 20px' }}
            >
                <Typography
                    variant='h4'
                    gutterBottom
                >
                    Usuarios
                </Typography>
                <Stack
                    direction='row'
                    alignItems='flex-end'
                    justifyContent='space-between'
                    style={{
                        border: '1px solid white',
                        padding: '20px 20px 0 0',
                        width: '100%',
                    }}
                >
                    <Typography
                        variant='p'
                        gutterBottom
                    >
                        Se muestran todos los usuarios categorizados en{' '}
                        {rolls.length} tipos de usuario.
                    </Typography>
                    <Button
                        variant='contained'
                        style={{ backgroundColor: '#143f6c' }}
                        onClick={handleOpen}
                    >
                        Agregar usuario
                    </Button>
                </Stack>
            </Stack>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label='basic tabs example'
                >
                    {rolls.map((role, index) => (
                        <Tab
                            label={role}
                            {...a11yProps(index)}
                            key={`tab-${index}`}
                        />
                    ))}
                </Tabs>
            </Box>
            {rolls.map((role, index) => (
                <TabPanel
                    value={value}
                    index={index}
                    key={`role-tab-${index}`}
                >
                    <UsersTable
                        data={users}
                        profile={role}
                        roleTemp = {profile}
                        refresh={getData}
                        count={count}
                    />
                </TabPanel>
            ))}
        </Box>
    );
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};
