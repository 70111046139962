import React from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

// Define rolls
const rolls = ['Administrador', 'Jefe de Área', 'Capturista', 'Reportes'];

// Initial values for form
const initialValuesFormik = {
	name: '',
};

// Formik schema
const RegisterSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, '¡Demasiado corto!')
		.max(100, '¡Demasiado largo!')
		.nullable(true)
		.required('Se requiere el nombre'),
});

export const NewTeamForm = ({ handleClose }) => {
	// Define state to know if it's loading or not
	const [isLoading, setIsLoading] = useState(false);

	const formik = useFormik({
		initialValues: initialValuesFormik,
		validationSchema: RegisterSchema,
		onSubmit: () => {
			console.log(formik.values);
		},
	});

	const { handleSubmit, getFieldProps, touched, errors } = formik;

	// Text component for reuse
	const TextComp = (name, label, type = 'text') => (
		<TextField
			margin='normal'
			type={type}
			fullWidth
			{...getFieldProps(name)}
			label={label}
			error={Boolean(touched[name] && errors[name])}
			helperText={touched[name] && errors[name]}
		/>
	);

	const rollComponent = (params, label) => (
		<TextField
			required
			margin='normal'
			{...params}
			{...getFieldProps('roll')}
			label={label}
			error={Boolean(touched.roll && errors.roll)}
			helperText={touched.roll && errors.roll}
		/>
	);

	return (
		<FormikProvider value={formik}>
			<Form autoComplete='off' noValidate onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{/* Name field */}
						{TextComp('name', 'Nombre')}
					</Grid>

					<Grid item xs={6}>
						{/* Cancel button */}
						<LoadingButton
							fullWidth
							size='large'
							color='error'
							onClick={() => {
								formik.resetForm(initialValuesFormik);
								handleClose();
							}}
							variant='contained'
							loading={false}
						>
							Cancelar
						</LoadingButton>
					</Grid>

					<Grid item xs={6}>
						{/* Confirm button */}
						<LoadingButton
							fullWidth
							size='large'
							style={{ backgroundColor: '#143f6c' }}
							type='submit'
							variant='contained'
							loading={isLoading}
						>
							Crear Equipo
						</LoadingButton>
					</Grid>
				</Grid>
			</Form>
		</FormikProvider>
	);
};
