import React from 'react';
import { useQuery } from 'react-query';
import { getFundingsSums } from '../actions/funding';
import { Typography } from '@mui/material';

export default function Test() {
    const { isLoading, error, data, isError } = useQuery({
        queryKey: ['repoData'],
        queryFn: () => getFundingsSums(),
    });

    return (
        <div>
            <Typography>Fetch data with React Query</Typography>
            {isLoading ? (
                <div>Cargando</div>
            ) : isError ? (
                <div>{error.message}</div>
            ) : (
                <div>{JSON.stringify(data.data)}</div>
            )}
        </div>
    );
}
