import * as React from 'react';
import { useEffect,useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Grid, Typography } from '@mui/material';
import { money } from '../../helpers/money';
import { useDispatch } from 'react-redux';
import { getReturnsAndCommissionsByApplicationID } from '../../actions/applications';

export const DialogFundingsResume = ({
    fundings = [],
    show = false,
    handleClose,
    applicationId = null, 
}) => {
    // Dispatch
    const dispatch = useDispatch();
    const [tempFundings , setTempFundings] = useState([])

    const returnAllFundingsAsArray = (AllReturnsAndCommissions=[]) => {
        let totalArrayOfFundings = [] 
        AllReturnsAndCommissions.forEach((oneReturn) => {
            oneReturn.fundings.forEach((fundingElement) => {
                totalArrayOfFundings.push(fundingElement);
            })
        })
        setTempFundings(totalArrayOfFundings);
        return totalArrayOfFundings;
    }

    useEffect(() => {
        if (applicationId !== null) {
            getReturnsAndCommissionsByApplicationID(applicationId).then(({ data }) => {
                console.log(returnAllFundingsAsArray(data.results));
            });
        }
        
    }, [applicationId]);

    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={show}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            {/* Title */}
            <DialogTitle>Información Fondeo</DialogTitle>

            {/* Content */}
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    style={{
                        width: '100%',
                        marginBottom: '10px',
                    }}
                >
                    {tempFundings.length > 0 ? (
                        tempFundings.map((funding) => (
                            <React.Fragment key={funding?.id}>
                                <Grid
                                    item
                                    xs={5}
                                >
                                    <ItemTexts
                                        title='Pagadora'
                                        text={funding.payer}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                >
                                    <ItemTexts
                                        title='Importe'
                                        text={money(funding.amount)}
                                    />
                                </Grid>
                            </React.Fragment>
                        ))
                    ) : (
                        <Grid
                            item
                            xs={12}
                        >
                            <Alert severity='info'>
                                No hay ningun elemento
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color='primary'
                    onClick={handleClose}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);
