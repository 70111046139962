import React from 'react';
import {
    Autocomplete, Grid
} from '@mui/material';
import CurrencyFormat from 'react-currency-format';

export function KVGroupComponent({
    TextField,
    commisionCorporative,
    corporativeFunding,
    setCorporativeFunding,
    SelectComponent,
    importFunding,
    companies,
}) {
    return (
        <>
            <Grid
                item
                xs={4}
            >
                <CurrencyFormat
                    customInput={TextField}
                    fullWidth
                    label='Comisión corporativo'
                    InputProps={{
                        readOnly: true,
                    }}
                    value={commisionCorporative}
                    thousandSeparator={true}
                    prefix={'$'}
                    allowNegative={false}
                    margin='normal'
                />
            </Grid>
            {/* Lista desplegable */}
            <Grid
                item
                xs={4}
            >
                {/* Client */}
                <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    required // style={{ marginTop: '20px' }}
                    fullWidth
                    value={corporativeFunding}
                    onChange={(_, value) => {
                        setCorporativeFunding(value);
                    }}
                    options={companies.map((item) => item.name)}
                    renderInput={(params) =>
                        SelectComponent(params, 'Empresa Fondeo Corporativo')
                    }
                />
            </Grid>
            <Grid
                item
                xs={4}
            >
                <CurrencyFormat
                    customInput={TextField}
                    fullWidth
                    label='Importe/Fondeo'
                    value={importFunding}
                    InputProps={{
                        readOnly: true,
                    }}
                    thousandSeparator={true}
                    prefix={'$'}
                    allowNegative={false}
                    margin='normal'
                />
            </Grid>
        </>
    );
}
