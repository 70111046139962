import {
    setCurrentApplication,
    updateApplication,
} from '../../actions/applications';
import {
    refreshReturnAction,
    setListOfSafeguards,
    updatePerceptionsInvoice,
} from '../../actions/requests';
import {
    deleteReturnAndCommission,
    registerReturnAndCommission,
    registerSafeguard,
} from '../../actions/returnAndCommissions';
import store from '../../store/store';
import { calculateAvailableKV } from '../totalAvailableKV';
import { handleError, handleSuccess } from '../utils';


export const baseObj = [
    { label: 'TOTAL' },
    { label: 'TOTAL FACTURA' },
    { label: 'SUBTOTAL' },
    { label: 'SUBTOTAL + IVA' },
    { label: 'SUBTOTAL FACTURA' },
    { label: 'SUBTOTAL + 2% RET' },
    { label: 'RETORNO' },
    { label: 'RETORNO + 2%' },
    { label: 'RETORNO + IVA' },
    { label: 'RETORNO + IVA (10%)' },
    { label: 'RETORNO Y COSTO' },
    { label: 'PERCEPCIONES' },
    { label: 'PERCEPCIONES + IVA' },
    { label: 'DISPONIBLE' },
    { label: 'SUB DEL RETORNO' },
    { label: 'SUB DEL RETORNO + 2%' },
    { label: 'COSTOS' },
];

export const handleDeleteReturnAndCommission = (
    id,
    dispatch,
    rows,
    setTotalPagadoras
) => {
    // Filter the existing rows to remove the record with the specified ID
    const newArr = rows.filter((element) => element?.id !== id);

    // Dispatch an action to refresh the state with the updated row data
    dispatch(refreshReturnAction(newArr));

    // Send a request to delete the corresponding record from the backend
    deleteReturnAndCommission(id)
        .then(() => {
            // Display a success message upon successful deletion
            handleSuccess('Retorno eliminado correctamente');
        })
        .catch((error) => {
            // Handle any errors that may occur during the deletion process
            handleError(
                error,
                'Ocurrió un error al intentar eliminar el registro'
            );
        });

    // Perform any necessary actions such as updating totals
    updateTotals(rows, setTotalPagadoras);
};

export async function updateTotals(rows, setCallback) {
    // Create an array to collect all fundings from rows
    let arrayOfPayers = [];

    // Iterate through each row
    rows.forEach((element) => {
        // Iterate through each funding in the row and add them to the array
        element?.fundings.forEach((funding) => arrayOfPayers.push(funding));
    });

    // Calculate the total amount by summing the amounts of all fundings
    const totalAmount = arrayOfPayers.reduce((accumulator, funding) => {
        return accumulator + Number(funding?.amount);
    }, 0);

    // Set the total pagadoras (payers) to the calculated total amount
    setCallback(totalAmount);
}

export function updateApplicationPerceptions(
    applicationTemp,
    dispatch,
    perceptionsTemp
) {
    if (perceptionsTemp !== '') {
        // Prepare the updated application data with the new 'total_perceptions' value
        updateApplication(applicationTemp.id, {
            ...applicationTemp,
            total_perceptions: perceptionsTemp,
        })
            .then(({ data }) => {
                // Handle success by displaying a success message
                handleSuccess('Éxito editando Percepciones');

                // Dispatch an action to update the perceptions and invoice data in Redux
                dispatch(
                    updatePerceptionsInvoice({
                        ...data,
                        promoter: applicationTemp.promoter,
                        executive: applicationTemp.executive,
                    })
                );
            })
            .catch((error) => {
                // Handle error by displaying an error message
                handleError('Error editando Percepciones');
            });
    }
}

export function updateApplicationInvoice(
    applicationTemp,
    invoiceVar,
    dispatch
) {
    if (invoiceVar !== '') {
        // Prepare the updated application data with the new 'invoice' value
        updateApplication(applicationTemp.id, {
            ...applicationTemp,
            invoice: invoiceVar,
        })
            .then(({ data }) => {
                // Handle success by displaying a success message
                handleSuccess('Éxito editando factura');

                // Dispatch an action to update the perceptions and invoice data in Redux
                dispatch(
                    updatePerceptionsInvoice({
                        ...data,
                        promoter: applicationTemp.promoter,
                        executive: applicationTemp.executive,
                    })
                );
            })
            .catch((error) => {
                // Handle error by displaying an error message
                handleError('Error editando factura');
            });
    } else {
        // Set the invoice value to 0 when 'invoiceVar' is empty
        updateApplication(applicationTemp.id, {
            ...applicationTemp,
            invoice: 0,
        })
            .then(({ data }) => {
                // Handle success by displaying a success message
                handleSuccess('Éxito editando factura');

                // Dispatch an action to update the perceptions and invoice data in Redux
                dispatch(
                    updatePerceptionsInvoice({
                        ...data,
                        promoter: applicationTemp.promoter,
                        executive: applicationTemp.executive,
                    })
                );
            })
            .catch((error) => {
                // Handle error by displaying an error message
                handleError('Error editando factura');
            });
    }
}



export async function addRow(
    variablesToAdd,
    dispatch,
    callbackResetVariables,
    Esquema,
    rows = [],
    BaseComision,
    application,
    applicationTemp,
    companyName,
    handleCloseCallback = () => {}
) {
    const {
        tempBalance,
        tempPercent,
        tempReturn,
        tempCommission,
        tempISN,
        tempISR,
        tempSocialCost,
        tempIVA,
    } = variablesToAdd;

    // Determine the ID for the new row
    let lastItem = 0;
    if (rows.length !== 0) {
        lastItem = rows[rows.length - 1]?.id + 1;
    }

    // Duplicate Return in case it's only Safeguard
    if (Esquema === 'RESGUARDO') {
        const state = store.getState();
        const { listOfSafeguardsPerClient = [] } = state.request;
        registerSafeguard({
            balance: tempBalance,
            application: application?.id,
            client_application:
                applicationTemp.client.id == null
                    ? applicationTemp.client
                    : applicationTemp.client.id,
            company_name: companyName !== null ? companyName : 'vacio',
        })
            .then(({ data }) => {
                dispatch(
                    setListOfSafeguards([
                        ...listOfSafeguardsPerClient,
                        data,
                    ])
                );
            })
            .catch((error) => {
                // Handle error by displaying an error message
                handleError(error, 'No se pudo registrar el retorno.');
            });
    }

    const clientApplication =
        applicationTemp.folio === 'SOLICITUD DE RETIRO'
            ? applicationTemp.id
            : applicationTemp.client.id;

    // Register the return and commission information
    registerReturnAndCommission({
        scheme: Esquema,
        balance: tempBalance,
        base_commission: BaseComision,
        percentage: tempPercent,
        return_value: tempReturn,
        commission: tempCommission,
        isn: tempISN,
        isr: tempISR,
        iva: tempIVA,
        social_cost: tempSocialCost,
        application: application?.id,
        fundings: [],
        client_application: clientApplication,
        company_name: companyName !== null ? companyName : 'vacio',
    })
        .then(({ data }) => {
            // Handle success by displaying a success message
            handleSuccess('El retorno se creó correctamente.');
            // Add the new instance to Redux store
            dispatch(refreshReturnAction([...rows, data]));
            // Close the dialog
            handleCloseCallback();
        })
        .catch((error) => {
            // Handle error by displaying an error message
            handleError(error, 'No se pudo registrar el retorno.');
        });

    // Reset the form fields
    callbackResetVariables();
}
