import React from 'react';
import {
	deleteOriginSource,
	getOriginSourceInfo,
	getOriginsSource,
	registerOriginSource,
	searchOriginSource,
	updateOriginSource,
} from '../../actions/catalogs';
import { SimpleCatalogTable } from './SimpleCatalogTable';

// Origins Source Table
export const OriginSource = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getOriginsSource,
		getCatalogInfo: getOriginSourceInfo,
		deleteCatalog: deleteOriginSource,
		updateCatalog: updateOriginSource,
		registerCatalog: registerOriginSource,
		searchCatalog: searchOriginSource,
		catalogSingular: 'Origen recurso',
		catalogPlural: 'Origen recursos',
		pronounSingular: 'el',
		pronoun: 'o',
		conceptName: 'name',
		conceptNameLabel: 'Nombre',
		hasKey: false,
	};

	return <SimpleCatalogTable config={configuration} />;
};
