import axios from 'axios';
import { getToken } from './auth';

// Get funding info by id
export const getFundingInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/funding/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get all fundings
export const getFundings = async () => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: '/api/application/funding/',
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get all fundings sum
export const getFundingsSums = async () => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: '/api/application/funding/sum/',
		headers: { Authorization: 'Bearer ' + token },
	});
};

export const getFundingsFiltered = async (obj) => {
	
	const token = getToken();
	return await axios({
		method: 'get',
		url: '/api/application/funding/filter/',
		headers: { Authorization: 'Bearer ' + token },
		params: obj
	});
};

export const getFundingsPaginated = async () => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: '/api/application/funding/',
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register funding
export const registerFunding = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/application/funding/create/',
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update funding
export const updateFunding = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/application/funding/update/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete funding
export const deleteFunding = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/application/funding/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};
