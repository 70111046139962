import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Alert, Grid,
    IconButton, Typography
} from '@mui/material';
import { money } from '../../helpers/money';
import { useSelector, useDispatch } from 'react-redux';
import {
    updatePerceptionsInvoice
} from '../../actions/requests';
import { deleteFunding } from '../../actions/funding';
import {
    handleError,
    handleSuccess,
    handleConfirmModal,
} from '../../helpers/utils';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';


// Error message
const errorMsg =
    'Ocurrió un error al intentar eliminar el fondeo. Intenta más tarde.';

export const FundingsAvailableOE = ({
    show = false,
    handleClose = null,
    fundings = [],
    typeFunding = '',
    tempBanks = null,
}) => {
    // Dispatch
    const dispatch = useDispatch();

    // Redux state

    const applicationTemp = useSelector((state) => state.request.application);

    // Handle delete funding
    const handleDeleteFunding = (id, applicationTemp) => {
        // Perform action delete
        handleConfirmModal(
            'Precaución',
            '¿Seguro que deseas borrar el fondeo?',
            'warning',
            'Sí, borrar'
        ).then(({ isConfirmed }) => {
            if (isConfirmed) {
                deleteFundingAction(id, applicationTemp);
            }
        });
    };

    // Delete funding from DB and update state
    const deleteFundingAction = async (id, applicationTemp) => {
        try {
            // Delete funding (DB)
            await deleteFunding(id)
                .then(({ data }) => {
                    const deletedElement = applicationTemp.fundings.filter(
                        (element) => element.id !== id
                    );

                    handleSuccess('Fondeo eliminado exitosamente');
                    dispatch(
                        updatePerceptionsInvoice({
                            ...applicationTemp,
                            fundings: deletedElement,
                        })
                    );
                })
                .catch((error) => {});

            // Get updated register (DB)

            // Feedback success
            // handleSuccess("El fondeo se eliminó correctamente");

            // Close modal
            handleClose();
        } catch (error) {
            // Feedback error
            handleError(error, errorMsg);
        }
    };

    const searchBank = (fundingTemp) => {
        // While loading all banks names renders loading
        if (
            typeof tempBanks?.find((item) => item?.id === fundingTemp)?.name ===
            'undefined'
        ) {
            return 'Cargando...';
        }
        return tempBanks?.find((item) => item?.id === fundingTemp)?.name;
    };

    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={show}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            {/* Title */}
            <DialogTitle>Información Fondeo</DialogTitle>

            {/* Content */}
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    style={{
                        width: '100%',
                        marginBottom: '10px',
                    }}
                >
                    {fundings.length > 0 ? (
                        fundings
                            .filter((element) => element.ope == typeFunding)
                            .map((funding) => (
                                <React.Fragment key={funding?.id}>
                                    <Grid
                                        item
                                        xs={3}
                                    >
                                        <ItemTexts
                                            title='Pagadora'
                                            text={funding.payer}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                    >
                                        <ItemTexts
                                            title='Banco'
                                            text={searchBank(funding?.bank)}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                    >
                                        <ItemTexts
                                            title='Importe'
                                            text={money(funding.amount)}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                    >
                                        <IconButton
                                            style={{ color: '#143f6c' }}
                                            aria-label='delete'
                                            color='primary'
                                            onClick={() => {
                                                handleDeleteFunding(
                                                    funding?.id,
                                                    applicationTemp
                                                );
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </React.Fragment>
                            ))
                    ) : (
                        <Grid
                            item
                            xs={12}
                        >
                            <Alert severity='info'>
                                No hay ningun elemento
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color='primary'
                    onClick={handleClose}
                >
                    Ok
                </Button>
                {/* <Button color='error' onClick={handleClose}>
					Cancelar
				</Button> */}
                {/* <Button
                    onClick={() => {
                        setPayerAmountValues();
                        handleChange();
                        handleClose();
                        setValuesZero();
                    }}
                    autoFocus
                >
                    Crear
                </Button> */}
            </DialogActions>
        </Dialog>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);
