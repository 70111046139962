import React from 'react';
import { deleteCompany, getCompanies } from '../../actions/catalogs';
import { CompaniesTable } from './CompaniesTable';

// Companies Table
export const Company = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getCompanies,
		deleteCatalog: deleteCompany,
		catalogSingular: 'Empresa',
		catalogPlural: 'Empresas',
		pronounSingular: 'la',
		pronoun: 'a',
	};

	// Configuration for the Details Table
	const detailGridConfig = {
		gridItems: [
			{ xsWidth: 8, title: 'NOMBRE COMPLETO', code: 'name' },
			{ xsWidth: 4, title: 'NOMBRE CORTO', code: 'shortname' },
			{ xsWidth: 4, title: 'RFC', code: 'rfc' },
			{ xsWidth: 4, title: 'SEDE', code: 'headquarter' },
			{ xsWidth: 4, title: 'NIVEL', code: 'level' },
			{ xsWidth: 4, title: 'GIRO', code: 'concept' },
			{ xsWidth: 4, title: 'Es corporativa', code: 'is_corp' },
			{ xsWidth: 12, title: 'CLIENTE', code: 'client_name' },
			{ xsWidth: 12, title: 'DESCRIPCION', code: 'description' },
			{ xsWidth: 12, title: 'OBSERVACIONES', code: 'observations' },
		],
	};

	return (
		<CompaniesTable
			config={configuration}
			detailGridConfig={detailGridConfig}
		/>
	);
};
