import { IconButton } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSideBar, setSideBarStatus } from '../../../actions/sidebar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const NavBar = () => {
	const { sidebar = true } = useSelector((state) => state);
	const dispatch = useDispatch();
	return (
		<nav className='navbar navbar-expand-lg bg-light'>
			<div className='container-fluid'>
				<IconButton
					style={{ color: '#143f6c' }}
					aria-label='profile'
					color='primary'
					onClick={() => {
						const newStatus = !sidebar;
						// Change sidebar status (redux)
						dispatch(setShowSideBar(newStatus));
						// Change sidebar status (local storage)
						setSideBarStatus(newStatus);
					}}
				>
					{sidebar ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
				</IconButton>
				<a className='navbar-brand' href='#test'></a>
				<button
					className='navbar-toggler'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#navbarSupportedContent'
					aria-controls='navbarSupportedContent'
					aria-expanded='false'
					aria-label='Toggle navigation'
				>
					<span className='navbar-toggler-icon'></span>
				</button>
				<div
					className='collapse navbar-collapse'
					id='navbarSupportedContent'
				>
					<ul className='navbar-nav me-auto mb-2 mb-lg-0'>
						<li className='nav-item'>
							<a
								className='nav-link active'
								aria-current='page'
								href='#test'
							></a>
						</li>
					</ul>
					{/* <form className='d-flex' role='search'>
						<input
							className='form-control me-2'
							type='search'
							placeholder='Código de orden'
							aria-label='Search'
						/>
						<Button variant='outline-primary'>Buscar</Button>
					</form> */}
				</div>
			</div>
		</nav>
	);
};
