import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { updateCreditToDebt, updatePromoters } from '../../actions/requests';
import { handleError, handleSuccess } from '../../helpers/utils';
import { registerDebt, updateDebt } from './../../actions/debt';
import CurrencyFormat from 'react-currency-format';

export const NewCreditToDebtModal = ({
    show = false,
    handleClose = null,
    handleChange = null,
    handleUpdateTotals = null,
    sectionTitle = '',
    isEdit = false,
    currentRow = null,
}) => {
    const dispatch = useDispatch();
    const request = useSelector((state) => state.request);
    const { application = null } = useSelector((state) => state.request);
    const creditToDebt = useSelector((state) => state.request.creditToDebt);
    const rows = useSelector((state) => state.request.returnAndCommission);

    const [nameTemp, setNameTemp] = React.useState(currentRow ? currentRow?.name : '');
    const [percentTemp, setPercentTemp] = React.useState(currentRow ? currentRow?.percentage : '');
    const [baseTemp, setBaseTemp] = React.useState(currentRow ? currentRow?.base_commission : '');
    const [commissionTemp, setCommissionTemp] = React.useState(currentRow ? currentRow?.commission : 0);

    /* Reset values on Dialog on close or on */
    const setValuesZero = () => {
        setNameTemp('');
        setPercentTemp(0);
        setBaseTemp('');
        setCommissionTemp(0);
    };

    /* Add a new row on Promoters table */
    const addRowPromoter = () => {
        registerDebt({
            name: nameTemp,
            percentage: percentTemp,
            base_commission: baseTemp,
            amount: 0,
            commission: parseFloat(commissionTemp),
            application: application.id,
            fundings: [],
        })
            .then(({ data }) => {
                let tempObj = { ...creditToDebt };
                const newData = [
                    ...creditArray.creditArray,
                    {
                        id: data.id,
                        name: nameTemp,
                        percentage: percentTemp,
                        base_commission: baseTemp,
                        commission: parseFloat(commissionTemp),
                        amount: 0,
                        fundings: [],
                        application,
                    },
                ];
                tempObj.promotersArray = newData;
                dispatch(updatePromoters(tempObj));
                handleSuccess('Registro agregado correctamente');
                handleUpdateTotals(newData);
            })
            .catch((error) => {
                handleError(
                    error,
                    'Ocurrió un error al intentar agregar un registro'
                );
            });
    };

    /* Add a new row on Debt table */
    const addRowDebt = () => {
        registerDebt({
            name: nameTemp,
            percentage: percentTemp,
            base_commission: baseTemp,
            amount: 0,
            commission: parseFloat(commissionTemp),
            application: application.id,
            fundings: [],
        })
            .then(({ data }) => {
                let tempObj = { ...creditToDebt };
                const newData = [
                    ...creditToDebt.creditArray,
                    {
                        id: data.id,
                        name: nameTemp,
                        percentage: percentTemp,
                        base_commission: baseTemp,
                        commission: parseFloat(commissionTemp),
                        amount: 0,
                        fundings: [],
                        application,
                    },
                ];
                tempObj.creditArray = newData;
                dispatch(updateCreditToDebt(tempObj));
                handleSuccess('Registro agregado correctamente');
                handleUpdateTotals(newData);
            })
            .catch((error) => {
                handleError(
                    error,
                    'Ocurrió un error al intentar agregar un registro'
                );
            });
    };

    /* Edit a new row on Debt table */
    const editRowDebt = () => {
        updateDebt(currentRow?.id, {
            name: nameTemp,
            percentage: percentTemp,
            base_commission: baseTemp,
            amount: 0,
            commission: parseFloat(commissionTemp),
            application: application.id,
            fundings: [],
        })
            .then(({ data }) => {
                // Update an item of the table
				// Find the index of the item to edit
				let tempObj = { ...creditToDebt };
				const indexData = creditToDebt.creditArray.findIndex((item) => item.id === data.id);
				
				const tmpData = creditToDebt.creditArray.map((item, index) => {
					if (index === indexData) {
						return {
							id: data.id,
                            name: nameTemp,
                            percentage: percentTemp,
                            base_commission: baseTemp,
                            commission: parseFloat(commissionTemp),
                            amount: 0,
                            fundings: [],
                            application,
						};
					}
					return item;
				});

				tempObj.creditArray = tmpData;
				dispatch(updateCreditToDebt(tempObj));
				handleSuccess('Registro actualizado correctamente');
				handleUpdateTotals(tmpData);
            })
            .catch((error) => {
                handleError(
                    error,
                    'Ocurrió un error al intentar agregar un registro'
                );
            });
    };

    useEffect(() => {
        let result = 0;
        /* revisada */
        if (baseTemp === 'TOTAL DEPOSITO') {
            result = request.depositTotal * (percentTemp / 100);
        } /* revisada */ else if (baseTemp === 'SUBTOTAL DEPOSITO') {
            result = (request.depositTotal / 1.16) * (percentTemp / 100);
        } /* revisada */ else if (baseTemp === 'RETORNO') {
            const totalReturn = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.return_value);
            }, 0);
            result = totalReturn * (percentTemp / 100);
        } /* revisada */ else if (baseTemp === 'COMISION TOTAL') {
            const totalCommission = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.commission);
            }, 0);
            result = totalCommission * (percentTemp / 100);
        } /* revisada */ else if (baseTemp === 'COMISION - BANCARIA') {
            const totalCommission = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.commission);
            }, 0);

            result =
                (totalCommission -
                    request.operatingExpenses.comisionesBancarias) *
                (percentTemp / 100);
        } /* revisado */ else if (
            baseTemp === 'COMISION - BANCARIA - COSTO EFECTIVO CLIENTE'
        ) {
            const totalCommission = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.commission);
            }, 0);

            result =
                (totalCommission -
                    request.operatingExpenses.comisionesBancarias -
                    request.operatingExpenses.costoEfectivo) *
                (percentTemp / 100);
        } /* revisado */ else if (baseTemp === 'COMISION - COSTOS OPERATIVOS') {
            const totalCommission = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.commission);
            }, 0);

            result =
                (totalCommission -
                    request.operatingExpenses.comisionesBancarias -
                    request.operatingExpenses.costoEfectivo -
                    request.operatingExpenses.costoSindicato -
                    request.operatingExpenses.costoSC -
                    request.operatingExpenses.costoFID) *
                (percentTemp / 100);
        } /* revisado */ else if (
            baseTemp === 'COMISION - COSTO EFECTIVO CLIENTE'
        ) {
            const totalCommission = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.commission);
            }, 0);
            result =
                (totalCommission - request.operatingExpenses.costoEfectivo) *
                (percentTemp / 100);
        } /* revisado */ else if (baseTemp === 'COMISION - COSTO SC') {
            const totalCommission = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.commission);
            }, 0);
            result =
                (totalCommission - request.operatingExpenses.costoSC) *
                (percentTemp / 100);
        } /* revisado */ else if (baseTemp === 'COMISION - 2% EDEN RED') {
            let edenRedKV = rows.reduce(function (acc, obj) {
                if (
                    obj.scheme === 'EDEN RED KV' &&
                    obj.return_value.length != 0
                ) {
                    return acc + Number(obj.return_value);
                } else {
                    return acc;
                }
            }, 0);

            let edenRedCorpo = rows.reduce(function (acc, obj) {
                if (
                    obj.scheme === 'EDEN RED CORPO' &&
                    obj.return_value.length != 0
                ) {
                    return acc + Number(obj.return_value);
                } else {
                    return acc;
                }
            }, 0);

            const totalCommission = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.commission);
            }, 0);

            result =
                ((totalCommission - (edenRedKV + edenRedCorpo) * (2 / 100)) *
                    percentTemp) /
                100;
        } /* revisada */ else if (
            baseTemp === 'COMISION - 2% EDEN RED - BANCARIA'
        ) {
            var edenRedKV = rows.reduce(function (acc, obj) {
                if (
                    obj.scheme === 'EDEN RED KV' &&
                    obj.return_value.length != 0
                ) {
                    return acc + Number(obj.return_value);
                } else {
                    return acc;
                }
            }, 0);

            var edenRedCorpo = rows.reduce(function (acc, obj) {
                if (
                    obj.scheme === 'EDEN RED CORPO' &&
                    obj.return_value.length != 0
                ) {
                    return acc + Number(obj.return_value);
                } else {
                    return acc;
                }
            }, 0);

            const totalCommission = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.commission);
            }, 0);
            result =
                ((totalCommission -
                    (edenRedKV + edenRedCorpo) * (2 / 100) -
                    request.operatingExpenses.comisionesBancarias) *
                    percentTemp) /
                100;
        } /* revisado */ else if (
            baseTemp === 'COMISION - 2% EDEN RED - COM. CONTACTO - BANCARIA'
        ) {
            let edenRedKV = rows.reduce(function (acc, obj) {
                if (
                    obj.scheme === 'EDEN RED KV' &&
                    obj.return_value.length != 0
                ) {
                    return acc + Number(obj.return_value);
                } else {
                    return acc;
                }
            }, 0);

            let edenRedCorpo = rows.reduce(function (acc, obj) {
                if (
                    obj.scheme === 'EDEN RED CORPO' &&
                    obj.return_value.length != 0
                ) {
                    return acc + Number(obj.return_value);
                } else {
                    return acc;
                }
            }, 0);

            let saldoContacto = rows.reduce(function (acc, obj) {
                if (
                    obj.scheme === 'SALDO CONTACTO' &&
                    obj.return_value.length != 0
                ) {
                    return acc + Number(obj.return_value);
                } else {
                    return acc;
                }
            }, 0);

            const totalCommission = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.commission);
            }, 0);
            result =
                ((totalCommission -
                    (edenRedKV + edenRedCorpo) * (2 / 100) -
                    saldoContacto -
                    request.operatingExpenses.comisionesBancarias) *
                    percentTemp) /
                100;
        } else if (baseTemp === 'COMISION - PROMOTORIA 1 - BANCARIA') {
            const totalCommission = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.commission);
            }, 0);

            result =
                (totalCommission -
                    request.promoter.promotersArray[0] -
                    request.operatingExpenses.comisionesBancarias) *
                (percentTemp / 100);
        } else if (baseTemp === 'COMISION - PROMOTORIA 2 - BANCARIA') {
            const totalCommission = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.commission);
            }, 0);

            const totalPayer1 = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.amount1);
            }, 0);
            result =
                (totalCommission -
                    request.promoter.promotersArray[1] -
                    request.operatingExpenses.comisionesBancarias) *
                (percentTemp / 100);
        } else if (baseTemp === 'COMISION - PROMOTORIA 3 - BANCARIA') {
            const totalCommission = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.commission);
            }, 0);

            const totalPayer1 = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.amount1);
            }, 0);
            result =
                (totalCommission -
                    request.promoter.promotersArray[2] -
                    request.operatingExpenses.comisionesBancarias) *
                (percentTemp / 100);
        } else if (baseTemp === 'COMISION - PROMOTORIA 3 - BANCARIA') {
            const totalCommission = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.commission);
            }, 0);

            const totalPayer1 = rows.reduce((accumulator, object) => {
                return accumulator + Number(object.amount1);
            }, 0);
            result =
                (totalCommission -
                    request.promoter.promoter1.commission -
                    request.operatingExpenses.costoSC) *
                (percentTemp / 100);
        }

        // setCommissionTemp(result.toFixed(2));
    }, [baseTemp, percentTemp]);

    return (
        <Dialog
            fullWidth
            maxWidth='xs'
            open={show}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle>{isEdit ? 'Editar' : 'Nueva'} {sectionTitle}:</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Complete el formulario para crear una nueva {sectionTitle}
                </DialogContentText>
                
                <hr />
                <Grid
                    container
                    spacing={2}
                    style={{
                        marginBottom: '10px',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <TextField
                            autoFocus
                            required
                            fullWidth
                            label='Nombre'
                            value={nameTemp}
                            id='outlined-start-adornment'
                            type='text'
                            onChange={(e) => {
                                setNameTemp(e.target.value);
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <TextField
                            required
                            fullWidth
                            label='%'
                            value={percentTemp}
                            id='outlined-start-adornment'
                            type='number'
                            onChange={(e) => {
                                setPercentTemp(e.target.value);
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <TextField
							fullWidth
							label='Base de Comisión'
							value={baseTemp}
							id='combo-box-demo'
							onChange={(e) => setBaseTemp(e.target.value)}
						/>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <CurrencyFormat
                            customInput={TextField}
                            variant='outlined'
                            fullWidth
                            value={commissionTemp}
                            label='Comisiones'
                            // type='number'
                            thousandSeparator={true}
                            prefix={'$'}
                            allowNegative={false}
                            onChange={({ target }) => {
                                setCommissionTemp(
                                    Number(
                                        target.value.replace(/[^0-9.-]+/g, '')
                                    )
                                );
                            }}
                        />
                        
                    </Grid>
                    
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color='error'
                    onClick={handleClose}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        if (sectionTitle === 'Promotoría') {
                            addRowPromoter();
                        } else {
                            isEdit ? editRowDebt() : addRowDebt();
                        }
                        handleClose();
                        handleChange();
                        setValuesZero();
                    }}
                >
                    {isEdit ? 'Editar' : 'Crear'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);
