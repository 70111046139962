import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import {
    Alert,
    Box,
    Button,
    DialogActions,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from '@mui/material';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { money } from '../../helpers/money';

import {
    getPromotersPerName
} from '../../actions/returnAndCommissions';

export const PromotersResultsDialog = ({
    open,
    handleClose,
    applicationId = null,
    rowInfo = { promoter: 'null' },
}) => {
    const [deposits, setDeposits] = useState([]);
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const [titleName, setTitleName] = useState('Cargando...');

    useEffect(() => {
        if (rowInfo) {
            // Get the names of the promoters and the sum of all commissions
            getPromotersPerName({ promoterName: rowInfo.promoterName }).then(
                ({ data }) => {
                    setRows(data.results);
                    setTitleName(rowInfo.promoterName);
                }
            );
        }
    }, [rowInfo]);

    return (
        <Dialog
            maxWidth='lg'
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            {/* Content */}
            <Box sx={{ flexGrow: 1 }}>
                <div style={{ padding: '20px' }}>
                    <Text
                        variant={'h6'}
                        text={titleName}
                        bold={false}
                    />

                    {rows?.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table
                                sx={{ width: '100%' }}
                                aria-label='simple table'
                            >
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align='center'>
                                            #
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Fecha
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Solicitud
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Porcentaje
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Base de comisión
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Monto
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((promoterElement, index) => (
                                        <StyledTableRow
                                            key={promoterElement.key}
                                            sx={styleLabel}
                                        >
                                            <ColumnItem
                                                align='center'
                                                text={index + 1}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={
                                                    moment(
                                                        promoterElement?.created_at
                                                    ).format('DD/MM/YYYY') ??
                                                    'Empty'
                                                }
                                            />
                                            <TableCell>
                                                <Button
                                                    onClick={() => {
                                                        navigate(
                                                            `/dashboard/application/${promoterElement.application.id}`
                                                        );
                                                    }}
                                                >
                                                    {promoterElement
                                                        ?.application?.key ??
                                                        'Empty'}
                                                    <LinkIcon fontSize='small' />
                                                </Button>
                                            </TableCell>

                                            <ColumnItem
                                                align='center'
                                                text={
                                                    `${promoterElement?.percentage ?? 'Empty'}%` 
                                                    
                                                }
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={
                                                    promoterElement?.base_commission ??
                                                    'Empty'
                                                }
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={
                                                    money(
                                                        promoterElement?.commission
                                                    ) ?? 'Empty'
                                                }
                                            />
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Alert severity='info'>No hay Resguardos</Alert>
                    )}
                </div>
            </Box>
            <DialogActions>
                <Button onClick={() => handleClose()}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

const ColumnItem = ({ align, text }) => (
    <StyledTableCell align={align}>{text}</StyledTableCell>
);

const styleLabel = {
    '&:last-child td, &:last-child th': { border: 0 },
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction='up'
            ref={ref}
            {...props}
        />
    );
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#01579b',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const Text = ({ variant, text, bold = true }) => (
    <Typography
        variant={variant}
        gutterBottom
    >
        {bold ? <b>{text}</b> : text}
    </Typography>
);
