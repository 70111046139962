import React from 'react';
import {
    Autocomplete,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch
} from '@mui/material';
import CurrencyFormat from 'react-currency-format';

export function KVFormComponent({
    costNameKVGroup,
    setCostNameKVGroup,
    payersName,
    SelectComponent,
    costKVGroup,
    setCostKVGroup,
    TextField,
    costAmountKVGroup,
    setCostAmountKVGroup,
    Number,
    expenseNameKVGroup,
    setExpenseNameKVGroup,
    expenseKVGroup,
    setExpenseKVGroup,
    setExpenseAmountKVGroup,
    expenseAmountKVGroup,
    bank,
    substractionImport,
    hasCorporateCommissions,
    handleCheck,
    commisionCorporative,
    corporativeFunding,
    setCorporativeFunding,
    importFunding,
    companies,
}) {
    return (
        <>
            <Grid
                item
                xs={4}
            >
                {/* Cost */}
                <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    required // style={{ marginTop: '20px' }}
                    fullWidth
                    value={costNameKVGroup}
                    onChange={(_, value) => {
                        setCostNameKVGroup(value);
                    }}
                    options={payersName}
                    renderInput={(params) =>
                        SelectComponent(params, 'Empresa Costo')
                    }
                />
            </Grid>

            <Grid
                item
                xs={4}
            >
                {/* Cost */}

                <TextField
                    fullWidth
                    required
                    value={costKVGroup}
                    onChange={({ target }) => {
                        setCostKVGroup(target.value);
                    }}
                    type='number'
                    margin='normal'
                    label='Porcentaje de costo'
                />
            </Grid>

            <Grid
                item
                xs={4}
            >
                {/* Cost */}
                <CurrencyFormat
                    customInput={TextField}
                    fullWidth
                    label='Importe Costo'
                    value={costAmountKVGroup}
                    thousandSeparator={true}
                    prefix={'$'}
                    allowNegative={false}
                    margin='normal'
                    onChange={({ target }) => {
                        setCostAmountKVGroup(
                            Number(target.value.replace(/[^0-9.-]+/g, ''))
                        );
                    }}
                />
            </Grid>

            <Grid
                item
                xs={4}
            >
                {/* Cost */}
                <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    required // style={{ marginTop: '20px' }}
                    fullWidth
                    value={expenseNameKVGroup}
                    onChange={(_, value) => {
                        setExpenseNameKVGroup(value);
                    }}
                    options={payersName}
                    renderInput={(params) =>
                        SelectComponent(params, 'Empresa Gasto')
                    }
                />
            </Grid>

            <Grid
                item
                xs={4}
            >
                {/* Cost */}
                <TextField
                    fullWidth
                    required
                    type='number'
                    value={expenseKVGroup}
                    margin='normal'
                    onChange={({ target }) => {
                        setExpenseKVGroup(target.value);
                    }}
                    label='Porcentaje de gasto'
                />
            </Grid>

            <Grid
                item
                xs={4}
            >
                <CurrencyFormat
                    customInput={TextField}
                    fullWidth
                    label='Importe gasto'
                    value={expenseAmountKVGroup}
                    thousandSeparator={true}
                    prefix={'$'}
                    allowNegative={false}
                    margin='normal'
                    onChange={({ target }) => {
                        setExpenseAmountKVGroup(target.value);
                    }}
                />
                {/* Cost */}
            </Grid>

            {bank == 'STP' || bank == 'ASP INTEGRA' || bank == 'KUSPIT' ? (
                <Grid
                    item
                    xs={4}
                >
                    <CurrencyFormat
                        customInput={TextField}
                        fullWidth
                        label='Importe - comisiones'
                        value={substractionImport}
                        InputProps={{
                            readOnly: true,
                        }}
                        thousandSeparator={true}
                        prefix={'$'}
                        allowNegative={false}
                        margin='normal'
                    />
                </Grid>
            ) : (
                <></>
            )}

            {/* Switch Corporate Commissions*/}
            <Grid
                item
                xs={12}
                sx={{
                    mt: -2,
                    mb: -2,
                }}
            >
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={hasCorporateCommissions}
                                onChange={handleCheck}
                                inputProps={{
                                    'aria-label': 'controlled',
                                }}
                            />
                        }
                        label='Comisiones corporativo'
                    />
                </FormGroup>
            </Grid>

            {/* Corporate Commissions */}
            <Grid
                item
                xs={4}
            >
                <CurrencyFormat
                    customInput={TextField}
                    fullWidth
                    disabled={!hasCorporateCommissions}
                    label='Comisión corporativo'
                    InputProps={{
                        readOnly: true,
                    }}
                    value={commisionCorporative}
                    thousandSeparator={true}
                    prefix={'$'}
                    allowNegative={false}
                    margin='normal'
                />
            </Grid>
            {/* Lista desplegable */}
            <Grid
                item
                xs={4}
            >
                {/* Client */}
                <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    required // style={{ marginTop: '20px' }}
                    fullWidth
                    value={corporativeFunding}
                    onChange={(_, value) => {
                        setCorporativeFunding(value);
                    }}
                    options={companies.map((item) => item.name)}
                    renderInput={(params) =>
                        SelectComponent(params, 'Empresa Fondeo Corporativo')
                    }
                />
            </Grid>
            <Grid
                item
                xs={4}
            >
                <CurrencyFormat
                    customInput={TextField}
                    fullWidth
                    disabled={!hasCorporateCommissions}
                    label='Importe/Fondeo'
                    value={importFunding}
                    InputProps={{
                        readOnly: true,
                    }}
                    thousandSeparator={true}
                    prefix={'$'}
                    allowNegative={false}
                    margin='normal'
                />
            </Grid>
        </>
    );
}
