import { getToken } from '../actions/auth';
import { types } from '../types/types';

const initialState = { token: getToken() };

export const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.login:
			return action.payload;

		case types.logout:
			return { token: null };

		default:
			return state;
	}
};
