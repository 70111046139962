import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startLogout } from '../../../actions/auth';
import Dropdown from 'react-bootstrap/Dropdown';
import { Icon } from '../../utils/UtilsComponent';

const MenuItem = ({ title, icon, action = null }) => (
    <Dropdown.Item onClick={action}>
        <Icon name={icon} /> {title}
    </Dropdown.Item>
);


export const UserMenu2 = () => {
	const dispatch = useDispatch();

	// Selector to see Role
    const rolePicker = {
        ADM: 'Administrador',
        OPE: 'Operador',
        EXE: 'Ejecutivo',
    };

    const handleLogout = () => dispatch(startLogout());
    // Handle go to admin site
    const handleAdmin = () => window.open('http://127.0.0.1:8000/admin/');

    const {
        first_name = '',
        last_name = '',
        profile = '',
    } = useSelector((state) => state.user);

    const { user } = useSelector((state) => state);


    /**
     * Check and truncate a full name based on character length.
     *
     * This function takes a 'firstname' and 'lastname' as input, combines them into a 'wholeName,' and checks its character length.
     * If the 'wholeName' is equal to or longer than 16 characters, it truncates it to the first 16 characters followed by an ellipsis ('...').
     * Otherwise, it returns the original 'wholeName' without any truncation.
     *
     * @param {string} firstname - The first name part of the full name.
     * @param {string} lastname - The last name part of the full name.
     * @returns {string} - The truncated or original full name.
     */
    const nameChecker = (firstname, lastname) => {
        // Combine 'firstname' and 'lastname' into 'wholeName'
        let wholeName = `${firstname} ${lastname}`;

        // Check if 'wholeName' length is greater than or equal to 16 characters
        if (wholeName.length >= 16) {
            // If yes, truncate 'wholeName' to the first 16 characters followed by an ellipsis ('...')
            return `${wholeName.slice(0, 16)}...`;
        } else {
            // If not, return the original 'wholeName' without any truncation
            return wholeName;
        }
    };

    return (
        <Dropdown className='d-inline mx-2'>
            <Dropdown.Toggle
                id='dropdown-autoclose-true'
                variant='light'
                style={{ width: '100%' }}
            >
                <div className='d-flex align-items-center link-dark text-decoration-none '>
                    {/* Just use the name and the first lastname  */}
                    {user && (
                        <>
                            <img
                                src='https://www.inspirations.boutique/wp-content/uploads/2019/10/blank-person-profile.png'
                                alt='test'
                                width='32'
                                height='32'
                                className='rounded-circle me-2'
                            />
                            <div
                                style={{
                                    // ...columnStyle,
                                    margin: '0 0 -15px 10px',
                                    width: '100%',
                                    textAlign: 'start',
                                }}
                            >
                                <p>
                                    <strong>
                                        {nameChecker(
                                            first_name,
                                            last_name.split(' ')[0]
                                        )}
                                    </strong>
                                    <br />
                                    <small>{rolePicker[profile]}</small>
                                    <br />
                                    <small>{'1.0'}</small>
                                </p>
                            </div>
                        </>
                    )}
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {/* Admin */}
                <MenuItem
                    title='Administración'
                    icon='building'
                    action={handleAdmin}
                />
                {/* Logout */}
                <MenuItem
                    title='Salir'
                    icon='box-arrow-left'
                    action={handleLogout}
                />
            </Dropdown.Menu>
        </Dropdown>
    );
};
