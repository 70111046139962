import React, { useState } from 'react';

import { TextField, Button, Typography, Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { handleError, handleSuccess, handleWarning } from '../../helpers/utils';
import { registerClient, updateClient } from '../../actions/catalogs';

// Default configuration for a new form
const defaultConfig = {
	title: 'Añadir Cliente',
	handleClose: false,
	addOneItem: false,
	updateOneItem: false,
};

// Create/Edit Client Form
export const NewClientForm = ({ data = null, config = defaultConfig }) => {
	// Desestructure config property
	const {
		title = 'Añadir Cliente',
		handleClose,
		addOneItem,
		updateOneItem,
	} = config;

	const [description, setDescription] = data
		? useState(data.description)
		: useState('');
	const [headquarter, setHeadquarter] = data
		? useState(data.headquarter)
		: useState('NOA');
	const [name, setName] = data ? useState(data.name) : useState('');

	//  Event Handlers for form items
	const handleHeadquarter = (event) => {
		setHeadquarter(event.target.value);
	};

	// Function to create a Client
	function handleCreate(value) {
		// Validate that a name exits
		if (value.name !== '') {
			registerClient(value)
				.then(({ data }) => {
					handleSuccess('Cliente creado correctamente');
					addOneItem(data);
					handleClose();
				})
				.catch((error) => {
					handleError(error, 'No se creó el cliente');
				});
		} else {
			handleWarning('Falta nombre');
		}
	}

	// Function to update a Client
	function handleUpdate(value) {
		// Validate that a name exits
		if (value.name !== '') {
			updateClient(data.id, value)
				.then(({ data }) => {
					handleSuccess('Cliente actualizado correctamente');
					updateOneItem(data);
					handleClose();
				})
				.catch((error) => {
					handleError(
						error,
						'No se actualizo' + pronounSingular + ' ' + catalog
					);
				});
		} else {
			handleWarning('Falta Concepto');
		}
	}

	return (
		<div
			style={{
				padding: '20px',
				margin: 6,
			}}
		>
			<Typography variant='h4' gutterBottom>
				{title}
			</Typography>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={8}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Nombre'
						onChange={(e) => setName(e.target.value)}
						value={name}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={4}>
					<FormControl fullWidth required>
						<InputLabel variant='standard' htmlFor='uncontrolled-native'>
							Sede
						</InputLabel>
						<NativeSelect
							defaultValue={headquarter}
							inputProps={{
								name: 'headquarter',
								id: 'uncontrolled-native',
							}}
							onChange={handleHeadquarter}
						>
							<option value={'NOA'}>No asignado</option>
							<option value={'AGS'}>Aguascalientes</option>
							<option value={'BCN'}>Baja california</option>
							<option value={'CS'}>Baja california Sur</option>
							<option value={'CAM'}>Campeche</option>
							<option value={'CHP'}>Chiapas</option>
							<option value={'CHI'}>Chihuahua</option>
							<option value={'COA'}>Coahuila</option>
							<option value={'COL'}>Colima</option>
							<option value={'DUR'}>Durango</option>
							<option value={'GTO'}>Guanajuato</option>
							<option value={'GRO'}>Guerrero</option>
							<option value={'HGO'}>Hidalgo</option>
							<option value={'JAL'}>Jalisco</option>
							<option value={'MEX'}>CDMX</option>
							<option value={'MIC'}>Michoacán</option>
							<option value={'MOR'}>Morelos</option>
							<option value={'NAY'}>Nayarit</option>
							<option value={'NLE'}>Nuevo León</option>
							<option value={'OAX'}>Oaxaca</option>
							<option value={'PUE'}>Puebla</option>
							<option value={'QRO'}>Querétaro</option>
							<option value={'ROO'}>Quintana Roo</option>
							<option value={'SLP'}>San Luis Potosí</option>
							<option value={'SIN'}>Sinaloa</option>
							<option value={'SON'}>Sonora</option>
							<option value={'TAB'}>Tabasco</option>
							<option value={'TAM'}>Tamaulipas</option>
							<option value={'TLX'}>Tlaxcala</option>
							<option value={'VER'}>Veracruz</option>
							<option value={'YUC'}>Yucatán</option>
							<option value={'ZAC'}>Zacatecas</option>
						</NativeSelect>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Descripción'
						onChange={(e) => setDescription(e.target.value)}
						value={description}
						multiline
						rows={4}
						inputProps={{ maxLength: 500 }}
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<Button
						variant='contained'
						fullWidth
						color='error'
						sx={{ mt: 2 }}
						onClick={handleClose}
					>
						Cancelar
					</Button>
				</Grid>
				<Grid item xs={6}>
					{data ? (
						<Button
							variant='contained'
							fullWidth
							style={{ backgroundColor: '#143f6c' }}
							sx={{ mt: 2 }}
							onClick={() => {
								handleUpdate({
									name: name,
									headquarter: headquarter,
									description: description,
								});
							}}
						>
							Actualizar
						</Button>
					) : (
						<Button
							variant='contained'
							fullWidth
							style={{ backgroundColor: '#143f6c' }}
							sx={{ mt: 2 }}
							onClick={() => {
								handleCreate({
									name: name,
									headquarter: headquarter,
									description: description,
								});
							}}
						>
							Registrar
						</Button>
					)}
				</Grid>
			</Grid>
		</div>
	);
};
