import React from 'react';
import {
	deleteCatApplication,
	getCatApplicationInfo,
	getCatApplications,
	registerCatApplication,
	searchCatApplication,
	updateCatApplication,
} from '../../actions/catalogs';
import { SimpleCatalogTable } from './SimpleCatalogTable';

// Applications Table
export const CatApplication = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getCatApplications,
		getCatalogInfo: getCatApplicationInfo,
		deleteCatalog: deleteCatApplication,
		updateCatalog: updateCatApplication,
		registerCatalog: registerCatApplication,
		searchCatalog: searchCatApplication, 
		catalogSingular: 'Aplicacion',
		catalogPlural: 'Aplicaciones',
		pronounSingular: 'la',
		pronoun: 'a',
	};

	return <SimpleCatalogTable config={configuration} />;
};
