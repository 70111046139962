import React from 'react';
import {
	deletePromotery,
	getPromoteryInfo,
	registerPromotery,
	updatePromotery,
	getPromoteries,
	searchPromotery,
} from '../../actions/catalogs';
import { SimpleCatalogTable } from './SimpleCatalogTable';

// Promoteries Table
export const Promotery = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getPromoteries,
		getCatalogInfo: getPromoteryInfo,
		deleteCatalog: deletePromotery,
		updateCatalog: updatePromotery,
		registerCatalog: registerPromotery,
		searchCatalog: searchPromotery,
		catalogSingular: 'Promotoria',
		catalogPlural: 'Promotorias',
		pronounSingular: 'la',
		pronoun: 'a',
	};

	return <SimpleCatalogTable config={configuration} />;
};
