import React from 'react';
import { useState, useEffect } from 'react';
import { Alert, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {
    getDepartmentID,
    getPositionID,
    getProfileID,
    handleDeleteUserModal,
    handleError,
} from '../../helpers/utils';
import { DialogTemplate } from '../dialogs/DialogTemplate';
import { NewUserForm } from '../auth/NewUserForm';
import { deleteUser, getNextUser, getUserInfo } from '../../actions/user';
import { useDispatch } from 'react-redux';
import { formatDateMX } from '../../helpers/hours';

// Users table
export const UsersTable = ({
    data = [],
    profile = null,
    refresh = null,
    count = 0,
    roleTemp = '',
}) => {
    // Hook for navigation
    const navigate = useNavigate();

    const [dataLoaded, setDataLoaded] = useState([]);
    const [localPage, setLocalPage] = useState(1);

    // Dispatch
    const dispatch = useDispatch();

    // Dialog
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Current user id
    const [currentUser, setCurrentUser] = useState(null);

    /**
     * Detect and retrieve user data changes for a specific page and role.
     *
     * This function is used to detect changes in user data, typically when navigating between pages of user listings
     * based on their roles. It retrieves the user data for a specific page and role by calling the getNextUser function,
     * and then sets the retrieved data to the 'dataLoaded' state variable.
     *
     * @param {number} page - The page number indicating the desired page of user data.
     * @param {string} roleTemp - The role for which user data changes are detected.
     * @throws {Error} Throws an error if there is an issue fetching user data.
     */
    const detectChange = (page) => {
        // Retrieve user data for the specified page and role
        getNextUser(page, roleTemp)
            .then((data) => {
                // Set the retrieved data to the 'dataLoaded' state variable
                setDataLoaded(data.data.results);
            })
            .catch((error) => {
                // Handle and log any errors that occur during data fetching
                console.error('Error fetching user data:', error);
            });
    };

    // Generate rows by array
    /**
     * Generates an array of formatted rows from an input array of user data.
     *
     * This function performs the following steps:
     * 1. Filters the input array to include only users matching a specific user type (profile).
     * 2. Maps each filtered user's data into a structured row format.
     *
     * @param {Array} array - An array of user data.
     * @returns {Array} An array of formatted rows for display.
     */
    const generateRows = (array) => {
        // Check if the input array is empty; return an empty array if true
        if (array.length === 0) return [];

        // Filter users by user type (profile) and not deleted condition
        const filteredUsers = array.filter(
            (item) => getProfileID(item.profile) === profile
        );

        // Map each filtered user's data into a structured row format
        const formattedRows = filteredUsers.map((item) => ({
            id: item.id, // Unique user identifier
            col1: item.id, // Column 1: User ID (can be customized)
            col2: `${item.first_name} ${item.last_name}`, // Column 2: Full Name
            col3: item.email, // Column 3: Email Address
            col4: getProfileID(item.profile), // Column 4: User Profile ID
            col5: getDepartmentID(item.department), // Column 5: Department ID (if applicable)
            col6: getPositionID(item.position), // Column 6: Position ID (if applicable)
            col7: item.is_staff ? 'Administrador' : 'Usuario', // Column 7: User Role (Administrador or Usuario)
            col8: formatDateMX(item.created_at), // Column 8: Formatted Creation Date (in Mexican date format)
        }));

        // Return the array of formatted rows for display
        return formattedRows;
    };

    // Handle delete user with modal confirmation
    const handleDeleteUser = (userId) => {
        handleDeleteUserModal()
            .then(({ isConfirmed }) => {
                if (isConfirmed) {
                    dispatch(deleteUser(userId));
                    setTimeout(() => {
                        refresh();
                    }, 1000);
                }
            })
            .catch((error) => handleError(error));
    };

    useEffect(() => {
        setDataLoaded(data);
    }, []);

    // Define columns
    const columns = [
        { field: 'col1', headerName: 'ID', width: 50 },
        { field: 'col2', headerName: 'Nombre', width: 250 },
        { field: 'col3', headerName: 'Correo', width: 250 },
        { field: 'col4', headerName: 'Perfil', width: 130 },
        { field: 'col5', headerName: 'Departamento', width: 120 },
        { field: 'col6', headerName: 'Posición', width: 170 },
        { field: 'col7', headerName: 'Tipo de usuario', width: 130 },
        { field: 'col8', headerName: 'Creado', width: 100 },
        {
            field: 'col9',
            headerName: 'Acciones',
            width: 150,
            // Add actions to each case
            renderCell: ({ row }) => (
                <>
                    <Tooltip title='Ver perfil'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='profile'
                            color='primary'
                            onClick={() => {
                                const path = `/dashboard/user/${row.id}`;
                                navigate(path);
                                localStorage.setItem('lastPathKv', path);
                            }}
                        >
                            <PersonIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Editar'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='edit'
                            color='primary'
                            onClick={() => {
                                getUserInfo(row.id).then(({ data }) => {
                                    setCurrentUser(data);
                                    handleOpen();
                                });
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Eliminar'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='delete'
                            color='primary'
                            onClick={() => handleDeleteUser(row.id)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
    ];

    return (
        <>
            <DialogTemplate
                config={{
                    open: open,
                    handleClose: handleClose,
                    btnCancel: false,
                    btnConfirm: false,
                    title: 'Editar usuario',
                    subtitle: '',
                }}
                content={
                    <NewUserForm
                        handleClose={handleClose}
                        data={currentUser}
                        refresh={refresh}
                    />
                }
            />
            {/* Datagrid table */}
            {dataLoaded?.length > 0 ? (
                <DataGrid
                    autoHeight
                    rows={generateRows(dataLoaded)}
                    columns={columns}
                    // autoPageSize
                    components={{ Toolbar: GridToolbar }}
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    rowCount={count}
                    paginationMode='server'
                    pagination
                    page={localPage - 1}
                    pageSize={10}
                    onPageChange={(newPage) => {
                        setLocalPage(newPage + 1);
                        detectChange(newPage + 1);
                    }}
                />
            ) : (
                <Alert severity='info'>No hay ningún usuario</Alert>
            )}
        </>
    );
};
