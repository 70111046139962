import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { handleSuccess } from '../../helpers/utils';
import { registerAdministrativeCostWithdraw } from '../../actions/administrativeCostsWithdraw';
import { useQuery } from 'react-query';
import { getPayers } from '../../actions/catalogs';
import { EXPENSES_CONCEPTS, STATES } from '../../helpers/lists';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CustomCurrencyInput } from '../utils/UtilsComponent';

const arrBases = [
    { label: 'ARRENDAMIENTO' },
    { label: 'CAJA CHICA CMDX' },
    { label: 'CAJA CHICA PUEBLA' },
    { label: 'COSTOS SOCIALES' },
    { label: 'DEUDA' },
    { label: 'GASTOS ADMINISTRATIVOS' },
    { label: 'GASTOS ADMINISTRATIVOS DOMICILIOS' },
    { label: 'GASTOS DIRECCION' },
    { label: 'GASTOS JURIDICOS' },
    { label: 'GASTOS SOFOM - SAPI' },
    { label: 'IMPUESTOS ESTATALES (ISN)' },
    { label: 'IMPUESTOS FEDERALES' },
    { label: 'MANTENIMIENTO VEHICULOS' },
    { label: 'NOMINA' },
    { label: 'RENTAS ' },
    { label: 'SERVICIOS APOYO EXTERNO' },
    { label: 'SISTEMAS' },
    { label: 'TELEFONOS' },
    { label: 'COMISIONES BANCARIAS' },
    { label: 'VIATICOS' },
    { label: 'COMISIONES T. PREPAGO' },
    { label: 'CAJA CHICA METEPEC' },
    { label: 'CAJA CHICA DOMICILIOS' },
    { label: 'CAPACITACION' },
    { label: 'MULTAS' },
    { label: 'PROMOTORIAS' },
    { label: 'AGUINALDOS' },
    { label: 'COMISION COMPRA EFECTIVO' },
    { label: 'SEGUROS VEHICULOS' },
    { label: 'TRASLADO EFECTIVO' },
    { label: 'FINIQUITOS' },
    { label: 'PRESTAMO' },
    { label: 'NO APLICA' },
];

export const NewAdministrativeCostsWithdraw = ({
    show = false,
    handleClose,
    handleChange,
    handleUpdateTotals,
}) => {
    const application = useSelector((state) => state.request.application);

    const { administrativeCostsWithdrawArray = [] } = useSelector(
        (state) => state.request.administrativeCostsWithdraw
    );

    const { banks = [] } = useSelector((state) => state.catalogs);

    // Get list of Payers
    const payerQuery = useQuery({
        queryKey: ['getPayers'],
        queryFn: () => getPayers(),
    });

    const banksQuery = useQuery({
        queryKey: ['getPayers'],
        queryFn: () => getPayers(),
    });

    const [campus, setCampus] = useState(null);
    const [payer, setPayer] = useState(null);
    const [concept, setConcept] = useState(null);
    const [amount, setAmount] = useState(null);
    const [bank, setBank] = useState(null);
    const [bankCommission, setBankCommission] = useState(null);
    const [comments, setComments] = useState(null);
    const [otherConcept, setOtherConcept] = useState();
    const handleAdministrativeCosts = () => {
        registerAdministrativeCostWithdraw({
            campus: campus,
            payer: payer,
            concept: concept,
            amount: amount,
            bank: bank,
            bank_commission: bankCommission,
            comments: comments,
            application: application.id, // ID of the associated application
            fundings: [], // Fundings data (empty for now)
        })
            .then((data) => {
                // Handle successful submission
                handleClose(); // Close the administrative cost form dialog
                const administrativeCostArrayTemp = [
                    ...administrativeCostsWithdrawArray,
                    data.data,
                ];
                // Update totals and Redux store
                handleUpdateTotals(administrativeCostArrayTemp);
                // Display a success message
                handleSuccess('Gasto Administrativo Creado Correctamente');
            })
            .catch((error) => {
                // Handle submission error by logging it
                console.error(error);
            });
    };

    const handleOtherConcepts = (event) => {
        setOtherConcept(event.target.checked);
    };

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={show}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle>Nuevo Gasto Administrativo:</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Complete el formulario para crear un nuevo Gasto
                    Administrativo
                </DialogContentText>
                <hr />

                <Grid
                    container
                    spacing={2}
                    style={{
                        marginBottom: '10px',
                    }}
                >
                    <Grid
                        item
                        xs={6}
                    >
                        <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            required
                            disablePortal
                            id='campus'
                            name='campus'
                            options={STATES?.map((element) => element.name)}
                            sx={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Sede'
                                />
                            )}
                            onChange={(event, value) => {
                                console.log(value);
                                setCampus(value);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        {payerQuery.isLoading ? (
                            <TextField
                                disabled
                                id='filled-disabled'
                                label='Cargando Pagadoras...'
                                variant='filled'
                                fullWidth
                            />
                        ) : (
                            <Autocomplete
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                required
                                disablePortal
                                id='payer'
                                name='payer'
                                options={payerQuery?.data?.data?.results.map(
                                    (element) => element.name
                                )}
                                sx={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Pagadoras'
                                    />
                                )}
                                onChange={(event, value) => {
                                    setPayer(value);
                                }}
                            />
                        )}
                    </Grid>
                    {otherConcept ? (
                        <Grid
                            item
                            xs={6}
                        >
                            <TextField
                                required
                                fullWidth
                                label='Concepto'
                                id='concept'
                                name='concept'
                                type='text'
                                onChange={(e) => {
                                    setConcept(e.target.value);
                                }}
                            />
                        </Grid>
                    ) : (
                        <Grid
                            item
                            xs={6}
                        >
                            <Autocomplete
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                required
                                disablePortal
                                id='concept-options'
                                name='concept-options'
                                options={EXPENSES_CONCEPTS}
                                sx={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Concepto'
                                    />
                                )}
                                onChange={(event, value) => {
                                    console.log(value);
                                    setConcept(value);
                                }}
                            />
                        </Grid>
                    )}

                    <Grid
                        item
                        xs={6}
                    >
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(e) => {
                                            handleOtherConcepts(e);
                                        }}
                                    />
                                }
                                label='Otro'
                            />
                        </FormGroup>
                    </Grid>

                    <Grid
                        item
                        xs={6}
                    >
                        <CustomCurrencyInput
                            required
                            fullWidth
                            label='Importe'
                            id='import'
                            name='import'
                            type='text'
                            step='0.01'
                            value={amount}
                            onValueChange={(e) => {
                                setAmount(e);
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={6}
                    >
                        {banks.length === 0 ? (
                            <TextField
                                disabled
                                id='filled-disabled'
                                label='Cargando Bancos...'
                                variant='filled'
                                fullWidth
                            />
                        ) : (
                            <Autocomplete
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                required
                                disablePortal
                                id='bank'
                                name='bank'
                                options={banks?.map((element) => element.name)}
                                sx={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Bancos'
                                    />
                                )}
                                onChange={(event, value) => {
                                    setBank(value);
                                }}
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <TextField
                            required
                            fullWidth
                            label='Costos operativos'
                            id='bank_commission'
                            name='bank_commission'
                            type='number'
                            onChange={(e) => {
                                setBankCommission(e.target.value);
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={6}
                    >
                        <TextField
                            required
                            fullWidth
                            label='Comentarios'
                            id='comments'
                            name='comments'
                            onChange={(e) => {
                                setComments(e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color='error'
                    onClick={handleClose}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        handleAdministrativeCosts();
                    }}
                >
                    Crear
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);

const SelectComponent = (params, label) => (
    <TextField
        fullWidth
        required
        margin='normal'
        {...params}
        label={label}
    />
);
