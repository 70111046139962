import axios from 'axios';
import { types } from '../types/types';
import { getToken } from './auth';

// Dispatch set catalogs
export const setCatalogs = (data) => ({
	type: types.setCatalogs,
	payload: data,
});

// Dispatch Set Scheme
export const setScheme = (data) => ({
	type: types.setScheme,
	payload: data,
});

// Dispatch clean catalogs
export const cleanCatalogs = () => ({
	type: types.cleanCatalogs,
});

// Get catalog by name
export const getCatalog = async (catalogName) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/${catalogName}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get Scheme of DB
export const loadCatalogs = () => {
	return () => {
		console.log('Se cargaron los catálogos.');
	};
};

// Load catalogs for deposit form
export const loadCatalogsForDepositForm = () => {
	return async (dispatch) => {
		const clients = (await getCatalog('client')).data.results;
		const banks = (await getCatalog('bank')).data.results;
		const bankCommissions = (await getCatalog('bank-commission')).data.results;
		const companies = (await getCatalog('company')).data.results;
		const conscompanies = (await getCatalog('conscompany')).data.results;
		const transfers = (await getCatalog('transfer')).data.results;

		dispatch(
			setCatalogs({
				clients,
				banks,
				bankCommissions,
				companies,
				conscompanies,
				transfers,
			})
		);
	};
};

/* DEBT REPAYMENTS */
// Get all DebtRepayments or perform a query
export const getDebtRepayments = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/debt-repayment/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search DebtRepayments
export const searchDebtRepayment = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/debt-repayment/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get Debt repayment info by id
export const getDebtRepaymentInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/debt-repayment/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Debt repayment
export const registerDebtRepayment = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/debt-repayment/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update Debt repayment
export const updateDebtRepayment = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/debt-repayment/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete debt repayment
export const deleteDebtRepayment = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/debt-repayment/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* APPLICATIONS */
// Get all Applications
export const getCatApplications = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/application/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search Application
export const searchCatApplication = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/application/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get Application info by id
export const getCatApplicationInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/application/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Application
export const registerCatApplication = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/application/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update Application
export const updateCatApplication = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/application/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete Application
export const deleteCatApplication = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/application/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* BANKS */
// Get all Banks
export const getBanks = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/bank/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search Bank
export const searchBank = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/bank/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get Bank info by id
export const getBankInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/bank/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Bank
export const registerBank = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/bank/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update Bank
export const updateBank = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/bank/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete Bank
export const deleteBank = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/bank/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};


export const getPayers = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/payers/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search payer
export const searchPayer = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/payers/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get payer info by id
export const getPayerInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/payers/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register payer
export const registerPayer = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/payers/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update payer
export const updatePayer = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/payers/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete payer
export const deletePayer = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/payers/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* CLIENTS */
// Get all Clients
export const getClients = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/client/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search Client
export const searchClient = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/client/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get Client info by id
export const getClientInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/client/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Client
export const registerClient = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/client/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update Client
export const updateClient = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/client/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete Client
export const deleteClient = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/client/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* BASE COMMISSIONS */
// Get all Base Commissions
export const getBaseCommissions = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/base-commission/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search BaseCommission
export const searchBaseCommission = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/base-commission/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get BaseCommission info by id
export const getBaseCommissionInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/base-commission/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Base Commission
export const registerBaseCommission = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/base-commission/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update BaseCommission
export const updateBaseCommission = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/base-commission/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete BaseCommission
export const deleteBaseCommission = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/base-commission/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* Bank COMMISSIONS */
// Get all Bank Commissions
export const getBankCommissions = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/bank-commission/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search BankCommission
export const searchBankCommission = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/bank-commission/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get BankCommission info by id
export const getBankCommissionInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/bank-commission/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Bank Commission
export const registerBankCommission = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/bank-commission/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update BankCommission
export const updateBankCommission = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/bank-commission/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete BankCommission
export const deleteBankCommission = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/bank-commission/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* PREPAID CARD COMMISSIONS */
// Get all Prepaid Card Commissions
export const getPrepaidCardCommissions = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/prepaidcard-commission/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search PrepaidCardCommission
export const searchPrepaidCardCommission = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/prepaidcard-commission/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get PrepaidCardCommission info by id
export const getPrepaidCardCommissionInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/prepaidcard-commission/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register PrepaidCard Commission
export const registerPrepaidCardCommission = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/prepaidcard-commission/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update PrepaidCardCommission
export const updatePrepaidCardCommission = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/prepaidcard-commission/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete PrepaidCardCommission
export const deletePrepaidCardCommission = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/prepaidcard-commission/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* SCHEMES COST */
// Get all Schemes Cost
export const getSchemesCost = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/scheme-cost/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search SchemesCost
export const searchSchemeCost = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/scheme-cost/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};
// Get Scheme Cost info by id
export const getSchemeCostInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/scheme-cost/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Scheme Cost
export const registerSchemeCost = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/scheme-cost/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update Scheme Cost
export const updateSchemeCost = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/scheme-cost/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete Scheme Cost
export const deleteSchemeCost = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/scheme-cost/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* COMPANIES */
// Get all companies
export const getCompanies = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/company/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};




// Search Company
export const searchCompany = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/company/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register company
export const registerCompany = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/company/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get Company info by id
export const getCompanyInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/company/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update Company
export const updateCompany = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/company/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete Company
export const deleteCompany = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/company/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* CONS COMPANIES */
// Get all Cons companies
export const getConsCompanies = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/conscompany/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search ConsCompany
export const searchConsCompany = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/conscompany/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get Cons Company info by id
export const getConsCompanyInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/conscompany/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Cons company
export const registerConsCompany = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/conscompany/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update Cons Company
export const updateConsCompany = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/conscompany/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete Cons Company
export const deleteConsCompany = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/conscompany/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* SCHEMES */
// Get all Schemes of DB
export const getSchemes = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/scheme/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search Scheme
export const searchScheme = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/scheme/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get Scheme info by id
export const getSchemeInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/scheme/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Scheme
export const registerScheme = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/scheme/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update Scheme
export const updateScheme = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/scheme/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete Scheme
export const deleteScheme = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/scheme/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* ORIGIN SOURCE */
// Get all Origins Source
export const getOriginsSource = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/origin-source/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search OriginSource
export const searchOriginSource = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/origin-source/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get Origin Source info by id
export const getOriginSourceInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/origin-source/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Origin Source
export const registerOriginSource = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/origin-source/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update Origin Source
export const updateOriginSource = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/origin-source/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete Origin Source
export const deleteOriginSource = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/origin-source/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* DESTINATION SOURCE */
// Get all Destinantions Source
export const getDestinantionsSource = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/destination-source/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search Destination Source
export const searchDestinationSource = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/destination-source/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get Destination Source info by id
export const getDestinationSourceInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/destination-source/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Destination Source
export const registerDestinationSource = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/destination-source/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update Destination Source
export const updateDestinationSource = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/destination-source/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete Destination Source
export const deleteDestinationSource = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/destination-source/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* PROMOTERS */
// Get all Promoters
export const getPromoters = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/promoter/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search Promoter
export const searchPromoter = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/promoter/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get Promoter info by id
export const getPromoterInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/promoter/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Promoter
export const registerPromoter = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/promoter/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update Promoter
export const updatePromoter = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/promoter/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete Promoter
export const deletePromoter = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/promoter/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* PROMOTERIES */
// Get all Promoteries
export const getPromoteries = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/promotery/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search Promotery
export const searchPromotery = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/promotery/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get Promotery info by id
export const getPromoteryInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/promotery/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Promotery
export const registerPromotery = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/promotery/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update Promotery
export const updatePromotery = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/promotery/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete Promotery
export const deletePromotery = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/promotery/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

/* TRANSFERS */
// Get all Transfers
export const getTransfers = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/transfer/${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Search Transfer
export const searchTransfer = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/transfer/?search=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get Transfer info by id
export const getTransferInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/transfer/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register Transfer
export const registerTransfer = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/catalog/transfer/',
		data: data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update Transfer
export const updateTransfer = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/catalog/transfer/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete Transfer
export const deleteTransfer = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/catalog/transfer/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};
