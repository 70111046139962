import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { ApplicationDetailTab } from './ApplicationDetailTab';
import { ApplicationDeposits } from './ApplicationDeposits';
import { GastosOperativos } from '../../containers/GastosOperativos';
import { PromotoriaYAbono } from '../../containers/PromotoriaYAbono';
import { AbonoADeuda } from '../../containers/AbonoADeuda';
import { AdministrativeCosts } from '../../containers/AdministrativeCosts';
import { DisponibleKV } from '../../containers/DisponibleKV';
import { ReturnAndComission } from '../../containers/ReturnAndComission';
import { useSelector } from 'react-redux';


export function ConventionalAndSafeguardApplications({
    currentTabLocal,
    handleChange,
    applicationTemp,
    application,
    handleGetDeposits,
    handleGetReturnCommissions,
}) {
    const { banks = [] } = useSelector((state) => state.catalogs);
    const { fundings = [] } = useSelector((state) => state.request.application);


    return (
        <>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
            >
                <Tabs
                    value={currentTabLocal}
                    onChange={handleChange}
                    aria-label='basic tabs example'
                    variant='scrollable'
                    scrollButtons='auto'
                >
                    <Tab
                        label='Detalle'
                        {...a11yProps(0)}
                    />
                    <Tab
                        label={
                            applicationTemp?.folio !== 'RETIRO DE RESGUARDO'
                                ? 'Depósitos'
                                : 'Resguardos'
                        }
                        {...a11yProps(1)}
                    />
                    <Tab
                        label='Retorno y comisión Cliente'
                        {...a11yProps(2)}
                    />
                    <Tab
                        label='Costos Operativos'
                        {...a11yProps(3)}
                    />
                    <Tab
                        label='Promotoría'
                        {...a11yProps(4)}
                    />
                    <Tab
                        label='Abono a Deuda'
                        {...a11yProps(5)}
                    />
                    <Tab
                        label='Gastos Administrativos'
                        {...a11yProps(5)}
                    />
                    <Tab
                        label='Disponible KV'
                        {...a11yProps(6)}
                    />
                </Tabs>
            </Box>
            <TabPanel
                value={currentTabLocal}
                index={0}
            >
                <ApplicationDetailTab application={application} />
            </TabPanel>
            <TabPanel
                value={currentTabLocal}
                index={1}
            >
                <ApplicationDeposits
                    application={application}
                    handleGetDeposits={handleGetDeposits}
                />
            </TabPanel>
            <TabPanel
                value={currentTabLocal}
                index={2}
            >
                <ReturnAndComission
                    application={application}
                    handleGetReturnCommissions={handleGetReturnCommissions}
                />
            </TabPanel>
            <TabPanel
                value={currentTabLocal}
                index={3}
            >
                <GastosOperativos applicationTemp={application} />
            </TabPanel>
            <TabPanel
                value={currentTabLocal}
                index={4}
            >
                <PromotoriaYAbono applicationTemp={application} />
            </TabPanel>
            <TabPanel
                value={currentTabLocal}
                index={5}
            >
                <AbonoADeuda applicationTemp={application} />
            </TabPanel>
            <TabPanel
                value={currentTabLocal}
                index={6}
            >
                <AdministrativeCosts applicationTemp={application} />
            </TabPanel>
            <TabPanel
                value={currentTabLocal}
                index={7}
            >
                <DisponibleKV banks={banks} fundingsPassed={fundings} />
            </TabPanel>
        </>
    );
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};
