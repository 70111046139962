import React, { useState } from 'react';

import { TextField, Button, Typography, Grid } from '@mui/material';

import { handleError, handleSuccess, handleWarning } from '../../helpers/utils';
import {
	registerPrepaidCardCommission,
	updatePrepaidCardCommission,
} from '../../actions/catalogs';

// Default configuration for a new form
const defaultConfig = {
	title: 'Añadir Tarjeta prepago',
	handleClose: false,
	addOneItem: false,
	updateOneItem: false,
};

// Create/Edit Prepaid card commission Form
export const NewPrepaidCardCommissionForm = ({
	data = null,
	config = defaultConfig,
}) => {
	// Desestructure config property
	const {
		title = 'Añadir Tarjeta prepago',
		handleClose,
		addOneItem,
		updateOneItem,
	} = config;

	const [description, setDescription] = data
		? useState(data.description)
		: useState('');
	const [platform, setPlatform] = data ? useState(data.platform) : useState('');
	const [commission, setCommission] = data
		? useState(data.commission * 100)
		: useState(0.0);
	const [iva, setIva] = data ? useState(data.iva * 100) : useState(16.0);
	const [minAmount, setMinAmount] = data
		? useState(data.min_amount)
		: useState(0.0);
	const [minCommission, setMinCom] = data
		? useState(data.min_commission * 100)
		: useState(0.0);

	// Function to create a Prepaid Card commission
	function handleCreate(value) {
		// Validate that a platform exits
		if (value.platform !== '') {
			registerPrepaidCardCommission(value)
				.then(({ data }) => {
					handleSuccess('Comisión de tarjeta de prepago creada correctamente');
					addOneItem(data);
					handleClose();
				})
				.catch((error) => {
					handleError(error, 'No se creó la comisión');
				});
		} else {
			handleWarning('Falta Concepto');
		}
	}

	// Function to update a Prepaid Card commission
	function handleUpdate(value) {
		// Validate that a platform exits
		if (value.platform !== '') {
			updatePrepaidCardCommission(data.id, value)
				.then(({ data }) => {
					handleSuccess(
						'Comisión de tarjeta de prepago actualizada correctamente'
					);
					updateOneItem(data);
					handleClose();
				})
				.catch((error) => {
					handleError(error, 'No se actualizó la comisión');
				});
		} else {
			handleWarning('Falta Concepto');
		}
	}

	return (
		<div
			style={{
				padding: '20px',
				margin: 6,
			}}
		>
			<Typography variant='h4' gutterBottom>
				{title}
			</Typography>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={12}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Plataforma'
						onChange={(e) => setPlatform(e.target.value)}
						value={platform}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						type='number'
						variant='outlined'
						color='secondary'
						label='Comisión (%)'
						onChange={(e) => setCommission(e.target.value)}
						value={commission}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						type='number'
						variant='outlined'
						color='secondary'
						label='IVA (%)'
						onChange={(e) => setIva(e.target.value)}
						value={iva}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						type='number'
						variant='outlined'
						color='secondary'
						label='Monto mínimo:'
						onChange={(e) => setMinAmount(e.target.value)}
						value={minAmount}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						type='number'
						variant='outlined'
						color='secondary'
						label='Comisión mínima (%)'
						onChange={(e) => setMinCom(e.target.value)}
						value={minCommission}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Descripción'
						onChange={(e) => setDescription(e.target.value)}
						value={description}
						multiline
						rows={4}
						inputProps={{ maxLength: 500 }}
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<Button
						variant='contained'
						fullWidth
						color='error'
						sx={{ mt: 2 }}
						onClick={handleClose}
					>
						Cancelar
					</Button>
				</Grid>
				<Grid item xs={6}>
					{data ? (
						<Button
							variant='contained'
							fullWidth
							style={{ backgroundColor: '#143f6c' }}
							sx={{ mt: 2 }}
							onClick={() => {
								handleUpdate({
									platform: platform,
									commission: commission / 100,
									iva: iva / 100,
									min_amount: minAmount,
									min_commission: minCommission / 100,
									description: description,
								});
							}}
						>
							Actualizar
						</Button>
					) : (
						<Button
							variant='contained'
							fullWidth
							style={{ backgroundColor: '#143f6c' }}
							sx={{ mt: 2 }}
							onClick={() => {
								handleCreate({
									platform: platform,
									commission: commission / 100,
									iva: iva / 100,
									min_amount: minAmount,
									min_commission: minCommission / 100,
									description: description,
								});
							}}
						>
							Registrar
						</Button>
					)}
				</Grid>
			</Grid>
		</div>
	);
};
