import React, { useState } from 'react';

import { TextField, Button, Typography, Grid } from '@mui/material';

import { handleError, handleSuccess, handleWarning } from '../../helpers/utils';
import {
	registerBankCommission,
	updateBankCommission,
} from '../../actions/catalogs';

// Default configuration for a new form
const defaultConfig = {
	title: 'Añadir Comisión Bancaria',
	handleClose: false,
	addOneItem: false,
	updateOneItem: false,
};

// Create/Edit Bank Commission Form
export const NewBankCommissionForm = ({
	data = null,
	config = defaultConfig,
}) => {
	// Desestructure config property
	const {
		title = 'Añadir Comisión Bancaria',
		handleClose,
		addOneItem,
		updateOneItem,
	} = config;

	const [description, setDescription] = data
		? useState(data.description)
		: useState('');
	const [concept, setConcept] = data ? useState(data.concept) : useState('');
	const [firstCom, setFirstCom] = data
		? useState(data.first_commission * 100)
		: useState(0.0);
	const [secondCom, setSecondCom] = data
		? useState(data.second_commission * 100)
		: useState(0.0);

	// Function to create a Bank commission
	function handleCreate(value) {
		// Validate that a concept exits
		if (value.concept !== '') {
			registerBankCommission(value)
				.then(({ data }) => {
					handleSuccess('Comisión de banco creada correctamente');
					addOneItem(data);
					handleClose();
				})
				.catch((error) => {
					handleError(error, 'No se creó la comisión');
				});
		} else {
			handleWarning('Falta Concepto');
		}
	}

	// Function to update a Bank commission
	function handleUpdate(value) {
		// Validate that a concept exits
		if (value.concept !== '') {
			updateBankCommission(data.id, value)
				.then(({ data }) => {
					handleSuccess('Comisión de banco actualizada correctamente');
					updateOneItem(data);
					handleClose();
				})
				.catch((error) => {
					handleError(error, 'No se actualizó la comisión');
				});
		} else {
			handleWarning('Falta Concepto');
		}
	}

	return (
		<div
			style={{
				padding: '20px',
				margin: 6,
			}}
		>
			<Typography variant='h4' gutterBottom>
				{title}
			</Typography>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={8}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Concepto'
						onChange={(e) => setConcept(e.target.value)}
						value={concept}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						type='number'
						variant='outlined'
						color='secondary'
						label='Primera comisión (%)'
						onChange={(e) => setFirstCom(e.target.value)}
						value={firstCom}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						type='number'
						variant='outlined'
						color='secondary'
						label='Segunda comisión (%)'
						onChange={(e) => setSecondCom(e.target.value)}
						value={secondCom}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Descripción'
						onChange={(e) => setDescription(e.target.value)}
						value={description}
						multiline
						rows={4}
						inputProps={{ maxLength: 500 }}
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<Button
						variant='contained'
						fullWidth
						color='error'
						sx={{ mt: 2 }}
						onClick={handleClose}
					>
						Cancelar
					</Button>
				</Grid>
				<Grid item xs={6}>
					{data ? (
						<Button
							variant='contained'
							fullWidth
							style={{ backgroundColor: '#143f6c' }}
							sx={{ mt: 2 }}
							onClick={() => {
								handleUpdate({
									concept: concept,
									first_commission: firstCom / 100,
									second_commission: secondCom / 100,
									description: description,
								});
							}}
						>
							Actualizar
						</Button>
					) : (
						<Button
							variant='contained'
							fullWidth
							style={{ backgroundColor: '#143f6c' }}
							sx={{ mt: 2 }}
							onClick={() => {
								handleCreate({
									concept: concept,
									first_commission: firstCom / 100,
									second_commission: secondCom / 100,
									description: description,
								});
							}}
						>
							Registrar
						</Button>
					)}
				</Grid>
			</Grid>
		</div>
	);
};
