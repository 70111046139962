import { types } from '../types/types';

const initialState = {};

export const catalogReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.setCatalogs:
			return {
				...state,
				...action.payload,
			};
			
		case types.cleanCatalogs:
			return initialState;

		case types.setScheme:
			return action.payload;

		default:
			return state;
	}
};
