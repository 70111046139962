import React, { useEffect } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    registerApplication,
    updateApplication,
} from '../../actions/applications';
import {
    capitalizeFirstLetter,
    handleError,
    handleSuccess,
    handleWarning,
} from '../../helpers/utils';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';
import { getCatalog, setCatalogs } from '../../actions/catalogs';
import { STATES } from '../../helpers/lists';

export const NewApplicationForm = ({
    editOrCreate = null,
    handleClose,
    refresh = null,
    promoters = [],
    data = null,
    show = false,
}) => {
    // Define state to know if it's loading or not
    const [isLoading, setIsLoading] = useState(false);
    const [newPromoter, setNewPromoter] = useState(null);
    const [currentClient, setCurrentClient] = useState(null);
    const [folio, setFolio] = useState(null);
    const [clients, setClients] = useState([]);
    const [site, setSite] = useState(null);
    const { id: executiveID } = useSelector((state) => state.user);
    const catalogs = useSelector((state) => state.catalogs);
    const [nameApplication, setNameApplication] = useState(null);

    // Dispatch
    const dispatch = useDispatch();

    // Navigation
    const navigate = useNavigate();

    const handleResetStates = () => {
        setNewPromoter(null);
        setIsLoading(false);
        setFolio(null);
        setSite(null);
        setCurrentClient(null);
        handleClose();
    };

    useEffect(() => {
        if (catalogs?.clients) {
            // Update local state
            setClients(catalogs?.clients);
        } else {
            getCatalog('client').then(({ data }) => {
                // Update redux
                dispatch(setCatalogs({ clients: data.results }));
                // Update local state
                setClients(data.results);
            });
        }
    }, []);

    const handleSubmit = () => {
        // setIsLoading(true);
        if (folio === 'SOLICITUD DE RETIRO') {
            if (site) {
                const siteCode =
                    STATES.find((prom) => prom.name === site).code || null;
                const formData = {
                    promoter: null,
                    executive: executiveID,
                    client: null,
                    folio: folio,
                    headquarter: siteCode,
                };
                if (data !== null) {
                    updateApplication(data.id, formData)
                        .then(() => {
                            handleSuccess(
                                'Solicitud actualizada correctamente.'
                            );
                        })
                        .catch((error) => {
                            handleError(
                                error,
                                'No se pudo actualizar la solicitud.'
                            );
                        })
                        .finally(() => {
                            handleResetStates();
                            refresh();
                        });
                } else {
                    registerApplication(formData)
                        .then(({ data }) => {
                            if (data) {
                                handleSuccess(
                                    'La solicitud se creó correctamente.'
                                );
                                handleResetStates();
                                navigate(`/dashboard/application/${data.id}`);
                            }
                        })
                        .catch((error) => {
                            handleError(
                                error,
                                'No se pudo registrar la solicitud.'
                            );
                        })
                        .finally(() => {
                            handleResetStates();
                        });
                }
            } else {
                handleWarning('Hacen falta campos');
            }
        } else {
            if (newPromoter && currentClient && site) {
                const promoterID =
                    promoters.find((prom) => {
                        return (
                            newPromoter.toUpperCase() ===
                            `${prom.name} ${prom.lastname}`.toUpperCase()
                        );
                    }).id || null;

                const clientId =
                    clients.find((prom) => prom.name === currentClient).id ||
                    null;

                const siteCode =
                    STATES.find((prom) => prom.name === site).code || null;

                const formData = {
                    promoter: promoterID,
                    executive: executiveID,
                    client: clientId,
                    folio: folio,
                    headquarter: siteCode,
                };

                if (data !== null) {
                    updateApplication(data.id, formData)
                        .then(() => {
                            handleSuccess(
                                'Solicitud actualizada correctamente.'
                            );
                        })
                        .catch((error) => {
                            handleError(
                                error,
                                'No se pudo actualizar la solicitud.'
                            );
                        })
                        .finally(() => {
                            handleResetStates();
                            refresh();
                        });
                } else {
                    registerApplication(formData)
                        .then(({ data }) => {
                            if (data) {
                                handleSuccess(
                                    'La solicitud se creó correctamente.'
                                );
                                handleResetStates();
                                navigate(`/dashboard/application/${data.id}`);
                            }
                        })
                        .catch((error) => {
                            handleError(
                                error,
                                'No se pudo registrar la solicitud.'
                            );
                        })
                        .finally(() => {
                            handleResetStates();
                        });
                }
            } else {
                handleWarning('Hacen falta campos');
            }
        }
    };

    const SelectComponent = (params, label) => (
        <TextField
            required
            margin='normal'
            {...params}
            label={label}
        />
    );

    const formatName = (name, lastname) =>
        capitalizeFirstLetter(`${name} ${lastname}`);

    useEffect(() => {
        //Only safe the previous promoter at first render
        if (data) {
            setNewPromoter(
                formatName(data?.promoter?.name, data?.promoter?.lastname)
            );
            setCurrentClient(data?.client?.name);
            setFolio(data?.folio);
            setSite(
                STATES.find((element) => data?.headquarter == element.code).name
            );
        }
    }, [data]);

    return (
        <>
            <Offcanvas
                show={show}
                onHide={() => {
                    handleClose();
                    handleResetStates();
                }}
                placement='end'
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Solicitud de fondeo</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    Seleccione un promotor encargado de la solicitud.
                    <hr />
                    <Grid
                        container
                        spacing={2}
                        className='mt-3'
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            {/* Invoice field */}
                            <Autocomplete
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                required
                                fullWidth
                                value={folio}
                                onChange={(_, value) => {
                                    setFolio(value);
                                }}
                                options={[
                                    'SOLICITUD CONVENCIONAL',
                                    'BANCARIZADA',
                                    'RETIRO DE RESGUARDO',
                                    'SOLICITUD DE RETIRO',
                                    'COMISIONES DIRECTAS',
                                ]}
                                renderInput={(params) =>
                                    SelectComponent(params, 'Folio')
                                }
                            />
                        </Grid>
                        {folio !== 'SOLICITUD DE RETIRO' ? (
                            <Grid
                                item
                                xs={12}
                            >
                                {/* Profile field */}
                                <Autocomplete
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    required
                                    // style={{ marginTop: '20px' }}
                                    fullWidth
                                    value={newPromoter}
                                    onChange={(_, value) => {
                                        // formik.setFieldValue('promoter', value);
                                        setNewPromoter(value);
                                    }}
                                    options={promoters.map((promoter) =>
                                        capitalizeFirstLetter(
                                            `${promoter.name} ${promoter.lastname}`
                                        )
                                    )}
                                    renderInput={(params) =>
                                        SelectComponent(params, 'Promotor')
                                    }
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}

                        <Grid
                            item
                            xs={12}
                        >
                            {/* Site field */}
                            <Autocomplete
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                required
                                // style={{ marginTop: '20px' }}
                                fullWidth
                                value={site}
                                onChange={(_, value) => {
                                    setSite(value);
                                }}
                                options={STATES.map(({ name }) => name)}
                                renderInput={(params) =>
                                    SelectComponent(params, 'Sede')
                                }
                            />
                        </Grid>

                        {folio !== 'SOLICITUD DE RETIRO' ? (
                            <Grid
                                item
                                xs={12}
                            >
                                {/* Client field */}
                                <Autocomplete
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    required
                                    // style={{ marginTop: '20px' }}
                                    fullWidth
                                    value={currentClient}
                                    onChange={(_, value) => {
                                        setCurrentClient(value);
                                    }}
                                    options={
                                        clients?.map((item) => item.name) || []
                                    }
                                    renderInput={(params) =>
                                        SelectComponent(params, 'Cliente')
                                    }
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}

                        <Grid
                            item
                            xs={6}
                        >
                            {/* Cancel button */}
                            <LoadingButton
                                fullWidth
                                size='large'
                                color='error'
                                onClick={() => {
                                    handleResetStates();
                                    handleClose();
                                }}
                                variant='contained'
                                loading={false}
                            >
                                Cancelar
                            </LoadingButton>
                        </Grid>

                        <Grid
                            item
                            xs={6}
                        >
                            {/* Confirm button */}
                            <LoadingButton
                                fullWidth
                                size='large'
                                style={{
                                    backgroundColor: '#143f6c',
                                }}
                                onClick={handleSubmit}
                                variant='contained'
                                loading={isLoading}
                            >
                                {data ? 'Actualizar' : 'Crear'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
