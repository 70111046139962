import React from 'react';
import { SimpleCatalogTable } from './SimpleCatalogTable';
import {
    deletePayer,
    getPayerInfo,
    getPayers,
    registerPayer,
    searchPayer,
    updatePayer,
} from '../../actions/catalogs';

// Banks Table
export const Payers = () => {
    // Configuration for the table
    const configuration = {
        getCatalogs: getPayers,
        getCatalogInfo: getPayerInfo,
        deleteCatalog: deletePayer,
        updateCatalog: updatePayer,
        registerCatalog: registerPayer,
        searchCatalog: searchPayer,
        catalogSingular: 'Pagadora',
        catalogPlural: 'Pagadoras',
        pronounSingular: 'la',
        pronoun: 'a',
        conceptName: 'name',
        conceptNameLabel: 'Nombre',
    };

    return <SimpleCatalogTable config={configuration} />;
};
