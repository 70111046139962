import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
	getDepartmentID,
	getPositionID,
	getProfileID,
} from '../../helpers/utils';
import { formatDateMX, formatDateTimeMX } from '../../helpers/hours';

export const UserProfileTab = ({ user }) => {
	return (
		<Grid container spacing={2} style={{ border: '1px solid white' }}>
			{user && (
				<>
					<Grid item xs={5} style={imgDivStyle}>
						<img
							src={
								user?.photoURL ||
								'https://www.inspirations.boutique/wp-content/uploads/2019/10/blank-person-profile.png'
							}
							alt='test'
							width='300'
							height='300'
							className='rounded-circle me-2'
							referrerPolicy='no-referrer'
						/>
					</Grid>
					<Grid
						item
						xs={7}
						style={{
							border: '1px solid white',
							paddingTop: '3em',
						}}
					>
						<Grid
							container
							spacing={2}
							style={{ border: '1px solid white' }}
						>
							<GridItem
								xs={6}
								title='Nombre'
								text={`${user.first_name} ${user.last_name}`}
							/>
							<GridItem xs={6} title='Correo' text={user.email} />
							<GridItem
								xs={6}
								title='Perfil'
								text={getProfileID(user.profile)}
							/>
							{/* <GridItem
								xs={6}
								title='Es super usuario'
								text={user.is_superuser ? 'Sí' : 'No'}
							/> */}
							<GridItem
								xs={6}
								title='Tiene acceso a administración'
								text={user.is_staff ? 'Sí' : 'No'}
							/>
							{/* <GridItem
								xs={6}
								title='Está activo'
								text={user.is_active ? 'Sí' : 'No'}
							/> */}
							<GridItem
								xs={6}
								title='Departamento'
								text={getDepartmentID(user.department)}
							/>
							<GridItem
								xs={6}
								title='Posición'
								text={getPositionID(user.position)}
							/>
							<GridItem
								xs={6}
								title='Último acceso'
								text={formatDateTimeMX(user.last_login)}
							/>
							<GridItem
								xs={6}
								title='Creado'
								text={formatDateTimeMX(user.created_at)}
							/>
						</Grid>
					</Grid>
				</>
			)}
		</Grid>
	);
};

const GridItem = ({ xs, title, text }) => (
	<Grid item xs={xs}>
		<Typography variant='p'>{title}</Typography>
		<Typography variant='h6'>{text}</Typography>
	</Grid>
);

const imgDivStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};
