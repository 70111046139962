const objComisiones = [
	{ bank: 'KUSPIT', firstCommission: 0.25, secondCommission: 0 },
	{ bank: 'KUSPIT Resguardo', firstCommission: 0, secondCommission: 0 },
	{ bank: 'TRAFALGAR', firstCommission: 0.25, secondCommission: 0 },
	{ bank: 'ALQUIMIA', firstCommission: 0.5, secondCommission: 0.25 },
	{ bank: 'ALQUIMIA PAY', firstCommission: 0.5, secondCommission: 0.25 },
	{ bank: 'BANCOMER', firstCommission: 0, secondCommission: 0.25 },
	{ bank: 'BANORTE', firstCommission: 0, secondCommission: 0.25 },
	{ bank: 'BANREGIO', firstCommission: 0, secondCommission: 0.25 },
	{ bank: 'CUENTAS OK', firstCommission: 0, secondCommission: 0.25 },
	{ bank: 'INBURSA', firstCommission: 0, secondCommission: 0.25 },
	{ bank: 'MULTIVA', firstCommission: 0, secondCommission: 0.25 },
	{ bank: 'SANTANDER', firstCommission: 0, secondCommission: 0.25 },
	{ bank: 'SCOTIABANK', firstCommission: 0, secondCommission: 0.25 },
	{ bank: 'PAGOS BANORTE', firstCommission: 0, secondCommission: 0.25 },
	{ bank: 'STP', firstCommission: 0.2 * 1.16, secondCommission: 0 },
	{ bank: 'ASP INTEGRA', firstCommission: 0.2, secondCommission: 0 },
];

/**
 * Calculate the KV Bank commission based on deposit data for a specific group of banks.
 *
 * @param {Array} kvArray - The array of deposit data for a specific group of banks.
 * @returns {number} - The calculated commission for the group of banks divided by 100.
 */
const getKVBankCommission = (kvArray) => {
	let result = kvArray.reduce(function (acc, obj) {
		const bankName = obj?.bank?.name;
		const depositNumber = obj?.deposit;

		// Find the commission rates for the bank in 'objComisiones'
		const objectBank = objComisiones.find(
			(element) => element?.bank === bankName
		);

		// Calculate the commission for the deposit using the commission rates
		const resultTemp =
			depositNumber * (objectBank?.firstCommission || 0) +
			(depositNumber -
				depositNumber * (objectBank?.firstCommission || 0)) *
				(objectBank?.secondCommission || 0);

		// Accumulate the individual deposit commissions
		return acc + resultTemp;
	}, 0);

	// Divide the total commission by 100 to get the final commission value
	return result / 100;
};

const getKvGroupCommission = (tempArray) => {
	// Sum the 'import_funding' values from all elements in the specified array
	let totalImportFunding = tempArray.reduce(
		(accumulator, element) => accumulator + element?.import_funding,
		0
	);

	// Calculate the commission as 0.25% of the total import funding
	return (totalImportFunding * 0.25) / 100;
};

export const calculateBankCommissions = (deposits) => {
	// Filter deposits into four separate arrays based on company_type

	const kVArray = deposits.filter((element) => element?.company_type == 'KV');
	const kvGroupArray = deposits.filter(
		(element) => element?.company_type == 'KV + Grupo'
	);
	const groupArray = deposits.filter(
		(element) => element?.company_type == 'Grupo'
	);
	const groupTwoArray = deposits.filter(
		(element) => element?.company_type == 'Grupo 2'
	);

	// Calculate commissions for each deposit category
	const kVResult = getKVBankCommission(kVArray);
	const kVGroupResult = getKVBankCommission(kvGroupArray);
	const groupResult = getKvGroupCommission(groupArray);
	const group2Result = getKvGroupCommission(groupTwoArray);

	// Sum all commissions from different categories
	return kVResult + kVGroupResult + groupResult + group2Result;
};

export const getDepositsCommission = (deposits) => {
	// Sum the 'deposit' values from all deposit elements
	let totalImportFunding = deposits.reduce(
		(accumulator, element) => accumulator + element?.deposit,
		0
	);

	// Calculate the commission as 0.25% of the total import funding
	return (totalImportFunding * 0.25) / 100;
};

export function edenRedKVCalc(rows) {
	let result = 0;

	// Sum the return values from rows with the 'EDEN RED KV' scheme and non-empty 'return_value'
	let temp = rows.reduce(function (acc, obj) {
		if (obj.scheme === 'EDEN RED KV' && obj.return_value.length != 0) {
			return acc + Number(obj.return_value);
		} else {
			return acc;
		}
	}, 0);

	// Calculate the commission based on the summed return values
	if (temp === 0) {
		result = 0;
	} else if (temp < 22210) {
		result = 334.85;
	} else if (temp >= 22210) {
		result =
			temp * (1.3 / 100 / 100) + temp * (1.3 / 100 / 100) * (16 / 100);
	}

	return result;
}

export function edenRedCorpoCalc(rows) {
	let result = 0;

	// Use the 'reduce' function to iterate over the 'rows' array and calculate the total.
	let temp = rows.reduce(function (acc, obj) {
		// Check if the scheme is 'EDEN RED CORPO' and the 'return_value' property is not empty.
		if (obj.scheme === 'EDEN RED CORPO' && obj.return_value.length !== 0) {
			return acc + Number(obj.return_value);
		} else {
			return acc;
		}
	}, 0);

	if (temp === 0) {
		result = 0;
	} else if (temp > 0) {
		// Calculate the result as 'temp' multiplied by a percentage (1.3%) and also including a tax (16% of the calculated amount).
		result = temp * (1.3 / 100) + temp * (1.3 / 100) * (16 / 100);
	}
	return result;
}

export const calculateCostCorporative = (deposits, depositTotal) => {
	let result = 0;

	// Verify if deposits contain 'Grupo'
	// let depositsHaveGroup = deposits
	// 	.map((element) => element.company_type)
	// 	.includes('Grupo');

	// // Verify if deposits contain 'Grupo 2'
	// let depositsHaveGroup2 = deposits
	// 	.map((element) => element.company_type)
	// 	.includes('Grupo 2');

	// if (!depositsHaveGroup2 && !depositsHaveGroup) {
	// 	console.log('depositTotal', depositTotal);
	// 	// Calculate the cost using a fixed formula when neither 'Grupo' nor 'Grupo 2' is present
	// 	result = ((depositTotal / 1.16) * (1.5 / 100)).toFixed(2);
	// 	console.log('IF');
	// } else {
	// 	console.log('ELSE');
	// Calculate the cost by summing commission_corporative values from deposits
	result = deposits.reduce(
		(accumulator, element) => accumulator + element.commission_corporative,
		0
	);
	// }

	return result;
};

export const calculateCostEfective = (rows) => {
	// Sum the return values from rows with the 'EFECTIVO' scheme and non-empty 'return_value'
	var result = rows.reduce(function (acc, obj) {
		if (obj.scheme === 'EFECTIVO' && obj.return_value.length != 0) {
			return acc + Number(obj.return_value);
		} else {
			return acc;
		}
	}, 0);

	// Calculate the cost as 0.75% of the summed return values, and round to two decimal places
	return ((result * 0.75) / 100).toFixed(2);
};

export const calculateSCCost = (rows) => {
	// Sum the return values from rows with the 'SC' scheme and non-empty 'return_value'
	var result = rows.reduce(function (acc, obj) {
		if (obj.scheme === 'SC' && obj.return_value.length != 0) {
			return acc + (Number(obj.balance) - Number(obj?.commission));
		} else {
			return acc;
		}
	}, 0);

	// Calculate the cost as 1.5% of the summed return values, and round to two decimal places
	return ((result * 1.5) / 100).toFixed(2);
};

export const calculateCostUnion = (rows) => {
	// Sum the return values from rows with the 'SINDICATO' scheme and non-empty 'return_value'
	let result = rows.reduce(function (acc, obj) {
		if (obj.scheme === 'SINDICATO' && obj.return_value.length != 0) {
			return acc + Number(obj.return_value);
		} else {
			return acc;
		}
	}, 0);

	// Calculate the cost as 2.5% of the summed return values and round to two decimal places
	return ((result * 2.5) / 100).toFixed(2);
};

export const getDoubleCommission = (deposits) => {
	const result = deposits.reduce(
		(accumulator, element) => accumulator + element.double_bank_commission,
		0
	);

	return result;
};
