import { types } from '../types/types';

// Sidebar set state
export const setShowSideBar = (data) => ({
	type: types.setShowSideBar,
	payload: data,
});

// Sidebar reset state
export const resetSideBar = () => ({
	type: types.resetSideBar,
});

// Get the status of the sidebar from local storage
export const getSideBarStatus = () =>
	localStorage.getItem('sidebarKV') === 'true';

// Set the status of the sidebar in local storage
export const setSideBarStatus = (value = undefined) => {
	const status = getSideBarStatus();
	if (value === undefined) {
		localStorage.setItem('sidebarKV', status);
	} else {
		localStorage.setItem('sidebarKV', value);
	}
};
