import React from 'react';
import {
	deleteDestinationSource,
	getDestinationSourceInfo,
	getDestinantionsSource,
	registerDestinationSource,
	updateDestinationSource,
	searchDestinationSource,
} from '../../actions/catalogs';
import { SimpleCatalogTable } from './SimpleCatalogTable';

// Destination Sources Table
export const DestinationSource = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getDestinantionsSource,
		getCatalogInfo: getDestinationSourceInfo,
		deleteCatalog: deleteDestinationSource,
		updateCatalog: updateDestinationSource,
		registerCatalog: registerDestinationSource,
		searchCatalog: searchDestinationSource,
		catalogSingular: 'Origen Destino',
		catalogPlural: 'Origen Destinos',
		pronounSingular: 'el',
		pronoun: 'o',
		conceptName: 'name',
		conceptNameLabel: 'Nombre',
		hasKey: false,
	};

	return <SimpleCatalogTable config={configuration} />;
};