import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import {
    Alert,
    Box,
    Button,
    DialogActions,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
    Tooltip,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { money } from '../../helpers/money';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { getFundingsFiltered } from '../../actions/funding';
import { getBanks, getClients } from '../../actions/catalogs';

export const ReturnsAndFundingsResults = ({
    open,
    handleClose,
    applicationId = null,
    rowInfo = { payer: 'temp', bank: 'null' },
}) => {
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const [titleName, setTitleName] = useState('Cargando...');


    const columns = [
        {
            field: 'application_tag',
            headerName: 'Solicitud',
            ...columnStyleCell,
            width: 200,
            renderCell: ({ row }) => (
                <div style={rowDialogStyle}>
                    <Button
                        onClick={() => {
                            navigate(
                                `/dashboard/application/${row.application_id}`
                            );
                        }}
                    >
                        {row?.application_tag ?? '-'}
                        <LinkIcon fontSize='small' />
                    </Button>
                </div>
            ),
        },
        {
            field: 'client',
            headerName: 'Cliente',
            ...columnStyleCell,
            width: 500,
            renderCell: ({ row }) => (
                <div style={rowDialogStyle}>
                    <span className='table-cell-trucate'>
                        {row?.client ?? '-'}
                    </span>
                </div>
            ),
        },
        {
            field: 'concept',
            headerName: 'Concepto',
            ...columnStyleCell,
            width: 500,
            renderCell: ({ row }) => (
                <div style={rowDialogStyle}>
                    <span className='table-cell-trucate'>
                        {row?.concept ?? '-'}
                    </span>
                </div>
            ),
        },
        {
            field: 'amount',
            headerName: 'Monto',
            ...columnStyleCell,
            width: 300,
            renderCell: ({ row }) => (
                <div style={rowDialogStyle}>
                    <span className='table-cell-trucate'>
                        {money(row?.amount)}
                    </span>
                </div>
            ),
        },
    ];


    /**
     * Effect hook to fetch and filter funding data based on payer and bank information.
     *
     * This effect hook performs the following steps:
     * 1. It fetches the list of available banks.
     * 2. It retrieves the specific bank object based on the `rowInfo`'s bank name.
     * 3. It fetches the list of clients.
     * 4. It filters fundings based on the provided `rowInfo`'s payer and bank.
     * 5. It formats the filtered funding data by mapping and enhancing it with client information.
     * 6. It sets the formatted funding data to the `rows` state variable.
     * 7. It sets the title name for display.
     *
     * Dependencies: getBanks (to fetch banks), getClients (to fetch clients), getFundingsFiltered (to filter fundings)
     */
    useEffect(() => {
        // Fetch the list of available banks
        getBanks().then(({ data }) => {
            // Find the specific bank object based on the `rowInfo`'s bank name
            let bankObj = data?.results?.find(
                (element) => element?.name == rowInfo?.bank
            );

            // Fetch the list of clients
            getClients().then(({ data }) => {
                // Store the list of clients
                let listClients = data.results;

                // Filter fundings based on payer and bank
                getFundingsFiltered({
                    payer: rowInfo?.payer,
                    bank: bankObj?.id,
                }).then(({ data }) => {
                    // Format and enhance the filtered funding data with client information
                    const fixedObjects = data.results.map((element, index) => {
                        return {
                            ...element,
                            id: index,
                            client: listClients.find((clientElement) => {
                                if (
                                    clientElement.id == element.application_id
                                ) {
                                    return clientElement;
                                }
                            })?.name,
                        };
                    });

                    // Set the formatted funding data to the `rows` state variable
                    setRows(fixedObjects);

                    // Set the title name for display as a combination of payer and bank (uppercase)
                    setTitleName(
                        `${rowInfo?.payer} - ${rowInfo?.bank}`.toUpperCase()
                    );
                });
            });
        });
    }, [applicationId, rowInfo]);

    return (
        <Dialog
            maxWidth='xl'
            fullWidth
            // style={{width: "1000px"}}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            {/* Content */}
            <Box sx={{ flexGrow: 1 }}>
                <div style={{ padding: '20px' }}>
                    <Text
                        variant={'h6'}
                        text={titleName}
                        bold={false}
                    />

                    {rows?.length > 0 ? (

                        <DataGrid
                            autoHeight
                            rows={rows}
                            columns={columns}
                            // autoPageSize
                            components={{ Toolbar: GridToolbar }}
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                        />

                        
                    ) : (
                        <Alert severity='info'>
                            No hay ninguna pagadora registrada
                        </Alert>
                    )}
                </div>
            </Box>
            <DialogActions>
                <Button onClick={() => handleClose()}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

const ColumnItem = ({ align, text }) => (
    <StyledTableCell align={align}>{text}</StyledTableCell>
);

const styleLabel = {
    '&:last-child td, &:last-child th': { border: 0 },
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction='up'
            ref={ref}
            {...props}
        />
    );
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#01579b',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const columnStyleCell = {
    headerClassName: 'column-header-pilu',
    headerAlign: 'center',
};

const rowDialogStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
};

const buttonDialogStyle = {
    // backgroundColor: '#143f6c',
    margin: '0 0 0 10px',
};

export const Text = ({ variant, text, bold = true }) => (
    <Typography
        variant={variant}
        gutterBottom
    >
        {bold ? <b>{text}</b> : text}
    </Typography>
);
