import React from 'react';

import { StackApplication } from '../components/applications/StackApplication';
import { Alert, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	getApplicationInfo,
	getDepositsByApplicationID,
	setCurrentApplication,
} from '../actions/applications';
import { formatDateTimeMX } from '../helpers/hours';
import {
	getWithdrawTotal,
	refreshReturnAction,
	setCommissionsBank,
	setCommissionsCorporative,
	setCommissionsTotal,
	setCurrentTab,
	setDepositsRX,
	setDepositsTotal,
	setListOfSafeguards,
	setRequestEvaluation,
	setTotalCommissionSchemeTotal,
	updateAvailableKV,
	updateCreditToDebt,
	updateDecOperatingExpenses,
	updateOperatingExpenses,
	updateOperatingExpensesTotal,
	updatePromoters,
} from '../actions/requests';
import { useDispatch, useSelector } from 'react-redux';
import {
	getReturnAndCommissions,
	getSafeguardTotal,
	getSafeguardsPerClient,
} from '../actions/returnAndCommissions';
import {
	getSchemes,
	loadCatalogsForDepositForm,
	setScheme,
} from '../actions/catalogs';
import { getPromoters } from '../actions/promoter';
import { getDebts } from '../actions/debt';
import {
	calculateAllPayersOnReturnAndCommission,
	calculateTotalISN,
	calculateTotalISR,
	calculateTotalReturns,
} from '../helpers/ReturnCommissionCalc';
import {
	getAdministrativeCost,
	setAdministrativeCostsAction,
} from '../actions/administrativeCosts';
import { ConventionalAndSafeguardApplications } from '../components/applications/ConventionalAndSafeguardApplications';
import { WithdrawalApplicationTabs } from '../components/applications/WithdrawalApplicationTabs';
import {
	getAdministrativeCostWithdraw,
	setAdministrativeCostsWithdrawAction,
} from '../actions/administrativeCostsWithdraw';
import { handleError } from '../helpers/utils';
import { Loader } from '../components/utils/Loader';

// Calculate amount and commission totals and load it to redux
export const calculateAndLoadDeposits = (dispatch, deposits) => {
	// Create redux deposits format
	const reduxDeposits = deposits.map((item) => ({
		...item,
		cost: item?.cost || 0,
		expense: item?.expense || 0,
	}));

	const newTotal = reduxDeposits.reduce(
		(sum, { deposit }) => sum + deposit,
		0
	);

	const newTotalCommissions = reduxDeposits.reduce(
		(sum, { commission }) => sum + commission,
		0
	);

	const newTotalCommissionsCorporative = reduxDeposits.reduce(
		(sum, { commission_corporative }) => sum + commission_corporative,
		0
	);

	const totalCommissions =
		newTotalCommissionsCorporative + newTotalCommissions;

	// Set current deposits in Redux
	dispatch(setDepositsRX(reduxDeposits));
	// Set current deposits total in Redux
	dispatch(setDepositsTotal(newTotal));

	dispatch(setCommissionsTotal(totalCommissions));
	dispatch(setCommissionsCorporative(newTotalCommissionsCorporative));
	dispatch(setCommissionsBank(newTotalCommissions));
};

export const ApplicationDetail = () => {
	const { idApplication = null } = useParams();
	const [currentTabLocal, setCurrentTabLocal] = useState(0);
	const [application, setApplication] = useState(null);
	const [loading, setLoading] = useState(true);
	const { currentTab = 0 } = useSelector((state) => state.request);
	const { comisionesBancarias: bankCommissions = 0 } = useSelector(
		(state) => state.request.operatingExpenses
	);
	const objectOperativeExpenses = useSelector(
		(state) => state.request.operatingExpenses
	);
	const { costoEfectivo: effectiveCost = 0 } = useSelector(
		(state) => state.request.operatingExpenses
	);

	const applicationTemp = useSelector((state) => state.request.application);

	const proveAmount = useSelector((state) => state.request.requestTotal);

	// Dispatch
	const dispatch = useDispatch();

	// Change tabs
	const handleChange = (_, newValue) => {
		setCurrentTabLocal(newValue);
	};

	// Gets Administrative Costs and stores it in redux
	const handleGetAdministrativeCosts = async (applicationId) => {
		return new Promise(async (res, _) => {
			const { data } = await getAdministrativeCost(applicationId);

			//Calculate total Import for Administrative Expenses
			const totalManualImports = data?.results?.reduce(
				(sum, { manual_import }) => sum + manual_import,
				0
			);

			// console.log("administrative costs", data.results)
			dispatch(
				setAdministrativeCostsAction({
					administrativeCostsArray: data.results,
					totalImports: totalManualImports,
				})
			);
		});
	};

	const handleGetWithdraw = async (applicationId) => {
		return new Promise(async (res, _) => {
			const { data } = await getAdministrativeCostWithdraw(applicationId);

			//Calculate total Import for Administrative Expenses
			const totalManualImports = data?.results?.reduce(
				(sum, { amount }) => sum + amount,
				0
			);

			const totalCommissions = data?.results?.reduce(
				(sum, { bank_commission }) => sum + Number(bank_commission),
				0
			);

			dispatch(
				setAdministrativeCostsWithdrawAction({
					administrativeCostsWithdrawArray: data.results,
					totalCommissions: totalCommissions,
					totalImports: totalManualImports,
				})
			);
		});
	};

	// Get deposits, show it in datagrid and store it in redux
	const handleGetDeposits = async (applicationId) => {
		const { data } = await getDepositsByApplicationID(applicationId);

		// Fail first before continue
		if (!data || data?.results?.length === 0) dispatch(setDepositsRX([]));

		// Calculate amount and commission totals and load it to redux
		calculateAndLoadDeposits(dispatch, data.results);
	};

	// Get Return and Commissions, show it in datagrid and store it in redux
	const handleGetReturnCommissions = (
		applicationId,
		operativeExpensesTotal
	) => {
		return new Promise(async (res, _) => {
			const { data } = await getReturnAndCommissions(applicationId);

			// Fail first before continue
			if (!data || data?.results?.length === 0) {
				dispatch(refreshReturnAction([]));
				// Response tith the commission total
				res(0);
			} else {
				// Return and commissions results
				const responseResults = data.results;
				const tempPayers =
					calculateAllPayersOnReturnAndCommission(responseResults);
				const tempReturn = calculateTotalReturns(responseResults);
				const tempISN = calculateTotalISN(responseResults);

				const tempISR = calculateTotalISR(responseResults);

				let totalAmount = 0;
				responseResults.forEach((item) => {
					totalAmount += item.fundings.reduce(
						(sum, { amount }) => sum + amount,
						0
					);
				});

				// Calculate total return (return and commission)
				const requestTotal =
					totalAmount -
					tempReturn -
					tempISN -
					tempISR -
					// operativeExpensesTotal +
					bankCommissions +
					effectiveCost;

				// Calculate total commission scheme (return and commission)
				const totalCommissionScheme = responseResults.reduce(
					(accumulator, { commission }) => {
						return accumulator + Number(commission || 0);
					},
					0
				);

				// Request Total
				dispatch(setRequestEvaluation(requestTotal));

				// Total Operating expenses
				// dispatch(updateOperatingExpensesTotal(total));
				// Total commission scheme total
				dispatch(setTotalCommissionSchemeTotal(totalCommissionScheme));

				// Set current returns and commissions in Redux
				dispatch(refreshReturnAction(responseResults));

				// Response tith the commission total
				res(totalCommissionScheme);
			}
		});
	};

	const handleGetSafeguardsPerClient = (application) => {
		if (application?.client?.id) {
			getSafeguardsPerClient({ client: application?.client?.id }).then(
				({ data }) => {
					dispatch(setListOfSafeguards(data.results));
				}
			);
		} else {
			handleError(null, 'Cliente no definido');
		}
	};

	// Get Promoters, show it in datagrid and store it in redux
	const handleGetPromoters = (applicationId) => {
		return new Promise(async (res) => {
			const { results = [] } = (await getPromoters(applicationId)).data;

			// Fail first before continue
			if (!results || results?.length === 0) {
				dispatch(
					updatePromoters({
						promotersArray: [],
						commissionTotal: 0,
						amountTotal: 0,
					})
				);

				// Response tith the commission total
				res(0);
			} else {
				// Total commissions
				const totalCommissions = results.reduce(
					(sum, { commission }) => sum + commission,
					0
				);

				// Total amounts
				let totalAmounts = 0;
				results.forEach((item) => {
					totalAmounts += item.fundings.reduce(
						(sum, { amount }) => sum + amount,
						0
					);
				});

				// Set current returns and commissions in Redux
				dispatch(
					updatePromoters({
						promotersArray: results,
						commissionTotal: totalCommissions,
						amountTotal: totalAmounts,
					})
				);

				// Response tith the commission total
				res(totalCommissions);
			}
		});
	};

	// Get credit to debt, show it in datagrid and store it in redux
	const handleGetDebts = (applicationId) => {
		return new Promise(async (res, _) => {
			const { results = [] } = (await getDebts(applicationId)).data;

			// Fail first before continue
			if (!results || results?.length === 0) {
				dispatch(
					updateCreditToDebt({
						creditArray: [],
						commissionTotal: 0,
						amountTotal: 0,
						totalExpenses: 0,
						availableKV: 0,
						totalPayer: 0,
						totalImport: 0,
					})
				);
				// Response tith the commission total
				res(0);
			} else {
				// Total commissions
				const totalCommissions = results.reduce(
					(sum, { commission }) => sum + commission,
					0
				);

				// Total amounts
				let totalAmounts = 0;
				results.forEach((item) => {
					totalAmounts += item.fundings.reduce(
						(sum, { amount }) => sum + amount,
						0
					);
				});

				// Set current credits to debt in Redux
				dispatch(
					updateCreditToDebt({
						creditArray: results,
						commissionTotal: totalCommissions,
						amountTotal: totalAmounts,
						totalExpenses: 0,
						availableKV: 0,
						totalPayer: 0,
						totalImport: 0,
					})
				);

				// Response tith the commission total
				res(totalCommissions);
			}
		});
	};

	const handleAvailableKVObservations = (data) => {
		dispatch(updateAvailableKV({ observations: data?.observations }));
	};

	const handleSetOperativeExpenses = async (data) => {
		setLoading(true);
		dispatch(
			updateDecOperatingExpenses({
				comisionesBancarias: data?.bank_commission ? true : false,
				secondBankCommission: data?.second_bank_commission_dec
					? true
					: false,
				doubleBankCommission: data?.double_bank_commission_dec
					? true
					: false,
				costoProvedor4ST: data?.supplier_expense_4ST ? true : false,
				costoEfectivo: data?.cost_effective ? true : false,
				costoFID: data?.fid_expense ? true : false,
				costoSC: data?.sc_expense ? true : false,
				costoSindicato: data?.union_expense ? true : false,
				comisionSpei: data?.spei_commission || 0,
				cuentaKV: data?.account_kv ? true : false,
			})
		);
		dispatch(
			updateOperatingExpenses({
				comisionesConvencionales: data?.conventional_commissions || 0,
				comisionesBancarias: data?.bank_commission_value || 0,
				secondBankCommission: data?.second_bank_commission || 0,
				doubleBankCommission: data?.double_bank_Commission || 0,
				costoTraslado: data?.cost_transfer || 0,
				porcentajeTraslado: data?.percentage_transfer || 0,
				costoExtra: data?.extra_cost || 0,
				costoProvedor4ST: data?.supplier_expense_4ST_value || 0,
				costoEfectivo: data?.cost_effective_value || 0,
				costoFID: data?.fid_expense_value || 0,
				costoSC: data?.sc_expense_value || 0,
				costoSindicato: data?.union_expense_value || 0,
				comisionSpei: data?.spei_commission_value || 0,
				comisionEdenredKV: data?.comision_edenred_kv || 0,
				comisionEdenredCorpo: data?.comision_edenred_corpo || 0,
				comisionFacil: data?.comision_facil || 0,
				gastosPromotoria: data?.gastos_promotoria || 0,
				gastosAAbonoDeuda: data?.gastos_abono_a_deuda || 0,
				amountEffectivePayer: data?.amount_effectivecost_payer || 0,
				amountFidPayer: data?.amount_fid_payer || 0,
				amountScPayer: data?.amount_sc_payer || 0,
				amountSindicatePayer: data?.amount_sindicate_payer || 0,
				amountSpeiPayer: data?.amount_spei_payer || 0,
				amountEdenredPayer: data?.amount_edenred_payer || 0,
				AmountEdenredCorpoPayer: data?.amount_edenred_corpo_payer || 0,
				amountFacilPayer: data?.amount_facil_payer || 0,
				payerEffective: data?.payer_effectivecost_payer || 0,
				payerFid: data?.payer_fid_payer || '',
				payerSC: data?.payer_sc_payer || '',
				payerSindicate: data?.payer_sindicate_payer || '',
				payerSpei: data?.payer_spei_payer || '',
				payerEdenred: data?.payer_edenred_payer || '',
				payerEdenredCorpo: data?.payer_edenred_corpo_payer || '',
				payerFacil: data?.payer_facil_payer || '',
				bankCommissionSafeguard: data?.bank_commission_safeguards || 0,
				secondBankCommissionSafeguard:
					data?.second_bank_commission_safeguards || 0,
				corporativeCostSafeguard:
					data?.expenses_corporative_safeguards || 0,
			})
		);

		const tempObj = {
			comisionesConvencionales: data?.conventional_commissions || 0,
			costoTraslado: data?.cost_transfer || 0,
			comisionesBancarias: data?.bank_commission_value || 0,
			secondBankCommission: data?.second_bank_commission || 0,
			costoProvedor4ST: data?.supplier_expense_4ST_value || 0,
			costoEfectivo: data?.cost_effective_value || 0,
			costoFID: data?.fid_expense_value || 0,
			costoExtra: data?.extra_cost || 0,
			costoSC: data?.sc_expense_value || 0,
			costoSindicato: data?.union_expense_value || 0,
			comisionSpei: data?.spei_commission_value || 0,
			comisionEdenredKV: data?.comision_edenred_kv || 0,
			comisionEdenredCorpo: data?.comision_edenred_corpo || 0,
			comisionFacil: data?.comision_facil || 0,
		};

		const totalExpenses = Object.values(tempObj).reduce(
			(a, b) => a + Number(b),
			0
		);

		dispatch(updateOperatingExpensesTotal(totalExpenses));

		const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
		await delay(100);
		setLoading(false);

		return totalExpenses;
	};

	useEffect(() => {
		// Get application info
		getApplicationInfo(idApplication).then(async ({ data }) => {
			dispatch(setCurrentApplication(data));
			setApplication(data);

			await handleGetDeposits(data?.id);
			// totalOperatingExpenses
			const expenses = await handleSetOperativeExpenses(data);
			// totalCommissionScheme
			await handleGetReturnCommissions(data?.id, expenses);
			// totalPromotersCommision
			await handleGetPromoters(data?.id);
			// totalDebtsCommision
			await handleGetDebts(data?.id);
			// handle Administrative Costs
			await handleGetAdministrativeCosts(data?.id);

			// handle Administrative Costs Withdraw
		});

		getSchemes().then((response) => {
			dispatch(setScheme({ scheme: response.data.results }));
		});
		//llamada a la API de los esquemas
		// Load catalogs for deposit form
		dispatch(loadCatalogsForDepositForm());

		// Set current tab
		setCurrentTabLocal(currentTab);
	}, []);

	useEffect(() => {
		getApplicationInfo(idApplication).then(async ({ data }) => {
			await handleGetSafeguardsPerClient(data);
			await handleAvailableKVObservations(data);
		});
	}, []);

	// Update current tab on Redux
	useEffect(() => {
		const finalTab = currentTabLocal === 6 ? 0 : currentTabLocal;
		dispatch(setCurrentTab(finalTab));
		localStorage.setItem('currentTab', finalTab);
	}, [currentTabLocal]);

	useEffect(() => {
		// console.log("applicacion nombre",application.folio)
	}, []);

	return (
		<Box sx={{ width: '100%' }}>
			{loading ? 
                <Loader/>
            :
				application ? (
					<>
						{/* Header for all applications */}
						<StackApplication
							formatDateTimeMX={formatDateTimeMX}
							application={application}
						/>
						{application.folio === 'SOLICITUD DE RETIRO' ? (
							<WithdrawalApplicationTabs
								currentTabLocal={currentTabLocal}
								handleChange={handleChange}
								applicationTemp={applicationTemp}
								application={application}
								handleGetDeposits={handleGetDeposits}
								handleGetReturnCommissions={
									handleGetReturnCommissions
								}
							/>
						) : (
							<ConventionalAndSafeguardApplications
								currentTabLocal={currentTabLocal}
								handleChange={handleChange}
								applicationTemp={applicationTemp}
								application={application}
								handleGetDeposits={handleGetDeposits}
								handleGetReturnCommissions={
									handleGetReturnCommissions
								}
							/>
						)}
					</>
				) : (
					<div style={{ padding: '40px' }}>
						<Alert severity='warning'>
							No existe el usuario con ID: {idApplication}
						</Alert>
					</div>
				)
			}
		</Box>
	);
};
