import React from 'react';
import { deleteConsCompany, getConsCompanies } from '../../actions/catalogs';
import { ConsCompaniesTable } from './ConsCompaniesTable';

// Companies Contra Table
export const ConsCompany = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getConsCompanies,
		deleteCatalog: deleteConsCompany,
		catalogSingular: 'Empresa Contra',
		catalogPlural: 'Empresas Contra',
		pronounSingular: 'la',
		pronoun: 'a',
	};

	// Configuration for the Details Table
	const detailGridConfig = {
		gridItems: [
			{ xsWidth: 8, title: 'NOMBRE COMPLETO', code: 'name' },
			{ xsWidth: 4, title: 'NOMBRE CORTO', code: 'shortname' },
			{ xsWidth: 6, title: 'ESTATUS', code: 'status' },
			{ xsWidth: 6, title: 'TIPO DE EMPRESA', code: 'company_type' },
			{ xsWidth: 4, title: 'NOMBRE COSTO', code: 'cost_name' },
			{ xsWidth: 4, title: 'PORCENTAJE COSTO', code: 'cost' },
			{ xsWidth: 4, title: 'NOMBRE GASTO', code: 'expense_name' },
			{ xsWidth: 4, title: 'PORCENTAJE GASTO', code: 'expense' },
			{ xsWidth: 4, title: 'SERVIDOR DE OPERACIÓN', code: 'operation_server' },
			{ xsWidth: 8, title: 'CONCEPTO', code: 'concept' },
			{ xsWidth: 12, title: 'DESCRIPCIÓN', code: 'description' },
		],
	};

	return (
		<ConsCompaniesTable
			config={configuration}
			detailGridConfig={detailGridConfig}
		/>
	);
};
