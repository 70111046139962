import React, { useEffect, useState } from 'react';
import {
    Alert,
    Button,
    Tooltip,
    Stack,
    Typography,
    TextField,
} from '@mui/material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import moment from 'moment';
import { money } from '../helpers/money';
import {
    getResults,
    getReturnsAndCommissionsByApplicationID,
} from '../actions/applications';
import { useSelector } from 'react-redux';
import {
    getPromotersTotals,
    getSafeguards,
} from '../actions/returnAndCommissions';
import { getClients } from '../actions/catalogs';
import { SafeguardsResultsDialog } from '../components/results/SafeguardsResultsDialog';
import { PromotersResultsDialog } from '../components/results/PromotersResultsDialog';

const checkImportFunding = (item) => {
    if (item?.company_type == 'KV') {
        return '-';
    } else if (item?.company_type == 'KV + Grupo') {
        return '-';
    } else if (item?.company_type == 'Grupo') {
        return formatterCurrency(item?.import_funding);
    }
};

// Results table
export const PromotersResults = () => {
    // Date state
    const [date, setDate] = useState(null);
    // Date range state
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const ranges = { startDate, setStartDate, endDate, setEndDate };

    // Rows state
    const [rows, setRows] = useState(null);

    // Current application
    const [currentApplicationId, setCurrentApplicationId] = useState(null);
    const { application = null } = useSelector((state) => state.request);

    // Deposits dialog
    const [openDepositsDialog, setOpenReturnsAndfundings] = useState(false);
    const handleOpenReturnsAndFundingsDialog = () =>
        setOpenReturnsAndfundings(true);
    const handleCloseReturnsAndFundingsDialog = () =>
        setOpenReturnsAndfundings(false);

    const [currentRowInfo, setCurrentRowInfo] = useState(null);

    const [promotersTemp, setPromotersTemp] = useState(null);

    const getTotalSafeguardPerRow = async (applicationId) => {
        const result = await getReturnsAndCommissionsByApplicationID(
            applicationId
        );
        let filteredSafeguards = result?.data?.results.filter(
            (element) => element.scheme == 'RESGUARDO'
        );
        const totalSafeguards = filteredSafeguards?.reduce(
            (accumulator, object) => {
                return accumulator + Number(object.balance);
            },
            0
        );

        return totalSafeguards;
    };

    // Generate rows
    const generateRows = async (formattedStartDate, formattedEndDate) => {
        // Get results by date from API
        const { data } = await getResults(formattedStartDate, formattedEndDate);
        // const { data } = await getResults(formattedStartDate);
        // If results are empty set an empty array
        if (data?.results?.length === 0 || !data?.ok) setRows([]);

        const genericPromoter = {
            name: '-',
            commission: '-',
            percentage: '-',
            base_commission: '-',
        };

        // Map data results
        const res = data?.results.map((item) => {
            return {
                ...item,
            };
        });

        // Set mapped rows
        setRows(res);
    };

    // Handle get results
    const handleGetResults = () => {
        // Date format to YYYY-MM-DD
        const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
        const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

        // Generate rows from formatted dates
        generateRows(formattedStartDate, formattedEndDate);
    };

    const columns = [
        {
            field: 'client_application',
            headerName: 'Promotor',
            ...columnStyleCell,
            width: 500,
            renderCell: ({ row }) => (
                <div style={rowDialogStyle}>{row?.name}</div>
            ),
        },

        {
            field: 'sum',
            headerName: 'Monto',
            ...columnStyleCell,
            width: 500,
            renderCell: ({ row }) => (
                <Tooltip title='Ver Montos'>
                    <div style={rowDialogStyle}>
                        <span className='table-cell-trucate'>
                            {money(row.sum)}
                        </span>
                        <Button
                            variant='text'
                            style={buttonDialogStyle}
                            onClick={() => {
                                setCurrentRowInfo({
                                    promoterName: row.name,
                            
                                });
                                handleOpenReturnsAndFundingsDialog();
                            }}
                        >
                            Ver
                        </Button>
                    </div>
                </Tooltip>
            ),
        },
    ];

    useEffect(() => {
        // Get the sum of promoters
        getPromotersTotals().then(({ data }) => {
            let tempDataSafeguards = data.results;

            const fixedObjects = tempDataSafeguards.map((element, index) => {
                return {
                    ...element,
                    id: index,
                };
            });

            setPromotersTemp(fixedObjects);
        });
    }, []);

    return (
        <div style={divStyle}>
            <PromotersResultsDialog
                open={openDepositsDialog}
                handleClose={handleCloseReturnsAndFundingsDialog}
                rowInfo={currentRowInfo}
            />

            <Stack
                direction='row'
                alignItems='flex-end'
                justifyContent='space-between'
                style={stackStyle}
            >
                <Typography
                    variant='h4'
                    gutterBottom
                >
                    Tabla de Promotores
                </Typography>
            </Stack>
            <hr />

            <Typography variant='h6'>Solicitudes de día</Typography>

            <div style={datagridTableStyle}>
                {/* Datagrid table */}
                {promotersTemp?.length > 0 ? (
                    <DataGrid
                        autoHeight
                        // style={{ height: '600px' }}
                        rowHeight={50}
                        rows={promotersTemp}
                        components={{ Toolbar: GridToolbar }}
                        columns={columns}
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                    />
                ) : (
                    <>
                        {rows === null ? (
                            <Alert severity='success'>
                                Busca todas las solicitudes de un día
                            </Alert>
                        ) : (
                            <Alert severity='info'>
                                No hay ninguna solicitud
                            </Alert>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

const SelectComponent = (params, label) => (
    <TextField
        fullWidth
        required
        margin='normal'
        {...params}
        label={label}
    />
);

const rowDialogStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
};

const stackStyle = {
    padding: '20px 20px 0 0',
    margin: '0 0 40px 0',
    width: '100%',
};

const divStyle = {
    padding: '20px',
};

const gridStyle = {
    width: '70%',
    display: 'flex',
    justifyContent: 'flex-end',
};

const gridButtonStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const datagridTableStyle = { margin: '20px 0' };

const columnStyleCell = {
    headerClassName: 'column-header-pilu',
    headerAlign: 'center',
};

const Cell = ({ subtitle, text = '', lastBreaks = true }) => (
    <>
        <b>{subtitle}:</b>
        <br />
        {text ? (text === 'No lleva' ? '' : text) : 'No se asignó'}
        {lastBreaks && <br />}
    </>
);

const buttonDialogStyle = {
    // backgroundColor: '#143f6c',
    margin: '0 0 0 10px',
};
