import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';
import LinkIcon from '@mui/icons-material/Link';
import {
    Alert,
    Box,
    Button,
    DialogActions,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from '@mui/material';
import { useState, useEffect } from 'react';
import {
    getApplicationInfo,
    getApplicationInfoKey,
    getDepositsByApplicationID,
    getReturnsAndCommissionsByApplicationID,
} from '../../actions/applications';
import moment from 'moment';
import { money } from '../../helpers/money';

import {
    checkCompanyFunding,
    checkImportFunding,
} from '../../helpers/ApplicationDetailCalculus';
import { getFundingsFiltered } from '../../actions/funding';
import { banksTranslate } from '../../helpers/banksTranslate';
import { getBanks, getClients } from '../../actions/catalogs';
import {
    getReturnAndCommissionInfo,
    getReturnAndCommissionKey,
    getSafeguardsPerClient,
} from '../../actions/returnAndCommissions';
import { handleError } from '../../helpers/utils';

export const SafeguardsResultsDialog = ({
    open,
    handleClose,
    applicationId = null,
    rowInfo = { client: 'temp', clientName: 'loading' },
}) => {
    const [deposits, setDeposits] = useState([]);
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const [titleName, setTitleName] = useState('Cargando...');

    /**
     * Fetch Safeguards by Client Effect.
     *
     * This `useEffect` function retrieves safeguards data for a specific client (rowInfo.client) and updates the component state with the fetched data. It is triggered whenever the 'rowInfo' object changes.
     *
     * @param {object} rowInfo - Information about the selected row.
     */
    useEffect(() => {
        // Check if 'rowInfo' is available
        if (rowInfo) {
            // Fetch safeguards data for the specified client from the API
            getSafeguardsPerClient({ client: rowInfo.client })
                .then(({ data }) => {
                    // Update component state with the fetched safeguards data
                    setRows(data.results);

                    // Set the title name for the component to the client's name from 'rowInfo'
                    setTitleName(rowInfo.clientName);
                })
                .catch((error) => {
                    // Handle errors that occur during the API request, if necessary
                    handleError(error, 'Error buscando resguardos.');
                });
        }
    }, [rowInfo]);

    return (
        <Dialog
            maxWidth='lg'
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            {/* Content */}
            <Box sx={{ flexGrow: 1 }}>
                <div style={{ padding: '20px' }}>
                    <Text
                        variant={'h6'}
                        text={titleName}
                        bold={false}
                    />

                    {rows?.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table
                                sx={{ width: '100%' }}
                                aria-label='simple table'
                            >
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align='center'>
                                            #
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Fecha
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Solicitud
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Monto
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((returnCommission, index) => (
                                        <StyledTableRow
                                            key={returnCommission.key}
                                            sx={styleLabel}
                                        >
                                            <ColumnItem
                                                align='center'
                                                text={index + 1}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={
                                                    moment(
                                                        returnCommission?.created_at
                                                    ).format('DD/MM/YYYY') ??
                                                    'Empty'
                                                }
                                            />
                                            <TableCell>
                                                <Button
                                                    onClick={() => {
                                                        navigate(
                                                            `/dashboard/application/${returnCommission.application.id}`
                                                        );
                                                    }}
                                                >
                                                    {returnCommission
                                                        ?.application?.key ??
                                                        'Empty'}
                                                    <LinkIcon fontSize='small' />
                                                </Button>
                                            </TableCell>

                                            <ColumnItem
                                                align='center'
                                                text={
                                                    money(
                                                        returnCommission?.balance
                                                    ) ?? 'Empty'
                                                }
                                            />
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Alert severity='info'>No hay Resguardos</Alert>
                    )}
                </div>
            </Box>
            <DialogActions>
                <Button onClick={() => handleClose()}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

const ColumnItem = ({ align, text }) => (
    <StyledTableCell align={align}>{text}</StyledTableCell>
);

const styleLabel = {
    '&:last-child td, &:last-child th': { border: 0 },
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction='up'
            ref={ref}
            {...props}
        />
    );
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#01579b',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const Text = ({ variant, text, bold = true }) => (
    <Typography
        variant={variant}
        gutterBottom
    >
        {bold ? <b>{text}</b> : text}
    </Typography>
);
