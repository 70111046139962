import React from 'react';
import {
	deleteScheme,
	getSchemeInfo,
	getSchemes,
	registerScheme,
	searchScheme,
	updateScheme,
} from '../../actions/catalogs';
import { SimpleCatalogTable } from './SimpleCatalogTable';

// Schemes Table
export const Scheme = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getSchemes,
		getCatalogInfo: getSchemeInfo,
		deleteCatalog: deleteScheme,
		updateCatalog: updateScheme,
		registerCatalog: registerScheme,
		searchCatalog: searchScheme,
		catalogSingular: 'Esquema',
		catalogPlural: 'Esquemas',
		pronounSingular: 'el',
		pronoun: 'o',
	};

	return <SimpleCatalogTable config={configuration} />;
};
