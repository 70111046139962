/**
 * Calculates the available KV based on various financial factors.
 *
 * This function takes multiple financial parameters as input and calculates the available KV based on specific rules.
 * The available KV represents the remaining value after deducting various expenses and commitments from the total commission.
 *
 * @param {number} totalCommission - The total commission earned.
 * @param {number} totalOperativeExpenses - The total operative expenses.
 * @param {number} totalPromoter - The total promoter-related expenses.
 * @param {number} totalCreditToDebt - The total credit-to-debt expenses.
 * @param {boolean} switchAvailableKV - A switch to control whether to consider administrative expenses.
 * @param {number} totalAdministrativeExpenses - The total administrative expenses (optional if 'switchAvailableKV' is true).
 * @returns {number} The available KV after deducting expenses and commitments.
 */
export const calculateAvailableKV = (
    applicationVariables,
    switchAvailableKV,
    typeOfInvoice
) => {
    const {
        totalCommissionScheme,
        totalOperativeExpenses,
        totalPromoter,
        totalCreditToDebt,
        totalAdministrativeExpenses,
        totalDeposits,
    } = applicationVariables;

    //if the type of application is "Comisión directa", then makes all the calculus below, else are the other invoices types of applications
    if (typeOfInvoice == 'COMISIONES DIRECTAS') {
        if (switchAvailableKV) {
            // Deduct total expenses and commitments including administrative expenses if 'switchAvailableKV' is true
            return (
                totalCommissionScheme -
                totalOperativeExpenses -
                totalPromoter -
                totalCreditToDebt -
                totalOperativeExpenses - // Repeated here intentionally
                totalAdministrativeExpenses
            );
        } else {
            // Deduct total expenses and commitments without considering administrative expenses
            return (
                totalDeposits -
                totalOperativeExpenses -
                totalCommissionScheme -
                totalCreditToDebt
            );
        }
    } else {
        if (switchAvailableKV) {
            // Deduct total expenses and commitments including administrative expenses if 'switchAvailableKV' is true
            return (
                totalCommissionScheme -
                totalOperativeExpenses -
                totalPromoter -
                totalCreditToDebt -
                totalOperativeExpenses - // Repeated here intentionally
                totalAdministrativeExpenses
            );
        } else {
            // Deduct total expenses and commitments without considering administrative expenses
            console.log(`totalCommissionScheme ${totalCommissionScheme} -
                totalOperativeExpenses ${totalOperativeExpenses} -
                totalPromoter ${totalPromoter} -
                totalCreditToDebt ${totalCreditToDebt}-
                totalAdministrativeExpenses ${totalAdministrativeExpenses}`)
            return (
                totalCommissionScheme -
                totalOperativeExpenses -
                totalPromoter -
                totalCreditToDebt -
                totalAdministrativeExpenses
            );
        }
    }
};




