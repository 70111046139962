import React, { useEffect, useState } from 'react';
import {
    Button,
    Stack,
    Typography,
    Tooltip,
    IconButton,
    Alert,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { money } from '../helpers/money';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateAvailableKV,
    updateCreditToDebt,
    updatePerceptionsInvoice,
} from '../actions/requests';
import {
    handleUpdateFieldApp, setCurrentApplication,
    updateApplication
} from '../actions/applications';
import {
    handleConfirmModal,
    handleError,
    handleSuccess,
} from '../helpers/utils';
import { NewPagadoraDisponible2 } from './../components/applications/NewPagadoraDisponible2';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { calculateAvailableKV } from '../helpers/totalAvailableKV';
import { PayersDialog } from '../components/applications/PayersDialog';
import { FundingsAvailableOE } from '../components/applications/FundingsAvailableOE';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { deleteFunding } from '../actions/funding';
import DeleteIcon from '@mui/icons-material/Delete';
import { saveApplication } from '../helpers/SaveApplication';

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

// Users table
export const DisponibleKV = ({ banks = [], fundingsPassed = [] }) => {
    const dispatch = useDispatch();

    // Handle Dialog
    const [openModal, setOpenModal] = React.useState(false);
    const handleCloseDialog = () => setOpenModal(false);
    const [change, setChange] = React.useState(false);
    const handleDelete = () => {
        setChange(!change);
    };

    //New Payers Dialog
    const [showPayersDialog, setShowPayersDialog] = useState(false);
    const handleClose = () => {
        setShowPayersDialog(false);
    };
    const handleOpen = () => {
        setShowPayersDialog(true);
    };

    //See PayersDialog
    const [openPayerDialogReturn, setOpenPayerDialogReturn] = useState(false);
    const handleClosePayerDialog = () => {
        setOpenPayerDialogReturn(false);
    };

    const [editObservations, setEditObservations] = React.useState(false);
    const [observations, setObservations] = React.useState(false);
    const [depositCorrect, setDepositCorrect] = useState('');
    const [fixedFundings, setFixedFundings] = useState(null);

    const depositTotals = useSelector((state) => state.request.depositTotal);

    const applicationTemp = useSelector((state) => state.request.application);
    const { application = null } = useSelector((state) => state.request);
    const availableKV = useSelector((state) => state.request.availableKV);
    const creditToDebt = useSelector((state) => state.request.creditToDebt);
    const returnAndCommission = useSelector(
        (state) => state.request.returnAndCommission
    );
    const applicationInvoice = useSelector(
        (state) => state.request.application.folio
    );
    const totalDeposits = useSelector((state) => state.request.depositTotal);

    // Update when component unmount
    useEffect(() => {
        return () => {
            dispatch(saveApplication());
        };
    },[]);

    // const handleObservations = (value) => {
    //     const tempObj = { ...availableKV };
    //     tempObj.observations = value;
    //     dispatch(updateAvailableKV(tempObj));
    // };

    //calculates the sum of all fundings imports
    const sumAllPayers = (credit) => {
        return credit.reduce((sum, payer) => {
            return sum + Number(payer.amount);
        }, 0);
    };

    const setAvailableToggle = (event) => {
        const applicationVariables = {
            totalCommissionScheme: application.total_commission_scheme,
            totalOperativeExpenses: application.total_operative_expenses,
            totalPromoter: application.total_promoter,
            totalCreditToDebt: application.total_credit_to_debt,
            totalAdministrativeExpenses:
                application.total_administrative_expenses,
            totalDeposits,
        };
        updateApplication(application.id, {
            ...application,
            account_kv: event.target.checked,
            total_available_KV: Number(
                calculateAvailableKV(
                    applicationVariables,
                    event.target.checked,
                    applicationInvoice
                ).toFixed(2)
            ),
        }).then(({ data }) => {
            dispatch(
                setCurrentApplication({
                    ...data,
                    fundings: application.fundings,
                    promoter: application.promoter,
                    executive: application.executive,
                })
            );
            handleSuccess('Disponible KV GUARDADO');
        });
    };

    useEffect(() => {
        // Sum of all commissions
        const totalCommission = creditToDebt.creditArray.reduce(
            (accumulator2, object) => {
                return accumulator2 + Number(object.commission);
            },
            0
        );

        // Calculate sum of all amounts on all objects
        const totalAmount = creditToDebt.creditArray.reduce((sum, credit) => {
            return sum + Number(sumAllPayers(credit.fundings));
        }, 0);

        const tempObj = {
            ...creditToDebt,
            commissionTotal: totalCommission,
            amountTotal: totalAmount,
        };

        dispatch(updateCreditToDebt(tempObj));
    }, [change]);
    // Updates the total Available KV on all tabs
    useEffect(() => {
        setObservations(application?.observations);
    }, []);

    // Calculate "SALDO POR ASIGNAR" variable
    useEffect(() => {
        // Formula: Sum of all deposits - Sum of all balance
        const totalBalance = returnAndCommission.reduce(
            (accumulator, object) => {
                return accumulator + Number(object?.balance || 0);
            },
            0
        );

        const decition = Number(depositTotals - totalBalance).toFixed(2);
        if (decition < -1) {
            setDepositCorrect(decition);
        } else if (decition > 1) {
            setDepositCorrect(decition);
        } else {
            setDepositCorrect(0);
        }
    }, [returnAndCommission, depositTotals]);

    const handleDeleteFunding = (id, applicationTemp) => {
        // Perform action delete
        handleConfirmModal(
            'Precaución',
            '¿Seguro que deseas borrar el fondeo?',
            'warning',
            'Sí, borrar'
        ).then(({ isConfirmed }) => {
            if (isConfirmed) {
                deleteFundingAction(id, applicationTemp);
            }
        });
    };

    const deleteFundingAction = async (id, applicationTemp) => {
        try {
            // Delete funding (DB)
            await deleteFunding(id)
                .then(({ data }) => {
                    const deletedElement = applicationTemp.fundings.filter(
                        (element) => element.id !== id
                    );

                    handleSuccess('Fondeo eliminado exitosamente');
                    dispatch(
                        updatePerceptionsInvoice({
                            ...applicationTemp,
                            fundings: deletedElement,
                        })
                    );
                })
                .catch((error) => {});

            // Get updated register (DB)

            // Feedback success
            // handleSuccess("El fondeo se eliminó correctamente");

            // Close modal
        } catch (error) {
            // Feedback error
            handleError(error, errorMsg);
        }
    };

    const EraseComments = () => {
        handleUpdateFieldApp(application, 'observations', '')
            .then(() => {
                handleSuccess('Observaciones Eliminadas');
            })
            .catch((error) => {
                handleError(
                    error,
                    'Ocurrió un error al intentar guardar las observaciones'
                );
            });
        handleObservations('');
        setObservations('');
        setEditObservations(false);
    };

    const handleSafeComments = () => {
        if (editObservations) {
            handleUpdateFieldApp(application, 'observations', observations)
            .then(() => {
                    console.log("estas son observations",observations)
                    handleSuccess('Observaciones guardadas');
                    dispatch(updateAvailableKV({ ...availableKV, observations }));
                })
                .catch((error) => {
                    handleError(
                        error,
                        'Ocurrió un error al intentar guardar las observaciones'
                    );
                });
        }
        setEditObservations(!editObservations);
    };


    useEffect(() => {
      console.log("observaciones:",availableKV.observations)
    }, [])
    

    useEffect(() => {
        setFixedFundings(
            fundingsPassed.map((fundingElement) => {
                return {
                    ...fundingElement,
                    bank: banks?.find(
                        (element) => element.id == fundingElement.bank
                    )?.name,
                };
            })
        );
    }, [fundingsPassed, banks]);

    return (
        <div>
            <Stack
                direction='row'
                alignItems='flex-end'
                justifyContent='space-between'
                style={{
                    border: '1px solid white',
                    padding: '5px',
                    width: '100%',
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={7}
                        >
                            <Typography
                                variant='h5'
                                gutterBottom
                            >
                                Disponible KV
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={5}
                        >
                            <Typography
                                variant='h6'
                                gutterBottom
                            >
                                {depositCorrect == 0
                                    ? `OPERACIÓN CORRECTA`
                                    : depositCorrect > 1
                                    ? `SALDO POR ASIGNAR (${money(
                                          depositCorrect
                                      )})`
                                    : `SALDO INSUFICIENTE - VERIFICAR (${money(
                                          depositCorrect
                                      )})`}
                            </Typography>
                        </Grid>

                        <Box sx={{ flexGrow: 1, ml: 2, mb: 4 }}>
                            <Grid
                                container
                                spacing={2}
                                sx={{ mt: 1 }}
                            >
                                <Grid
                                    item
                                    xs={1}
                                >
                                    <Button
                                        sx={{ fontSize: '12px' }}
                                        variant='contained'
                                        onClick={() => {
                                            handleOpen();
                                        }}
                                    >
                                        Nuevo fondeo
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <>
                                        <Typography variant='p'>
                                            <b>Saldo total</b>
                                        </Typography>
                                        <Typography variant='h6'>
                                            {money(
                                                application.total_available_KV
                                            )}
                                        </Typography>
                                    </>
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                >
                                    <Typography
                                        variant='h5'
                                        component='div'
                                        sx={{ marginBottom: '10px' }}
                                    >
                                        Fondeos
                                    </Typography>
                                    <Card>
                                        <CardContent>
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                {fixedFundings?.length > 0 ? (
                                                    fixedFundings
                                                        ?.filter(
                                                            (element) =>
                                                                element.ope ==
                                                                'AKV'
                                                        )
                                                        ?.map((funding) => (
                                                            <React.Fragment
                                                                key={
                                                                    funding?.id
                                                                }
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                >
                                                                    <IconButton
                                                                        style={{
                                                                            color: '#143f6c',
                                                                        }}
                                                                        aria-label='delete'
                                                                        color='primary'
                                                                        onClick={() => {
                                                                            handleDeleteFunding(
                                                                                funding?.id,
                                                                                applicationTemp
                                                                            );
                                                                        }}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={3}
                                                                >
                                                                    <ItemTexts
                                                                        title='Pagadora'
                                                                        text={
                                                                            funding?.payer
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={3}
                                                                >
                                                                    <ItemTexts
                                                                        title='Banco'
                                                                        text={
                                                                            funding?.bank ??
                                                                            'Cargando...'
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                >
                                                                    <ItemTexts
                                                                        title='Importe'
                                                                        text={money(
                                                                            funding?.amount
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </React.Fragment>
                                                        ))
                                                ) : (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                    >
                                                        <Alert severity='info'>
                                                            No hay ningun fondeo
                                                        </Alert>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{
                                            marginBottom: '10px',
                                            marginLeft: '5px',
                                        }}
                                    >
                                        {application.folio !== 'COMISIONES DIRECTAS' ? (
                                            <>
                                                <Grid
                                                    item
                                                    xs={3}
                                                >
                                                    <Typography>
                                                        Gastos Op.
                                                    </Typography>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                        padding: 0,
                                                        margin: '15px 0',
                                                        width: '40%',
                                                    }}
                                                >
                                                    <FormControl
                                                        fullWidth
                                                        variant='standard'
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Android12Switch
                                                                    checked={
                                                                        application.account_kv
                                                                    }
                                                                    size={
                                                                        'large'
                                                                    }
                                                                    onChange={async (
                                                                        event
                                                                    ) => {
                                                                        setAvailableToggle(
                                                                            event
                                                                        );
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        ) : (
                                            <></>
                                        )}

                                        <Grid
                                            item
                                            xs={6}
                                        ></Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography variant='p'>
                                        <b>Observaciones/Comentarios</b>
                                    </Typography>
                                    <br />
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{
                                            marginBottom: '10px',
                                            marginLeft: '5px',
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <Tooltip title='Editar'>
                                                <IconButton
                                                    style={{ color: '#143f6c' }}
                                                    aria-label='edit'
                                                    color='primary'
                                                    onClick={() => {
                                                        handleSafeComments();
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title='Eliminar'>
                                                <IconButton
                                                    style={{ color: '#143f6c' }}
                                                    aria-label='delete'
                                                    color='primary'
                                                    onClick={() =>
                                                        EraseComments()
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={11}
                                        >
                                            {editObservations ? (
                                                <TextField
                                                    style={{
                                                        margin: '5px 0 0 0',
                                                    }}
                                                    fullWidth
                                                    id='outlined-multiline-static'
                                                    placeholder='Escriba observaciones o comentarios finales sobre la soliciud'
                                                    multiline
                                                    rows={4}
                                                    defaultValue={
                                                        availableKV.observations
                                                    }
                                                    onChange={(e) => {
                                                        setObservations(
                                                            e.target.value
                                                        );
                                                    }}
                                                    onBlur={() => {
                                                        handleSafeComments();
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        margin: '10px 0 5px 0',
                                                    }}
                                                >
                                                    <Typography variant='p'>
                                                        {observations.length > 0
                                                            ? availableKV.observations
                                                            : 'No hay comentarios'}
                                                    </Typography>
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <NewPagadoraDisponible2
                        show={openModal}
                        handleClose={handleCloseDialog}
                        handleChange={handleDelete}
                    />

                    <PayersDialog
                        show={showPayersDialog}
                        handleClose={handleClose}
                        typeFunding={'AKV'}
                        concept={'DISPONIBLE KV'}
                    />

                    <FundingsAvailableOE
                        show={openPayerDialogReturn}
                        handleClose={handleClosePayerDialog}
                        fundings={fundingsPassed}
                        typeFunding={'AKV'}
                        tempBanks={banks}
                    />
                </Box>
            </Stack>
            <hr />
        </div>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);
