import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Alert, Grid,
	IconButton, Typography
} from '@mui/material';
import { money } from '../../helpers/money';
import { useSelector, useDispatch } from 'react-redux';
import {
	refreshReturnAction
} from '../../actions/requests';
import { deleteFunding } from '../../actions/funding';
import {
	handleError,
	handleSuccess,
	handleConfirmModal,
} from '../../helpers/utils';
import { getReturnAndCommissionInfo } from '../../actions/returnAndCommissions';

const arrBases = [
	{ label: 'TOTAL DEPOSITO' },
	{ label: 'SUBTOTAL DEPOSITO' },
	{ label: 'RETORNO' },
	{ label: 'COMISION TOTAL' },
	{ label: 'COMISION - BANCARIA' },
	{ label: 'COMISION - BANCARIA - COSTO EFECTIVO CLIENTE' },
	{ label: 'COMISION - COSTO EFECTIVO CLIENTE' },
	{ label: 'COMISION - COSTOS OPERATIVOS' },
	{ label: 'COMISION - COSTO SC' },
	{ label: 'COMISION - 2% EDEN RED' },
	{ label: 'COMISION - 2% EDEN RED - BANCARIA' },
	{ label: 'COMISION - 2% EDEN RED - COM. CONTACTO - BANCARIA' },
	{ label: 'COMISION - PROMOTORIA 1 - BANCARIA' },
	{ label: 'COMISION - PROMOTORIA 2 - BANCARIA' },
	{ label: 'COMISION - PROMOTORIA 3 - BANCARIA' },
	{ label: 'COMISION - COSTO SC - PROMOTORIA 1' },
	{ label: 'RETORNO + 10% SUBTOTAL' },
];

// Error message
const errorMsg =
	'Ocurrió un error al intentar eliminar el fondeo. Intenta más tarde.';

export const PayerReturnDialog = ({
	currentRow = null, // Current return and commission register
	fundings = [],
	show = false,
	handleClose = null,
	handleChange = null,
	updateTotals = null,
}) => {
	// Dispatch
	const dispatch = useDispatch();

	// Redux state
	const { returnAndCommission: rows = [] } = useSelector(
		(state) => state.request
	);
    const tempBanks = useSelector((state) => state.catalogs.banks);

	// Handle delete funding
	const handleDeleteFunding = (id) => {
		// Perform action delete
		handleConfirmModal(
			'Precaución',
			'¿Seguro que deseas borrar el fondeo?',
			'warning',
			'Sí, borrar'
		).then(({ isConfirmed }) => {
			if (isConfirmed) {
				deleteFundingAction(id);
			}
		});
	};

	

	// Delete funding from DB and update state
	const deleteFundingAction = async (id) => {
		try {
			// Delete funding (DB)
			await deleteFunding(id);

			// Get updated register (DB)
			const updatedRegister = (
				await getReturnAndCommissionInfo(currentRow?.id)
			).data;

			// Update specific row
			const updatedRows = rows.map((row) =>
				row?.id === currentRow?.id ? updatedRegister : row
			);

			// Update redux state
			dispatch(refreshReturnAction(updatedRows));

			// Feedback success
			handleSuccess('El fondeo se eliminó correctamente');

			// Close modal
			handleClose();

			// Update totals
			updateTotals();
		} catch (error) {
			// Feedback error
			handleError(error, errorMsg);
		}
	};


	const searchBank = (fundingTemp) => {
        // While loading all banks names renders loading
        // If the funding name is undefined, render a Loading String
        if (
            typeof tempBanks?.find((item) => item?.id === fundingTemp)?.name ===
            'undefined'
        ) {
            return 'Cargando...';
        }
        // Else return the bank name loaded
        return tempBanks?.find((item) => item?.id === fundingTemp)?.name;
    };

	return (
		<Dialog
			maxWidth='sm'
			fullWidth
			open={show}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			{/* Title */}
			<DialogTitle>Información Fondeo</DialogTitle>

			{/* Content */}
			<DialogContent>
				<Grid
					container
					spacing={2}
					style={{
						width: '100%',
						marginBottom: '10px',
					}}
				>
					{fundings.length > 0 ? (
						fundings.map((funding) => (
							<React.Fragment key={funding?.id}>
								<Grid item xs={3}>
									<ItemTexts
										title='Pagadora'
										text={funding.payer}
									/>
								</Grid>
								<Grid item xs={3}>
									<ItemTexts
										title='Banco'
										text={searchBank(funding?.bank)}
									/>
								</Grid>
								<Grid item xs={3}>
									<ItemTexts
										title='Importe'
										text={money(funding.amount)}
									/>
								</Grid>
								<Grid item xs={2}>
									<IconButton
										style={{ color: '#143f6c' }}
										aria-label='delete'
										color='primary'
										onClick={() => {
											handleDeleteFunding(funding?.id);
										}}
									>
										<DeleteIcon />
									</IconButton>
								</Grid>
							</React.Fragment>
						))
					) : (
						<Grid item xs={12}>
							<Alert severity='info'>
								No hay ningun elemento
							</Alert>
						</Grid>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color='primary' onClick={handleClose}>
					Ok
				</Button>
				
			</DialogActions>
		</Dialog>
	);
};

export const ItemTexts = ({ title = '', text = '' }) => (
	<>
		<Typography variant='p'>
			<b>{title}</b>
		</Typography>
		<Typography variant='h6'>{text}</Typography>
	</>
);
