import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik, FormikProvider } from 'formik';
import { handleWarning, mainColor } from '../../helpers/utils';
import { startLoginEmailPassword } from '../../actions/auth';
import { useDispatch } from 'react-redux';

// Copyright component
const Copyright = (props) => {
	return (
		<Typography
			variant='body2'
			color='text.secondary'
			align='center'
			{...props}
		>
			{'Copyright © '}
			<Link color='inherit' href='#' target='_blank'>
				KV
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
};

// Initial values for form
const initialValues = {
	email: '',
	password: '',
};

// Formik schema
const RegisterSchema = Yup.object().shape({
	email: Yup.string().nullable(true),
	password: Yup.string().nullable(true),
});

// MUI theme object
const theme = createTheme({
	status: {
		danger: '#e53e3e',
	},
	palette: {
		primary: {
			main: '#0971f1',
			darker: '#053e85',
		},
		'kv-color': {
			main: mainColor,
			contrastText: '#d60e399f',
		},
	},
});

/* Authentication component for register & login */
export const AuthComponent = () => {
	// Define dispatch obj
	// const dispatch = useDispatch();

	// State to know if user is signing or loggin
	const [isLogin, setIsLogin] = useState(true);
	const dispatch = useDispatch();

	// Define formik object containting the
	// initial values and actions for the form
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: RegisterSchema,
		onSubmit: () => {
			const { email, password } = values;

			if (email.length > 0 && password.length > 0) {
				dispatch(startLoginEmailPassword(email, password));
				formik.resetForm(initialValues);
			} else {
				handleWarning('Ingresa correo y contraseña');
			}
		},
	});

	// Desestructure formik values and methods
	const { getFieldProps, touched, errors, handleSubmit, values } = formik;

	// Text component for reuse
	const TextComp = (name, label, type = 'text') => (
		<TextField
			margin='normal'
			type={type}
			required
			fullWidth
			// style={{ width: '70%' }}
			{...getFieldProps(name)}
			label={label}
			error={Boolean(touched[name] && errors[name])}
			helperText={touched[name] && errors[name]}
		/>
	);

	const imgWallpaper =
		'https://images.pexels.com/photos/590022/pexels-photo-590022.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';

	return (
		<ThemeProvider theme={theme}>
			<FormikProvider value={formik}>
				<Grid container component='main' sx={{ height: '100vh' }}>
					<CssBaseline />
					{/* WALLPAPER BACKGROUND */}
					<Grid
						item
						xs={false}
						sm={4}
						md={7}
						sx={{
							backgroundImage: `url('${imgWallpaper}')`,
							backgroundRepeat: 'no-repeat',
							backgroundColor: (t) =>
								t.palette.mode === 'light'
									? t.palette.grey[50]
									: t.palette.grey[900],
							backgroundSize: 'cover',
							backgroundPosition: 'center',
						}}
					/>

					{/* LOGIN & REGISTER FORM */}
					<Grid
						item
						xs={12}
						sm={8}
						md={5}
						component={Paper}
						elevation={6}
						square
					>
						<Box
							sx={{
								my: 8,
								mx: 4,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							{/* Img Logo */}
							<img
								style={{
									m: 1,
									width: '70%',
									height: '70%',
								}}
								src={'/static/logo-kv.png'}
								alt='avatar'
							/>

							{/* Title */}
							<Typography
								component='h1'
								variant='h5'
								className='mt-3'
							>
								Iniciar sesión
							</Typography>

							{/* Form */}
							<Box component='form' noValidate sx={{ mt: 1 }}>
								{/* Email field */}
								{TextComp('email', 'Correo electrónico')}

								{/* Password field */}
								{TextComp('password', 'Contraseña', 'password')}

								{/* Submit button */}
								<Button
									type='submit'
									fullWidth
									variant='contained'
									sx={{ mt: 3, mb: 2, color: 'white' }}
									color='kv-color'
									onClick={handleSubmit}
								>
									{isLogin ? 'Iniciar sesión' : 'Registrarme'}
								</Button>

								{/* Copyright */}
								<Copyright sx={{ mt: 5 }} />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</FormikProvider>
		</ThemeProvider>
	);
};
