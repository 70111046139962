import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, TextField, Autocomplete } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { refreshReturnAction } from '../../actions/requests';
import {
	getReturnAndCommissionInfo,
	updateReturnAndCommission,
} from '../../actions/returnAndCommissions';
import { registerFunding } from '../../actions/funding';
import { handleError, handleSuccess } from '../../helpers/utils';
import { getPayers } from '../../actions/catalogs';
import CurrencyFormat from 'react-currency-format';

export const NewDialog = ({
	show = false,
	handleClose = null,
	row = null, // Current Return And Comission row
}) => {
	const dispatch = useDispatch();
	const [amount, setAmount] = React.useState('');
	const [payers, setPayers] = useState('');
	const [bank, setBank] = useState('');
	const [payersName, setPayersName] = useState([]);

	const { returnAndCommission: currentRows = [] } = useSelector(
		(state) => state.request
	);
	const { banks = [] } = useSelector((state) => state.catalogs);
	const applicationID = useSelector((state) => state.request.application?.id);
	const application = useSelector((state) => state.request.application);

	/**
 * Update the value of a funding entry and associate it with a specific return and commission record.
 *
 * @throws Error if no record (row) is selected.
 *
 * This function performs the following steps:
 * 1. Checks if a record (row) is selected; if not, throws an error.
 * 2. Creates a new funding record in the database.
 * 3. Retrieves the IDs of existing fundings associated with the selected row.
 * 4. Associates the newly created funding with the selected row by updating the row's fundings list.
 * 5. Retrieves the updated row information from the database.
 * 6. Replaces the old row in the current rows list with the updated row.
 * 7. Updates the Redux state with the refreshed rows list.
 * 8. Provides success feedback upon successful execution.
 * 9. Handles and logs any errors that occur during the process.

 * @async
 * @returns {Promise<void>}
 */
	const updateValue = async () => {
		try {
			// Fail first if no row is selected
			if (!row) throw new Error('Registro no seleccionado');

			// Create a new funding record in the database
			const newFunding = (
				await registerFunding({
					payer: payers,
					bank: banks.find((item) => item.name === bank)?.id,
					amount: parseFloat(amount),
					application_id: applicationID,
					application_tag: application.key,
					client:
						application.client?.id == null
							? application.client
							: application.client?.id,
					concept: 'RETORNO Y COMISIÓN',
				})
			).data;

			// Get and map the IDs of current fundings associated with the selected row
			const currentFundingsId = row.fundings.map(
				(funding) => funding?.id
			);

			// Update the selected row by associating it with the newly created funding
			await updateReturnAndCommission(row?.id, {
				...row,
				application: row.application?.id,
				fundings: [...currentFundingsId, newFunding?.id],
			});

			// Retrieve the updated row information from the database
			const updatedRow = (await getReturnAndCommissionInfo(row?.id)).data;

			// Replace the old row in the current rows list with the updated row
			const newRows = currentRows.map((rowItem) =>
				rowItem?.id === row?.id ? updatedRow : rowItem
			);

			// Update the Redux state with the refreshed rows list
			dispatch(refreshReturnAction(newRows));

			// Provide success feedback upon successful execution
			handleSuccess('Fondeo creado');
		} catch (error) {
			// Handle and log any errors that occur during the process
			console.log(error);
			handleError(
				error,
				'Ocurrió un error al intentar agregar el fondeo'
			);
		}
	};

	/**
	 * Verify the compatibility of selected banks and then trigger the updateValue function.
	 *
	 * This function performs the following steps:
	 * 1. Compares the detected bank in the current application with the selected bank.
	 * 2. If the combination of banks is KUSPIT and STP, it raises an error to indicate an incompatible selection.
	 * 3. If the banks are compatible or not both KUSPIT and STP, it calls the `updateValue` function to proceed with the update.
	 *
	 * @returns {void}
	 */
	const verifyTypeBank = () => {
		const errorPayers = Error(
			'Se creó una mala combinación de STP y KUSPIT entre depósitos y Pagadoras de Retorno'
		);

		// First, verify the combination of banks (KUSPIT or STP) to avoid conflicts
		if (application.bank_detected === 'KUSPIT' && bank === 'STP') {
			// Handle the error when KUSPIT and STP are selected together
			handleError(
				errorPayers,
				'La operación no puede proceder, revise los bancos seleccionados'
			);
		} else if (application.bank_detected === 'STP' && bank === 'KUSPIT') {
			// Handle the error when STP and KUSPIT are selected together
			handleError(
				errorPayers,
				'La operación no puede proceder, revise los bancos seleccionados'
			);
		} else {
			// If banks are compatible or not both KUSPIT and STP, proceed with the update
			updateValue();
		}
	};

	const handleEraseData = () => {
		setAmount(0);
		setPayers('');
		setBank('');
	};

	useEffect(() => {
		getPayers().then(({ data }) => {
			let onlyNamesOfPayers = data.results.map((element) => element.name);
			setPayersName(onlyNamesOfPayers);
		});
	}, []);

	return (
		<Dialog
			open={show}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle>Nuevo Fondeo Retorno - Cliente</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Complete el formulario para crear un nuevo Fondeo
				</DialogContentText>

				<Grid container spacing={2} style={{ marginTop: '15px' }}>
					<Grid item xs={12}>
						<Autocomplete
							isOptionEqualToValue={(option, value) =>
								option?.id === value?.id
							}
							required
							// style={{ marginTop: '20px' }}
							fullWidth
							value={payers}
							onChange={(_, value) => {
								setPayers(value);
							}}
							options={payersName}
							renderInput={(params) =>
								SelectComponent(params, 'Pagadoras')
							}
						/>
					</Grid>

					<Grid item xs={12}>
						<Autocomplete
							isOptionEqualToValue={(option, value) =>
								option?.id === value?.id
							}
							required
							// style={{ marginTop: '20px' }}
							fullWidth
							value={bank}
							onChange={(_, value) => {
								setBank(value);
							}}
							options={banks.map((item) => item.name)}
							renderInput={(params) =>
								SelectComponent(params, 'Banco')
							}
						/>
					</Grid>

					<Grid item xs={12}>
						<CurrencyFormat
							customInput={TextField}
							label='Importe'
							fullWidth
							thousandSeparator={true}
							prefix={'$'}
							allowNegative={false}
							onChange={({ target }) => {
								setAmount(
									Number(
										target.value.replace(/[^0-9.-]+/g, '')
									)
								);
							}}
						/>
						{/* <TextField
                            id='outlined-basic'
                            label='Importe'
                            variant='outlined'
                            type='number'
                            fullWidth
                            onChange={(e) => {
                                setAmount(e.target.value);
                            }}
                        /> */}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						handleClose();
						handleEraseData();
					}}
					color='error'
				>
					Cancelar
				</Button>
				<Button
					disabled={
						String(amount).length === 0 ||
						bank?.length === 0 ||
						payers.length === 0
					}
					onClick={() => {
						//verifyTypeBank()
						updateValue();
						handleClose();
						handleEraseData();
					}}
				>
					Crear
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const SelectComponent = (params, label) => (
	<TextField fullWidth required margin='normal' {...params} label={label} />
);
