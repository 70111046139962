import React from 'react';
import { deleteTransfer, getTransfers } from '../../actions/catalogs';
import { TransfersTable } from './TransfersTable';

// Transfer Table
export const Transfer = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getTransfers,
		deleteCatalog: deleteTransfer,
		catalogSingular: 'Traspaso',
		catalogPlural: 'Traspasos',
		pronounSingular: 'el',
		pronoun: 'o',
	};

	// Configuration for the Details Table
	const detailGridConfig = {
		gridItems: [
			{ xsWidth: 4, title: 'CONCEPTO', code: 'concept' },
			{ xsWidth: 4, title: 'COSTO', code: 'cost' },
			{ xsWidth: 4, title: 'GASTO', code: 'expense' },
			{ xsWidth: 12, title: 'Descripcion', code: 'description' },
		],
	};

	return (
		<TransfersTable
			config={configuration}
			detailGridConfig={detailGridConfig}
		/>
	);
};
