import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startLogout } from '../../../actions/auth';
import Dropdown from 'react-bootstrap/Dropdown';
import { Icon } from '../../utils/UtilsComponent';

const MenuItem = ({ title, icon, action = null }) => (
    <Dropdown.Item onClick={action}>
        <Icon name={icon} /> {title}
    </Dropdown.Item>
);

export const UserMenu = () => {
    const dispatch = useDispatch();
    const handleLogout = () => dispatch(startLogout());
    // Handle go to admin site
    const handleAdmin = () => window.open('http://127.0.0.1:8000/admin/');
    const { first_name = '', last_name = '' } = useSelector(
        (state) => state.user
    );

    return (
        <Dropdown className='d-inline mx-2'>
            <Dropdown.Toggle
                id='dropdown-autoclose-true'
                variant='light'
            >
                <a
                    href='#test'
                    className='d-flex align-items-center link-dark text-decoration-none '
                >
                    <img
                        src='https://www.inspirations.boutique/wp-content/uploads/2019/10/blank-person-profile.png'
                        alt='test'
                        width='32'
                        height='32'
                        className='rounded-circle me-2'
                    />
                    {/* Just use the name and the first lastname  */}
                    <strong>{first_name} {last_name}</strong>
                </a>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {/* Admin */}
                <MenuItem
                    title='Administración'
                    icon='building'
                    action={handleAdmin}
                />

                {/* Logout */}
                <MenuItem
                    title='Salir'
                    icon='box-arrow-left'
                    action={handleLogout}
                />
            </Dropdown.Menu>
        </Dropdown>
    );
};
