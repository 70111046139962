import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { updatePerceptionsInvoice } from '../../actions/requests';
import { registerFunding } from '../../actions/funding';
import { handleError, handleSuccess } from '../../helpers/utils';
import { updateApplication } from '../../actions/applications';

export const NewFundingOE = ({
	show = false,
	handleClose = null,
	typeFunding = '',
}) => {
	const dispatch = useDispatch();
	const [payer, setPayer] = React.useState('');
	const [amount, setAmount] = React.useState('');
	const applicationTemp = useSelector((state) => state.request.application);

	//only getting id on registerFunding
	function updateFundings(value, applicationTemp) {
		registerFunding(value).then((response) => {
			updateApplicationFunding(response.data, applicationTemp, value);
		});
	}

	function updateApplicationFunding(newFundingUpdated, applicationTemp) {
		updateApplication(applicationTemp.id, {
			...applicationTemp,
			fundings: [...applicationTemp.fundings, newFundingUpdated],
		})
			.then(({ data }) => {
				// Actualizar en Redux
				handleSuccess('Pagadora creada correctamente');
				dispatch(
					updatePerceptionsInvoice({
						...data,
						promoter: applicationTemp.promoter,
						executive: applicationTemp.executive,
						client: applicationTemp.client,
						fundings: [
							...applicationTemp.fundings,
							newFundingUpdated,
						],
					})
				);
			})
			.catch((error) => {
				handleError('No se creó la pagadora');
			});
	}

	return (
		<Dialog
			open={show}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle>Nuevo Fondeo</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Complete el formulario para crear un nuevo Fondeo
				</DialogContentText>

				<Grid container spacing={2} style={{ marginTop: '15px' }}>
					<Grid item xs={12}>
						<TextField
							autoFocus
							id='outlined-basic'
							label='Pagadora'
							variant='outlined'
							fullWidth
							onChange={(e) => {
								setPayer(e.target.value);
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							id='outlined-basic'
							label='Importe'
							variant='outlined'
							type='number'
							fullWidth
							onChange={(e) => {
								setAmount(e.target.value);
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color='error'>
					Cancelar
				</Button>
				<Button
					disabled={payer.length === 0 || amount.length === 0}
					onClick={() => {
						updateFundings(
							{ payer: payer, amount: amount, ope: typeFunding },
							applicationTemp
						);
						// updateValue();
						handleClose();
					}}
				>
					Crear
				</Button>
			</DialogActions>
		</Dialog>
	);
};
