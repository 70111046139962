import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import {
    Alert,
    Box,
    Button,
    DialogActions,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { getReturnsAndCommissionsByApplicationID } from '../../actions/applications';
import moment from 'moment';
import { money } from '../../helpers/money';

export const ReturnsAndComissionsDialog = ({
    open,
    handleClose,
    applicationId = null,
}) => {
    const [returns, setReturns] = useState([]);

    /**
     * Effect hook to fetch and format return and commission data for a specific application ID.
     *
     * This effect hook performs the following steps:
     * 1. Checks if both `applicationId` and `open` are provided.
     * 2. If both conditions are met, it calls the `getReturnsAndCommissionsByApplicationID` function to retrieve return and commission data.
     * 3. Formats the retrieved data for display by mapping and transforming each item.
     * 4. Sets the formatted return and commission data to the `returns` state variable.
     * 5. If no return and commission data is found for the application, it sets `returns` to an empty array.
     *
     * Dependencies: moment (for date formatting), money (for currency formatting)
     */
    useEffect(() => {
        // Check if both `applicationId` and `open` are provided
        if (applicationId && open) {
            // Fetch return and commission data by application ID
            getReturnsAndCommissionsByApplicationID(applicationId).then(
                ({ data }) => {
                    if (data.count > 0) {
                        // Format and transform return and commission data for display
                        const formattedData = data.results.map((item) => ({
                            ...item,
                            created_at: moment(item.created_at).format(
                                'DD/MM/YYYY'
                            ), // Format date as 'DD/MM/YYYY'
                            balance: money(item.balance), // Format balance as currency
                            percentage: `${item.percentage}%`, // Format percentage with '%'
                            commission: money(item.commission), // Format commission as currency
                            return_value: money(item.return_value), // Format return value as currency
                            isn: money(item.isn), // Format isn as currency
                            isr: money(item.isr), // Format isr as currency
                            iva: money(item.iva), // Format iva as currency
                            social_cost: money(item.social_cost), // Format social cost as currency
                        }));

                        // Set the formatted return and commission data to the `returns` state variable
                        setReturns(formattedData);
                    } else {
                        // Set `returns` to an empty array when no return and commission data is found
                        setReturns([]);
                    }
                }
            );
        }
    }, [applicationId, open]);

    return (
        <Dialog
            maxWidth='lg'
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            {/* Content */}
            <Box sx={{ flexGrow: 1 }}>
                <div style={{ padding: '20px' }}>
                    <Text
                        variant='h6'
                        text='Retornos'
                    />

                    {returns?.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table
                                sx={{ width: '100%' }}
                                aria-label='simple table'
                            >
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align='center'>
                                            #
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            Esquema
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Balance
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Comisión base
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Porcentaje
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Valor de retorno
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Comisión
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            ISN
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            ISR
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Costo social
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            IVA
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Creado
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {returns.map((deposit, index) => (
                                        <StyledTableRow
                                            key={deposit.key}
                                            sx={styleLabel}
                                        >
                                            <ColumnItem
                                                align='center'
                                                text={index + 1}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit.scheme}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit.balance}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit.base_commission}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit.percentage}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit.return_value}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit.commission}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit.isn}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit.isr}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit.social_cost}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit.iva}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit.created_at}
                                            />
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Alert severity='info'>No hay retornos</Alert>
                    )}
                </div>
            </Box>
            <DialogActions>
                <Button onClick={() => handleClose()}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

const ColumnItem = ({ align, text }) => (
    <StyledTableCell align={align}>{text}</StyledTableCell>
);

const styleLabel = {
    '&:last-child td, &:last-child th': { border: 0 },
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction='up'
            ref={ref}
            {...props}
        />
    );
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#01579b',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const Text = ({ variant, text, bold = true }) => (
    <Typography
        variant={variant}
        gutterBottom
    >
        {bold ? <b>{text}</b> : text}
    </Typography>
);
