import moment from 'moment';

// Replace slash for dash
export const replaceSlashForDash = (date) =>
	date.replace('/', '-').replace('/', '-');

// Replace dash for slash
export const replaceDashForSlash = (date) =>
	date.replace('-', '/').replace('-', '/');

// Fill date with zeros
export const fillDateWithZeros = (date) => {
	date = replaceDashForSlash(date).split('/');
	date.forEach((item, i) => {
		if (item.length < 2) date[i] = '0' + item;
	});
	return date.join('/');
};

// Convert timestamp obj to normal date
export const timestampToNormalDate = (date) => {
	if (date) {
		date = new Date(date.seconds * 1000).toLocaleDateString('mx');
		return fillDateWithZeros(date);
	}
	return null;
};

// Convert timestamp obj to normal datetime
export const timestampToNormalDateTime = (date) => {
	if (date) {
		date = new Date(date?.seconds * 1000)?.toLocaleString('mx');
		return fillDateWithZeros(date);
	}
	return null;
};

// 2022-10-28 --> 28/10/2022
export const formatDateMX = (date) => {
	if (!date) return false;
	const newDate = date?.split('T')[0]?.split('-');
	return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
};

// 2022-10-28 --> 28/10/2022 - 12:31:02
export const formatDateTimeMX = (date) => {
	if (!date) return false;
	const datetime = date?.split('T');
	const newDate = datetime[0]?.split('-');
	const time = datetime[1]?.split('.')[0];
	return `${newDate[2]}/${newDate[1]}/${newDate[0]} - ${time}`;
};

// Format date DD/MM/YYYY
export const formatDate = (date, type = 0) => {
	if ((typeof date === 'string' && date.length > 0) || type === 1) {
		try {
			return fillDateWithZeros(date.toLocaleDateString('mx'));
		} catch (error) {
			return date;
		}
	} else if (typeof date === 'object') {
		return timestampToNormalDate(date);
	}
	return false;
};

// Sort array by timestamp
export const sortByTimestamp = (array) =>
	array.sort((a, b) => b.created.seconds - a.created.seconds);

// Add days to date
export const addDaysToDate = (date, n) =>
	moment(date, 'DD/MM/YYYY').add(parseInt(n), 'days').format('DD/MM/YYYY');

// Substract days to date
export const substractDaysToDate = (date, n) =>
	moment(date, 'DD/MM/YYYY')
		.subtract(parseInt(n), 'days')
		.format('DD/MM/YYYY');

// Get difference between days
export const diffDays = (date1, date2) => {
	if (date1 && date2) {
		date1 = moment(date1, 'DD/MM/YYYY');
		date2 = moment(date2, 'DD/MM/YYYY');
		return date2.diff(date1, 'days');
	}
	return 0;
};

// Money
export const money = (amount) => `${formatterCurrency(amount)}`;

// Create our number formatter.
export const formatterCurrency = (num) => {
	return new Intl.NumberFormat('es-MX', {
		style: 'currency',
		currency: 'MXN',
	}).format(num);
};
