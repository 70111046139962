import React from 'react';
import { Autocomplete, Grid } from '@mui/material';
export function BancarizadaFormComponent({
    company,
    setCompany,
    verifyIfCorp,
    SelectComponent,
    companies,
}) {
    return (
        <>
            <Grid
                item
                xs={4}
            >
                {/* Client */}

                <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    required // style={{ marginTop: '20px' }}
                    fullWidth
                    value={company}
                    onChange={(_, value) => {
                        setCompany(value.split('[CORP] - ').pop());
                        verifyIfCorp(value);
                    }}
                    options={companies.map((item) => {
                        return item.is_corp
                            ? `[CORP] - ${item.name}`
                            : item.name;
                    })}
                    renderInput={(params) => SelectComponent(params, 'Empresa')}
                />
            </Grid>
            
        </>
    );
}
