import React from 'react';
import { Alert, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { handleDeleteUserModal, handleError } from '../../helpers/utils';
import { pseudoDeleteAccount } from '../../actions/auth';

// Users table
export const TeamsTable = ({ data = [], userType = null }) => {
	// Hook for navigation
	const navigate = useNavigate();
	// Generate rows by array
	const generateRows = (array) => {
		if (array.length === 0) return [];

		// Filter users by user type & not deleted condition
		const filteredUsers = array.filter(
			(item) => item.role === userType && !item?.deleted
		);

		return filteredUsers.map((item) => ({
			id: item.id,
			col1: item.id,
			col2: item.name,
			col3: item.email,
			col4: item.role,
		}));
	};

	// Handle delete user with modal confirmation
	const handleDeleteUser = (userId) => {
		handleDeleteUserModal()
			.then(({ isConfirmed }) => {
				if (isConfirmed) {
					pseudoDeleteAccount(userId);
					// document.location.reload();
				}
			})
			.catch((error) => handleError(error));
	};

	// Define columns
	const columns = [
		{ field: 'col1', headerName: 'ID', width: 150 },
		{ field: 'col2', headerName: 'Nombre', width: 250 },
		{ field: 'col3', headerName: 'Área', width: 250 },
		{
			field: 'col5',
			headerName: 'Acciones',
			width: 250,
			// Add actions to each case
			renderCell: ({ row }) => (
				<>
					<Tooltip title='Editar usuario'>
						<IconButton
							style={{ color: '#143f6c' }}
							aria-label='edit'
							color='primary'
							onClick={() => {
								alert('edit');
							}}
						>
							<EditIcon />
						</IconButton>
					</Tooltip>
				</>
			),
		},
	];

	return (
		<>
			{/* Datagrid table */}
			{data.length > 0 ? (
				<DataGrid
					autoHeight
					rows={generateRows(data)}
					columns={columns}
					// autoPageSize
					components={{ Toolbar: GridToolbar }}
					localeText={
						esES.components.MuiDataGrid.defaultProps.localeText
					}
				/>
			) : (
				<Alert severity='info'>No hay ningún usuario</Alert>
			)}
		</>
	);
};
