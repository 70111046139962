import React, { useState } from 'react';
import { TextField, Button, Typography, Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

import { handleError, handleSuccess, handleWarning } from '../../helpers/utils';
import { registerConsCompany, updateConsCompany } from '../../actions/catalogs';

// Default configuration for a new form
const defaultConfig = {
	title: 'Añadir Empresa Contra',
	handleClose: false,
	addOneItem: false,
	updateOneItem: false,
};

export const NewConsCompanyForm = ({ data = null, config = defaultConfig }) => {
	// Desestructure config property
	const {
		title = 'Añadir Empresa Contra',
		handleClose,
		addOneItem,
		updateOneItem,
	} = config;

	const [status, setStatus] = data ? useState(data.status) : useState('INF');
	const [completeName, setCompleteName] = data
		? useState(data.name)
		: useState('');
	const [companyType, setCompanyType] = data
		? useState(data.company_type)
		: useState('');
	const [shortName, setShortName] = data
		? useState(data.shortname)
		: useState('');
	const [costName, setCostName] = data
		? useState(data.cost_name)
		: useState('');
	const [cost, setCost] = data ? useState(data.cost * 100) : useState(0.0);
	const [expenseName, setExpenseName] = data
		? useState(data.expense_name)
		: useState('');
	const [expense, setExpense] = data
		? useState(data.expense * 100)
		: useState(0.0);
	const [operationServer, setOperationServer] = data
		? useState(data.operation_server)
		: useState('');
	const [concept, setConcept] = data ? useState(data.concept) : useState('');
	const [description, setDescription] = data
		? useState(data.description)
		: useState('');

	//  Event Handlers for form items
	const handleStatus = (event) => {
		setStatus(event.target.value);
	};

	// Function to create a ConsCompany
	function handleCreate(value) {
		// Validate that a name exists
		const requiredCamps =
			value.name !== '' &&
			value.company_type !== '' &&
			value.shortname !== '' &&
			value.cost_name !== '' &&
			value.expense_name !== '' &&
			value.operation_server !== '' &&
			value.concept !== '';

		// Validate that required camps exists
		if (requiredCamps) {
			registerConsCompany(value)
				.then(({ data }) => {
					handleSuccess('Empresa contra creada correctamente');
					addOneItem(data);
					handleClose();
				})
				.catch((error) => {
					handleError(error, 'No se creó la Empresa contra');
				});
		} else {
			handleWarning('Faltan campos');
		}
	}

	// Function to update a ConsCompany
	function handleUpdate(value) {
		// Validate that a name exists
		const requiredCamps =
			value.name !== '' &&
			value.company_type !== '' &&
			value.shortname !== '' &&
			value.cost_name !== '' &&
			value.expense_name !== '' &&
			value.operation_server !== '' &&
			value.concept !== '';

		// Validate that required camps exists
		if (requiredCamps) {
			updateConsCompany(data.id, value)
				.then(({ data }) => {
					handleSuccess('Empresa contra creada correctamente');
					updateOneItem(data);
					handleClose();
				})
				.catch((error) => {
					handleError(error, 'No se creó la Empresa contra');
				});
		} else {
			handleWarning('Faltan campos');
		}
	}

	return (
		<div
			style={{
				padding: '20px',
				margin: 6,
			}}
		>
			<Typography variant='h4' gutterBottom>
				{title}
			</Typography>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={6}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Nombre Completo de la empresa'
						onChange={(e) => setCompleteName(e.target.value)}
						value={completeName}
						inputProps={{ maxLength: 255 }}
						fullWidth
						required
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Nombre corto'
						onChange={(e) => setShortName(e.target.value)}
						value={shortName}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={3}>
					<FormControl fullWidth required>
						<InputLabel variant='standard' htmlFor='uncontrolled-native'>
							Estatus
						</InputLabel>
						<NativeSelect
							defaultValue={status}
							inputProps={{
								name: 'status',
								id: 'uncontrolled-native',
							}}
							onChange={handleStatus}
						>
							<option value={'INF'}>Vigente</option>
							<option value={'SPE'}>Especial</option>
							<option value={'DNU'}>No se usa</option>
							<option value={'NLU'}>Ya no usar</option>
						</NativeSelect>
					</FormControl>
				</Grid>

				<Grid item xs={4}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Tipo de Empresa'
						onChange={(e) => setCompanyType(e.target.value)}
						value={companyType}
						inputProps={{ maxLength: 13 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Servidor de operación'
						onChange={(e) => setOperationServer(e.target.value)}
						value={operationServer}
						inputProps={{ maxLength: 13 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Concepto'
						onChange={(e) => setConcept(e.target.value)}
						value={concept}
						inputProps={{ maxLength: 13 }}
						required
						fullWidth
					/>
				</Grid>

				<Grid item xs={4}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Nombre costo'
						onChange={(e) => setCostName(e.target.value)}
						value={costName}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						type='number'
						variant='outlined'
						color='secondary'
						label='Porcentaje de costo (%)'
						onChange={(e) => setCost(e.target.value)}
						value={cost}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Nombre gasto'
						onChange={(e) => setExpenseName(e.target.value)}
						value={expenseName}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						type='number'
						variant='outlined'
						color='secondary'
						label='Porcentaje de gasto (%)'
						onChange={(e) => setExpense(e.target.value)}
						value={expense}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Descripcion'
						onChange={(e) => setDescription(e.target.value)}
						value={description}
						multiline
						rows={6}
						inputProps={{ maxLength: 700 }}
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<Button
						variant='contained'
						fullWidth
						color='error'
						sx={{ mt: 2 }}
						onClick={handleClose}
					>
						Cancelar
					</Button>
				</Grid>
				<Grid item xs={6}>
					{data ? (
						<Button
							variant='contained'
							fullWidth
							style={{ backgroundColor: '#143f6c' }}
							sx={{ mt: 2 }}
							onClick={() => {
								handleUpdate({
									status: status,
									name: completeName,
									company_type: companyType,
									shortname: shortName,
									cost_name: costName,
									cost: cost / 100,
									expense_name: expenseName,
									expense: expense / 100,
									operation_server: operationServer,
									concept: concept,
									description: description,
								});
							}}
						>
							Actualizar
						</Button>
					) : (
						<Button
							variant='contained'
							fullWidth
							style={{ backgroundColor: '#143f6c' }}
							sx={{ mt: 2 }}
							onClick={() => {
								handleCreate({
									status: status,
									name: completeName,
									company_type: companyType,
									shortname: shortName,
									cost_name: costName,
									cost: cost / 100,
									expense_name: expenseName,
									expense: expense / 100,
									operation_server: operationServer,
									concept: concept,
									description: description,
								});
							}}
						>
							Registrar
						</Button>
					)}
				</Grid>
			</Grid>
		</div>
	);
};
