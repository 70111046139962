import React, { useEffect } from 'react';
import {
    Alert,
    Button,
    IconButton,
    Tooltip,
    Stack,
    Typography,
    Switch,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { money } from '../helpers/money';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateCreditToDebt,
    updateOperatingExpenses,
    updateAvailableKV,
} from '../actions/requests';
import { NewPayerAmount } from '../components/applications/NewPayerAmount';
import { deleteDebt, updateDebt } from '../actions/debt';
import { handleError, handleSuccess } from '../helpers/utils';
import { PayerDialogReturnPromoters } from '../components/applications/PayerDialogReturnPromoters';
import {
    handleUpdateFieldApp,
    setCurrentApplication
} from '../actions/applications';
import { NewCreditToDebtModal } from '../components/applications/NewCreditToDebtModal';
import Chip from '@mui/material/Chip';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { saveApplication } from '../helpers/SaveApplication';

export const AbonoADeuda = ({ applicationTemp = {} }) => {
    const columns = [
        {
            field: 'col9',
            headerName: 'Acciones',
            width: 180,
            // Add actions to each case
            renderCell: ({ row }) => (
                <>
                    <Tooltip title='Nuevo fondeo'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='new-funding'
                            color='primary'
                            onClick={() => {
                                setCurrentRow(row);
                                setOpenModal2(true);
                            }}
                        >
                            <AttachMoneyIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Ver fondeos'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='view-fundings'
                            color='primary'
                            onClick={() => {
                                setCurrentRow(row);
                                setTempArr(row?.fundings);
                                setOpenPayerDialogReturn(true);
                            }}
                        >
                            <PriceChangeIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Editar'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='edit'
                            color='primary'
                            onClick={() => {
                                setCurrentRow(row);
                                setIsEdit(true);
                                setOpenModal(true);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Eliminar'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='delete'
                            color='primary'
                            onClick={() => {
                                handleDeletePromoter(row.id);

                                handleDelete2();
                                handleValue();
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
        {
            field: 'name',
            headerName: 'Nombre',
            width: 200,
            type: 'singleSelect',
        },
        {
            field: 'percentage',
            headerName: 'Porcentaje',
            width: 100,
            type: 'number',

            valueFormatter: (params) => {
                let percentage = params.value;
                return `${percentage}%`;
            },
        },
        {
            field: 'base_commission',
            headerName: 'Base',
            width: 180,
        },
        {
            field: 'commission',
            headerName: 'Comisión',
            width: 170,

            valueFormatter: (params) => {
                let commission = params.value;
                return `${money(commission)}`;
            },
        },
        {
            field: 'is_safeguard',
            headerName: 'En resguardo',
            width: 120,
            renderCell: ({ row }) => (
                <>
                    <Tooltip title='En resguardo'>
                        <Switch 
                            checked={row.is_safeguard}
                            onChange={(e) => {
                                handleSwitchDebt(row, e.target.checked);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Tooltip>
                </>
            ),
        },
    ];

    const dispatch = useDispatch();
    const [tempArr, setTempArr] = React.useState([]);
    const [currentRow, setCurrentRow] = React.useState(null);
    const [isEdit, setIsEdit] = React.useState(false);
    const { application = null } = useSelector((state) => state.request);
    const [openPayerDialogReturn, setOpenPayerDialogReturn] =
        React.useState(false);
    const handlePayerDialogReturn = () => {
        setOpenPayerDialogReturn(false);
        setCurrentRow(null);
    
    };
    const availableKV = useSelector((state) => state.request.availableKV);
    const [currentAvailableKV, setCurrentAvailableKV] = React.useState(0);
    const request = useSelector((state) => state.request);

    const creditToDebt = useSelector((state) => state.request.creditToDebt);

    const objOperativeExpenses = useSelector(
        (state) => state.request.operatingExpenses
    );

    const handleCloseDialog = () => {
        setOpenModal(false);
        setCurrentRow(null);
    };
    const handleCloseDialog2 = () => {
        setOpenModal2(false);
        setCurrentRow(null);
    };
    const [openModal, setOpenModal] = React.useState(false);
    const [openModal2, setOpenModal2] = React.useState(false);
    const [change2, setChange2] = React.useState(false);
    const [deleteTemp, setDelete] = React.useState(false);
    const [disponibleTemp, setDisponibleTemp] = React.useState(0);
    const [expenseCredit, setExpenseCredit] = React.useState('');

    // Update when component unmount
    useEffect(() => {
        return () => {
            dispatch(saveApplication());
        };
    },[]);

    const handleSwitchDebt = ( currentRow, isSafeguardValue ) => {
        updateDebt(currentRow?.id, { is_safeguard: isSafeguardValue })
            .then(({ data }) => {
                // Update an item of the table
				// Find the index of the item to edit
				let tempObj = { ...creditToDebt };
				const indexData = creditToDebt.creditArray.findIndex((item) => item.id === data.id);
				
				const tmpData = creditToDebt.creditArray.map((item, index) => {
					if (index === indexData) {
						return {
							...item,
                            is_safeguard: isSafeguardValue,
						};
					}
					return item;
				});

				tempObj.creditArray = tmpData;
				dispatch(updateCreditToDebt(tempObj));
				handleSuccess('Registro actualizado correctamente');
				handleUpdateTotals(tmpData);
            })
            .catch((error) => {
                handleError(
                    error,
                    'Ocurrió un error al intentar agregar un registro'
                );
            });
    };

    const handleValue = () => {
        setDelete(!deleteTemp);
    };

    const handleDelete2 = () => {
        setChange2(!change2);
    };

    /**
     * Update Redux Data with Total Commission and Amounts.
     *
     * This function calculates and updates relevant data in the Redux store based on an array of administrative cost entries. It calculates the total commissions and total amounts across the entries and dispatches the updated data to the Redux store for further use in the application.
     *
     * @param {Array} array - An array of administrative cost entries to calculate totals from.
     */
    const handleUpdateTotals = (array) => {
        // Calculate the total commissions by summing the commission values from the array
        const totalCommissions = array.reduce(
            (sum, { commission }) => sum + commission,
            0
        );

        // Calculate the total amounts by summing the amounts from all fundings in the array
        let totalAmounts = 0;
        array.forEach((item) => {
            totalAmounts += item.fundings.reduce(
                (sum, { amount }) => sum + amount,
                0
            );
        });

        // Update the Redux store with the calculated totals and relevant data
        dispatch(
            updateCreditToDebt({
                creditArray: array, // The array of administrative cost entries
                commissionTotal: totalCommissions, // Total commissions calculated
                amountTotal: totalAmounts, // Total amounts calculated
                totalExpenses: 0, // Placeholder for total expenses (not calculated here)
                availableKV: 0, // Placeholder for available KV (not calculated here)
                totalPayer: 0, // Placeholder for total payer (not calculated here)
                totalImport: 0, // Placeholder for total import (not calculated here)
            })
        );
    };

    /**
     * Delete a User from a Table and Update Totals.
     *
     * This function is responsible for deleting a user entry from a table (likely representing credit-to-debt data) and updating the totals accordingly. It removes the specified user entry from the table, triggers the deletion request, handles success or error feedback, and updates the relevant totals in the Redux store.
     *
     * @param {number} id - The unique identifier of the user entry to be deleted.
     */
    const handleDeletePromoter = (id) => {
        // Create a new array excluding the user entry with the specified ID
        let newArr = creditToDebt.creditArray.filter(
            (element) => element.id != id
        );

        // Send a deletion request for the user entry
        deleteDebt(id)
            .then(() => {
                // Handle success feedback if the deletion is successful
                handleSuccess('Registro eliminado correctamente');

                // Update the totals in the Redux store based on the modified array
                handleUpdateTotals(newArr);
            })
            .catch((error) => {
                // Handle errors, if any, and provide feedback
                handleError(
                    error,
                    'Ocurrió un error al intentar eliminar el registro'
                );
            });
    };

   

    /**
     * Update Expenses for Credit-to-Debt and Update Application Data.
     *
     * This function is responsible for updating the expenses associated with credit-to-debt for a specific application. It handles the update of the application data in the Redux store, calculates available KV, and provides feedback upon success.
     *
     * @param {number} value - The expense value to be associated with credit-to-debt.
     */
    const expensesCreditToDebt = (value) => {
        // Update the specified field ('gastos_abono_a_deuda') in the application data
        handleUpdateFieldApp(
            application,
            'gastos_abono_a_deuda',
            expenseCredit !== '' ? Number(expenseCredit) : 0
        ).then(({ data }) => {
            // Update the current application data in the Redux store
            dispatch(
                setCurrentApplication({
                    ...data,
                    fundings: application.fundings,
                    promoter: application.promoter,
                    executive: application.executive,
                })
            );

            // Create a temporary object for operative expenses and update the 'gastosAAbonoDeuda' field
            const tempObj2 = { ...objOperativeExpenses };
            tempObj2.gastosAAbonoDeuda = Number(expenseCredit);

            // Dispatch an action to update the operative expenses in the Redux store
            dispatch(updateOperatingExpenses(tempObj2));

            // Provide success feedback to notify the user that the expense has been registered
            handleSuccess('Gasto registrado');
        });
    };

    /**
     * Handle Available KV Update.
     *
     * This function is responsible for updating the available KV value in the Redux store.
     *
     * @param {number} value - The new available KV value.
     */
    const handleAvailableKV = (value) => {
        // Create a temporary object for available KV and update the 'availableKV' field
        let tempObj = { ...availableKV };
        tempObj.availableKV = value;

        // Dispatch an action to update the available KV in the Redux store
        dispatch(updateAvailableKV(tempObj));
    };

    /**
     * Update Total Available KV Effect.
     *
     * This `useEffect` function is responsible for recalculating the total available KV (Key Value) based on various factors and updating it across different tabs or components. The total available KV represents the remaining key value after considering commissions, operating expenses, and other factors.
     *
     * @param {number} creditToDebtCommissionTotal - The total commission related to credit-to-debt.
     */
    useEffect(() => {
        // Extract relevant data for calculations
        const totalCommissionScheme = request.totalCommissionSchemeTotal;
        const totalOperatingExpenses = request.operatingExpensesTotal;
        const totalCommisionPromoters = request.promoters.commissionTotal;
        const totalCommisionCreditsToDebt = creditToDebt.commissionTotal;

        // Calculate the profit, which is the difference between total commission scheme, operating expenses,
        // promoter commissions, and credit-to-debt commissions
        const profit =
            totalCommissionScheme -
            totalOperatingExpenses -
            totalCommisionPromoters -
            totalCommisionCreditsToDebt;

        // Set the temporary variable 'DisponibleTemp' to the calculated profit
        setDisponibleTemp(profit);

        // Update the current available KV in the local state
        setCurrentAvailableKV(profit);

        // Call 'handleAvailableKV' function to handle the update of available KV across the application
        handleAvailableKV(profit);
    }, [creditToDebt.commissionTotal]);

    /**
     * Update Total Credit to Debt Effect.
     *
     * This `useEffect` function is responsible for updating the 'total_credit_to_debt' field in the application data based on changes in the 'creditToDebt.commissionTotal' value. It introduces a slight delay before making the update to ensure it doesn't trigger too frequently.
     *
     * @param {number} creditToDebtCommissionTotal - The total commission related to credit-to-debt.
     */
    useEffect(() => {
        // Introduce a slight delay using setTimeout to prevent rapid updates
        setTimeout(() => {
            // Update the 'total_credit_to_debt' field of the application with the 'creditToDebt.commissionTotal' value
            handleUpdateFieldApp(
                application,
                'total_credit_to_debt',
                creditToDebt.commissionTotal
            ).then(({ data }) => {
                // Dispatch an action to set the updated application data in the Redux state
                dispatch(
                    setCurrentApplication({
                        ...data,
                        fundings: application.fundings,
                        promoter: application.promoter,
                        executive: application.executive,
                        bankDetected: application.bankDetected,
                    })
                );
            });
        }, 50); // Delay of 50 milliseconds
    }, [creditToDebt.commissionTotal]);

    useEffect(() => {
        setExpenseCredit(application?.gastos_abono_a_deuda);
    }, []);

    return (
        <div>
            {/* Payer return dialog */}
            <PayerDialogReturnPromoters
                currentRow={currentRow}
                fundings={tempArr}
                show={openPayerDialogReturn}
                handleClose={handlePayerDialogReturn}
                updateTotals={handleUpdateTotals}
                sectionTitle='Abono a Deuda'
            />
            <Stack
                direction='row'
                alignItems='flex-end'
                justifyContent='space-between'
                style={{
                    border: '1px solid white',
                    padding: '5px',
                    width: '100%',
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: '10px' }}
                    >
                        <Grid
                            item
                            xs={9}
                        >
                            <Typography
                                variant='h5'
                                gutterBottom
                            >
                                Abono a Deuda
                            </Typography>
                        </Grid>
                        
                        <Grid
                            item
                            xs={3}
                        >
                            <Button
                                variant='contained'
                                style={{ backgroundColor: '#143f6c' }}
                                onClick={() => {
                                    setOpenModal(true);
                                }}
                            >
                                Nuevo Abono
                            </Button>
                        </Grid>
                        <Box sx={{ flexGrow: 1, ml: 2, mb: 4 }}>
                            <Grid
                                container
                                spacing={2}
                                sx={{ mt: 2 }}
                            >
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <ItemTexts
                                        title='Total Comisiones'
                                        text={money(
                                            creditToDebt.commissionTotal
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <ItemTexts
                                        title='Total Importes'
                                        text={money(creditToDebt.amountTotal)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <CurrencyFormat
                                        customInput={TextField}
                                        variant='standard'
                                        label='Gasto'
                                        // type='number'

                                        thousandSeparator={true}
                                        prefix={'$'}
                                        allowNegative={false}
                                        value={expenseCredit}
                                        onChange={({ target }) => {
                                            setExpenseCredit(
                                                Number(
                                                    target.value.replace(
                                                        /[^0-9.-]+/g,
                                                        ''
                                                    )
                                                )
                                            );
                                        }}
                                        onBlurCapture={() => {
                                            expensesCreditToDebt();
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    {application.total_available_KV < 1000 ? (
                                        <Chip
                                            label='Se esta pagando de más'
                                            color='warning'
                                            avatar={
                                                <WarningIcon
                                                    style={{ color: 'white' }}
                                                />
                                            }
                                        />
                                    ) : (
                                        <Chip
                                            label='Disponible correcto'
                                            color='success'
                                            avatar={
                                                <CheckCircleIcon
                                                    style={{ color: 'white' }}
                                                />
                                            }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                        {openModal ? 
                            <NewCreditToDebtModal
                                show={openModal}
                                handleClose={handleCloseDialog}
                                handleChange={handleDelete2}
                                handleUpdateTotals={handleUpdateTotals}
                                sectionTitle='Abono a Deuda'
                                isEdit={isEdit}
                                currentRow={currentRow}
                            />
                        : null}
                        <NewPayerAmount
                            show={openModal2}
                            handleClose={handleCloseDialog2}
                            currentRow={currentRow}
                            currentRows={creditToDebt}
                            handleChange={handleDelete2}
                            sectionTitle='Abono a Deuda'
                        />
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            marginBottom: '10px',
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            {creditToDebt.creditArray?.length > 0 ? (
                                <DataGrid
                                    sx={{
                                        '& .MuiDataGrid-cell': {
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            padding: '10px',
                                        },
                                    }}
                                    style={{
                                        height: '600px',
                                        margin: '10px 0',
                                    }}
                                    density='compact'
                                    getRowHeight={() => 'auto'}
                                    rows={creditToDebt.creditArray}
                                    columns={columns}
                                    components={{ Toolbar: GridToolbar }}
                                    localeText={
                                        esES.components.MuiDataGrid.defaultProps
                                            .localeText
                                    }
                                />
                            ) : (
                                <Alert severity='info'>
                                    No hay ninguna fila
                                </Alert>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
            <hr />
        </div>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);
