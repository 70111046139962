import axios from 'axios';
import { handleSuccess } from '../helpers/utils';
import { types } from '../types/types';
import { getToken } from './auth';

// Dispatch set user obj
export const setUser = (data) => ({
    type: types.setUser,
    payload: data,
});

// Dispatch clean user obj
export const cleanUser = () => ({
    type: types.cleanUser,
});

// Get all users
export const getUsers = async (profile) => {
    const token = getToken();
    return await axios({
        method: 'get',
        url: `/api/auth/users?profile=${profile}`,
        headers: { Authorization: 'Bearer ' + token },
    });
};

export const getNextUser = async (page, profile) => {
    console.log(`/api/auth/users?page=${page}&profile=${profile}`);
    const token = getToken();
    return await axios({
        method: 'get',
        url: `/api/auth/users?page=${page}&profile=${profile}`,
        headers: { Authorization: 'Bearer ' + token },
    });
};

// Register user
export const registerUser = async (userInfo) => {
    // Get token if authenticated
    const token = getToken();
    return axios({
        method: 'post',
        url: '/api/auth/register',
        data: userInfo,
        headers: { Authorization: 'Bearer ' + token },
    });
};

// Update user
export const updateUser = async (id, data) => {
    // Get token if authenticated
    const token = getToken();
    return axios({
        method: 'put',
        url: `/api/auth/user/${id}`,
        data,
        headers: { Authorization: 'Bearer ' + token },
    });
};

// Delete user
export const deleteUser = (id) => {
    return () => {
        // Get token if authenticated
        const token = getToken();
        axios({
            method: 'delete',
            url: `/api/auth/user/${id}`,
            headers: { Authorization: 'Bearer ' + token },
        })
            .then(() => {
                handleSuccess('Usuario eliminado correctamente.');
            })
            .catch((error) => {
                handleError(error, 'No se pudo eliminar al usuario.');
            });
    };
};

// Get user info by id
export const getUserInfo = async (id) => {
    const token = getToken();
    return await axios({
        method: 'get',
        url: `/api/auth/user/${id}`,
        headers: { Authorization: 'Bearer ' + token },
    });
};
