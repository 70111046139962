import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useSelector, useDispatch } from 'react-redux';
import {
	updateAvailableKV
} from '../../actions/requests';

const arrBases = [
	{ label: 'TOTAL DEPOSITO' },
	{ label: 'SUBTOTAL DEPOSITO' },
	{ label: 'RETORNO' },
	{ label: 'COMISION TOTAL' },
	{ label: 'COMISION - BANCARIA' },
	{ label: 'COMISION - BANCARIA - COSTO EFECTIVO CLIENTE' },
	{ label: 'COMISION - COSTO EFECTIVO CLIENTE' },
	{ label: 'COMISION - COSTOS OPERATIVOS' },
	{ label: 'COMISION - COSTO SC' },
	{ label: 'COMISION - 2% EDEN RED' },
	{ label: 'COMISION - 2% EDEN RED - BANCARIA' },
	{ label: 'COMISION - 2% EDEN RED - COM. CONTACTO - BANCARIA' },
	{ label: 'COMISION - PROMOTORIA 1 - BANCARIA' },
	{ label: 'COMISION - PROMOTORIA 2 - BANCARIA' },
	{ label: 'COMISION - PROMOTORIA 3 - BANCARIA' },
	{ label: 'COMISION - COSTO SC - PROMOTORIA 1' },
	{ label: 'RETORNO + 10% SUBTOTAL' },
];

export const NewPagadoraDisponible2 = ({
	show = false,
	handleClose,
	idPass = null,
	handleChange,
}) => {
	const dispatch = useDispatch();
	const request = useSelector((state) => state.request);
	const availableKVTemp = useSelector((state) => state.request.availableKV);
	const creditToDebt = useSelector((state) => state.request.creditToDebt);
	const rows = useSelector((state) => state.request.returnAndCommission);

	const [nameTemp, setNameTemp] = React.useState('');
	const [payer, setPayer] = React.useState('');
	const [amount, setAmount] = React.useState(0);

	const [baseTemp, setBaseTemp] = React.useState('');
	const [commissionTemp, setCommissionTemp] = React.useState(0);

	/* Reset values on Dialog on close or on */
	const setValuesZero = () => {
		setPayer('');
		setAmount(0);
	};

	function setPayerAmountValues() {
		const tempObj = JSON.parse(JSON.stringify(availableKVTemp));
		tempObj.arrayPayers.push({
			payer: payer,
			amount: amount,
		});
		dispatch(updateAvailableKV({ ...tempObj }));
	}

	return (
		<Dialog
			maxWidth='lg'
			open={show}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle>Nuevo Pagadora - Importe:</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Complete el formulario para crear un nuevo Pagadora -
					Importe
				</DialogContentText>
				<hr />

				<Grid
					container
					spacing={2}
					style={{
						marginBottom: '10px',
					}}
				>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							label='Pagadora'
							id='outlined-start-adornment'
							onChange={(e) => {
								setPayer(e.target.value);
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							label='Importe'
							id='outlined-start-adornment'
							type='number'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										$
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position='end'>
										MXN
									</InputAdornment>
								),
							}}
							onChange={(e) => {
								setAmount(e.target.value);
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color='error' onClick={handleClose}>
					Cancelar
				</Button>
				<Button
					onClick={() => {
						setPayerAmountValues();
						handleChange();
						handleClose();
						setValuesZero();
					}}
					autoFocus
				>
					Crear
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export const ItemTexts = ({ title = '', text = '' }) => (
	<>
		<Typography variant='p'>
			<b>{title}</b>
		</Typography>
		<Typography variant='h6'>{text}</Typography>
	</>
);
