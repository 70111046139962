import axios from 'axios';
import { handleError } from '../helpers/utils';
import { types } from '../types/types';
import { getToken } from './auth';

// Set application
export const setCurrentApplication = (data) => ({
	type: types.currentApplication,
	payload: data,
});

// Get results
export const getResults = async (startDate, endDate) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/application/results-of-the-day',
		headers: { Authorization: 'Bearer ' + token },
		data: { start_date: startDate, end_date: endDate },
	});
};

export const getFundingsResults = async (date) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/application/fundings-results-of-the-day',
		headers: { Authorization: 'Bearer ' + token },
		data: { date },
	});
};

// General query for get applications
export const getApplication = async (query) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/${query ?? ''}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get application info by id
export const getApplicationInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

export const getApplicationInfoKey = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/?key=${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get all applications
export const getApplications = async () => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: '/api/application?deleted=0',
		headers: { Authorization: 'Bearer ' + token },
	});
};

export const getPaginatedApplications = async (page) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/?page=${page}&ordering=-creating_at`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register application
export const registerApplication = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/application/create/',
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update application
export const updateApplication = async (id, data) => {
	const token = getToken();
	let newData = data;
	if (typeof newData?.executive === 'object') {
		newData = {
			...newData,
			executive: data.executive.id,
		};
	}
	if (typeof newData?.promoter === 'object') {
		newData = {
			...newData,
			promoter: data?.promoter?.id,
		};
	}
	if (typeof newData?.client === 'object') {
		newData = {
			...newData,
			client: data?.client?.id,
		};
	}

	if (newData?.fundings?.length > 0) {
		if (typeof newData?.fundings[0] === 'object') {
			newData = {
				...newData,
				fundings: newData.fundings.map(({ id }) => id),
			};
		}
	}
	return await axios({
		method: 'put',
		url: `/api/application/update/${id}`,
		data: newData,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Map db key field
export const whichDBKey = (key) => {
	switch (key) {
		case 'comisionesBancarias':
			return 'bank_commission';
		case 'costoProvedor4ST':
			return 'supplier_expense_4ST';
		case 'costoEfectivo':
			return 'cost_effective';
		case 'costoFID':
			return 'fid_expense';
		case 'costoSC':
			return 'sc_expense';
		case 'costoSindicato':
			return 'union_expense';
		// conventional_commissions
		// spei_commission
	}
};

// Update application field
export const handleUpdateFieldApp = async (application, field, value) => {
	return await updateApplication(application.id, {
		...application,
		[field]: value,
	});
};

export const handleUpdateFieldOperativeExpenses = (application) => {
	return async (_, getState) => {
		if (application) {
			try {
				let finalData = {};
				const tmp = getState().request.decOperatingExpenses;
				const tmpNum = getState().request.operatingExpenses;

				finalData = {
					...application,
					// Foreign values

					// Boolean values
					account_kv: tmp.cuentaKV === true,
					bank_commission: tmp.comisionesBancarias === true,
					supplier_expense_4ST: tmp.costoProvedor4ST === true,
					cost_effective: tmp.costoEfectivo === true,
					fid_expense: tmp.costoFID === true,
					sc_expense: tmp.costoSC === true,
					union_expense: tmp.costoSindicato === true,

					// Number values
					spei_commission: parseFloat(tmp?.comisionSpei),
					conventional_commissions: parseFloat(
						tmpNum?.comisionesConvencionales
					),
					bank_commission_value: parseFloat(
						tmpNum?.comisionesBancarias
					),
					supplier_expense_4ST_value: parseFloat(
						tmpNum?.costoProvedor4ST
					),
					//Number values of Models
					cost_effective_value: parseFloat(tmpNum?.costoEfectivo),
					extra_cost: parseFloat(tmpNum?.costoExtra),
					cost_transfer: parseFloat(tmpNum?.costoTraslado),
					percentage_transfer: parseFloat(tmpNum?.porcentajeTraslado),
					fid_expense_value: parseFloat(tmpNum?.costoFID),
					sc_expense_value: parseFloat(tmpNum?.costoSC),
					union_expense_value: parseFloat(tmpNum?.costoSindicato),
					spei_commission_value: parseFloat(tmpNum?.comisionSpei),
					comision_edenred_kv: parseFloat(tmpNum?.comisionEdenredKV),
					comision_edenred_corpo: parseFloat(
						tmpNum?.comisionEdenredCorpo
					),
					comision_facil: parseFloat(tmpNum?.comisionFacil),
					gastos_promotoria: parseFloat(tmpNum?.gastosPromotoria),
					gastos_abono_a_deuda: parseFloat(tmpNum?.gastosAAbonoDeuda),
					//Fundings
					amount_fid_payer: parseFloat(tmpNum?.amountFidPayer),
					amount_sc_payer: parseFloat(tmpNum?.amountScPayer),
					amount_sindicate_payer: parseFloat(
						tmpNum?.amountSindicatePayer
					),
					amount_spei_payer: parseFloat(tmpNum?.amountSpeiPayer),
					amount_edenred_payer: parseFloat(
						tmpNum?.amountEdenredPayer
					),
					amount_edenred_corpo_payer: parseFloat(
						tmpNum?.AmountEdenredCorpoPayer
					),

					amount_facil_payer: parseFloat(tmpNum?.amountFacilPayer),
					payer_fid_payer: tmpNum?.payerFid,
					payer_sc_payer: tmpNum?.payerSC,
					payer_sindicate_payer: tmpNum?.payerSindicate,
					payer_spei_payer: tmpNum?.payerSpei,
					payer_edenred_payer: tmpNum?.payerEdenred,
					payer_edenred_corpo_payer: tmpNum?.payerEdenredCorpo,
					payer_facil_payer: tmpNum?.payerFacil,
				};

				await updateApplication(application.id, finalData);
			} catch (error) {
				// Feedback
				handleError(
					error,
					'Ocurrió un error al intentar actualizar el gasto operativo'
				);
			}
		} else {
			const errorMsg = 'La solicitud no está definida';
			handleError(errorMsg, errorMsg);
		}
	};
};

// Delete application
export const deleteApplication = (id) => {
	const token = getToken();
	return axios({
		method: 'delete',
		url: `/api/application/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete application fake
export const deleteApplicationFake = async (data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/application/update/${data.id}`,
		headers: { Authorization: 'Bearer ' + token },
		data: {
			...data,
			promoter: data.promoter.id,
			executive: data.executive.id,
			client: data.client.id,
			deleted: true,
		},
	});
};

// Get deposit info by id
export const getDepositInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/deposit/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get all deposits
export const getDeposits = async () => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: '/api/application/deposit',
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get all deposits
export const getDepositsByApplicationID = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/deposit?application=${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register deposit
export const registerDeposit = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/application/deposit/create/',
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update deposit
export const updateDeposit = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/application/deposit/update/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete deposit
export const deleteDeposit = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/application/deposit/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get bank commission by concept
export const getBankCommissionByConcept = async (concept) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/bank-commission?concept=${concept}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get promoter info by id
export const getPromoterInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/catalog/promoter/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get all users
export const getPromoters = async (token) => {
	return await axios({
		method: 'get',
		url: '/api/catalog/promoter',
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get all deposits
export const getReturnsAndCommissionsByApplicationID = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/return-commission?application=${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Download Excel
export const getExcel = async (
	table,
	type = 'catalog',
	startDate,
	endDate,
	isExcel = false,
	filename = undefined
) => {
	console.log('APPLICATION');
	console.log({
		table,
		type,
		startDate,
		endDate,
		isExcel,
		filename,
	});
	const token = getToken();

	// Request configuration
	const config = {
		method: 'post',
		url: `/api/${type}/${table}`,
		headers: {
			Authorization: 'Bearer ' + token,
		},
		data: {
			start_date: startDate,
			end_date: endDate,
			is_excel: isExcel,
		},
		responseType: 'blob', // Response type blob to get a binary response
	};

	return await axios(config)
		.then((response) => {
			try {
				// Create a URL for the downloaded file
				const url = window.URL.createObjectURL(new Blob([response.data]));
	
				// Create a temporary <a> element and set the URL as its href
				const link = document.createElement('a');
				link.href = url;
	
				// Set the filename for the downloaded file
				link.setAttribute(
					'download',
					filename ? `${filename}.xlsx` : 'Resumen.xlsx'
				);
	
				// Append the <a> element to the DOM and trigger the click event to start the download
				document.body.appendChild(link);
				link.click();
	
				// Clean up by removing the temporary <a> element and revoking the URL object
				link.parentNode.removeChild(link);
				window.URL.revokeObjectURL(url);
			} catch (e) {
				handleError(error, 'Error al descargar el excel [X]');
			}
		})
		.catch((error) => {
			handleError(error, 'Error al descargar el excel');
		});
};
