// Money
export function money(amount = 0) {
	let tempAmount = Number(amount);
	// return `$ ${Number(amount.toFixed(2)).toLocaleString()} MXN`;
	return `${formatterCurrency(tempAmount)}`;
}

// Create our number formatter.
export function formatterCurrency(num) {
	return new Intl.NumberFormat('es-MX', {
		style: 'currency',
		currency: 'MXN',
	}).format(num);
}
