import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { money } from '../../helpers/money';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
	setCurrentApplication,
	updateApplication,
} from '../../actions/applications';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux';
import { NewFundingOE } from './NewFundingOE';
import { FundingsAvailableOE } from './FundingsAvailableOE';
import TextField from '@mui/material/TextField';
import { handleError, handleSuccess } from '../../helpers/utils';
import {
	updateOperatingExpenses,
	updatePerceptionsInvoice,
} from '../../actions/requests';
import Switch from '@mui/material/Switch';
import CurrencyFormat from 'react-currency-format';

const Android12Switch = styled(Switch)(({ theme }) => ({
	padding: 8,
	'& .MuiSwitch-track': {
		borderRadius: 22 / 2,
		'&:before, &:after': {
			content: '""',
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			width: 16,
			height: 16,
		},
		'&:before': {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main)
			)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
			left: 12,
		},
		'&:after': {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main)
			)}" d="M19,13H5V11H19V13Z" /></svg>')`,
			right: 12,
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: 'none',
		width: 16,
		height: 16,
		margin: 2,
	},
}));

export const GastosOperativosInput = ({
	inputName,
	stateValue,
	setDecOperatingExpenses,
	showState,
	objKey,
	typeFunding = '',
	hasPayer,
	selectedPayer,
	selectedAmount,
	dbKeyValue,
	dbKeyDec,
	calculationFunction,
	calculationFunction2,
	dbFunding,
	dbAmount,
}) => {
	const dataBaseVariables = {
		'COMISIONES BANCARIAS': 'bank_commission_safeguards',
		'SEGUNDA COMISIÓN BANCARIA': 'second_bank_commission_safeguards',
		'COSTO CORPORATIVO': 'expenses_corporative_safeguards',
	};
	// Dispatch
	const dispatch = useDispatch();

	// Redux state
	const { application = null, commissionCorporative = 0 } = useSelector(
		(state) => state.request
	);
	const fundings = useSelector((state) => state.request.application.fundings);
	const decOperatingExpenses = useSelector(
		(state) => state.request.decOperatingExpenses
	);
	//handle Dialog Fundings
	const [show3, setShow3] = useState(false);
	const handleClose3 = () => setShow3(false);

	const [payerTemp, setPayerTemp] = useState('');
	const [amountTemp, setAmountTemp] = useState(0);

	//Handle Informacion - Fondeo
	const [openPayerDialogReturn, setOpenPayerDialogReturn] =
		React.useState(false);
	const handlePayerDialogReturn = () => setOpenPayerDialogReturn(false);

	const applicationRequested = useSelector(
		(state) => state.request.application
	);

	const objectOperativeExpenses = useSelector(
		(state) => state.request.operatingExpenses
	);

	/**
	 * Check if both 'payerTemp' and 'amountTemp' values are filled to send information to the database.
	 *
	 * This function checks whether both 'payerTemp' and 'amountTemp' have valid values. If both values are
	 * not empty or zero, it updates specific fields in the 'applicationUsed' object, sends the updated data
	 * to the database, and dispatches actions to update 'objectOperativeExpenses' and 'application' states.
	 *
	 * @param {Object} applicationUsed - The application object used for updating data.
	 */
	function checkIfHasValue(applicationUsed) {
		if (payerTemp !== '' && amountTemp != 0 && amountTemp != '') {
			let applicationTemp = { ...applicationUsed };

			// Update specific fields in the temporary object with 'payerTemp' and 'amountTemp' values
			applicationTemp[dbFunding] = payerTemp;
			applicationTemp[dbAmount] = Number(amountTemp);

			// Update the application data in the database with the modified 'applicationTemp'
			updateApplication(applicationTemp.id, {
				...applicationTemp,
			}).then(({ data }) => {
				// Create a temporary copy of 'objectOperativeExpenses' for modification
				let tempOperativeExpenses = { ...objectOperativeExpenses };

				// Update 'tempOperativeExpenses' with 'amountTemp' and 'payerTemp'
				tempOperativeExpenses[selectedAmount] = amountTemp;
				tempOperativeExpenses[selectedPayer] = payerTemp;

				// Dispatch an action to update 'objectOperativeExpenses' with the modified 'tempOperativeExpenses'
				dispatch(updateOperatingExpenses(tempOperativeExpenses));

				// Dispatch an action to update the 'application' state with the modified 'data'
				dispatch(
					setCurrentApplication({
						...data,
						fundings: applicationUsed.fundings,
						promoter: applicationUsed.promoter,
						executive: applicationUsed.executive,
					})
				);

				handleSuccess('Pagadora actualizada existosamente');
			});
		}
	}

	/**
	 * Update 'payerTemp' and 'amountTemp' state variables based on 'objectOperativeExpenses' values.
	 *
	 * This `useEffect` hook is responsible for updating the 'payerTemp' and 'amountTemp' state variables based on
	 * the values found in 'objectOperativeExpenses' for the selected payer and amount fields. It listens to changes
	 * in 'objectOperativeExpenses[selectedPayer]' and 'objectOperativeExpenses[selectedAmount]' and sets 'payerTemp'
	 * and 'amountTemp' accordingly. It also logs relevant information for debugging.
	 */
	useEffect(() => {
		setPayerTemp(objectOperativeExpenses[selectedPayer]);
		setAmountTemp(objectOperativeExpenses[selectedAmount]);
	}, [
		objectOperativeExpenses[selectedPayer],
		objectOperativeExpenses[selectedAmount],
	]);

	return (
		<Grid
			container
			spacing={2}
			style={{
				marginBottom: '10px',
			}}
		>
			<Grid item xs={4}>
				<Typography>{inputName}</Typography>
			</Grid>

			<Grid item xs={6}>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<Typography>{money(showState)}</Typography>
					</Grid>
					{/* {inputName === 'COSTO CORPORATIVO' ||
                    inputName === 'COMISIONES BANCARIAS' ||
                    inputName === 'SEGUNDA COMISIÓN BANCARIA' ? (
                        <Grid
                            item
                            xs={4}
                        >
                            {money(application[dataBaseVariables[inputName]])}
                        </Grid>
                    ) : (
                        <></>
                    )} */}
					{hasPayer ? (
						<>
							<Grid item xs={4}>
								<TextField
									id='outlined-basic'
									label='Pagadora'
									variant='standard'
									value={payerTemp}
									style={{ width: '80%' }}
									onChange={(e) => {
										setPayerTemp(e.target.value);
									}}
									onBlurCapture={(element) => {
										checkIfHasValue(applicationRequested);
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<CurrencyFormat
									customInput={TextField}
									variant='standard'
									label='Monto'
									// type='number'

									thousandSeparator={true}
									prefix={'$'}
									allowNegative={false}
									value={amountTemp}
									onChange={({ target }) => {
										setAmountTemp(
											Number(
												target.value.replace(
													/[^0-9.-]+/g,
													''
												)
											)
										);
									}}
									onBlurCapture={() => {
										checkIfHasValue(applicationRequested);
									}}
								/>
							</Grid>
						</>
					) : (
						<></>
					)}
				</Grid>
			</Grid>

			<Grid
				item
				xs={2}
				style={{
					padding: 0,
					margin: '15px 0',
					width: '40%',
				}}
			>
				<FormControl fullWidth variant='standard'>
					<FormControlLabel
						control={
							<Android12Switch
								checked={stateValue}
								size={'large'}
								onChange={async (event) => {
									let calculationTotal = 0;
									let calculationTotal2 = 0;
									if (event.target.checked) {
										calculationTotal = calculationFunction;
										if (
											inputName === 'COSTO CORPORATIVO' ||
											inputName ===
												'COMISIONES BANCARIAS' ||
											inputName ===
												'SEGUNDA COMISIÓN BANCARIA'
										) {
											calculationTotal2 =
												commissionCorporative;
										}
									}

									updateApplication(application.id, {
										...application,
										[dbKeyValue]: Number(calculationTotal),
										[dbKeyDec]: event.target.checked,
										[dataBaseVariables[inputName]]:
											Number(calculationTotal2),
									}).then((data) => {
										dispatch(
											setCurrentApplication({
												...data,
												[dataBaseVariables[inputName]]:
													Number(calculationTotal2),
												fundings: application.fundings,
												promoter: application.promoter,
												executive:
													application.executive,
											})
										);
										setDecOperatingExpenses(
											dbKeyDec,
											!event.target.checked,
											dbKeyValue,
											objKey,
											calculationTotal,
											inputName,
											Number(calculationTotal2),
											dispatch,
											decOperatingExpenses,
											objectOperativeExpenses,
											application
										);
									});
								}}
							/>
						}
					/>
				</FormControl>
				<NewFundingOE
					show={show3}
					handleClose={handleClose3}
					typeFunding={typeFunding}
				/>
				<FundingsAvailableOE
					show={openPayerDialogReturn}
					handleClose={handlePayerDialogReturn}
					fundings={fundings}
					typeFunding={typeFunding}
				/>
			</Grid>
		</Grid>
	);
};
