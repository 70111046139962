import { setCurrentApplication } from '../../actions/applications';
import {
	updateDecOperatingExpenses,
	updateOperatingExpenses,
} from '../../actions/requests';

export const setDecOperatingExpenses = (
	decKey,
	decValue,
	dbKey,
	oeKey,
	value,
	stringValue = '',
	secondOptionOperatingExpenses = 0,
	dispatch,
	decOperatingExpenses,
	objectOperativeExpenses,
	application
) => {
	// Define mappings for database variables and Redux variables related to the context
	const dataBaseVariables = {
		'COMISIONES BANCARIAS': 'bank_commission_safeguards',
		'SEGUNDA COMISIÓN BANCARIA': 'second_bank_commission_safeguards',
		'COSTO CORPORATIVO': 'expenses_corporative_safeguards',
	};
	const reduxVariablesSecondOption = {
		'COMISIONES BANCARIAS': 'bankCommissionSafeguard',
		'SEGUNDA COMISIÓN BANCARIA': 'secondBankCommissionSafeguard',
		'COSTO CORPORATIVO': 'corporativeCostSafeguard',
	};

	// Dispatch actions to update the corresponding state variables
	dispatch(
		updateDecOperatingExpenses({
			...decOperatingExpenses,
			[oeKey]: decValue,
		})
	);
	dispatch(
		updateOperatingExpenses({
			...objectOperativeExpenses,
			[oeKey]: value,
			[reduxVariablesSecondOption[stringValue]]:
				secondOptionOperatingExpenses,
		})
	);
	dispatch(
		setCurrentApplication({
			...application,
			[dbKey]: value,
			[decKey]: decValue,
			[dataBaseVariables[stringValue]]: secondOptionOperatingExpenses,
			fundings: application.fundings,
			promoter: application.promoter,
			executive: application.executive,
		})
	);
};
