import React, { useEffect, useState } from 'react';
import {
    Alert,
    Button,
    IconButton,
    Tooltip,
    Stack,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import {
    getPromoterName,
    handleConfirmModal,
    handleError,
    handleSuccess,
} from '../../helpers/utils';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {
    deleteDeposit,
    getDepositInfo,
    setCurrentApplication,
    updateApplication,
} from '../../actions/applications';
import { NewDepositForm } from './NewDepositForm';
import { calculateAndLoadDeposits } from '../../containers/ApplicationDetail';
import { formatDateMX } from '../../helpers/hours';
import { formatterCurrency, money } from '../../helpers/money';
import {
    checkCommissionCorporative,
    checkCompanyFunding,
    checkCompanyType,
    checkCost,
    checkCostCompany,
    checkCostImport,
    checkExpense,
    checkExpenseImport,
    checkExpenseName,
    checkImportFunding,
} from '../../helpers/ApplicationDetailCalculus';
import {
    getSafeguardsPerClient,
    registerSafeguard,
    registerSafeguardTotal,
} from '../../actions/returnAndCommissions';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { cleanDecimalNumber } from '../../helpers/CleanDecimalNumber';
import { getWithdrawTotal, setListOfSafeguards } from '../../actions/requests';
import { NewWithdrawForm } from './NewWithdrawForm';
import { saveApplication } from '../../helpers/SaveApplication';

// Users table
export const ApplicationDeposits = ({ application = null }) => {
    // Dispatch
    const dispatch = useDispatch();
    const totalDeposit = useSelector((state) => state.request.depositTotal);
    const rowsTemp = useSelector((state) => state.request.returnAndCommission);
    const applicationTemp = useSelector((state) => state.request.application);
    const withdrawalTotal = useSelector((state) => state.request.withdrawTotal);
    const clientTemp = useSelector((state) => state.request.application.client);
    const totalReturn = useSelector(
        (state) => state.request.application.total_return
    );
    const { listOfSafeguardsPerClient = [] } = useSelector(
        (state) => state.request
    );
    const [withdrawCounter , setWithdrawCounter] = useState(0)

    // Deposits
    const {
        deposits: rows = [],
        depositTotal = 0,
        commissionTotal = 0,
        commissionCorporative = 0,
        commissionBank = 0,
    } = useSelector((state) => state.request);

    // Catalogs
    const {
        clients = [],
        banks = [],
        bankCommissions = [],
        companies = [],
        conscompanies = [],
        transfers = [],
    } = useSelector((state) => state.catalogs);

    // Current user id
    const [currentDeposit, setCurrentDeposit] = useState(null);
    const [totalImportFunding, setTotalImportFunding] = useState(null);

    // Dialog
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);

    const [showWithdraw, setShowWithdraw] = useState(false);
    const handleCloseWithdraw = () => setShowWithdraw(false);
    const handleOpenWithdraw = () => setShowWithdraw(true);

    const [depositCorrect, setDepositCorrect] = useState('');
    const [totalSafeguards, setTotalSafeguards] = useState(null);
    const [safeguards, setSafeguards] = useState(null);
    // When deposits are updated, the total imports variable is updated

    const saveCreditToDebt = () => {
        updateApplication(application.id, {
            ...applicationTemp,
            total_imports: depositTotal,
        }).then(({ data }) => {
            dispatch(
                setCurrentApplication({
                    ...data,
                    fundings: application.fundings,
                    promoter: application.promoter,
                    executive: application.executive,
                })
            );
            handleSuccess('DEPÓSITOS GUARDADOS');
        });
    };
    
    // Update when component unmount
    useEffect(() => {
        return () => {
            dispatch(saveApplication());
        };
    },[]);

    useEffect(() => {
        const totalBalance = rowsTemp.reduce((accumulator, object) => {
            return accumulator + Number(object?.balance || 0);
        }, 0);

        const totalImportFunding = rows.reduce((accumulator, object) => {
            return accumulator + Number(object?.import_funding || 0);
        }, 0);

        setTotalImportFunding(totalImportFunding);

        const decition = Number(totalDeposit - totalBalance).toFixed(2);
        let value = 0;
        if (decition < -1) {
            setDepositCorrect(decition);
        } else if (decition > 1) {
            setDepositCorrect(decition);
        } else {
            setDepositCorrect(0);
        }
    }, [show, rowsTemp, totalDeposit]);

    // Handle delete user with modal confirmation
    const handleDeleteDeposit = (depositId) => {
        deleteDeposit(depositId)
            .then(() => {
                handleSuccess('Depósito eliminado correctamente.');
                const newDeposits = rows.filter(
                    (item) => item.id !== depositId
                );
                calculateAndLoadDeposits(dispatch, newDeposits);
            })
            .catch((error) => {
                handleError(error, 'No se pudo eliminar el depósito.');
            });
    };

    useEffect(() => {
        let clientNumber = null;
        if (clientTemp.id == null) {
            clientNumber = clientTemp;
        } else {
            clientNumber = clientTemp.id;
        }
        getSafeguardsPerClient({ client: clientNumber }).then(({ data }) => {
            safeguardsDisplay(data.results);
        });
    }, []);

    const safeguardsDisplay = (arrSafeguards) => {
        const totalBalancePerSafeguard = arrSafeguards.reduce(
            (accumulator, object) => {
                return accumulator + Number(object?.balance || 0);
            },
            0
        );
        setSafeguards(arrSafeguards);
        setTotalSafeguards(totalBalancePerSafeguard);
    };

    const formatDeposits = (deposits) => {
        // Create rows for Datagrid
        return deposits.map((item) => ({
            id: item?.id,
            col1: item?.application?.key,
            col2: item?.key,
            col3: formatDateMX(item?.date),
            col4: item?.client?.name,
            col5: getPromoterName(item?.application?.promoter),
            col6: checkCompanyType(item),
            col7: item?.bank?.name,
            col8: formatterCurrency(item?.deposit || 0),
            col9: checkCostCompany(item),
            col10: checkCost(item),
            col11: checkCostImport(item),
            col12: checkExpenseName(item),
            col13: checkExpense(item),
            col14: checkExpenseImport(item),
            col15: formatterCurrency(item?.commission),
            col17: item?.company_type,
            col18: checkCommissionCorporative(item),
            col19: checkCompanyFunding(item),
            col20: checkImportFunding(item),
        }));
    };

    /**
     * Handles the return of a safeguard.
     *
     * @param {Object} safeguardToReturn - The safeguard to be returned.
     */
    const handleReturnSafeguard = (safeguardToReturn) => {
        const importSafeguard = Number(
            cleanDecimalNumber(safeguardToReturn.col8)
        );
        console.log('safeguardToReturn', importSafeguard);

        handleConfirmModal(
            'Precaución',
            '¿Desea restaurar el resguardo?',
            'warning',
            'Sí, restaurar'
        ).then(({ isConfirmed }) => {
            if (isConfirmed) {
                registerSafeguard({
                    balance: importSafeguard,
                    application: Number(applicationTemp.id),
                    client_application:
                        applicationTemp.client.id == null
                            ? applicationTemp.client
                            : applicationTemp.client.id,
                    company_name: 'Resguardo retornado',
                })
                    .then(({ data }) => {
                        dispatch(
                            setListOfSafeguards([
                                ...listOfSafeguardsPerClient,
                                data,
                            ])
                        );
                        // Log success message or provide feedback
                        handleDeleteDeposit(
                            safeguardToReturn.id,
                            '¿Seguro que deseas restaurar el depósito?'
                        );
                    })
                    .catch((error) => {
                        // Handle error by displaying an error message

                        handleError(error, 'No se pudo registrar el retorno.');
                    });
            }
        });
    };

    // Define columns
    const columns = [
        {
            field: 'col16',
            headerName: 'Acciones',
            width: 150,
            // Add actions to each case
            renderCell: ({ row }) => {
                return (
                    <>
                        {containsResguardoWord(row.col17) ? (
                            <Tooltip title='Retornar resguardo'>
                                <IconButton
                                    style={{ color: '#143f6c' }}
                                    aria-label='delete'
                                    color='primary'
                                    onClick={() => handleReturnSafeguard(row)}
                                >
                                    <AutorenewIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <>
                                <Tooltip title='Editar'>
                                    <IconButton
                                        style={{ color: '#143f6c' }}
                                        aria-label='edit'
                                        color='primary'
                                        onClick={() => {
                                            getDepositInfo(row.id).then(
                                                ({ data }) => {
                                                    setCurrentDeposit(data);
                                                    handleOpen();
                                                }
                                            );
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Eliminar'>
                                    <IconButton
                                        style={{ color: '#143f6c' }}
                                        aria-label='delete'
                                        color='primary'
                                        onClick={() => {
                                            handleConfirmModal(
                                                'Precaución',
                                                '¿Desea eliminar el depósito?',
                                                'warning',
                                                'Sí, restaurar'
                                            )
                                                .then(({ isConfirmed }) => {
                                                    if (isConfirmed) {
                                                        handleDeleteDeposit(
                                                            row.id,
                                                            '¿Seguro que deseas borrar el depósito?'
                                                        );
                                                    }
                                                })
                                                .catch((error) => {
                                                    // Handle error by displaying an error message

                                                    handleError(
                                                        error,
                                                        'No se pudo eliminar el depósito.'
                                                    );
                                                });
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </>
                );
            },
        },
        {
            field: 'col17',
            headerName: 'Tipo de Empresa',
            width: 200,
            renderCell: ({ row }) => (
                <Tooltip title={row.col17}>
                    {application.folio == 'RETIRO DE RESGUARDO' ? (
                        <span className='table-cell-trucate'>
                            RESGUARDO {row.col17}
                        </span>
                    ) : (
                        <span className='table-cell-trucate'>{row.col17}</span>
                    )}
                </Tooltip>
            ),
        },
        {
            field: 'col1',
            headerName: 'Solicitud',
            width: 90,
            renderCell: ({ row }) => (
                <Tooltip title={row.col1}>
                    <span className='table-cell-trucate'>{row.col1}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col2',
            headerName: 'Depósito',
            width: 90,
            renderCell: ({ row }) => (
                <Tooltip title={row.col2}>
                    <span className='table-cell-trucate'>{row.col2}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col3',
            headerName: 'Fecha',
            width: 120,
            renderCell: ({ row }) => (
                <Tooltip title={row.col3}>
                    <span className='table-cell-trucate'>{row.col3}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col4',
            headerName: 'Nombre del cliente',
            width: 250,
            renderCell: ({ row }) => (
                <Tooltip title={row.col4}>
                    <span className='table-cell-trucate'>{row.col4}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col5',
            headerName: 'Promotor',
            width: 150,
            renderCell: ({ row }) => (
                <Tooltip title={row.col5}>
                    <span className='table-cell-trucate'>{row.col5}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col6',
            headerName: 'Empresa operadora',
            width: 250,
            renderCell: ({ row }) => (
                <Tooltip title={row.col6}>
                    <span className='table-cell-trucate'>{row.col6}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col7',
            headerName: 'Banco',
            width: 150,
            renderCell: ({ row }) => (
                <Tooltip title={row.col7}>
                    <span className='table-cell-trucate'>{row.col7}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col8',
            headerName: 'Importe',
            width: 150,
            renderCell: ({ row }) => (
                <Tooltip title={row.col8}>
                    <span className='table-cell-trucate'>{row.col8}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col9',
            headerName: 'Empresa costo',
            width: 250,
            renderCell: ({ row }) => (
                <Tooltip title={row.col9}>
                    <span className='table-cell-trucate'>{row.col9}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col10',
            headerName: '% Costo',
            width: 120,
            renderCell: ({ row }) => (
                <Tooltip title={row.col10}>
                    <span className='table-cell-trucate'>{row.col10}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col11',
            headerName: 'Importe costo',
            width: 150,
            renderCell: ({ row }) => (
                <Tooltip title={row.col11}>
                    <span className='table-cell-trucate'>{row.col11}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col12',
            headerName: 'Empresa gasto',
            width: 250,
            renderCell: ({ row }) => (
                <Tooltip title={row.col12}>
                    <span className='table-cell-trucate'>{row.col12}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col13',
            headerName: '% Gasto',
            width: 120,
            renderCell: ({ row }) => (
                <Tooltip title={row.col13}>
                    <span className='table-cell-trucate'>{row.col13}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col14',
            headerName: 'Importe gasto',
            width: 150,
            renderCell: ({ row }) => (
                <Tooltip title={row.col14}>
                    <span className='table-cell-trucate'>{row.col14}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col15',
            headerName: 'Comisión',
            width: 150,
            renderCell: ({ row }) => (
                <Tooltip title={row.col15}>
                    <span className='table-cell-trucate'>{row.col15}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col18',
            headerName: 'Comisión Corporativo',
            width: 150,
            renderCell: ({ row }) => (
                <Tooltip title={row.col18}>
                    <span className='table-cell-trucate'>{row.col18}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col19',
            headerName: 'Empresa Fondeo Corporativo',
            width: 150,
            renderCell: ({ row }) => (
                <Tooltip title={row.col19}>
                    <span className='table-cell-trucate'>{row.col19}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col20',
            headerName: 'Importe/Fondeo',
            width: 150,
            renderCell: ({ row }) => (
                <Tooltip title={row.col20}>
                    <span className='table-cell-trucate'>{row.col20}</span>
                </Tooltip>
            ),
        },
    ];

    const columns2 = [
        {
            field: 'col16',
            headerName: 'Acciones',
            width: 150,
            // Add actions to each case
            renderCell: ({ row }) => (
                <>
                    {applicationTemp.folio !== 'RETIRO DE RESGUARDO' ? (
                        <Tooltip title='Editar'>
                            <IconButton
                                style={{ color: '#143f6c' }}
                                aria-label='edit'
                                color='primary'
                                onClick={() => {
                                    console.log('Es este edit owo');
                                    getDepositInfo(row.id).then(({ data }) => {
                                        console.log('Data: ', data);
                                        setCurrentDeposit(data);
                                        handleOpen();
                                    });
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                    <Tooltip title='Eliminar'>
                        <IconButton
                            style={{ color: '#143f6c' }}
                            aria-label='delete'
                            color='primary'
                            onClick={() => handleDeleteDeposit(row.id)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
        {
            field: 'col17',
            headerName: 'Tipo de Empresa',
            width: 150,
            renderCell: ({ row }) => (
                <Tooltip title={row.col17}>
                    <span className='table-cell-trucate'>{row.col17}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col1',
            headerName: 'Solicitud',
            width: 90,
            renderCell: ({ row }) => (
                <Tooltip title={row.col1}>
                    <span className='table-cell-trucate'>{row.col1}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col2',
            headerName: 'Depósito',
            width: 90,
            renderCell: ({ row }) => (
                <Tooltip title={row.col2}>
                    <span className='table-cell-trucate'>{row.col2}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col3',
            headerName: 'Fecha',
            width: 120,
            renderCell: ({ row }) => (
                <Tooltip title={row.col3}>
                    <span className='table-cell-trucate'>{row.col3}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col4',
            headerName: 'Nombre del cliente',
            width: 250,
            renderCell: ({ row }) => (
                <Tooltip title={row.col4}>
                    <span className='table-cell-trucate'>{row.col4}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col5',
            headerName: 'Promotor',
            width: 150,
            renderCell: ({ row }) => (
                <Tooltip title={row.col5}>
                    <span className='table-cell-trucate'>{row.col5}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col6',
            headerName: 'Empresa Fondeo Corporativo',
            width: 250,
            renderCell: ({ row }) => (
                <Tooltip title={row.col6}>
                    <span className='table-cell-trucate'>{row.col6}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col7',
            headerName: 'Caja',
            width: 150,
            renderCell: ({ row }) => (
                <Tooltip title={row.col7}>
                    <span className='table-cell-trucate'>{row.col7}</span>
                </Tooltip>
            ),
        },
        {
            field: 'col8',
            headerName: 'Importe',
            width: 150,
            renderCell: ({ row }) => (
                <Tooltip title={row.col8}>
                    <span className='table-cell-trucate'>{row.col8}</span>
                </Tooltip>
            ),
        },
    ];

    const containsResguardoWord = (str) => {
        return str.includes('Resguardo');
    };

    function countResguardoInArray(array) {
        return array.reduce((count, obj) => containsResguardoWord(obj.company_type) ? count + 1 : count, 0);
    }
    

    return (
        <div>
            <Stack
                direction='row'
                alignItems='flex-end'
                justifyContent='space-between'
                style={{
                    border: '1px solid white',
                    padding: '5px',
                    width: '100%',
                    marginBottom: '10px',
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={10}
                        >
                            <Typography
                                variant='h5'
                                gutterBottom
                            >
                                Depósitos {`(${rows.length-countResguardoInArray(rows)})`} | R.R {`(${countResguardoInArray(rows)})`}
                            </Typography>

                            <br />
                        </Grid>
                        <Grid
                            item
                            xs={2}
                        >
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    saveCreditToDebt();
                                }}
                                sx={{ mb: 1 }}
                                fullWidth
                            >
                                <SaveIcon /> Guardar
                            </Button>

                            <Button
                                variant='contained'
                                style={{ backgroundColor: '#143f6c' }}
                                sx={{ mb: 1 }}
                                onClick={() => {
                                    setCurrentDeposit(null);
                                    {applicationTemp?.folio !==
                                        'RETIRO DE RESGUARDO'
                                            ? handleOpen()
                                            : handleOpenWithdraw()}
                                }}
                                fullWidth
                            >
                                {applicationTemp?.folio !==
                                'RETIRO DE RESGUARDO'
                                    ? 'Nuevo depósito'
                                    : 'Nuevo resguardo'}
                            </Button>
                            {applicationTemp?.folio ===
                            'SOLICITUD CONVENCIONAL' ? (
                                <Button
                                    variant='contained'
                                    style={{ backgroundColor: '#143f6c' }}
                                    onClick={() => {
                                        setCurrentDeposit(null);
                                        handleOpenWithdraw();
                                    }}
                                    fullWidth
                                >
                                    Retiro de resguardo
                                </Button>
                            ) : (
                                <></>
                            )}

                            <br />
                        </Grid>
                        <Grid
                            item
                            xs={3}
                        >
                            <ItemTexts
                                title='Total de importes'
                                text={money(depositTotal)}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={3}
                        >
                            <ItemTexts
                                title='Total de comisiones bancarias'
                                text={money(commissionBank)}
                            />
                        </Grid>

                        {applicationTemp.folio !== 'RETIRO DE RESGUARDO' ? (
                            <Grid
                                item
                                xs={3}
                            >
                                <ItemTexts
                                    title={
                                        depositCorrect == 0
                                            ? `OPERACIÓN CORRECTA`
                                            : depositCorrect > 1
                                            ? 'SALDO POR ASIGNAR'
                                            : 'SALDO INSUFICIENTE - VERIFICAR'
                                    }
                                    text={money(depositCorrect)}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        <Grid
                            item
                            xs={3}
                        >
                            <ItemTexts
                                title={'Resguardos Disponibles'}
                                text={money(totalSafeguards)}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={3}
                        >
                            <ItemTexts
                                title='Total de Importe/Fondeo'
                                text={money(totalImportFunding)}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={3}
                        >
                            <ItemTexts
                                title='Total de comisiones corporativas'
                                text={money(commissionCorporative)}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Stack>

            <NewDepositForm
                application={application}
                data={currentDeposit}
                clients={clients}
                banks={banks}
                conscompanies={conscompanies}
                companies={companies}
                bankCommissions={bankCommissions}
                transfers={transfers}
                handleClose={handleClose}
                show={show}
                safeguards={safeguards}
                handleOperationSafeguards={safeguardsDisplay}
            />
            <NewWithdrawForm
                application={application}
                data={currentDeposit}
                clients={clients}
                banks={banks}
                conscompanies={conscompanies}
                companies={companies}
                bankCommissions={bankCommissions}
                transfers={transfers}
                handleClose={handleCloseWithdraw}
                showWithdraw={showWithdraw}
                safeguards={safeguards}
                handleOperationSafeguards={safeguardsDisplay}
            />
            {/* Datagrid table */}
            {rows.length > 0 ? (
                <DataGrid
                    autoHeight
                    rows={formatDeposits(rows)}
                    columns={
                        application.folio == 'SOLICITUD CONVENCIONAL' ||
                        application.folio == 'RETIRO DE RESGUARDO' ||
                        application.folio == 'COMISIONES DIRECTAS'
                            ? columns
                            : columns2
                    }
                    // autoPageSize
                    components={{ Toolbar: GridToolbar }}
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    pagination
                    rowsPerPageOptions={[12, 24, 36, 48, 60]}
                    pageSize={12}
                    onPageChange={(newPage) => {
                        setPageState((old) => ({ ...old, page: newPage + 1 }));
                    }}
                />
            ) : (
                <Alert severity='info'>No hay ningún depósito</Alert>
            )}
        </div>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);
