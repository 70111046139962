import axios from 'axios';
import { getToken } from './auth';

// Get return and commission info by id
export const getReturnAndCommissionInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/return-commission/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};


export const getReturnAndCommissionKey = async (key) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/return-commission/?key=${key}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get all return and commissions by application
export const getReturnAndCommissions = async (idApplication) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/return-commission?application=${idApplication}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};





export const getSafeguards = async (obj) => {
	
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/safeguards`,
		headers: { Authorization: 'Bearer ' + token },
		params: obj
	});
};

export const getSafeguardsPerClient = async (obj) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/safeguards/client`,
		headers: { Authorization: 'Bearer ' + token },
		params: obj
	});
};

export const deleteSafeguards = async (obj) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: `/api/application/delete-safeguards/`,
		headers: { Authorization: 'Bearer ' + token },
		data: obj
	});
};

export const getPromotersTotals = async (obj) => {
	
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/promoters-totals/`,
		headers: { Authorization: 'Bearer ' + token },
		params: obj
	});
};

export const getPromotersPerName = async (obj) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/promoters-name/`,
		headers: { Authorization: 'Bearer ' + token },
		params: obj
	});
};




// Register return and commission
export const registerReturnAndCommission = async (data) => {
	
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/application/return-commission/create/',
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update return and commission
export const updateReturnAndCommission = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/application/return-commission/update/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete return and commission
export const deleteReturnAndCommission = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/application/return-commission/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};


export const registerSafeguard = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/application/safeguards-copy/create/',
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

export const registerSafeguardTotal = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/application/safeguards-total/create/',
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};


export const getSafeguardTotal = async (query = '') => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/safeguards-total/get/?client_name=${query}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};


// COMMISSION SAFEGUARDS
export const getCommissionSafeguard = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/commission-safeguard/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

export const getCommissionSafeguards = async () => {
	
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/commission-safeguard`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register return and commission
export const registerCommissionSafeguard = async (data) => {
	
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/application/commission-safeguard/create/',
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update return and commission
export const updateCommissionSafeguard = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/application/commission-safeguard/update/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete return and commission
export const deleteCommissionSafeguard = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/application/commission-safeguard/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};