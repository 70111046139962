import React from 'react';
import {
	deletePrepaidCardCommission,
	getPrepaidCardCommissions,
} from '../../actions/catalogs';
import { PrepaidCardCommissionsTable } from './PrepaidCardCommissionsTable';

// Prepaid card commission Table
export const PrepaidCardCommission = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getPrepaidCardCommissions,
		deleteCatalog: deletePrepaidCardCommission,
		catalogSingular: 'Comisión de tarjeta prepago',
		catalogPlural: 'Comisiones de tarjetas prepago',
		pronounSingular: 'la',
		pronoun: 'a',
	};

	// Configuration for the Details Table
	const detailGridConfig = {
		gridItems: [
			{ xsWidth: 12, title: 'PLATAFORMA', code: 'platform' },
			{ xsWidth: 6, title: 'COMISIÓN', code: 'commission' },
			{ xsWidth: 6, title: 'IVA', code: 'iva' },
			{ xsWidth: 6, title: 'MONTO MÍNIMO', code: 'min_amount' },
			{ xsWidth: 6, title: 'COMISIÓN MÍNIMA', code: 'min_commission' },
			{ xsWidth: 12, title: 'Descripcion', code: 'description' },
		],
	};

	return (
		<PrepaidCardCommissionsTable
			config={configuration}
			detailGridConfig={detailGridConfig}
		/>
	);
};
