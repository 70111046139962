import { formatterCurrency } from './money';

export const checkCost = (item) => {
    if (item?.company_type == 'KV') {
        return `${item?.cost}%`;
    } else if (item?.company_type == 'KV + Grupo') {
        return `${item?.cost}%`;
    } else if (item?.company_type == 'Grupo') {
        return '-';
    } else if (item?.company_type == 'Bancarizada') {
        return '-';
    }
};

export const checkExpense = (item) => {
    // console.log(item.expense, item.cost);
    if (item?.company_type == 'KV') {
        return `${item?.expense}%`;
    } else if (item?.company_type == 'KV + Grupo') {
        return `${item?.expense}%`;
    } else if (item?.company_type == 'Grupo') {
        return '-';
    } else if (item?.company_type == 'Bancarizada') {
        return '-';
    }
};

export function checkExpenseName(item) {
    if (item?.company_type == 'KV') {
        return item?.expense_name || '';
    } else if (item?.company_type == 'KV + Grupo') {
        return item?.expense_name;
    } else if (item?.company_type == 'Grupo') {
        return '-';
    } else if (item?.company_type == 'Bancarizada') {
        return '-';
    }
}

export function checkCostImport(item) {
    if (item?.company_type == 'KV') {
        return formatterCurrency(item?.cost_amount || 0);
    } else if (item?.company_type == 'KV + Grupo') {
        return formatterCurrency(item?.cost_amount || 0);
    } else if (item?.company_type == 'Grupo') {
        return '-';
    } else {
        return '-';
    }
}

export function checkExpenseImport(item) {
    if (item?.company_type == 'KV') {
        return formatterCurrency(item?.expense_amount || 0);
    } else if (item?.company_type == 'KV + Grupo') {
        return formatterCurrency(item?.expense_amount || 0);
    } else if (item?.company_type == 'Grupo') {
        return '-';
    } else {
        return '-';
    }
}

export function checkCostCompany(item) {
    if (item?.company_type == 'KV') {
        return item?.cost_name;
    } else if (item?.company_type == 'KV + Grupo') {
        return item?.cost_name;
    } else if (item?.company_type == 'Grupo') {
        return '-';
    } else {
        return '-';
    }
}

export function checkCompanyType(item) {
    if (item?.company_type === 'Bancarizada') {
        return item?.company_funding;
    }
    return item?.company?.name;
}

export function checkCommissionCorporative(item) {
    if (
        item?.company_type == 'Grupo' ||
        item?.company_type == 'Grupo 2' ||
        item?.company_type == 'KV + Grupo' ||
        item?.company_type == 'KV' ||
        item?.company_type == 'Resguardo Grupo' ||
        item?.company_type == 'Resguardo Grupo 2' ||
        item?.company_type == 'Resguardo KV + Grupo' ||
        item?.company_type == 'Resguardo KV'
    ) {
        return formatterCurrency(item?.commission_corporative);
    } else {
        return '-';
    }
}

export function checkCompanyFunding(item) {
    if (
        item?.company_type == 'Grupo' ||
        item?.company_type == 'Grupo 2' ||
        item?.company_type == 'KV + Grupo' ||
        item?.company_type == 'KV' ||
        item?.company_type == 'Resguardo Grupo' ||
        item?.company_type == 'Resguardo Grupo 2' ||
        item?.company_type == 'Resguardo KV + Grupo' ||
        item?.company_type == 'Resguardo KV'
    ) {
        return item?.company_funding || '-';
    } else {
        return '-';
    }
}

export function checkImportFunding(item) {
    if (
        item?.company_type == 'Grupo' ||
        item?.company_type == 'Grupo 2' ||
        item?.company_type == 'KV + Grupo' ||
        item?.company_type == 'KV' ||
        item?.company_type == 'Resguardo Grupo' ||
        item?.company_type == 'Resguardo Grupo 2' ||
        item?.company_type == 'Resguardo KV + Grupo' ||
        item?.company_type == 'Resguardo KV'
    ) {
        return formatterCurrency(item?.import_funding);
    } else {
        return '-';
    }
}

export function balanceToConsiderate(item) {
    const banksToReturnZero = [
        'KV KUSPIT',
        'KV CONVENCIONAL - KUSPIT',
        'TRASPASOS ENTRE KUSPIT',
        'DESPACHO - KV CONVENCIONAL',
        'KV KUSPIT - DESPACHO - KV CONVENCIONAL',
        'KV CONVENCIONAL - DESPACHO - KV CONVENCIONAL',
    ];

    const banksToMakeOperation = [
        'DESPACHO - KV KUSPIT',
        'KV KUSPIT - DESPACHO - KV KUSPIT',
        'KV CONVENCIONAL - DESPACHO - KV KUSPIT',
    ];

    //Search if exist bank
    const decideIfZero = banksToReturnZero.some(
        (element) => element == item.bank.name
    );
    const decideIfMakesOperation = banksToMakeOperation.some(
        (element) => element == item.bank.name
    );

    //Return 0 or percent depending on bank
    if (decideIfZero) {
        return 0;
    } else if (decideIfMakesOperation) {
        if (item?.company_type == 'KV') {
            return formatterCurrency((item?.cost_amount * 99.75) / 100 || 0);
        } else if (item?.company_type == 'KV + Grupo') {
            return formatterCurrency((item?.cost_amount * 99.75) / 100 || 0);
        } else if (item?.company_type == 'Grupo') {
            return '-';
        } else {
            return '-';
        }
    } else {
        return '-';
    }
}
