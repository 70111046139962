import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
	updateOperatingExpenses,
	updatePerceptionsInvoice,
} from '../../actions/requests';
import { registerFunding } from '../../actions/funding';
import { handleError, handleSuccess } from '../../helpers/utils';
import {
	handleUpdateFieldApp,
	updateApplication,
} from '../../actions/applications';

export const CostDialog2 = ({
	show = false,
	handleClose = null,
	typeFunding = '',
	calculateCostTranslate,
}) => {
	const dispatch = useDispatch();
	const [payer, setPayer] = React.useState('');
	// FIXME: remove everything that you are not going to use anymore
	const [amount, setAmount] = React.useState('');
	const applicationTemp = useSelector((state) => state.request.application);
	const objectOperativeExpenses = useSelector(
		(state) => state.request.operatingExpenses
	);
	const { application = null } = useSelector((state) => state.request);
	const rows = useSelector((state) => state.request.returnAndCommission);

	//only getting id on registerFunding
	function updateFundings(value, applicationTemp) {
		registerFunding(value).then((response) => {
			updateApplicationFunding(response.data, applicationTemp, value);
		});
	}

	function updateApplicationFunding(newFundingUpdated, applicationTemp) {
		updateApplication(applicationTemp.id, {
			...applicationTemp,
		})
			.then(({ data }) => {
				// Actualizar en Redux
				handleSuccess('Pagadora creada correctamente');
				dispatch(
					updatePerceptionsInvoice({
						...data,
						promoter: applicationTemp.promoter,
						executive: applicationTemp.executive,
						fundings: [
							...applicationTemp.fundings,
							newFundingUpdated,
						],
					})
				);
			})
			.catch((error) => {
				handleError('No se creó la pagadora');
			});
	}

	return (
		<Dialog
			open={show}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle>Editar Porcentaje</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} style={{ marginTop: '15px' }}>
					<Grid item xs={12}>
						<TextField
							autoFocus
							id='outlined-basic'
							label='Porcentaje'
							variant='outlined'
							fullWidth
							onChange={(e) => {
								setPayer(e.target.value);
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color='error'>
					Cancelar
				</Button>
				<Button
					disabled={payer.length === 0}
					onClick={() => {
						// dispatch(
						//     updateOperatingExpenses({
						//         ...objectOperativeExpenses,
						//         porcentajeTraslado: ,
						//     })
						// );
						calculateCostTranslate(Number(payer));
						// handleUpdateFieldApp(
						//     application,
						//     "percentage_transfer",
						//     Number(payer)
						// ).then(({ data }) => {
						//     let tempObj = { ...objectOperativeExpenses };
						//     tempObj.porcentajeTraslado = Number(payer);
						//     dispatch(updateOperatingExpenses(tempObj));
						// });

						handleClose();
					}}
				>
					Editar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
