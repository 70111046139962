import React from 'react';
import { deleteClient, getClients } from '../../actions/catalogs';
import { ClientsTable } from './ClientsTable';

// Clients Table
export const Client = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getClients,
		deleteCatalog: deleteClient,
		catalogSingular: 'Cliente',
		catalogPlural: 'Clientes',
		pronounSingular: 'el',
		pronoun: 'o',
	};

	// Configuration for the Details Table
	const detailGridConfig = {
		gridItems: [
			{ xsWidth: 8, title: 'NOMBRE', code: 'name' },
			{ xsWidth: 4, title: 'SEDE', code: 'headquarter' },
			{ xsWidth: 12, title: 'Descripcion', code: 'description' },
		],
	};

	return (
		<ClientsTable config={configuration} detailGridConfig={detailGridConfig} />
	);
};
