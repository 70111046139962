import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export const Loader = () => {
    return (
        <div style={stylesDiv}>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
}

const stylesDiv = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#CCCCCC80'
}