import React from 'react';
import {
	deletePromoter,
	getPromoterInfo,
	registerPromoter,
	updatePromoter,
	getPromoters,
} from '../../actions/catalogs';
import { PromotersTable } from './PromotersTable';

// Promoter Table
export const Promoter = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getPromoters,
		getCatalogInfo: getPromoterInfo,
		deleteCatalog: deletePromoter,
		updateCatalog: updatePromoter,
		registerCatalog: registerPromoter,
		catalogSingular: 'Promotor',
		catalogPlural: 'Promotores',
		pronounSingular: 'el',
		pronoun: 'o',
	};

	// Configuration for the Details Table
	const detailGridConfig = {
		gridItems: [
			{ xsWidth: 8, title: 'NOMBRE', code: 'name' },
			{ xsWidth: 4, title: 'APELLIDO', code: 'lastname' },
			{ xsWidth: 4, title: 'COMISIÓN PROMOTOR 1', code: 'commission_1' },
			{ xsWidth: 4, title: 'COMISIÓN PROMOTOR 2', code: 'commission_2' },
			{ xsWidth: 4, title: 'COMISIÓN PROMOTOR 3', code: 'commission_3' },
			{ xsWidth: 12, title: 'Descripcion', code: 'description' },
		],
	};

	return (
		<PromotersTable
			config={configuration}
			detailGridConfig={detailGridConfig}
		/>
	);
};
