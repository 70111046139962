import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import {
    Alert,
    Box,
    Button,
    DialogActions,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { getDepositsByApplicationID } from '../../actions/applications';
import moment from 'moment';
import { money } from '../../helpers/money';

import {
    balanceToConsiderate,
    checkCompanyFunding,
    checkCostCompany,
    checkExpenseName,
    checkImportFunding,
} from '../../helpers/ApplicationDetailCalculus';

export const DepositsFundingDialog = ({
    open,
    handleClose,
    applicationId = null,
}) => {
    const [deposits, setDeposits] = useState([]);

    /**
     * Effect hook to fetch and format deposit data for a specific application ID.
     *
     * This effect hook performs the following steps:
     * 1. Checks if `applicationId` is provided.
     * 2. If `applicationId` is available, it calls the `getDepositsByApplicationID` function to retrieve deposit data.
     * 3. Formats the retrieved data for display by mapping and transforming each item.
     * 4. Sets the formatted deposit data to the `deposits` state variable.
     * 5. If no deposit data is found for the application, it sets `deposits` to an empty array.
     *
     * @returns {void}
     */
    useEffect(() => {
        if (applicationId) {
            // Fetch deposits by application ID
            getDepositsByApplicationID(applicationId).then(({ data }) => {
                if (data.count > 0) {
                    // Format and transform deposit data for display
                    const formattedData = data.results.map((item) => ({
                        ...item,
                        created_at: moment(item.created_at).format(
                            'DD/MM/YYYY'
                        ),
                        deposit: money(item.deposit),
                        cost: `${item.cost * 100}%`,
                        cost_amount: money(item.cost_amount),
                        expense: `${item.expense * 100}%`,
                        expense_amount: money(item.expense_amount),
                        commission: money(item.commission),
                        commission_corporative: money(
                            item.commission_corporative
                        ),
                        company_funding: checkCompanyFunding(item),
                        import_funding: checkImportFunding(item),
                    }));

                    // Set the formatted deposit data to the `deposits` state variable
                    setDeposits(formattedData);
                } else {
                    // Set `deposits` to an empty array when no deposit data is found
                    setDeposits([]);
                }
            });
        }
    }, [applicationId]);

    return (
        <Dialog
            maxWidth='lg'
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            {/* Content */}
            <Box sx={{ flexGrow: 1 }}>
                <div style={{ padding: '20px' }}>
                    <Text
                        variant='h6'
                        text='Depósitos'
                    />

                    {deposits?.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table
                                sx={{ width: '100%' }}
                                aria-label='simple table'
                            >
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align='center'>
                                            #
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Fecha
                                        </StyledTableCell>

                                        <StyledTableCell align='center'>
                                            Importe
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            Cliente
                                        </StyledTableCell>
                                        {/* <StyledTableCell align='center'>
											Empresa
										</StyledTableCell>
										<StyledTableCell align='center'>
											Empresa contra
										</StyledTableCell> */}
                                        <StyledTableCell align='center'>
                                            Banco
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Depositado en
                                        </StyledTableCell>
                                        {/* <StyledTableCell align='center'>
											Traspaso
										</StyledTableCell> */}
                                        <StyledTableCell align='center'>
                                            Empresa Costo
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Importe costo
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Empresa Gasto
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            Importe gasto
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            SALDO A CONSIDERAR por Comisiones
                                            Bancarias
                                        </StyledTableCell>

                                        {/* <StyledTableCell align='center'>
											Comisión corporativo
										</StyledTableCell> */}
                                        {/* <StyledTableCell align='center'>
											Empresa fondeo corporativo
										</StyledTableCell> */}
                                        <StyledTableCell align='center'>
                                            Importe/Fondeo
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {deposits.map((deposit, index) => (
                                        <StyledTableRow
                                            key={deposit.key}
                                            sx={styleLabel}
                                        >
                                            <ColumnItem
                                                align='center'
                                                text={index + 1}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit.created_at}
                                            />

                                            <ColumnItem
                                                align='center'
                                                text={deposit.deposit}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit.client?.name}
                                            />
                                            {/* <ColumnItem
												align='center'
												text={deposit.company?.name}
											/> */}
                                            {/* <ColumnItem
												align='center'
												text={
													deposit.cons_company?.name
												}
											/> */}
                                            <ColumnItem
                                                align='center'
                                                text={deposit.bank?.name}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit?.company?.name}
                                            />
                                            {/* <ColumnItem
												align='center'
												text={deposit.transfer.concept}
											/> */}
                                            <ColumnItem
                                                align='center'
                                                text={checkCostCompany(deposit)}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit.cost_amount}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={checkExpenseName(deposit)}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={deposit?.expense_amount}
                                            />
                                            <ColumnItem
                                                align='center'
                                                text={balanceToConsiderate(
                                                    deposit
                                                )}
                                            />

                                            {/* <ColumnItem
												align='center'
												text={deposit.company_funding}
											/> */}
                                            <ColumnItem
                                                align='center'
                                                text={deposit.import_funding}
                                            />
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Alert severity='info'>No hay depósitos</Alert>
                    )}
                </div>
            </Box>
            <DialogActions>
                <Button onClick={() => handleClose()}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

const ColumnItem = ({ align, text }) => (
    <StyledTableCell align={align}>{text}</StyledTableCell>
);

const styleLabel = {
    '&:last-child td, &:last-child th': { border: 0 },
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction='up'
            ref={ref}
            {...props}
        />
    );
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#01579b',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const Text = ({ variant, text, bold = true }) => (
    <Typography
        variant={variant}
        gutterBottom
    >
        {bold ? <b>{text}</b> : text}
    </Typography>
);
