import axios from 'axios';
import { getToken } from './auth';

// Get debt info by id
export const getDebtInfo = async (id) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/debt/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Get all debts
export const getDebts = async (idApplication) => {
	const token = getToken();
	return await axios({
		method: 'get',
		url: `/api/application/debt/?application=${idApplication}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Register debt
export const registerDebt = async (data) => {
	const token = getToken();
	return await axios({
		method: 'post',
		url: '/api/application/debt/create/',
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Update debt
export const updateDebt = async (id, data) => {
	const token = getToken();
	return await axios({
		method: 'put',
		url: `/api/application/debt/update/${id}`,
		data,
		headers: { Authorization: 'Bearer ' + token },
	});
};

// Delete debt
export const deleteDebt = async (id) => {
	const token = getToken();
	return await axios({
		method: 'delete',
		url: `/api/application/debt/${id}`,
		headers: { Authorization: 'Bearer ' + token },
	});
};
