import React, { useEffect, useState } from 'react';

import {
	TextField,
	Button,
	Typography,
	Grid,
	CircularProgress, Autocomplete
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { blue } from '@mui/material/colors';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

import { handleError, handleSuccess, handleWarning } from '../../helpers/utils';
import {
	getClients,
	registerCompany,
	searchClient,
	updateCompany
} from '../../actions/catalogs';

// Default configuration for a new form
const defaultConfig = {
	title: 'Añadir Cliente',
	handleClose: false,
	addOneItem: false,
	updateOneItem: false,
};

// Create/Edit Company Form
export const NewCompanyForm = ({ data = null, config = defaultConfig }) => {
	// Desestructure config property
	const {
		title = 'Añadir Empresa',
		handleClose,
		addOneItem,
		updateOneItem,
	} = config;

	const [completeName, setCompleteName] = data
		? useState(data.name)
		: useState('');
	const [shortname, setShortname] = data
		? useState(data.shortname)
		: useState('');
	const [description, setDescription] = data
		? useState(data.description)
		: useState('');
	const [observations, setObservations] = data
		? useState(data.observations)
		: useState('');
	const [isCorp, setIsCorp] = data ? useState(data.is_corp) : useState(false);
	const [rfc, setRfc] = data ? useState(data.rfc) : useState('');
	const [concept, setConcept] = data ? useState(data.concept) : useState('');
	const [headquarter, setHeadquarter] = data
		? useState(data.headquarter)
		: useState('NOA');
	const [level, setLevel] = data ? useState(data.level) : useState('L1');
	const [client, setClient] = data?.client_name
		? useState(data?.client_name)
		: useState('');

	const [clients, setClients] = useState([]);
	// Loading state
	const [loading, setLoading] = useState(true);

	//  Event Handlers for form items
	const handleLevel = (event) => {
		setLevel(event.target.value);
	};

	const handleHeadquarter = (event) => {
		setHeadquarter(event.target.value);
	};

	const handleCorp = (event) => {
		setIsCorp(event.target.checked);
	};

	// Function to create a company
	function handleCreate(value) {
		// Validate that a name exists
		if (value.name !== '') {
			// Validate that the RFC length is correct
			if (value.rfc.length == 13 || value.rfc === '') {
				// Validate that client exists
				if (value.client === '') {
					// Create the company
					registerCompany(value)
						.then(({ data }) => {
							handleSuccess('Empresa creada correctamente');
							addOneItem(data);
							handleClose();
						})
						.catch((error) => {
							handleError(error, 'No se creó la Empresa');
						});
				} else {
					// Get the client id
					searchClient(value?.client)
						.then(({ data }) => {
							value.client = data.results[0].id;
							// Create the company
							registerCompany(value)
								.then(({ data }) => {
									handleSuccess(
										'Empresa creada correctamente'
									);
									addOneItem(data);
									handleClose();
								})
								.catch((error) => {
									handleError(error, 'No se creó la Empresa');
								});
						})
						.catch((error) => {
							handleError(
								error,
								'Algo salio mal buscando el cliente'
							);
						});
				}
			} else {
				handleWarning('RFC no valido');
			}
		} else {
			handleWarning('Faltan Campos');
		}
	}

	// Function to update a company
	function handleUpdate(value) {
		// Validate that a name exists
		if (value.name !== '') {
			// Validate that the RFC length is correct
			if (value.rfc.length == 13 || value.rfc === '') {
				// Validate that client exists
				if (value.client !== '' && value.client !== null) {
					// Get the client id
					const companyId = data.id;
					searchClient(value?.client)
						.then(({ data }) => {
							value.client = data.results[0].id;
							// Update the company
							updateCompany(companyId, value)
								.then(({ data }) => {
									handleSuccess(
										'Empresa creada correctamente'
									);
									updateOneItem(data);
									handleClose();
								})
								.catch((error) => {
									handleError(
										error,
										'No se actualizó la Empresa'
									);
								});
						})
						.catch((error) => {
							handleError(
								error,
								'Algo salio mal buscando el cliente'
							);
						});
				} else {
					// Update the company
					updateCompany(data.id, value)
						.then(({ data }) => {
							handleSuccess('Empresa creada correctamente');
							updateOneItem(data);
							handleClose();
						})
						.catch((error) => {
							handleError(error, 'No se actualizó la Empresa');
						});
				}
			} else {
				handleWarning('RFC no valido');
			}
		} else {
			handleWarning('Faltan Campos');
		}
	}

	// Get info first time
	useEffect(() => {
		getFiltersOptions();
	}, []);

	// Get options for options
	const getFiltersOptions = () => {
		setLoading(true);
		// Get client options
		getClients()
			.then(({ data }) => {
				setLoading(false);
				setClients(data.results);
			})
			.catch((err) => {
				setLoading(false);
				handleError(err, 'Error al obtener clientes');
			});
	};

	const SelectComponent = (params, label) => (
		<TextField {...params} label={label} />
	);

	return (
		<div
			style={{
				padding: '20px',
				margin: 6,
			}}
		>
			<Typography variant='h4' gutterBottom>
				{title}
			</Typography>
			{loading ? (
				<CircularProgress />
			) : (
				<Grid container spacing={2} direction='row'>
					<Grid item xs={8}>
						<TextField
							type='text'
							variant='outlined'
							color='secondary'
							label='Nombre Completo de la empresa'
							onChange={(e) => setCompleteName(e.target.value)}
							value={completeName}
							inputProps={{ maxLength: 255 }}
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							type='text'
							variant='outlined'
							color='secondary'
							label='Nombre corto'
							onChange={(e) => setShortname(e.target.value)}
							value={shortname}
							inputProps={{ maxLength: 255 }}
							fullWidth
						/>
					</Grid>

					<Grid item xs={4}>
						<TextField
							type='text'
							variant='outlined'
							color='secondary'
							label='RFC'
							onChange={(e) => setRfc(e.target.value)}
							value={rfc}
							inputProps={{ maxLength: 13 }}
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<FormControl fullWidth required>
							<InputLabel
								variant='standard'
								htmlFor='uncontrolled-native'
							>
								Sede
							</InputLabel>
							<NativeSelect
								defaultValue={headquarter}
								inputProps={{
									name: 'headquarter',
									id: 'uncontrolled-native',
								}}
								onChange={handleHeadquarter}
							>
								<option value={'NOA'}>No asignado</option>
								<option value={'AGS'}>Aguascalientes</option>
								<option value={'BCN'}>Baja california</option>
								<option value={'CS'}>
									Baja california Sur
								</option>
								<option value={'CAM'}>Campeche</option>
								<option value={'CHP'}>Chiapas</option>
								<option value={'CHI'}>Chihuahua</option>
								<option value={'COA'}>Coahuila</option>
								<option value={'COL'}>Colima</option>
								<option value={'DUR'}>Durango</option>
								<option value={'GTO'}>Guanajuato</option>
								<option value={'GRO'}>Guerrero</option>
								<option value={'HGO'}>Hidalgo</option>
								<option value={'JAL'}>Jalisco</option>
								<option value={'MEX'}>CDMX</option>
								<option value={'MIC'}>Michoacán</option>
								<option value={'MOR'}>Morelos</option>
								<option value={'NAY'}>Nayarit</option>
								<option value={'NLE'}>Nuevo León</option>
								<option value={'OAX'}>Oaxaca</option>
								<option value={'PUE'}>Puebla</option>
								<option value={'QRO'}>Querétaro</option>
								<option value={'ROO'}>Quintana Roo</option>
								<option value={'SLP'}>San Luis Potosí</option>
								<option value={'SIN'}>Sinaloa</option>
								<option value={'SON'}>Sonora</option>
								<option value={'TAB'}>Tabasco</option>
								<option value={'TAM'}>Tamaulipas</option>
								<option value={'TLX'}>Tlaxcala</option>
								<option value={'VER'}>Veracruz</option>
								<option value={'YUC'}>Yucatán</option>
								<option value={'ZAC'}>Zacatecas</option>
							</NativeSelect>
						</FormControl>
					</Grid>
					<Grid item xs={4}>
						<FormControl fullWidth required>
							<InputLabel
								variant='standard'
								htmlFor='uncontrolled-native'
							>
								Nivel
							</InputLabel>
							<NativeSelect
								defaultValue={level}
								inputProps={{
									name: 'level',
									id: 'uncontrolled-native',
								}}
								onChange={handleLevel}
							>
								<option value={'L1'}>Nivel 1</option>
								<option value={'L2'}>Nivel 2</option>
							</NativeSelect>
						</FormControl>
					</Grid>

					<Grid item xs={4}>
						<TextField
							type='text'
							variant='outlined'
							color='secondary'
							label='Giro'
							onChange={(e) => setConcept(e.target.value)}
							value={concept}
							inputProps={{ maxLength: 255 }}
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<FormControlLabel
							value='start'
							control={
								<Checkbox
									onChange={handleCorp}
									inputProps={{ 'aria-label': 'controlled' }}
									sx={{
										color: blue[800],
										'&.Mui-checked': {
											color: blue[600],
										},
									}}
									defaultChecked={isCorp}
								/>
							}
							label='Es corporativa'
							labelPlacement='end'
						/>
					</Grid>

					{/* Client */}
					<Grid item xs={4}>
						<Autocomplete
							isOptionEqualToValue={(option, value) => option.id === value.id}
							fullWidth
							value={client}
							onChange={(_, value) => {
								setClient(value);
							}}
							options={clients?.map((item) => item.name) || []}
							renderInput={(params) =>
								SelectComponent(params, 'Cliente')
							}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							type='text'
							variant='outlined'
							color='secondary'
							label='Descripcion'
							onChange={(e) => setDescription(e.target.value)}
							value={description}
							multiline
							rows={6}
							inputProps={{ maxLength: 700 }}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							type='text'
							variant='outlined'
							color='secondary'
							label='Observaciones'
							onChange={(e) => setObservations(e.target.value)}
							value={observations}
							multiline
							minRows={6}
							inputProps={{ maxLength: 700 }}
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<Button
							variant='contained'
							fullWidth
							color='error'
							sx={{ mt: 2 }}
							onClick={handleClose}
						>
							Cancelar
						</Button>
					</Grid>
					<Grid item xs={6}>
						{data ? (
							<Button
								variant='contained'
								fullWidth
								style={{ backgroundColor: '#143f6c' }}
								sx={{ mt: 2 }}
								onClick={() => {
									handleUpdate({
										name: completeName,
										shortname: shortname,
										is_corp: isCorp,
										rfc: rfc,
										concept: concept,
										headquarter: headquarter,
										level: level,
										description: description,
										observations: observations,
										client: client,
									});
								}}
							>
								Actualizar
							</Button>
						) : (
							<Button
								variant='contained'
								fullWidth
								style={{ backgroundColor: '#143f6c' }}
								sx={{ mt: 2 }}
								onClick={() => {
									handleCreate({
										name: completeName,
										shortname: shortname,
										is_corp: isCorp,
										rfc: rfc,
										concept: concept,
										headquarter: headquarter,
										level: level,
										description: description,
										observations: observations,
										client: client,
									});
								}}
							>
								Registrar
							</Button>
						)}
					</Grid>
				</Grid>
			)}
		</div>
	);
};
