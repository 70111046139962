import React from 'react';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { Stack } from '@mui/system';
import { DialogTemplate } from '../components/dialogs/DialogTemplate';
import { TeamsTable } from '../components/teams/TeamsTable';
import { NewTeamForm } from '../components/teams/NewTeamForm';

export const Teams = () => {
	const [value, setTab] = React.useState(0);

	// Dialog
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleChange = (_, newValue) => {
		setTab(newValue);
	};

	// Dummy teams
	const [teams, setTeams] = useState([
		{
			id: 1,
			logoPath: '../../logo-1.jpg',
			name: 'Club Puebla',
			deleted: false,
		},
		{
			id: 2,
			logoPath: '../../logo-2.jpg',
			name: 'Atlas',
			deleted: false,
		},
		{
			id: 3,
			logoPath: '../../logo-3.jpg',
			name: 'Chivas',
			deleted: false,
		},
		{
			id: 4,
			logoPath: '../../logo-4.jpg',
			name: 'Cruz Azul',
			deleted: false,
		},
	]);

	// Panel component for each tab
	const TabPanel = (props) => {
		const { children, value, index, ...other } = props;

		return (
			<div
				role='tabpanel'
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
			</div>
		);
	};

	// Handle register action
	const handleRegister = (email, password, name) => {
		// startRegisterWithEmailPasswordName(email, password, name)
		// 	.then(() => handleSuccess('Usuario registado correctamente.'))
		// 	.catch((error) => handleError(error));
	};

	// Handle login action
	const handleLogin = (email, password) => {
		// dispatch(startLoginEmailPassword(email, password));
	};

	return (
		<Box sx={{ width: '100%' }}>
			<DialogTemplate
				config={{
					open: open,
					handleClose: handleClose,
					btnCancel: false,
					btnConfirm: false,
					title: 'Nuevo registro de equipo',
					subtitle:
						'Registro de nuevo equipo para las acreditaciones.',
				}}
				content={<NewTeamForm handleClose={handleClose} />}
			/>
			<Stack
				direction='column'
				alignItems='flex-start'
				justifyContent='space-between'
				mb={1}
				style={{ border: '1px solid white', padding: '20px 0 0 20px' }}
			>
				<Typography variant='h4' gutterBottom>
					Catálogos
				</Typography>
				<Stack
					direction='row'
					alignItems='flex-end'
					justifyContent='space-between'
					mb={1}
					style={{
						border: '1px solid white',
						padding: '20px 20px 0 0',
						width: '100%',
					}}
				>
					<Typography variant='p' gutterBottom>
						Catálogos de tallas, telas, prendas y variantes.
					</Typography>
				</Stack>
			</Stack>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label='basic tabs example'
				>
					<Tab label='Tallas' {...a11yProps(0)} />
					<Tab label='Telas' {...a11yProps(1)} />
					<Tab label='Prendas' {...a11yProps(2)} />
					<Tab label='Variantes' {...a11yProps(3)} />
				</Tabs>
			</Box>
			{/* CREATE JUST ONE REUSABLE TABLE FOR EVERY CATALOG */}
			<TabPanel value={value} index={0}>
				<TeamsTable data={teams} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<TeamsTable data={teams} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<TeamsTable data={teams} />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<TeamsTable data={teams} />
			</TabPanel>
		</Box>
	);
};

const a11yProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
};
