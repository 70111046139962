import React, { useState } from 'react';
import { TextField, Button, Typography, Grid } from '@mui/material';
import { handleError, handleSuccess, handleWarning } from '../../helpers/utils';

// Default configuration for a new form
const defaultConfig = {
	handleClose: false,
	addOneItem: false,
	updateOneItem: false,
	registerCatalog: false,
	updateCatalog: false,
	title: '',
	catalog: 'Catálogo',
	conceptName: 'concept',
	conceptNameLabel: 'Concepto',
	pronounSingular: 'el',
	pronoun: 'o',
};

// Simple Catalog form that only had 2 char parameters: Concept and Description
export const NewSimpleCatalogForm = ({
	data = null,
	config = defaultConfig,
}) => {
	// Desestructure config property
	const {
		handleClose,
		addOneItem,
		updateOneItem,
		registerCatalog,
		updateCatalog,
		title = '',
		catalog = 'Catálogo',
		conceptName,
		conceptNameLabel,
		pronounSingular = 'el',
		pronoun = 'o',
	} = config;

	const [description, setDescription] =
		data === null ? useState('') : useState(data.description);
	const [concept, setConcept] =
		data === null ? useState('') : useState(data[conceptName]);

	// Function to create a Catalog
	function handleCreate(value) {
		// Validate that a concept exits
		if (value[conceptName] !== '') {
			registerCatalog(value)
				.then(({ data }) => {
					handleSuccess(catalog + ' cread' + pronoun + ' correctamente');
					addOneItem(data);
					handleClose();
				})
				.catch((error) => {
					handleError(
						error, 
						'No se creó' + pronounSingular + ' ' + catalog
					);
				});
		} else {
			handleWarning('Faltan campos');
		}
	}

	// Function to update a Catalog
	function handleUpdate(value) {
		// Validate that a concept exits
		if (value.concept !== '') {
			updateCatalog(data.id, value)
				.then(({ data }) => {
					handleSuccess(catalog + ' actualizad' + pronoun + ' correctamente');
					updateOneItem(data);
					handleClose();
				})
				.catch((error) => {
					handleError(
						error,
						'No se actualizo' + pronounSingular + ' ' + catalog
					);
				});
		} else {
			handleWarning('Falta Concepto');
		}
	}

	return (
		<div
			style={{
				padding: '20px',
				margin: 6,
			}}
		>
			<Typography variant='h4' gutterBottom>
				{title}
			</Typography>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={8}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label={conceptNameLabel}
						onChange={(e) => setConcept(e.target.value)}
						value={concept}
						inputProps={{ maxLength: 255 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						type='text'
						variant='outlined'
						color='secondary'
						label='Descripción'
						onChange={(e) => setDescription(e.target.value)}
						value={description}
						multiline
						rows={4}
						inputProps={{ maxLength: 500 }}
						fullWidth
					/>
				</Grid>

				<Grid
					container
					direction='row'
					justifyContent='flex-end'
					alignItems='center'
					item
					xs={12}
					spacing={2}
				>
					<Grid item xs={6}>
						<Button
							variant='contained'
							fullWidth
							color='error'
							sx={{ mt: 2 }}
							onClick={handleClose}
						>
							Cancelar
						</Button>
					</Grid>

					<Grid item xs={6}>
						{data ? (
							<Button
								variant='contained'
								fullWidth
								style={{ backgroundColor: '#143f6c' }}
								sx={{ mt: 2 }}
								onClick={() => {
									handleUpdate({
										[conceptName]: concept,
										description: description,
									});
								}}
							>
								Actualizar
							</Button>
						) : (
							<Button
								variant='contained'
								fullWidth
								style={{ backgroundColor: '#143f6c' }}
								sx={{ mt: 2 }}
								onClick={() => {
									handleCreate({
										[conceptName]: concept,
										description: description,
									});
								}}
							>
								Registrar
							</Button>
						)}
					</Grid>
				</Grid>

				<Grid item xs={4}></Grid>
			</Grid>
		</div>
	);
};
