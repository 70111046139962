import React, { useEffect, useState } from 'react';
import {
	Alert,
	Button,
	IconButton,
	Tooltip,
	Stack,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import {
	handleDeleteCatalogModal,
	handleError,
	handleSuccess,
} from '../../helpers/utils';
import { formatDateTimeMX } from '../../helpers/hours';
import { DialogTemplate } from '../../components/dialogs/DialogTemplate';
import { CatalogDetail } from './CatalogDetail';
import { NewConsCompanyForm } from './NewConsCompanyForm';
import { SearchBar } from '../../components/utils/UtilsComponent';

// Complete name of priorities choices
export const STATUS_CHOICES = {
	INF: 'Vigente',
	SPE: 'Especial',
	DNU: 'No se usa',
	NLU: 'Ya no usar',
};

// Default configuration for a new table
const defaultConfig = {
	getCatalogs: false,
	deleteCatalog: false,
	catalogSingular: 'Empresa Contra',
	catalogPlural: 'Empresas Contra',
	pronounSingular: 'la',
	pronoun: 'a',
};

// Cons Companies Table
export const ConsCompaniesTable = ({
	config = defaultConfig,
	detailGridConfig,
}) => {
	// Desestructure config property
	const {
		getCatalogs,
		deleteCatalog,
		catalogSingular,
		catalogPlural,
		pronounSingular = 'el',
		pronoun = 'o',
	} = config;

	// Search
	const [searchValue, setSearchValue] = useState('');
	const [isSearchPage, setIsSearchPage] = useState(false);

	// Function to Filter the catalogs
	function handleSearch(value, searchPage = 1) {
		// Validate if value is an empty string and perform the search
		if (value === '') {
			generateRows();
			setIsSearchPage(false);
		} else {
			getCatalogs(`?search=${value}&page=${searchPage}&count=20`)
				.then(({ data }) => {
					setRowCount(data.count);
					setIsSearchPage(true);
					const res = data.results.map((item) => {
						return {
							id: item?.id,
							col1: item?.key,
							col2: item?.status,
							col3: item?.name,
							col4: item.shortname === '' ? '-' : item?.shortname,
							col5: item?.company_type,
							col6: item?.cost_name,
							col7: item?.cost,
							col8: item?.expense_name,
							col9: item?.expense,
							col10: item?.operation_server,
							col11: item.concept === '' ? '-' : item?.concept,
							col12: item.description === '' ? '-' : item?.description,
							col13: formatDateTimeMX(item?.updated_at),
							col14: formatDateTimeMX(item?.created_at),
							...item,
						};
					});

					setPage(searchPage);
					setRows(res);
				})
				.catch((error) => {
					handleError(error, 'Algo salio mal realizando la busqueda');
				});
		}
	}

	// Dialog Create/Edit
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setOpen(false);
		setHasData(null);
	};
	// Handle Data for editing
	const [hasData, setHasData] = useState(null);

	// Dialog Details
	const [openDetails, setOpenDetails] = useState(false);
	const handleOpenDetails = () => setOpenDetails(true);
	const handleCloseDetails = () => {
		setOpenDetails(false);
		setHasData(null);
	};

	// Rows
	const [rows, setRows] = useState([]);

	// Pagination
	const [page, setPage] = useState(1);
	const [rowCount, setRowCount] = useState(0);

	// Generate rows by array
	const generateRows = async () => {
		// Get the data from the DB
		const { data } = await getCatalogs(`?page=${page}&count=20`);
		setRowCount(data.count);

		if (data.results.length === 0) return [];

		const res = data.results.map((item) => {
			return {
				id: item?.id,
				col1: item?.key,
				col2: item?.status,
				col3: item?.name,
				col4: item.shortname === '' ? '-' : item?.shortname,
				col5: item?.company_type,
				col6: item?.cost_name,
				col7: item?.cost,
				col8: item?.expense_name,
				col9: item?.expense,
				col10: item?.operation_server,
				col11: item.concept === '' ? '-' : item?.concept,
				col12: item.description === '' ? '-' : item?.description,
				col13: formatDateTimeMX(item?.updated_at),
				col14: formatDateTimeMX(item?.created_at),
				...item,
			};
		});

		setRows(res);
	};

	useEffect(() => {
		// Generate rows
		isSearchPage ? handleSearch(searchValue, page) : generateRows();
	}, [page]);

	// Add an item to the rows
	const addOneItem = async (data) => {
		// Update the array with the information of the item
		const tmpDeposits = [
			...rows,
			{
				id: data?.id,
				col1: data?.key,
				col2: data?.status,
				col3: data?.name,
				col4: data.shortname === '' ? '-' : data?.shortname,
				col5: data?.company_type,
				col6: data?.cost_name,
				col7: data?.cost,
				col8: data?.expense_name,
				col9: data?.expense,
				col10: data?.operation_server,
				col11: data.concept === '' ? '-' : data?.concept,
				col12: data.description === '' ? '-' : data?.description,
				col13: formatDateTimeMX(data?.updated_at),
				col14: formatDateTimeMX(data?.created_at),
				...data,
			},
		];
		setRows(tmpDeposits);
	};

	// Update an item of the table
	const updateOneItem = async (data) => {
		// Find the index of the item to edit
		const indexDeposit = rows.findIndex((item) => item.id === data.id);

		const tmpDeposits = rows.map((item, index) => {
			if (index === indexDeposit) {
				return {
					id: data?.id,
					col1: data?.key,
					col2: data?.status,
					col3: data?.name,
					col4: data.shortname === '' ? '-' : data?.shortname,
					col5: data?.company_type,
					col6: data?.cost_name,
					col7: data?.cost,
					col8: data?.expense_name,
					col9: data?.expense,
					col10: data?.operation_server,
					col11: data.concept === '' ? '-' : data?.concept,
					col12: data.description === '' ? '-' : data?.description,
					col13: formatDateTimeMX(data?.updated_at),
					col14: formatDateTimeMX(data?.created_at),
					...data,
				};
			}
			return item;
		});

		setRows(tmpDeposits);
	};

	// Delete an item of the table
	const deleteRow = (rowId) => {
		const newRows = rows.filter((item) => item.id !== rowId);
		setRows(newRows);
	};

	// Handle delete with modal confirmation
	const handleDelete = (catalog) => {
		handleDeleteCatalogModal(pronounSingular + ' ' + catalogSingular)
			.then(({ isConfirmed }) => {
				if (isConfirmed) {
					deleteCatalog(catalog.id)
						.then(() => {
							handleSuccess(
								catalogSingular + ' eliminad' + pronoun + ' correctamente'
							);
							deleteRow(catalog.id);
						})
						.catch((error) => {
							handleError(
								error,
								'No se pudo eliminar' + pronounSingular + ' ' + catalogSingular
							);
						});
				}
			})
			.catch((error) => handleError(error));
	};

	// Define columns
	const columns = [
		{ field: 'col1', headerName: 'Clave', width: 100 },
		{
			field: 'col2',
			headerName: 'Estatus',
			width: 100,
			renderCell: (params) => {
				return params.value !== '-' ? (
					<Tooltip title={STATUS_CHOICES[params.value]}>
						<span className='table-cell-trucate'>
							{STATUS_CHOICES[params.value]}
						</span>
					</Tooltip>
				) : (
					'-'
				);
			},
		},
		{ field: 'col3', headerName: 'Nombre Completo', width: 300 },
		{ field: 'col4', headerName: 'Nombre Corto', width: 150 },
		{ field: 'col5', headerName: 'Tipo de Empresa', width: 150 },
		{ field: 'col6', headerName: 'Nombre costo', width: 150 },
		{
			field: 'col7',
			headerName: 'Porcentaje de costo',
			width: 150,
			renderCell: (params) => {
				return params.value !== '-' ? (
					<Tooltip title={params.value * 100 + '%'}>
						<span className='table-cell-trucate'>{params.value * 100}%</span>
					</Tooltip>
				) : (
					'-'
				);
			},
		},
		{ field: 'col8', headerName: 'Nombre gasto', width: 150 },
		{
			field: 'col9',
			headerName: 'Porcentaje de gasto',
			width: 150,
			renderCell: (params) => {
				return params.value !== '-' ? (
					<Tooltip title={params.value * 100 + '%'}>
						<span className='table-cell-trucate'>{params.value * 100}%</span>
					</Tooltip>
				) : (
					'-'
				);
			},
		},
		{ field: 'col10', headerName: 'Servidor de operación', width: 150 },
		{ field: 'col11', headerName: 'Concepto', width: 200 },
		{ field: 'col12', headerName: 'Descripcion', width: 210 },
		{ field: 'col13', headerName: 'Actualizado', width: 170 },
		{ field: 'col14', headerName: 'Creado', width: 170 },
		{
			field: 'col15',
			headerName: 'Acciones',
			width: 150,
			// Add actions to each case
			renderCell: ({ row }) => (
				<>
					<Tooltip title='Ver detalle'>
						<IconButton
							style={{ color: '#143f6c' }}
							aria-label='profile'
							color='primary'
							onClick={() => {
								setHasData(row);
								handleOpenDetails();
							}}
						>
							<InfoIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title='Editar'>
						<IconButton
							style={{ color: '#143f6c' }}
							aria-label='edit'
							color='primary'
							onClick={() => {
								setHasData(row);
								handleOpen();
							}}
						>
							<EditIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title='Eliminar'>
						<IconButton
							style={{ color: '#143f6c' }}
							aria-label='delete'
							color='primary'
							onClick={() => handleDelete(row)}
						>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</>
			),
		},
	];

	return (
		<div
			style={{
				padding: '20px',
			}}
		>
			<DialogTemplate
				config={{
					open: open,
					handleClose: handleClose,
					btnCancel: false,
					btnConfirm: false,
					title: '',
					maxWidth: 'xl',
				}}
				content={
					hasData ? (
						<NewConsCompanyForm
							data={hasData}
							config={{
								title: 'Editar ' + catalogSingular,
								handleClose: handleClose,
								updateOneItem: updateOneItem,
							}}
						/>
					) : (
						<NewConsCompanyForm
							config={{
								title: 'Añadir ' + catalogSingular,
								handleClose: handleClose,
								addOneItem: addOneItem,
							}}
						/>
					)
				}
			/>
			<DialogTemplate
				config={{
					open: openDetails,
					handleClose: handleCloseDetails,
					btnCancel: false,
					btnConfirm: true,
					title: '',
					maxWidth: 'lg',
				}}
				content={<CatalogDetail data={hasData} config={detailGridConfig} />}
			/>
			<Stack
				direction='row'
				alignItems='flex-end'
				justifyContent='space-between'
				style={{
					border: '1px solid white',
					padding: '20px 20px 0 0',
					margin: '0 0 40px 0',
					width: '100%',
				}}
			>
				<Typography variant='h4' gutterBottom>
					{catalogPlural}
				</Typography>
				<Button
					variant='contained'
					style={{ backgroundColor: '#143f6c' }}
					onClick={() => {
						handleOpen();
					}}
				>
					Añadir {catalogSingular}
				</Button>
			</Stack>
			<SearchBar
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				handleSearch={handleSearch}
			/>
			<hr />
			{/* Datagrid table */}
			{rows.length > 0 ? (
				<DataGrid
					autoHeight
					rows={rows}
					columns={columns}
					components={{ Toolbar: GridToolbar }}
					localeText={esES.components.MuiDataGrid.defaultProps.localeText}
					pagination
					rowCount={rowCount}
					rowsPerPageOptions={[20]}
					page={page - 1}
					pageSize={20}
					paginationMode='server'
					onPageChange={(newPage) => {
						setPage(newPage + 1);
					}}
				/>
			) : (
				<Alert severity='info'>No hay {catalogPlural}</Alert>
			)}
		</div>
	);
};
