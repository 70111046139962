import { types } from '../types/types';
import { cleanUser, setUser } from './user';
import axios from 'axios';
import { handleError, handleSuccessLogin } from '../helpers/utils';
import { cleanCatalogs } from './catalogs';
import { resetRequest } from './requests';


// Set login data in state
export const login = (data) => ({
	type: types.login,
	payload: data,
});

// Set logout in state
export const logout = () => ({
	type: types.logout,
});

// Sign out
export const startLogout = () => {
	return (dispatch) => {
		// Logout dispaches
		dispatch(logout());
		dispatch(cleanUser());
		dispatch(cleanCatalogs());
		dispatch(resetRequest());

		// Remove token from local storage
		localStorage.removeItem('token');
	};
};

// Get token from local storage
export const getToken = () => localStorage.getItem('token') || null;

// Verify token
export const verifyToken = async (token) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	// If token, add to headers config
	if (token) config.headers['Authorization'] = `Bearer ${token}`;

	return await axios.get('/api/auth/user', config);
};

// Check authentication
export const checkAuth = async (token) => {
	if (token) {
		const { data } = await verifyToken(token);
		return data?.user ? data?.user : false;
	}
	return false;
};

// Start login with email & password
export const startLoginEmailPassword = (email, password) => {
	return (dispatch) => {
		const data = { email, password };
		axios
			.post('/api/auth/login', data)
			.then((data) => {
				const { first_name, id, token } = data.data;
				// Set login state
				dispatch(login({ id, token }));
				// Set user info
				dispatch(setUser(data.data));
				// Store token in local storage
				localStorage.setItem('token', token);
				// Feedback
				handleSuccessLogin(first_name);
			})
			.catch((error) => {
				handleError(
					error,
					'No se pudo iniciar sesión con esas credenciales.'
				);
			});
	};
};

// Start login with email & password
export const authenticateUser = async (email, password) => {
	const data = { email, password };
	return await axios.post('/api/auth/user', data);
};

// Reset password with authentication
export const resetPasswordAuth = (email) => {
	return () => {
		// ...
	};
};

// Create user in Google authentication
export const startRegisterWithEmailPasswordName = async (email, password) => {
	// ...
};

export const pseudoDeleteAccount = (userUid) => {
	alert('Borrando usuario...');
};
