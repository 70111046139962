import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from '../routes/AppRouter';
import store from '../store/store';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const App = () => {
    return (
        <Provider store={store}>
            <HelmetProvider>
                <BrowserRouter>
                    <QueryClientProvider client={queryClient}>
                        <AppRouter />
                    </QueryClientProvider>
                </BrowserRouter>
            </HelmetProvider>
        </Provider>
    );
};

// Create root
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render root
root.render(<App />);
