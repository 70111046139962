import { handleWarning } from '../utils';

export const validateForm =
    () =>
    (
        folio,
        client,
        typeCompany,
        deposit,
        costAmount,
        expenseAmount,
        cost,
        conCompany,
        date
    ) => {
        // Check if 'folio' is not 'RETIRO DE RESGUARDO'.
        if (folio !== 'RETIRO DE RESGUARDO') {
            // Validate various form fields and display warnings if not selected or filled.
            if (date === null) {
                handleWarning('No se seleccionó la fecha');
                return false;
            }
            if (client === null) {
                handleWarning('No se seleccionó el cliente');
                return false;
            }
            if (typeCompany !== 'Bancarizada') {
                if (company === null) {
                    handleWarning('No se seleccionó la empresa');
                    return false;
                }
            }
            if (typeCompany !== 'Bancarizada') {
                if (bank === null) {
                    handleWarning('No se seleccionó el banco');
                    return false;
                }
            }
            if (deposit === '') {
                handleWarning('No se registró el importe');
                return false;
            }
        } else {
            // Validate various form fields when 'folio' is 'RETIRO DE RESGUARDO'.
            if (date === null) {
                handleWarning('No se seleccionó la fecha');
                return false;
            }
            if (client === null) {
                handleWarning('No se seleccionó el cliente');
                return false;
            }
            if (typeCompany !== 'Bancarizada') {
                if (company === null) {
                    handleWarning('No se seleccionó la empresa');
                    return false;
                }
            }
            if (typeCompany !== 'Bancarizada') {
                if (bank === null) {
                    handleWarning('No se seleccionó el banco');
                    return false;
                }
            }
        }

        // Additional validation for 'typeCompany' equal to 'KV'.
        if (typeCompany == 'KV') {
            if (costAmount === '') {
                return false;
            }
            if (cost === '') {
                return false;
            }
            if (expenseAmount === '') {
                return false;
            }
            if (conCompany === '') {
                handleWarning(
                    `Parece que no se encontró una "Empresa Contra" con el banco ${bank}`
                );
                return false;
            }
        }

        // If all validation checks pass, return true to indicate that the form data is valid.
        return true;
    };
