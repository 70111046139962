import React from 'react';
import { deleteSchemeCost, getSchemesCost } from '../../actions/catalogs';
import { SchemesCostTable } from './SchemesCostTable';

// Scheme Cost Table
export const SchemeCost = () => {
	// Configuration for the table
	const configuration = {
		getCatalogs: getSchemesCost,
		deleteCatalog: deleteSchemeCost,
		catalogSingular: 'Costo de Esquema',
		catalogPlural: 'Costos de Esquema',
		pronounSingular: 'el',
		pronoun: 'o',
	};

	// Configuration for the Details Table
	const detailGridConfig = {
		gridItems: [
			{ xsWidth: 8, title: 'ESQUEMA', code: 'scheme' },
			{ xsWidth: 4, title: 'SEGUNDA COMISIÓN', code: 'second_commission' },
			{ xsWidth: 12, title: 'Descripcion', code: 'description' },
		],
	};

	return (
		<SchemesCostTable
			config={configuration}
			detailGridConfig={detailGridConfig}
		/>
	);
};
