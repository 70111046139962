export const calculateAllPayersOnReturnAndCommission = (
    returnsAndCommissions
) => {
    // temp Array of all payers
    const arrayOfPayers = [];
    // Makes array of only payers
    returnsAndCommissions.forEach((element) => {
        element?.fundings.forEach((element) => arrayOfPayers.push(element));
    });

    return arrayOfPayers.reduce((accumulator2, object) => {
        return accumulator2 + Number(object?.amount);
    }, 0);
};

export const calculateTotalReturns = (returnsAndCommissions) => {
    return returnsAndCommissions.reduce((sum, { return_value }) => {
        return sum + Number(return_value || 0);
    }, 0);
};
export const calculateTotalISN = (returnAndCommissions) => {
    return returnAndCommissions.reduce((sum, { isn }) => {
        return sum + Number(isn || 0);
    }, 0);
};

export const calculateTotalISR = (returnAndCommissions) => {
    return returnAndCommissions.reduce((sum, { isr }) => {
        return sum + Number(isr || 0);
    }, 0);
};

export const calculateTotalSocialCost = (returnAndCommissions) => {
    return returnAndCommissions.reduce((accumulator, object) => {
        return accumulator + Number(object?.social_cost || 0);
    }, 0);
};

export const calculateTotalIVA = (returnAndCommissions) => {
    return returnAndCommissions.reduce((accumulator, object) => {
        return accumulator + Number(object?.iva || 0);
    }, 0);
};
export const calculateTotalBalance = (returnAndCommissions) => {
    return returnAndCommissions.reduce((accumulator, object) => {
        return accumulator + Number(object?.balance || 0);
    }, 0);
};

export const calculateTotalCommissions = (returnAndCommissions) => {
    return returnAndCommissions.reduce((accumulator, object) => {
        return accumulator + Number(object?.commission || 0);
    }, 0);
};

//Total calculation
export const totalCalc = (
    return_value,
    ISN,
    ISR,
    IVA,
    socialCostTemp,
    percentT
) => {
    //Real Formula might be ((RETORNO + ISN + COSTO SOCIAL + ISR + IVA) * PORCENTAJE)/(100%-PORCENTAJE)
    return (
        ((return_value + ISN + ISR + IVA + socialCostTemp) * percentT) /
            (1 - percentT) || 0
    ).toFixed(2);
};

//Total Factura Calculation
export const calculateTotalFact = (facturaTemp, percentT) => {
    return (facturaTemp * percentT).toFixed(2);
};

//Subtotal calculation
export const calculatesSubtotal = (
    return_value,
    ISN,
    ISR,
    IVA,
    socialCostTemp,
    percentT
) => {
    return (
        ((return_value + ISN + ISR + IVA + socialCostTemp) /
            (1.16 - percentT)) *
        percentT
    ).toFixed(2);
};

//Subtotal calculation
export const calculatesSubtotalIVA = (percentT, depositTotal) => {
   
    return (
        Number((depositTotal / 1.16) * percentT).toFixed(2)
    );
};

// Subtotal Factura Calculation
export const calculateSubotalFact = (facturaTemp, percentT) => {
    return ((facturaTemp / 1.16) * percentT).toFixed(2);
};

// Subtotal + 2% Return Calculation
export const calculateSubtotalTwoPercent = (
    return_value,
    ISN,
    ISR,
    IVA,
    socialCostTemp,
    percentT
) => {
    return (
        ((return_value +
            ISN +
            ISR +
            IVA +
            socialCostTemp +
            return_value * (2 / 100)) /
            (1.16 - percentT)) *
            percentT +
        return_value * (2 / 100)
    ).toFixed(2);
};

// Return Calculation
export const calculateReturn = (return_value, percentT) => {
    return (return_value * percentT).toFixed(2);
};

// Return + 2% Calculation
export const calculateReturnTwoPercent = (return_value, percentT) => {
    return (return_value * (percentT + 2 / 100)).toFixed(2);
};

// Calculate Return + IVA
export const calculateReturnIVA = (
    return_value,
    percentT,
    ISN,
    ISR,
    IVA,
    socialCostTemp
) => {
    return (
        return_value * percentT +
        (return_value +
            ISN +
            ISR +
            IVA +
            socialCostTemp +
            return_value * percentT) *
            0.16
    ).toFixed(2);
};

// Calculate Return iva 10%
export const calculateReturnIvaTenPercent = (
    return_value,
    percentT,
    ISN,
    ISR,
    IVA,
    socialCostTemp
) => {
    return (
        return_value * percentT +
        (return_value +
            ISN +
            ISR +
            IVA +
            socialCostTemp +
            return_value * percentT) *
            (10 / 100)
    ).toFixed(2);
};

//Fixed
// Calculates Return + Cost
export const calculateReturnAndCost = (
    return_value,
    percentT,
    socialCostTemp,
    ISN
) => {
    return ((return_value + socialCostTemp + ISN) * percentT).toFixed(2);
};
// Calculates Perceptions
export const calculatePerceptions = (percepcionesTemp, percentT) => {
    return (percepcionesTemp * percentT).toFixed(2);
};

// Calculates Perceptions + IVA
//FIXME calculations pendientes
export const calculatePerceptionsIva = (
    percepcionesTemp,
    percentT,
    return_value,
    ISN,
    ISR,
    IVA,
    socialCostTemp
) => {
    return (
        percepcionesTemp * percentT +
        (return_value +
            ISN +
            ISR +
            IVA +
            socialCostTemp +
            percepcionesTemp * percentT) *
            (16 / 100)
    ).toFixed(2);
};

// Calculates Available
export const calculateAvailable = (
    return_value,
    percentT,
    ISN,
    IVA,
    ISR,
    socialCostTemp
) => {
    return (
        (return_value + ISN + ISR + IVA + socialCostTemp) *
        percentT
    ).toFixed(2);
};

//
export const calculateAvailableIVA = (
    return_value,
    percentT,
    ISN,
    IVA,
    ISR,
    socialCostTemp
) => {
    return (
        (return_value + ISN + ISR + IVA + socialCostTemp) * percentT +
        (return_value + ISN + ISR + IVA + socialCostTemp) *
            percentT *
            (16 / 100)
    ).toFixed(2);
};

export const calculateSubOfReturn = (return_value, percentT) => {
    return ((return_value / 1.16) * percentT).toFixed(2);
};

export const calculateSubOfReturnTwoPercent = (return_value, percentT) => {
    return (
        (return_value / 1.16) * percentT +
        return_value * (2 / 100)
    ).toFixed(2);
};

export const calculateCommissionBySingleScheme = (
    commissionValuesCalculus,
    BaseComision
) => {
    const {
        return_value,
        percentT,
        facturaTemp,
        ISN,
        ISR,
        IVA,
        percepcionesTemp,
        socialCostTemp,
        depositTotal,
    } = commissionValuesCalculus;

    if (BaseComision === 'TOTAL') {
        return totalCalc(return_value, ISN, ISR, IVA, socialCostTemp, percentT);
    } else if (BaseComision === 'TOTAL FACTURA') {
        return calculateTotalFact(facturaTemp, percentT);
    } else if (BaseComision === 'SUBTOTAL') {
        return calculatesSubtotal(
            return_value,
            ISN,
            ISR,
            IVA,
            socialCostTemp,
            percentT
        );
    } else if (BaseComision === 'SUBTOTAL + IVA') {
        return calculatesSubtotalIVA(percentT, depositTotal);
    } else if (BaseComision === 'SUBTOTAL FACTURA') {
        return calculateSubotalFact(facturaTemp, percentT);
    } else if (BaseComision === 'SUBTOTAL + 2% RET') {
        return calculateSubtotalTwoPercent(
            return_value,
            ISN,
            ISR,
            IVA,
            socialCostTemp,
            percentT
        );
    } else if (BaseComision === 'RETORNO') {
        return calculateReturn(return_value, percentT);
    } else if (BaseComision === 'RETORNO + 2%') {
        return calculateReturnTwoPercent(return_value, percentT);
    } else if (BaseComision === 'RETORNO + IVA') {
        return calculateReturnIVA(
            return_value,
            percentT,
            ISN,
            ISR,
            IVA,
            socialCostTemp
        );
    } else if (BaseComision === 'RETORNO + IVA (10%)') {
        return calculateReturnIvaTenPercent(
            return_value,
            percentT,
            ISN,
            ISR,
            IVA,
            socialCostTemp
        );
    } else if (BaseComision === 'RETORNO Y COSTO') {
        return calculateReturnAndCost(
            return_value,
            percentT,
            socialCostTemp,
            ISN
        );
    } else if (BaseComision === 'PERCEPCIONES') {
        return calculatePerceptions(percepcionesTemp, percentT);
    } else if (BaseComision === 'PERCEPCIONES + IVA') {
        return calculatePerceptionsIva(
            percepcionesTemp,
            percentT,
            return_value,
            ISN,
            ISR,
            IVA,
            socialCostTemp
        );
    } else if (BaseComision === 'DISPONIBLE') {
        return calculateAvailable(
            return_value,
            percentT,
            ISN,
            IVA,
            ISR,
            socialCostTemp
        );
    } else if (BaseComision === 'DISPONIBLE + IVA') {
        return calculateAvailableIVA(
            return_value,
            percentT,
            ISN,
            IVA,
            ISR,
            socialCostTemp
        );
    } else if (BaseComision === 'SUB DEL RETORNO') {
        return calculateSubOfReturn(return_value, percentT);
    } else if (BaseComision === 'SUB DEL RETORNO + 2%') {
        return calculateSubOfReturnTwoPercent(return_value, percentT);
    }

    return 0;
};

// Return value calculation for subtotal + iva
export const returnValueSubtotalIVA = (percentT, depositTotal) => {
    const subtotalPlusIVA = calculatesSubtotalIVA(percentT, depositTotal);
    return (
        Number(depositTotal - subtotalPlusIVA).toFixed(2)
    );
};

// Calculate return commision
export const calculateReturnValue = (commissionValuesCalculus, BaseComision) => {
    const {
        percentT,
        depositTotal,
        return_value,
    } = commissionValuesCalculus;

    if (BaseComision === 'SUBTOTAL + IVA'){
        return returnValueSubtotalIVA(percentT, depositTotal);
    }
    else{
        return return_value;
    }
}