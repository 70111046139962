import React from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { registerUser, updateUser } from '../../actions/user';
import {
    getDepartmentDescription,
    getDepartmentID,
    getPositionDescription,
    getPositionID,
    getProfileDescription,
    getProfileID,
    handleError,
    handleSuccess,
} from '../../helpers/utils';

// Define rolls, departments, positions
const rolls = ['Administrador', 'Ejecutivo', 'Operador'];
const departments = ['Sistemas', 'Dirección', 'Operaciones'];
const positions = ['Gerente de sistemas', 'Gerente', 'Ejecutivo'];

// Formik schema
const RegisterSchema = Yup.object().shape({
    first_name: Yup.string()
        .min(2, '¡Demasiado corto!')
        .max(100, '¡Demasiado largo!')
        .nullable(true)
        .required('Se requiere el nombre'),
    last_name: Yup.string()
        .min(2, '¡Demasiado corto!')
        .max(100, '¡Demasiado largo!')
        .nullable(true)
        .required('Se requieren los apellidos'),
    email: Yup.string()
        .email('No es un correo valido')
        .min(2, '¡Demasiado corto!')
        .max(50, '¡Demasiado largo!')
        .nullable(true)
        .required('Se requiere un correo electrónico'),
    password: Yup.string()
        .min(7, '¡Demasiado corto!')
        .nullable(true)
        .required('Se requiere una contraseña'),
    role: Yup.string()
        .min(2, '¡Demasiado corto!')
        .max(50, '¡Demasiado largo!')
        .nullable(true)
        .required('Se requiere un rol'),
    department: Yup.string()
        .min(2, '¡Demasiado corto!')
        .max(50, '¡Demasiado largo!')
        .nullable(true)
        .required('Se requiere un departamento'),
    position: Yup.string()
        .min(2, '¡Demasiado corto!')
        .max(50, '¡Demasiado largo!')
        .nullable(true)
        .required('Se requiere una posición'),
});

export const NewUserForm = ({ handleClose, data = null, refresh = null }) => {
    // Define state to know if it's loading or not
    const [isLoading, setIsLoading] = useState(false);
    const [initialValuesFormik] = useState(
        data
            ? {
                  first_name: data.first_name,
                  last_name: data.last_name,
                  email: data.email,
                  password: data.password,
                  role: getProfileID(data.profile),
                  department: getDepartmentID(data.department),
                  position: getPositionID(data.position),
              }
            : {
                  first_name: '',
                  last_name: '',
                  email: '',
                  password: '',
                  role: null,
                  department: null,
                  position: null,
              }
    );
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: initialValuesFormik,
        validationSchema: RegisterSchema,
    });

    /**
     * This function handles the submission of a user registration or update form.
     * It is typically called when the user clicks the form's submit button.
     * The function performs the following steps:
     * 1. Sets the loading state to true to indicate form submission is in progress.
     * 2. Extracts form field values from the Formik state.
     * 3. Constructs a formData object with processed values.
     * 4. Dispatches an action to either update an existing user (if data is not null) or register a new user (if data is null).
     * 5. Sets a timeout to refresh the user data (simulated delay of 1 second).
     * 6. Sets the loading state back to false.
     * 7. Closes the form modal.
     */
    const handleSubmitFormik = () => {
        setIsLoading(true); // Set loading state to true to indicate form submission.

        // Extract form field values from the Formik state.
        const {
            first_name,
            last_name,
            email,
            password,
            role,
            department,
            position,
        } = formik.values;

        // Create a formData object with trimmed and processed values.
        const formData = {
            first_name: first_name.trim(),
            last_name: last_name.trim(),
            email: email.trim(),
            password,
            is_staff: role === 'Administrador',
            profile: getProfileDescription(role),
            department: getDepartmentDescription(department),
            position: getPositionDescription(position),
        };

        // Dispatch an action to update or register a user based on 'data'.
        dispatch(
            data !== null
                ? () => {
                      updateUser(data.id, formData)
                          .then(({ data }) => {
                              console.log('entré');
                              handleSuccess('El usuario se editó correctamente');
                          })
                          .catch((error) => {
                              handleError(
                                  error,
                                  'No se pudo editar el usuario.'
                              );
                          });
                  }
                : // Update user data if 'data' is not null.
                  () => {
                      registerUser(formData)
                          .then(({ data }) => {
                              console.log('entré');
                              handleSuccess('El usuario se creó correctamente');
                          })
                          .catch((error) => {
                              handleError(
                                  error,
                                  'No se pudo registrar el usuario.'
                              );
                          });
                  }
            // Register a new user if 'data' is null.
        );

        // Set a timeout to refresh the data after 1 second (simulated delay).
        setTimeout(() => {
            refresh();
        }, 1000);

        setIsLoading(false); // Set loading state back to false.
        handleClose(); // Close the form modal.
    };

    const { handleSubmit, getFieldProps, touched, errors } = formik;

    // Text component for reuse
    const TextComp = (name, label, type = 'text') => (
        <TextField
            margin='normal'
            type={type}
            disabled={name === 'password' && data !== null}
            fullWidth
            {...getFieldProps(name)}
            label={label}
            error={Boolean(touched[name] && errors[name])}
            helperText={touched[name] && errors[name]}
        />
    );

    const SelectComponent = (params, label) => (
        <TextField
            required
            margin='normal'
            {...params}
            {...getFieldProps(name)}
            label={label}
            error={Boolean(touched[name] && errors[name])}
            helperText={touched[name] && errors[name]}
        />
    );

    return (
        <FormikProvider value={formik}>
            <Form
                autoComplete='off'
                noValidate
                onSubmit={handleSubmit}
            >
                <Grid
                    container
                    spacing={2}
                    className='mt-3'
                >
                    <Grid
                        item
                        xs={6}
                    >
                        {/* First name field */}
                        {TextComp('first_name', 'Nombre')}
                    </Grid>

                    <Grid
                        item
                        xs={6}
                    >
                        {/* Last name field */}
                        {TextComp('last_name', 'Apellidos')}
                    </Grid>

                    <Grid
                        item
                        xs={6}
                    >
                        {/* Email field */}
                        {TextComp('email', 'Correo')}
                    </Grid>

                    {data === null && (
                        <Grid
                            item
                            xs={6}
                        >
                            {/* Password field */}
                            {TextComp('password', 'Contraseña', 'password')}
                        </Grid>
                    )}

                    <Grid
                        item
                        xs={6}
                    >
                        {/* Profile field */}
                        <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            required
                            // style={{ marginTop: '20px' }}
                            fullWidth
                            value={formik.values.role}
                            onChange={(_, value) => {
                                formik.setFieldValue('role', value);
                            }}
                            options={rolls}
                            renderInput={(params) =>
                                SelectComponent(params, 'Perfil', 'role')
                            }
                        />
                    </Grid>

                    <Grid
                        item
                        xs={6}
                    >
                        {/* Department field */}
                        <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            required
                            fullWidth
                            value={formik.values.department}
                            onChange={(_, value) => {
                                formik.setFieldValue('department', value);
                            }}
                            options={departments}
                            renderInput={(params) =>
                                SelectComponent(
                                    params,
                                    'Departamento',
                                    'department'
                                )
                            }
                        />
                    </Grid>

                    <Grid
                        item
                        xs={6}
                    >
                        {/* Position field */}
                        <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            required
                            fullWidth
                            value={formik.values.position}
                            onChange={(_, value) => {
                                formik.setFieldValue('position', value);
                            }}
                            options={positions}
                            renderInput={(params) =>
                                SelectComponent(params, 'Posición', 'position')
                            }
                        />
                    </Grid>

                    {data === null && (
                        <Grid
                            item
                            xs={6}
                        />
                    )}

                    <Grid
                        item
                        xs={6}
                    >
                        {/* Cancel button */}
                        <LoadingButton
                            fullWidth
                            size='large'
                            color='error'
                            onClick={() => {
                                formik.resetForm(initialValuesFormik);
                                handleClose();
                            }}
                            variant='contained'
                            loading={false}
                        >
                            Cancelar
                        </LoadingButton>
                    </Grid>

                    <Grid
                        item
                        xs={6}
                    >
                        {/* Confirm button */}
                        <LoadingButton
                            fullWidth
                            size='large'
                            style={{ backgroundColor: '#143f6c' }}
                            type='submit'
                            variant='contained'
                            loading={isLoading}
                            onClick={() => {
                                handleSubmitFormik();
                                handleClose();
                            }}
                        >
                            Guardar Usuario
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
};
