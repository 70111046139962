import React, { useEffect } from 'react';
import { Typography, Grid, Box, Alert, Stack } from '@mui/material';
import { formatDateTimeMX } from '../../helpers/hours';
import { HEADQUARTER_CHOICES, LEVEL_CHOICES } from './CompaniesTable';
import { STATUS_CHOICES } from './ConsCompaniesTable';
import { money } from '../../helpers/money';

// Default configuration for a new table
/*
  Default configuration for a grid details
  [
	{ xsWidth: 12, title: 'TITULO', code: 'code' },
    ...
  ]
*/
const detailGridConfig = {
	gridItems: [
		{ xsWidth: 6, title: 'CONCEPTO', code: 'concept' },
		{ xsWidth: 6, title: 'DESCRIPCION', code: 'description' },
	],
};

export const CatalogDetail = ({ data, config = detailGridConfig }) => {
	// Desestructure config property
	const {
		gridItems = [
			{ xsWidth: 6, title: 'CONCEPTO', code: 'concept' },
			{ xsWidth: 6, title: 'DESCRIPCION', code: 'description' },
		],
	} = config;

	// Function to filter the text for the DataGrids
	const FilterData = (keyName) => {
		if (keyName === 'headquarter') {
			return HEADQUARTER_CHOICES[data[keyName]];
		}
		if (keyName === 'level') {
			return LEVEL_CHOICES[data[keyName]];
		}
		if (keyName === 'status') {
			return STATUS_CHOICES[data[keyName]];
		}
		if (typeof data[keyName] === 'boolean') {
			return data[keyName] ? 'Sí' : 'No';
		}
		if (typeof data[keyName] === 'number') {
			const regExp = /amount/;
			if (regExp.test(keyName)) {
				return money(data[keyName]);
			}
			return (data[keyName] * 100).toString() + '%';
		}
		if (data[keyName] === '' || data[keyName] === null) {
			return '-';
		}
		return data[keyName];
	};

	return (
		<Box sx={{ width: '100%' }}>
			{data ? (
				<>
					<Stack
						direction='column'
						alignItems='flex-start'
						justifyContent='space-between'
						mb={1}
						style={{
							border: '1px solid white',
							padding: '20px 0 0 20px',
						}}
					>
						<Typography variant='h4' gutterBottom>
							Clave: {data.key}
						</Typography>
						<Typography variant='p' gutterBottom>
							<b>Actualizado:</b> {formatDateTimeMX(data.updated_at)}
						</Typography>
						<Typography variant='p' gutterBottom>
							<b>Creado:</b> {formatDateTimeMX(data.created_at)}
						</Typography>
					</Stack>

					<Grid
						container
						spacing={2}
						style={{ border: '1px solid white' }}
						margin={1}
					>
						<Grid item xs={12}>
							<Grid container spacing={2} style={{ border: '1px solid white' }}>
								{gridItems.map((item, key) => {
									return (
										<GridItem
											key={key}
											xs={item?.xsWidth}
											title={item?.title}
											text={FilterData(item?.code)}
										/>
									);
								})}
							</Grid>
						</Grid>
					</Grid>
				</>
			) : (
				<div style={{ padding: '40px' }}>
					<Alert severity='warning'>No se encontraron detalles</Alert>
				</div>
			)}
		</Box>
	);
};

const GridItem = ({ xs, title, text }) => (
	<Grid item xs={xs} style={{ margin: '10px 0' }}>
		<Typography variant='p'>
			<b>{title}</b>
		</Typography>
		<Typography variant='h6'>{text}</Typography>
	</Grid>
);
