import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { registerAdministrativeCost } from '../../actions/administrativeCosts';
import { handleSuccess } from '../../helpers/utils';
import { CustomCurrencyInput } from '../utils/UtilsComponent';

const arrBases = [
    { label: 'ARRENDAMIENTO' },
    { label: 'CAJA CHICA CMDX' },
    { label: 'CAJA CHICA PUEBLA' },
    { label: 'COSTOS SOCIALES' },
    { label: 'DEUDA' },
    { label: 'GASTOS ADMINISTRATIVOS' },
    { label: 'GASTOS ADMINISTRATIVOS DOMICILIOS' },
    { label: 'GASTOS DIRECCION' },
    { label: 'GASTOS JURIDICOS' },
    { label: 'GASTOS SOFOM - SAPI' },
    { label: 'IMPUESTOS ESTATALES (ISN)' },
    { label: 'IMPUESTOS FEDERALES' },
    { label: 'MANTENIMIENTO VEHICULOS' },
    { label: 'NOMINA' },
    { label: 'RENTAS ' },
    { label: 'SERVICIOS APOYO EXTERNO' },
    { label: 'SISTEMAS' },
    { label: 'TELEFONOS' },
    { label: 'COMISIONES BANCARIAS' },
    { label: 'VIATICOS' },
    { label: 'COMISIONES T. PREPAGO' },
    { label: 'CAJA CHICA METEPEC' },
    { label: 'CAJA CHICA DOMICILIOS' },
    { label: 'CAPACITACION' },
    { label: 'MULTAS' },
    { label: 'PROMOTORIAS' },
    { label: 'AGUINALDOS' },
    { label: 'COMISION COMPRA EFECTIVO' },
    { label: 'SEGUROS VEHICULOS' },
    { label: 'TRASLADO EFECTIVO' },
    { label: 'FINIQUITOS' },
    { label: 'PRESTAMO' },
    { label: 'NO APLICA' },
];

export const NewAdministrativeCosts = ({
    show = false,
    handleClose,
    handleChange,
    handleUpdateTotals,
}) => {
    const application = useSelector((state) => state.request.application);
    const [campus, setCampus] = useState(null);
    const [listOfExpenses, setListOfExpenses] = useState(null);
    const [manualImport, setManualImport] = useState(null);
    const [comments, setComments] = useState(null);

    const { administrativeCostsArray = [] } = useSelector(
        (state) => state.request.administrativeCosts
    );


    const handleAdministrativeCosts = () => {
        registerAdministrativeCost({
            campus, // Campus/sede selected by the user
            list_of_expenses: listOfExpenses, // List of expenses entered by the user
            manual_import: manualImport, // Manual import value entered by the user
            comments: comments, // Comments entered by the user
            application: application.id, // ID of the associated application
            fundings: [], // Fundings data (empty for now)
        })
            .then((data) => {
                // Handle successful submission
                handleClose(); // Close the administrative cost form dialog
                const administrativeCostArrayTemp = [
                    ...administrativeCostsArray,
                    data.data,
                ];
                // Update totals and Redux store
                handleUpdateTotals(administrativeCostArrayTemp);
                // Display a success message
                handleSuccess('Gasto Administrativo Creado Correctamente');
            })
            .catch((error) => {
                // Handle submission error by logging it
                console.error(error);
            });
    };

    return (
        <Dialog
            fullWidth
            maxWidth='xs'
            open={show}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle>Nuevo Gasto Administrativo:</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Complete el formulario para crear un nuevo Gasto
                    Administrativo
                </DialogContentText>
                <hr />

                <Grid
                    container
                    spacing={2}
                    style={{
                        marginBottom: '10px',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <TextField
                            required
                            fullWidth
                            label='Sede'
                            id='sede'
                            name='sede'
                            type='text'
                            onChange={(e) => {
                                setCampus(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            required
                            disablePortal
                            id='expensesList'
                            name='expensesList'
                            options={arrBases}
                            sx={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Lista de Gastos'
                                />
                            )}
                            onChange={(event, value) => {
                                setListOfExpenses(value.label)
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <CustomCurrencyInput
                            required
                            fullWidth
                            label='Importe'
                            id='import'
                            name='import'
                            type='text'
                            value={manualImport}
                            onValueChange={(e) => {
                                setManualImport(e);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <TextField
                            required
                            fullWidth
                            label='Comentarios'
                            id='comments'
                            name='comments'
                            onChange={(e) => {
                                setComments(e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color='error'
                    onClick={handleClose}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        handleAdministrativeCosts();
                    }}
                >
                    Crear
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);
