import React, { useEffect, useState } from 'react';
import { Stack, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { GastosOperativosInput } from '../components/applications/GastosOperativosInput';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateOperatingExpenses,
	updateOperatingExpensesTotal,
	updateDecOperatingExpenses,
	updateOperatingExpensesTotal2,
} from '../actions/requests';
import { money } from '../helpers/money';
import SaveIcon from '@mui/icons-material/Save';
import {
	handleUpdateFieldApp,
	setCurrentApplication,
	updateApplication,
} from '../actions/applications';
import { NewFundingOE } from '../components/applications/NewFundingOE';
import { FundingsAvailableOE } from '../components/applications/FundingsAvailableOE';
import InfoIcon from '@mui/icons-material/Info';
import { handleError, handleSuccess } from '../helpers/utils';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { CostDialog2 } from '../components/applications/CostDialog2';
import { PayersDialog } from './../components/applications/PayersDialog';
import { calculateAvailableKV } from './../helpers/totalAvailableKV';
import CurrencyFormat from 'react-currency-format';
import { setDecOperatingExpenses } from '../helpers/OperativeExpenses/OperativeExpensesFunctions';
import {
	calculateBankCommissions,
	getDepositsCommission,
	edenRedKVCalc,
	edenRedCorpoCalc,
	calculateCostCorporative,
	calculateCostEfective,
	calculateSCCost,
	calculateCostUnion,
	getDoubleCommission,
} from '../helpers/OperativeExpenses/OperativeExpensesCalculations';
import { saveApplication } from '../helpers/SaveApplication';

// Users table
export const GastosOperativos = ({ applicationTemp = {} }) => {
	const dispatch = useDispatch();

	// Redux state
	const { application = null } = useSelector((state) => state.request);
	const objectOperativeExpenses = useSelector(
		(state) => state.request.operatingExpenses
	);
	const creditToDebtExpenses = useSelector(
		(state) => state.request.creditToDebt.totalExpenses
	);
	const deposits = useSelector((state) => state.request.deposits);
	const depositTotal = useSelector((state) => state.request.depositTotal);
	const comisionesBancariasTemp = useSelector(
		(state) => state.request.operatingExpenses.comisionesBancarias
	);
	const rows = useSelector((state) => state.request.returnAndCommission);
	const operatingExpensesTotal = useSelector(
		(state) => state.request.operatingExpensesTotal
	);
	const operatingExpensesTotal2 = useSelector(
		(state) => state.request.application.total_operative_expenses2
	);
	const fundings = useSelector((state) => state.request.application.fundings);
	const decOperatingExpenses = useSelector(
		(state) => state.request.decOperatingExpenses
	);
	const { banks = [] } = useSelector((state) => state.catalogs);

	const applicationInvoice = useSelector(
		(state) => state.request.application.folio
	);
	const totalDeposits = useSelector((state) => state.request.depositTotal);

	//handle Fundings States
	const [tempFundingSpei, setFundingSpei] = React.useState('');
	const [tempAmountSpei, setAmountSpei] = React.useState(0);
	//handle Dialog Fundings
	const [show3, setShow3] = React.useState(false);
	const handleClose3 = () => setShow3(false);
	const [show5, setShow5] = React.useState(false);
	const handleClose5 = () => setShow5(false);
	const handleOpen5 = () => setShow5(true);
	const [extraCost, setExtraCost] = useState('');
	const [speiNumberPayments, setSpeiNumberPayments] = useState('');
	const [showPayersDialog, setShowPayersDialog] = useState(false);

	const handleClose = () => {
		setShowPayersDialog(false);
	};

	const handleOpen = () => {
		setShowPayersDialog(true);
	};

	// Handle Informacion - Fondeo
	const [openPayerDialogReturn, setOpenPayerDialogReturn] =
		React.useState(false);
	const handlePayerDialogReturn = () => setOpenPayerDialogReturn(false);
	const [tempTypeFunding, setTempType] = React.useState('');
	const [conventionalCommission, setConventionalCommission] = useState('');

	// Update when component unmount
	useEffect(() => {
		return () => {
			dispatch(saveApplication());
		};
	}, []);

	/**
	 * Updates in operative expenses the number of payments on the spei segment
	 *
	 * @param {string} propertyObj - The property name within 'decOperatingExpenses' to be updated.
	 * @param {string} value - The numerical value to set for the specified property.
	 */
	const setNumOperatingExpenses = (propertyObj, value) => {
		if (value == '') {
			// If the 'value' is empty, do nothing
		} else {
			let applicationUsed = { ...application };

			// Update the 'number_of_payments_spei' property in the temporary 'applicationUsed' object with 'value'
			applicationUsed['number_of_payments_spei'] = value;

			// Send the updated 'applicationUsed' data to the database
			updateApplication(application.id, {
				...applicationUsed,
			}).then(({ data }) => {
				let tempArray = { ...decOperatingExpenses };

				// Update the specified 'propertyObj' in the temporary 'tempArray' with 'value'
				tempArray[propertyObj] = value;

				// Dispatch an action to update 'decOperatingExpenses' with the modified 'tempArray'
				dispatch(updateDecOperatingExpenses(tempArray));
				handleSuccess('Número de pagos en comisión actualizados');
			});
		}
	};

	// Calculus for Fácil (Comisión prepago)
	/**
	 * This function calculates the total amount based for the FACIL scheme
	 *
	 * @returns {number} The calculated total amount based on the 'FÁCIL' scheme
	 */
	function facilCalc() {
		let result = 0;

		// Use the 'reduce' function to iterate over the 'rows' array and calculate the total.
		let temp = rows.reduce(function (acc, obj) {
			// Check if the scheme is 'FÁCIL' and the 'return_value' property is not empty.
			if (obj.scheme === 'FÁCIL' && obj.return_value.length !== 0) {
				return acc + Number(obj.return_value);
			} else {
				return acc; // Otherwise, return the accumulator unchanged.
			}
		}, 0);

		if (temp === 0) {
			result = 0;
		} else {
			// Calculate the result as 'temp' multiplied by a percentage (1% + 0.16%).
			result = temp * (1 / 100 + 0.16 / 100);
		}

		console.log('RESULT FACIL CALC: ', result);

		// Return the final calculated result.
		return result;
	}

	/**
	 * This function calculates the total operative expenses, including various expense categories
	 * and additional costs such as credit-to-debt expenses, application-specific debt-related costs,
	 * and promotional expenses.
	 *
	 * @returns {number} The total operative expenses calculated as a sum of various expense categories.
	 */
	const calculateTotalOP = () => {
		// Create a temporary object containing specific expense categories from 'objectOperativeExpenses'.
		let tempObj = {
			comisionesConvencionales:
				objectOperativeExpenses.comisionesConvencionales,
			costoTraslado: objectOperativeExpenses.costoTraslado,
			comisionesBancarias: objectOperativeExpenses.comisionesBancarias,
			secondBankCommission: objectOperativeExpenses.secondBankCommission,
			costoProvedor4ST: objectOperativeExpenses.costoProvedor4ST,
			costoEfectivo: objectOperativeExpenses.costoEfectivo,
			costoFID: objectOperativeExpenses.costoFID,
			costoExtra: objectOperativeExpenses.costoExtra,
			costoSC: objectOperativeExpenses.costoSC,
			costoSindicato: objectOperativeExpenses.costoSindicato,
			comisionSpei: objectOperativeExpenses.comisionSpei,
			comisionEdenredKV: objectOperativeExpenses.comisionEdenredKV,
			comisionEdenredCorpo: objectOperativeExpenses.comisionEdenredCorpo,
			comisionFacil: objectOperativeExpenses.comisionFacil,
		};

		let tempObj2 = {
			comisionesConvencionales:
				objectOperativeExpenses.comisionesConvencionales,
			costoTraslado: objectOperativeExpenses.costoTraslado,
			comisionesBancarias:
				objectOperativeExpenses.bankCommissionSafeguard,
			secondBankCommission:
				objectOperativeExpenses.secondBankCommissionSafeguard,
			costoProvedor4ST: objectOperativeExpenses.corporativeCostSafeguard,
			costoEfectivo: objectOperativeExpenses.costoEfectivo,
			costoFID: objectOperativeExpenses.costoFID,
			costoExtra: objectOperativeExpenses.costoExtra,
			costoSC: objectOperativeExpenses.costoSC,
			costoSindicato: objectOperativeExpenses.costoSindicato,
			comisionSpei: objectOperativeExpenses.comisionSpei,
			comisionEdenredKV: objectOperativeExpenses.comisionEdenredKV,
			comisionEdenredCorpo: objectOperativeExpenses.comisionEdenredCorpo,
			comisionFacil: objectOperativeExpenses.comisionFacil,
		};

		// Calculate the total expenses by summing up valid numeric values in the 'tempObj'.
		let totalExpenses = Object.values(tempObj).reduce((a, b) => {
			if (!isNaN(b)) {
				return a + Number(b);
			}

			return a;
		}, 0);

		let totalExpenses2 = Object.values(tempObj2).reduce((a, b) => {
			if (!isNaN(b)) {
				return a + Number(b);
			}

			return a;
		}, 0);

		let creditToDebtExpensesTemp = Number(creditToDebtExpenses);

		// Calculate the overall total by adding up various expense components.
		let total =
			totalExpenses +
			creditToDebtExpensesTemp +
			application?.gastos_abono_a_deuda +
			application?.gastos_promotoria;

		let totalConsideringSafeguards =
			totalExpenses2 +
			creditToDebtExpensesTemp +
			application?.gastos_abono_a_deuda +
			application?.gastos_promotoria;

		return {
			total: total,
			totalConsideringSafeguards: totalConsideringSafeguards,
		};
	};

	/**
	 * This function checks if certain input fields have valid values and updates
	 * the application data and operating expenses accordingly if the conditions are met.
	 */
	function checkIfHasValueSpei() {
		// Check if both textfields are not empty and the amount is not zero.
		if (
			tempFundingSpei !== '' &&
			tempAmountSpei != 0 &&
			tempAmountSpei != ''
		) {
			let applicationUsed = { ...application };

			// Update specific fields in the temporary object with 'payerTemp' and 'amountTemp' values
			applicationUsed['payer_spei_payer'] = tempFundingSpei;
			applicationUsed['amount_spei_payer'] = Number(tempAmountSpei);

			// Update the application data asynchronously using the 'updateApplication' function.
			updateApplication(application.id, {
				...applicationUsed,
			}).then(({ data }) => {
				// Create a temporary copy of the 'objectOperativeExpenses' object.
				let tempOperativeExpenses = { ...objectOperativeExpenses };

				tempOperativeExpenses['amountSpeiPayer'] = tempAmountSpei;
				tempOperativeExpenses['payerSpei'] = tempFundingSpei;

				dispatch(updateOperatingExpenses(tempOperativeExpenses));

				dispatch(
					setCurrentApplication({
						...data,
						fundings: applicationUsed.fundings,
						promoter: applicationUsed.promoter,
						executive: applicationUsed.executive,
					})
				);

				handleSuccess('Pagadora actualizada existosamente');
			});
		}
	}

	/**
	 * Calculates the cost of translation based on a given percentage, deposits, and application information.
	 *
	 * @param {number} percentage - The percentage to calculate the translation cost.
	 */
	const calculateCostTranslate = (percentage) => {
		let accumulatorValue = 0;
		let result = 0;

		// Check if there is at least one "Bancarizada" element in the 'deposits' array
		// and if the 'application.folio' is 'SOLICITUD CONVENCIONAL'
		if (
			deposits.some((element) => {
				return element.company_type == 'Bancarizada';
			}) &&
			(application.folio == 'SOLICITUD CONVENCIONAL' ||
				application.folio == 'BANCARIZADA')
		) {
			// If the conditions are met, sum all deposits that are "Bancarizada"
			accumulatorValue = deposits.reduce((accumulator, object) => {
				if (object.company_type == 'Bancarizada') {
					return accumulator + Number(object?.deposit || 0);
				} else {
					return accumulator + 0;
				}
			}, 0);

			// Calculate the cost of translation based on the given percentage and accumulatorValue
			result = Number(
				(Number(percentage) / 100) * Number(accumulatorValue)
			);
		} else {
			result = 0;
		}

		// Update the application data with the new percentage_transfer and cost_transfer values
		updateApplication(application.id, {
			...application,
			percentage_transfer: Number(percentage),
			cost_transfer: Number(result),
		}).then((data) => {
			// Dispatch OE (Operating Expenses) Variables
			dispatch(
				updateOperatingExpenses({
					...objectOperativeExpenses,
					porcentajeTraslado: Number(percentage),
					costoTraslado: Number(result),
				})
			);

			// Dispatch Application Variables with updated values
			dispatch(
				setCurrentApplication({
					...application,
					fundings: application.fundings,
					percentage_transfer: Number(percentage),
					cost_transfer: Number(result),
					promoter: application.promoter,
					executive: application.executive,
				})
			);
		});
	};

	/**
	 * Calculates the total bank commissions for safeguard-related deposits.
	 *
	 * This function categorizes deposits into four types: KV, KV + Grupo, Grupo, and Grupo 2,
	 * and calculates commissions for each category. It also calculates the sum of balances for rows
	 * with the scheme 'RESGUARDO' from a 'rows' array. The resulting commissions are then adjusted
	 * by subtracting the sum of balances associated with safeguard schemes.
	 *
	 * @returns {number} - The total bank commissions adjusted for safeguard balances.
	 */
	const calculateBankCommissionsSafeguards = () => {
		// Filter deposits into four separate arrays based on company_type
		const kVArray = deposits.filter(
			(element) => element?.company_type == 'KV'
		);
		const kvGroupArray = deposits.filter(
			(element) => element?.company_type == 'KV + Grupo'
		);
		const groupArray = deposits.filter(
			(element) => element?.company_type == 'Grupo'
		);
		const groupTwoArray = deposits.filter(
			(element) => element?.company_type == 'Grupo 2'
		);

		// Calculate the sum of balances for rows with the scheme 'RESGUARDO'
		const safeguardsOnlyCalculus = rows.reduce((accumulator, element) => {
			if (element.scheme == 'RESGUARDO') {
				return accumulator + element.balance;
			}
			return accumulator;
		}, 0);

		// Calculate commissions for each deposit category
		const kVResult = getKVBankCommission(kVArray);
		const kVGroupResult = getKVBankCommission(kvGroupArray);
		const groupResult = getKvGroupCommission(groupArray);
		const group2Result = getKvGroupCommission(groupTwoArray);

		// Sum all commissions and subtract safeguard-related balances
		return (
			kVResult +
			kVGroupResult +
			groupResult +
			group2Result -
			safeguardsOnlyCalculus
		);
	};

	/**
	 * Calculates the commission for deposits with a focus on safeguard schemes.
	 *
	 * This function calculates the commission for deposits with a focus on safeguard schemes.
	 * It first calculates the total import funding by summing the deposit values from the 'deposits' array.
	 * It also calculates the sum of balances for rows with the scheme 'RESGUARDO' from a 'rows' array.
	 * @returns {number} - The commission for deposits with a focus on safeguard schemes.
	 */
	const getDepositsCommissionSafeguards = () => {
		// Calculate the total import funding by summing deposit values
		const totalImportFunding = deposits.reduce(
			(accumulator, element) => accumulator + element?.deposit,
			0
		);

		// Calculate the sum of balances for rows with the scheme 'RESGUARDO'
		const safeguardsOnlyCalculus = rows.reduce((accumulator, element) => {
			if (element.scheme == 'RESGUARDO') {
				return accumulator + element.balance;
			}
			return accumulator;
		}, 0);

		// Calculate the commission by subtracting 25% of safeguard-related balance sum from total import funding
		// and dividing the result by 100
		return (totalImportFunding - safeguardsOnlyCalculus * 0.25) / 100;
	};

	/**
	 * Calculates the corporate cost with a focus on commissions and safeguard schemes.
	 *
	 * This function calculates the corporate cost by summing up commission_corporative values
	 * from the 'deposits' array and then subtracting the sum of balances associated with safeguard
	 * schemes ('RESGUARDO') from the 'rows' array.
	 *
	 * @returns {number} - The calculated corporate cost considering commissions and safeguard schemes.
	 */
	const calculateCostCorporative2 = () => {
		// Calculate the sum of commission_corporative values from deposits
		const depositTotal = deposits.reduce((accumulator, element) => {
			if (element.company_type !== 'KV') {
				return accumulator + element.deposit;
			} else {
				return accumulator;
			}
		}, 0);

		// Calculate the sum of balances for rows with the scheme 'RESGUARDO'
		const safeguardsOnlyCalculus = rows.reduce((accumulator, element) => {
			if (element.scheme == 'RESGUARDO') {
				return accumulator + element.balance;
			}
			return accumulator;
		}, 0);

		return (((depositTotal - safeguardsOnlyCalculus) / 1.16) * 1.5) / 100;
	};

	/**
	 * Load data from the initial render and set state variables.
	 *
	 * This useEffect hook is used to load and set initial data when the component is first rendered.
	 * It sets state variables such as 'ConventionalCommission', 'ExtraCost', and 'SpeiNumberPayments'
	 * based on the corresponding values from the 'application' object.
	 */
	useEffect(() => {
		// Set the 'ConventionalCommission' state based on the 'conventional_commissions' value from 'application'
		setConventionalCommission(application?.conventional_commissions);

		// Set the 'ExtraCost' state based on the 'extra_cost' value from 'application'
		setExtraCost(application?.extra_cost);

		// Set the 'SpeiNumberPayments' state based on the 'amount_spei_payer' value from 'application'
		setSpeiNumberPayments(application?.number_of_payments_spei);
	}, []);

	/**
	 * useEffect for Updating SPEI Commission Value
	 *
	 * value and related data when the 'speiNumberPayments' or 'decOperatingExpenses.comisionSpei' values change.
	 *
	 * 1. Calculates the updated SPEI commission value by multiplying 'speiNumberPayments' by 5.8.
	 * 2. Updates the 'application' object with the new 'spei_commission_value'.
	 * 3. Formats the calculated result with two decimal places and updates 'tempObj2.comisionSpei'.
	 * 4. Dispatches an action to update the operative expenses with the modified 'tempObj2'.
	 */
	useEffect(() => {
		updateApplication(application.id, {
			...application,
			spei_commission_value: Number(speiNumberPayments) * 5.8,
		}).then(({ data }) => {
			let result = Number(speiNumberPayments) * 5.8;
			let tempObj2 = { ...objectOperativeExpenses };
			tempObj2.comisionSpei = Number(result.toFixed(2));
			dispatch(updateOperatingExpenses(tempObj2));
		});
	}, [speiNumberPayments, decOperatingExpenses.comisionSpei]);

	/**
	 * Update specific commission values in the operating expenses when 'rows' data changes.
	 *
	 */
	useEffect(() => {
		let edenRedKVCalcRes = edenRedKVCalc(rows);
		let edenRedCorpoCalcRes = edenRedCorpoCalc(rows);
		let facilCalcRes = facilCalc();

		let tempObj2 = { ...objectOperativeExpenses };

		tempObj2.comisionEdenredKV = Number(edenRedKVCalcRes.toFixed(2));
		tempObj2.comisionEdenredCorpo = Number(edenRedCorpoCalcRes.toFixed(2));
		tempObj2.comisionFacil = Number(facilCalcRes.toFixed(2));

		dispatch(updateOperatingExpenses(tempObj2));
	}, [rows]);

	//  Calculate the sum of Operating expenses on row change or else
	useEffect(() => {
		const { total, totalConsideringSafeguards } = calculateTotalOP();

		updateApplication(application.id, {
			...application,
			total_operative_expenses: Number(total),
			total_operative_expenses2: Number(totalConsideringSafeguards),
			number_of_payments_spei: Number(speiNumberPayments),
			spei_commission_value: Number(speiNumberPayments) * 5.8,
		}).then(({ data }) => {
			dispatch(
				setCurrentApplication({
					...application,
					fundings: application.fundings,
					total_operative_expenses: Number(total),
					total_operative_expenses2: Number(
						totalConsideringSafeguards
					),
					number_of_payments_spei: Number(speiNumberPayments),
					promoter: application.promoter,
					executive: application.executive,
				})
			);
		});

		dispatch(updateOperatingExpensesTotal(total));
		dispatch(updateOperatingExpensesTotal2(total));
	}, [
		objectOperativeExpenses.comisionesConvencionales,
		objectOperativeExpenses.costoTraslado,
		objectOperativeExpenses.comisionesBancarias,
		objectOperativeExpenses.secondBankCommission,
		objectOperativeExpenses.costoProvedor4ST,
		objectOperativeExpenses.costoEfectivo,
		objectOperativeExpenses.costoFID,
		objectOperativeExpenses.costoExtra,
		objectOperativeExpenses.costoSC,
		objectOperativeExpenses.costoSindicato,
		objectOperativeExpenses.comisionSpei,
		objectOperativeExpenses.comisionEdenredKV,
		objectOperativeExpenses.comisionEdenredCorpo,
		objectOperativeExpenses.comisionFacil,
		rows,
		creditToDebtExpenses,
	]);

	useEffect(() => {
		setFundingSpei(objectOperativeExpenses.payerSpei);
		setAmountSpei(objectOperativeExpenses.amountSpeiPayer);
	}, [
		objectOperativeExpenses.payerSpei,
		objectOperativeExpenses.amountSpeiPayer,
	]);

	return (
		<div>
			<Stack
				direction='row'
				alignItems='flex-end'
				justifyContent='space-between'
				style={{
					border: '1px solid white',
					padding: '5px',
					// margin: '0 0 40px 0',
					width: '90%',
				}}
			>
				<Typography variant='h5' gutterBottom>
					Costos Operativos
				</Typography>
			</Stack>

			<br />

			<Grid container spacing={2}>
				<Grid item xs={10} style={{ padding: '20px' }}>
					<Grid container spacing={2}>
						<Grid
							container
							spacing={2}
							style={{
								border: '1px solid #D8D8D8',
								borderRadius: '10px',
								padding: '20px 0 0 0',
								margin: '0 0 40px 0',
								width: '100%',
								padding: '20px',
							}}
						>
							<Grid container style={{ margin: '0 0 25px 0' }}>
								<Grid item xs={4}>
									<Typography>
										<b>Concepto</b>
									</Typography>
								</Grid>

								<Grid item xs={6}>
									<Typography>
										<b>Importe</b>
									</Typography>
								</Grid>

								<Grid item xs={2}>
									<Typography>
										<b>Datos</b>
									</Typography>
								</Grid>
							</Grid>

							<GastosOperativosInput
								inputName={'COMISIONES BANCARIAS'}
								stateValue={
									decOperatingExpenses.comisionesBancarias
								}
								setDecOperatingExpenses={
									setDecOperatingExpenses
								}
								showState={
									objectOperativeExpenses.comisionesBancarias
								}
								objKey={'comisionesBancarias'}
								dbKeyValue={'bank_commission_value'}
								dbKeyDec={'bank_commission'}
								hasPayer={false}
								calculationFunction={calculateBankCommissions(
									deposits
								)}
								calculationFunction2={
									calculateBankCommissionsSafeguards
								}
							/>
							<GastosOperativosInput
								inputName={
									'COMISIÓN BANCARIA DEL TOTAL DEPOSITADO'
								}
								stateValue={
									decOperatingExpenses.secondBankCommission
								}
								setDecOperatingExpenses={
									setDecOperatingExpenses
								}
								showState={
									objectOperativeExpenses.secondBankCommission
								}
								objKey={'secondBankCommission'}
								dbKeyValue={'second_bank_commission'}
								dbKeyDec={'second_bank_commission_dec'}
								hasPayer={false}
								calculationFunction={getDepositsCommission(
									deposits
								)}
								calculationFunction2={
									getDepositsCommissionSafeguards
								}
							/>

							<GastosOperativosInput
								inputName={'DOBLE COMISIÓN BANCARIA'}
								stateValue={
									decOperatingExpenses.doubleBankCommission
								}
								setDecOperatingExpenses={
									setDecOperatingExpenses
								}
								showState={
									objectOperativeExpenses.doubleBankCommission
								}
								objKey={'doubleBankCommission'}
								dbKeyValue={'double_bank_Commission'}
								dbKeyDec={'double_bank_commission_dec'}
								hasPayer={false}
								calculationFunction={getDoubleCommission(
									deposits
								)}
							/>

							<Grid
								container
								spacing={2}
								style={{
									marginBottom: '10px',
								}}
							>
								<Grid item xs={4}>
									<Typography>
										COMISIONES CONVENCIONALES
									</Typography>
								</Grid>

								<Grid
									item
									xs={4}
									style={{
										padding: 0,
										margin: '0 0 10px 10px',
									}}
								>
									<CurrencyFormat
										customInput={TextField}
										variant='standard'
										label='Comisiones'
										// type='number'

										thousandSeparator={true}
										prefix={'$'}
										allowNegative={false}
										value={
											conventionalCommission?.toString() ??
											''
										}
										onChange={({ target }) => {
											setConventionalCommission(
												Number(
													target.value.replace(
														/[^0-9.-]+/g,
														''
													)
												)
											);
										}}
										onBlurCapture={() => {
											handleUpdateFieldApp(
												application,
												'conventional_commissions',
												Number(conventionalCommission)
											)
												.then((data) => {
													dispatch(
														setCurrentApplication({
															...application,
															fundings:
																application.fundings,
															conventional_commissions:
																Number(
																	conventionalCommission
																),
															promoter:
																application.promoter,
															executive:
																application.executive,
														})
													);

													dispatch(
														updateOperatingExpenses(
															{
																...objectOperativeExpenses,
																comisionesConvencionales:
																	Number(
																		conventionalCommission
																	),
															}
														)
													);

													handleSuccess(
														'Se registró comisión'
													);
												})
												.catch((error) => {
													handleError(
														'No se pudo registrar comisión'
													);
												});
										}}
									/>
								</Grid>

								<Grid item xs={4}></Grid>
							</Grid>

							<GastosOperativosInput
								inputName='COSTO CORPORATIVO'
								stateValue={
									decOperatingExpenses.costoProvedor4ST
								}
								setDecOperatingExpenses={
									setDecOperatingExpenses
								}
								showState={
									objectOperativeExpenses.costoProvedor4ST
								}
								objKey={'costoProvedor4ST'}
								dbKeyValue={'supplier_expense_4ST_value'}
								dbKeyDec={'supplier_expense_4ST'}
								hasPayer={false}
								calculationFunction={calculateCostCorporative(
									deposits,
									depositTotal
								)}
								calculationFunction2={calculateCostCorporative2}
							/>

							<Grid
								container
								spacing={2}
								style={{
									marginBottom: '30px',
								}}
							>
								<Grid item xs={4}>
									<Typography>COSTO DE TRASLADO</Typography>
								</Grid>

								<Grid item xs={6}>
									<Grid container spacing={2}>
										<Grid item xs={4}>
											<Typography>
												{money(
													Number(
														objectOperativeExpenses.costoTraslado
													)
												)}
											</Typography>
										</Grid>
										<Grid item xs={4}></Grid>
										<Grid item xs={2}>
											<Typography>
												{
													objectOperativeExpenses.porcentajeTraslado
												}
												%
											</Typography>
										</Grid>
										<Grid item xs={2}>
											<Button
												variant='contained'
												onClick={() => {
													handleOpen5();
												}}
											>
												Editar
											</Button>
										</Grid>
									</Grid>
								</Grid>

								<Grid
									item
									xs={2}
									style={{
										padding: 0,
										margin: '5px 0 10px 0',
									}}
								></Grid>
							</Grid>

							<Grid
								container
								spacing={2}
								style={{
									marginBottom: '10px',
								}}
							>
								<Grid item xs={4}>
									<Typography>COSTO MANUAL</Typography>
								</Grid>

								<Grid
									item
									xs={4}
									style={{
										padding: 0,
										margin: '0 0 10px 10px',
									}}
								>
									<CurrencyFormat
										customInput={TextField}
										variant='standard'
										label='Comisiones'
										// type='number'

										thousandSeparator={true}
										prefix={'$'}
										allowNegative={false}
										value={extraCost?.toString() ?? ''}
										onChange={({ target }) => {
											setExtraCost(
												Number(
													target.value.replace(
														/[^0-9.-]+/g,
														''
													)
												)
											);
										}}
										onBlurCapture={() => {
											handleUpdateFieldApp(
												application,
												'extra_cost',
												Number(extraCost)
											)
												.then((data) => {
													dispatch(
														setCurrentApplication({
															...application,
															fundings:
																application.fundings,
															extra_cost:
																Number(
																	extraCost
																),
															promoter:
																application.promoter,
															executive:
																application.executive,
														})
													);

													dispatch(
														updateOperatingExpenses(
															{
																...objectOperativeExpenses,
																costoExtra:
																	Number(
																		extraCost
																	),
															}
														)
													);

													handleSuccess(
														'Se registró Costo Manual'
													);
												})
												.catch((error) => {
													handleError(
														'No se pudo registrar comisión'
													);
												});
										}}
									/>
								</Grid>

								<Grid item xs={4}></Grid>
							</Grid>
							<GastosOperativosInput
								inputName={'0.75% - COSTO EFECTIVO'}
								stateValue={decOperatingExpenses.costoEfectivo}
								setDecOperatingExpenses={
									setDecOperatingExpenses
								}
								showState={
									objectOperativeExpenses.costoEfectivo
								}
								objKey={'costoEfectivo'}
								dbKeyValue={'cost_effective_value'}
								dbKeyDec={'cost_effective'}
								calculationFunction={calculateCostEfective(
									rows
								)}
								hasPayer={true}
								selectedAmount={'amountEffectivePayer'}
								selectedPayer={'payerEffective'}
								dbFunding={'payer_effectivecost_payer'}
								dbAmount={'amount_effectivecost_payer'}
							/>

							{/* <GastosOperativosInput
                                inputName={'3.50% - COSTO FID'}
                                stateValue={decOperatingExpenses.costoFID}
                                setDecOperatingExpenses={
                                    setDecOperatingExpenses
                                }
                                showState={objectOperativeExpenses.costoFID}
                                objKey={'costoFID'}
                                dbKeyValue={'fid_expense_value'}
                                dbKeyDec={'fid_expense'}
                                calculationFunction={calculateCostFID}
                                typeFunding={'FID'}
                                hasPayer={true}
                                selectedAmount={'amountFidPayer'}
                                selectedPayer={'payerFid'}
                                dbFunding={'payer_fid_payer'}
                                dbAmount={'amount_fid_payer'}
                            /> */}

							<GastosOperativosInput
								inputName={'1.50% - COSTO SC'}
								stateValue={decOperatingExpenses.costoSC}
								setDecOperatingExpenses={
									setDecOperatingExpenses
								}
								showState={objectOperativeExpenses.costoSC}
								objKey={'costoSC'}
								dbKeyValue={'sc_expense_value'}
								dbKeyDec={'sc_expense'}
								calculationFunction={calculateSCCost(rows)}
								typeFunding={'SC'}
								hasPayer={true}
								selectedAmount={'amountScPayer'}
								selectedPayer={'payerSC'}
								dbFunding={'payer_sc_payer'}
								dbAmount={'amount_sc_payer'}
							/>

							<GastosOperativosInput
								inputName={'2.50% - COSTO SINDICATO'}
								stateValue={decOperatingExpenses.costoSindicato}
								setDecOperatingExpenses={
									setDecOperatingExpenses
								}
								showState={
									objectOperativeExpenses.costoSindicato
								}
								objKey={'costoSindicato'}
								dbKeyValue={'union_expense_value'}
								dbKeyDec={'union_expense'}
								calculationFunction={calculateCostUnion(rows)}
								typeFunding={'CSD'}
								hasPayer={true}
								selectedAmount={'amountSindicatePayer'}
								selectedPayer={'payerSindicate'}
								dbFunding={'payer_sindicate_payer'}
								dbAmount={'amount_sindicate_payer'}
							/>

							<Grid
								container
								spacing={2}
								style={{
									marginBottom: '10px',
								}}
							>
								<Grid item xs={4}>
									<Typography>
										COMISIÓN SPEI BANORTE
									</Typography>
								</Grid>

								<Grid item xs={6}>
									<Grid container spacing={2}>
										<Grid item xs={4}>
											<Typography>
												{money(
													objectOperativeExpenses.comisionSpei
												)}
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<TextField
												id='outlined-basic'
												label='Pagadora'
												variant='standard'
												value={tempFundingSpei}
												style={{ width: '80%' }}
												onChange={(e) => {
													setFundingSpei(
														e.target.value
													);
												}}
												onBlurCapture={(element) => {
													checkIfHasValueSpei(
														application
													);
												}}
											/>
										</Grid>
										<Grid item xs={4}>
											<CurrencyFormat
												customInput={TextField}
												variant='standard'
												label='Monto'
												// type='number'

												thousandSeparator={true}
												prefix={'$'}
												allowNegative={false}
												value={tempAmountSpei}
												onChange={({ target }) => {
													setAmountSpei(
														Number(
															target.value.replace(
																/[^0-9.-]+/g,
																''
															)
														)
													);
												}}
												onBlurCapture={() => {
													checkIfHasValueSpei(
														applicationRequested
													);
												}}
											/>
										</Grid>
									</Grid>
								</Grid>

								<Grid
									item
									xs={2}
									style={{
										padding: 0,
										margin: '5px 0 10px 0',
									}}
								>
									<CurrencyFormat
										customInput={TextField}
										variant='standard'
										label='Num Pagos'
										// type='number'

										thousandSeparator={true}
										prefix={'$'}
										allowNegative={false}
										value={speiNumberPayments.toString()}
										onChange={({ target }) => {
											setSpeiNumberPayments(
												Number(
													target.value.replace(
														/[^0-9.-]+/g,
														''
													)
												)
											);
										}}
										onBlurCapture={() => {
											setNumOperatingExpenses(
												'comisionSpei',
												Number(speiNumberPayments)
											);
										}}
									/>
								</Grid>
							</Grid>

							<Grid
								container
								spacing={2}
								style={{
									marginBottom: '35px',
								}}
							>
								<Grid item xs={4}>
									<Typography>COMISIÓN PREPAGO</Typography>
								</Grid>

								<Grid item xs={6}>
									<Grid container spacing={2}>
										<Grid item xs={4}>
											<Typography>
												{money(
													objectOperativeExpenses.comisionFacil
												)}
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<Button
												variant='contained'
												style={{
													backgroundColor: '#143f6c',
												}}
												onClick={() => {
													setTempType('CIN');
													handleOpen();
												}}
												fullWidth
											>
												Pagadora
											</Button>
										</Grid>
										<Grid item xs={4}>
											<Button
												variant='contained'
												style={{
													backgroundColor: '#143f6c',
													fontSize: '13px',
												}}
												onClick={() => {
													setTempType('CIN');
													setOpenPayerDialogReturn(
														true
													);
												}}
											>
												<InfoIcon />
											</Button>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={2}></Grid>
							</Grid>

							<PayersDialog
								show={showPayersDialog}
								handleClose={handleClose}
								typeFunding={tempTypeFunding}
								concept={'COSTOS OPERATIVOS'}
							/>
							<CostDialog2
								show={show5}
								handleClose={handleClose5}
								typeFunding={tempTypeFunding}
								calculateCostTranslate={calculateCostTranslate}
							/>
							<NewFundingOE
								show={show3}
								handleClose={handleClose3}
								typeFunding={tempTypeFunding}
							/>
							<FundingsAvailableOE
								show={openPayerDialogReturn}
								handleClose={handlePayerDialogReturn}
								fundings={fundings}
								typeFunding={tempTypeFunding}
								tempBanks={banks}
							/>
							<Grid
								container
								spacing={2}
								style={{
									marginBottom: '35px',
								}}
							>
								<Grid item xs={4}>
									<Typography>GASTOS PROMOTORÍA</Typography>
								</Grid>

								<Grid item xs={4}>
									<Typography>
										{money(application.gastos_promotoria)}
									</Typography>
								</Grid>

								<Grid item xs={4}></Grid>
							</Grid>

							<Grid
								container
								spacing={2}
								style={{
									marginBottom: '35px',
								}}
							>
								<Grid item xs={4}>
									<Typography>
										GASTOS ABONO A DEUDA
									</Typography>
								</Grid>

								<Grid item xs={4}>
									<Typography>
										{money(
											application.gastos_abono_a_deuda
										)}
									</Typography>
								</Grid>

								<Grid item xs={4}></Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={2} style={{ padding: '20px' }}>
					<Grid
						container
						spacing={2}
						style={{
							marginBottom: '35px',
						}}
					>
						<Grid item xs={12}>
							<ItemTexts
								title='Total Costos Operativos'
								text={money(operatingExpensesTotal)}
							/>
						</Grid>

						<Grid item xs={12}>
							{/* <ItemTexts
                                title='Total (Resguardos)'
                                text={money(operatingExpensesTotal2)}
                            /> */}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export const ItemTexts = ({ title = '', text = '' }) => (
	<>
		<Typography variant='p'>
			<b>{title}</b>
		</Typography>
		<Typography variant='h6'>{text}</Typography>
	</>
);
