import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { updateCreditToDebt, updatePromoters } from '../../actions/requests';
import { registerFunding } from '../../actions/funding';
import { getPromoterInfo, updatePromoter } from '../../actions/promoter';
import { handleError, handleSuccess } from '../../helpers/utils';
import { getDebtInfo, updateDebt } from '../../actions/debt';
import { getPayers } from '../../actions/catalogs';
import CurrencyFormat from 'react-currency-format';

const arrPayers = [
    'Sukse',
    'Evor',
    'Mosep',
    'Kaylan',
    'Massuet',
    'Vireo',
    'Draxter',
    'Invarat',
    'Suare',
    'Henoceos',
    'Lexi',
    'Efectivo',
];

export const NewPayerAmount = ({
    show = false,
    handleClose,
    currentRow,
    handleChange,
    sectionTitle = '',
}) => {
    const dispatch = useDispatch();
    const request = useSelector((state) => state.request);
    const promoters = useSelector((state) => state.request.promoters);
    const creditToDebt = useSelector((state) => state.request.creditToDebt);
    const rows = useSelector((state) => state.request.returnAndCommission);
    const { banks = [] } = useSelector((state) => state.catalogs);
    const applicationID = useSelector((state) => state.request.application.id);
    const application = useSelector((state) => state.request.application);

    const [nameTemp, setNameTemp] = React.useState('');
    const [payer, setPayer] = React.useState('');
    const [amount, setAmount] = React.useState(0);
    const [payers, setPayers] = useState('');
    const [bank, setBank] = useState('');
    const [payersName, setPayersName] = useState(null);

    const [baseTemp, setBaseTemp] = React.useState('');
    const [commissionTemp, setCommissionTemp] = React.useState(0);

    /* Reset values on Dialog on close or on */
    const setValuesZero = () => {
        setPayers('');
        setAmount(0);
        setBank('');
    };

    const getTotals = (array) => {
        // Total commissions
        const totalCommissions = array.reduce(
            (sum, { commission }) => sum + commission,
            0
        );

        // Total amounts
        let totalAmounts = 0;
        array.forEach((item) => {
            totalAmounts += item.fundings.reduce(
                (sum, { amount }) => sum + amount,
                0
            );
        });
        return { totalCommissions, totalAmounts };
    };

    const updatePromoterRX = async () => {
        // Get updated row
        const updatedRow = (await getPromoterInfo(currentRow.id)).data;

        // Replace old register with the updated one
        const newRows = promoters.promotersArray.map((rowItem) =>
            rowItem.id === currentRow.id ? updatedRow : rowItem
        );

        // Get totals
        const { totalCommissions, totalAmounts } = getTotals(newRows);

        // Set current returns and commissions in Redux
        dispatch(
            updatePromoters({
                promotersArray: newRows,
                commissionTotal: totalCommissions,
                amountTotal: totalAmounts,
            })
        );
    };

    const updateDebtRX = async () => {
        // Get updated row
        const updatedRow = (await getDebtInfo(currentRow.id)).data;

        // Replace old register with the updated one
        const newRows = creditToDebt.creditArray.map((rowItem) =>
            rowItem.id === currentRow.id ? updatedRow : rowItem
        );

        // Get totals
        const { totalCommissions, totalAmounts } = getTotals(newRows);

        // Set current returns and commissions in Redux
        dispatch(
            updateCreditToDebt({
                creditArray: newRows,
                commissionTotal: totalCommissions,
                amountTotal: totalAmounts,
                totalExpenses: 0,
                availableKV: 0,
                totalPayer: 0,
                totalImport: 0,
            })
        );
    };

    const setPayerAmountValues = async () => {
        try {
            // Create new funding (DB)
            const newFunding = (
                await registerFunding({
                    payer: payers,
                    bank: banks.find((item) => item.name === bank).id,
                    amount: parseFloat(amount),
                    application_id: applicationID,
                    application_tag: application.key,
                    client:
                        application.client.id == null
                            ? application.client
                            : application.client.id,
                    concept: sectionTitle.toUpperCase(),
                })
            ).data;

            // Get and map current fundings' id
            const currentFundingsId = currentRow.fundings.map(
                (funding) => funding.id
            );

            const isPromoter = sectionTitle === 'Promotoría';

            if (isPromoter) {
                // Assign fundings to the current row by updating it
                await updatePromoter(currentRow.id, {
                    ...currentRow,
                    amount: 0,
                    application: currentRow.application.id,
                    fundings: [...currentFundingsId, newFunding.id],
                });

                // Update promoter redux
                updatePromoterRX();
            } else {
                // Assign fundings to the current row by updating it
                await updateDebt(currentRow.id, {
                    ...currentRow,
                    amount: 0,
                    application: currentRow.application.id,
                    fundings: [...currentFundingsId, newFunding.id],
                });

                // Update debt redux
                updateDebtRX();
            }

            // Success feedback
            handleSuccess('Fondeo creado');
        } catch (error) {
            // Error feedback
            handleError(
                error,
                'Ocurrió un error al intentar agregar el fondeo'
            );
        }
    };

    useEffect(() => {
        getPayers().then(({ data }) => {
            let onlyNamesOfPayers = data.results.map((element) => element.name);
            setPayersName(onlyNamesOfPayers);
        });
    }, []);

    return (
        <Dialog
            maxWidth='lg'
            open={show}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle>Nuevo Pagadora - Importe</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Complete el formulario para crear un nuevo Pagadora -
                    Importe
                </DialogContentText>
                <hr />

                <Grid
                    container
                    spacing={2}
                    style={{
                        marginBottom: '10px',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            required
                            // style={{ marginTop: '20px' }}
                            fullWidth
                            value={payers}
                            onChange={(_, value) => {
                                setPayers(value);
                            }}
                            options={payersName}
                            renderInput={(params) =>
                                SelectComponent(params, 'Pagadoras')
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            required
                            // style={{ marginTop: '20px' }}
                            fullWidth
                            value={bank}
                            onChange={(_, value) => {
                                setBank(value);
                            }}
                            options={banks.map((item) => item.name)}
                            renderInput={(params) =>
                                SelectComponent(params, 'Banco')
                            }
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <CurrencyFormat
                            customInput={TextField}
                            variant='outlined'
                            fullWidth
                            label='Importe'
                            // type='number'
                            thousandSeparator={true}
                            prefix={'$'}
                            allowNegative={false}
                            onChange={({ target }) => {
                                setAmount(
                                    Number(
                                        target.value.replace(/[^0-9.-]+/g, '')
                                    )
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color='error'
                    onClick={() => {
                        handleClose();
                        setValuesZero();
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        setPayerAmountValues();
                        handleChange();
                        handleClose();
                        setValuesZero();
                    }}
                >
                    Crear
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const ItemTexts = ({ title = '', text = '' }) => (
    <>
        <Typography variant='p'>
            <b>{title}</b>
        </Typography>
        <Typography variant='h6'>{text}</Typography>
    </>
);
const SelectComponent = (params, label) => (
    <TextField
        fullWidth
        required
        margin='normal'
        {...params}
        label={label}
    />
);
