import { getAdministrativeCost } from "../../actions/administrativeCosts";
import { getApplicationInfo, getDepositsByApplicationID } from "../../actions/applications";
import { getDebts } from "../../actions/debt";
import { getPDFInternalRequest } from "../../actions/pdfs";
import { getPromoters } from "../../actions/promoter";
import { getReturnAndCommissions } from "../../actions/returnAndCommissions";
import { calculateAllPayersOnReturnAndCommission, calculateTotalISN, calculateTotalISR, calculateTotalReturns } from "../../helpers/ReturnCommissionCalc";
import { handleError, handleSuccess } from "../../helpers/utils";

export const PDFFromTable = (idApplication) => {
    // First step: get application info
    getApplicationInfo(idApplication).then(async ({ data }) => {
        const { data: returnAndCommissionData } = await getReturnAndCommissions(idApplication);
        const { data: depositsData = {} } = await getDepositsByApplicationID(idApplication);
        const { results: promotersResults = [] } = (await getPromoters(idApplication))?.data;
        const { results: debtsResults = [] } = (await getDebts(idApplication))?.data;
        const { data: administrativeCostData } = await getAdministrativeCost(idApplication);

        const responseResultsDeposits = depositsData?.results;

        // Calculate the total commission for the 'corporative' scheme from 'depositsTemp'.
        let totalCommissionCorporative = responseResultsDeposits?.reduce(
            (accumulator, object) => {
                return (
                    accumulator + Number(object?.commission_corporative || 0)
                );
            },
            0
        );

        // Calculate the total import funding from 'depositsTemp'.
        let totalImportFunding = responseResultsDeposits?.reduce((accumulator, object) => {
            return accumulator + Number(object?.import_funding || 0);
        }, 0);


        // Total commissions promoters
        const totalCommissionsProm = promotersResults?.reduce(
            (sum, { commission }) => sum + commission,
            0
        );

        // Total amounts promoters
        let totalAmountsProm = 0;
        promotersResults?.forEach((item) => {
            totalAmountsProm += item.fundings.reduce(
                (sum, { amount }) => sum + amount,
                0
            );
        });

        // Promoters data
        const promotersData = {
            promotersArray: promotersResults ?? [],
            commissionTotal: totalCommissionsProm ?? 0,
            amountTotal: totalAmountsProm ?? 0,
        }

        // Total commissions
        const totalCommissionsDebts = debtsResults?.reduce(
            (sum, { commission }) => sum + commission,
            0
        );

        // Total amounts
        let totalAmountsDebts = 0;
        debtsResults?.forEach((item) => {
            totalAmountsDebts += item.fundings.reduce(
                (sum, { amount }) => sum + amount,
                0
            );
        });

        // Debts data
        const debtsData = {
            creditArray: debtsResults ?? [], 
            commissionTotal: totalCommissionsDebts ?? 0,
            amountTotal: totalAmountsDebts ?? 0,
            totalExpenses: 0,
            availableKV: 0,
            totalPayer: 0,
            totalImport: 0,
        };

        const hasRetAndCom = returnAndCommissionData?.results?.length >= 1;

        const totalFundingAvailableKV = data?.fundings?.reduce(
            (accumulator2, object) => {
                return accumulator2 + Number(object?.amount);
            },
            0
        );

        // Create redux deposits format
        const reduxDeposits = responseResultsDeposits?.map((item) => ({
            ...item,
            cost: item?.cost || 0,
            expense: item?.expense || 0,
        }));

        const newTotalDeposits = reduxDeposits?.reduce(
            (sum, { deposit }) => sum + deposit,
            0
        );

        const newTotalCommissions = reduxDeposits?.reduce(
            (sum, { commission }) => sum + commission,
            0
        );

        const newTotalCommissionsCorporative = reduxDeposits?.reduce(
            (sum, { commission_corporative }) => sum + commission_corporative,
            0
        );

        const totalCommissions = newTotalCommissionsCorporative + newTotalCommissions;

        const decOperatingExpenses = {
            comisionesBancarias: data?.bank_commission ? true : false,
            secondBankCommission: data?.second_bank_commission_dec
                ? true
                : false,
            doubleBankCommission: data?.double_bank_commission_dec
                ? true
                : false,
            costoProvedor4ST: data?.supplier_expense_4ST ? true : false,
            costoEfectivo: data?.cost_effective ? true : false,
            costoFID: data?.fid_expense ? true : false,
            costoSC: data?.sc_expense ? true : false,
            costoSindicato: data?.union_expense ? true : false,
            comisionSpei: data?.spei_commission || 0,
            cuentaKV: data?.account_kv ? true : false,
        };

		const operatingExpenses = {
            comisionesConvencionales: data?.conventional_commissions || 0,
            comisionesBancarias: data?.bank_commission_value || 0,
            secondBankCommission: data?.second_bank_commission || 0,
            doubleBankCommission: data?.double_bank_Commission || 0,
            costoTraslado: data?.cost_transfer || 0,
            porcentajeTraslado: data?.percentage_transfer || 0,
            costoExtra: data?.extra_cost || 0,
            costoProvedor4ST: data?.supplier_expense_4ST_value || 0,
            costoEfectivo: data?.cost_effective_value || 0,
            costoFID: data?.fid_expense_value || 0,
            costoSC: data?.sc_expense_value || 0,
            costoSindicato: data?.union_expense_value || 0,
            comisionSpei: data?.spei_commission_value || 0,
            comisionEdenredKV: data?.comision_edenred_kv || 0,
            comisionEdenredCorpo: data?.comision_edenred_corpo || 0,
            comisionFacil: data?.comision_facil || 0,
            gastosPromotoria: data?.gastos_promotoria || 0,
            gastosAAbonoDeuda: data?.gastos_abono_a_deuda || 0,
            amountEffectivePayer: data?.amount_effectivecost_payer || 0,
            amountFidPayer: data?.amount_fid_payer || 0,
            amountScPayer: data?.amount_sc_payer || 0,
            amountSindicatePayer: data?.amount_sindicate_payer || 0,
            amountSpeiPayer: data?.amount_spei_payer || 0,
            amountEdenredPayer: data?.amount_edenred_payer || 0,
            AmountEdenredCorpoPayer: data?.amount_edenred_corpo_payer || 0,
            amountFacilPayer: data?.amount_facil_payer || 0,
            payerEffective: data?.payer_effectivecost_payer || 0,
            payerFid: data?.payer_fid_payer || '',
            payerSC: data?.payer_sc_payer || '',
            payerSindicate: data?.payer_sindicate_payer || '',
            payerSpei: data?.payer_spei_payer || '',
            payerEdenred: data?.payer_edenred_payer || '',
            payerEdenredCorpo: data?.payer_edenred_corpo_payer || '',
            payerFacil: data?.payer_facil_payer || '',
            bankCommissionSafeguard: data?.bank_commission_safeguards || 0,
            secondBankCommissionSafeguard:
                data?.second_bank_commission_safeguards || 0,
            corporativeCostSafeguard:
                data?.expenses_corporative_safeguards || 0,
        };

        const tempObj = {
			comisionesConvencionales: data?.conventional_commissions || 0,
			costoTraslado: data?.cost_transfer || 0,
			comisionesBancarias: data?.bank_commission_value || 0,
			secondBankCommission: data?.second_bank_commission || 0,
			costoProvedor4ST: data?.supplier_expense_4ST_value || 0,
			costoEfectivo: data?.cost_effective_value || 0,
			costoFID: data?.fid_expense_value || 0,
			costoExtra: data?.extra_cost || 0,
			costoSC: data?.sc_expense_value || 0,
			costoSindicato: data?.union_expense_value || 0,
			comisionSpei: data?.spei_commission_value || 0,
			comisionEdenredKV: data?.comision_edenred_kv || 0,
			comisionEdenredCorpo: data?.comision_edenred_corpo || 0,
			comisionFacil: data?.comision_facil || 0,
		};

		const totalExpenses = Object.values(tempObj).reduce(
			(a, b) => a + Number(b),
			0
		);

        
        // Return and commissions results
        const responseResults = returnAndCommissionData?.results;

        const tempPayers = calculateAllPayersOnReturnAndCommission(responseResults);
        const tempReturn = calculateTotalReturns(responseResults);
        const tempISN = calculateTotalISN(responseResults);

        const tempISR = calculateTotalISR(responseResults);

        let totalAmount = 0;
        responseResults.forEach((item) => {
            totalAmount += item.fundings.reduce(
                (sum, { amount }) => sum + amount,
                0
            );
        });

        // Calculate total commission scheme (return and commission)
        const totalCommissionScheme = responseResults?.reduce(
            (accumulator, { commission }) => {
                return accumulator + Number(commission || 0);
            },
            0
        );

        // Calculate various sums from the 'returnAndCommission' array.
        const tempBalance = responseResults?.reduce(
            (accumulator, object) => {
                return accumulator + Number(object?.balance || 0);
            },
            0
        );
        const tempCommission = responseResults?.reduce(
            (accumulator, object) => {
                return accumulator + Number(object?.commission || 0);
            },
            0
        );

        const tempSocialCost = responseResults?.reduce((accumulator, object) => {
            return accumulator + Number(object?.social_cost || 0);
        }, 0);

        const tempIva = responseResults?.reduce((accumulator, object) => {
            return accumulator + Number(object?.iva || 0);
        }, 0);

        // Create an array 'arrayOfPayers' containing all 'fundings' from 'responseResults'.
        let arrayOfPayers = [];
        responseResults?.forEach((element) => {
            element?.fundings.forEach((element) => arrayOfPayers?.push(element));
        });

        // Calculate the total amount for all payers in 'arrayOfPayers'.
        const amountPayers = arrayOfPayers?.reduce((accumulator2, object) => {
            return accumulator2 + Number(object?.amount);
        }, 0);

        // Modify 'depositsTemp' by scaling 'expense' and 'cost' properties by 100.
        let depositsMod = responseResultsDeposits?.map((element) => {
            return {
                ...element,
                client_name: `${element.client.name} - ${element.company_type}`,
                expense: element.expense * 100,
                cost: element.cost * 100,
            };
        });

        // Calculate the total commission for promoters and credit-to-debt transactions.
        const promoterCommissionTotal = promotersData?.promotersArray?.reduce(
            (accumulator2, object) => {
                return accumulator2 + Number(object?.commission);
            },
            0
        );

        const creditCommissionTotal = debtsData?.creditArray?.reduce(
            (accumulator2, object) => {
                return accumulator2 + Number(object?.commission);
            },
            0
        );

        // Initialize 'totalAmounts' to calculate total funding amounts for promoters.
        let totalAmounts = 0;
        const totalFundingsPromoters = promotersData?.promotersArray?.forEach(
            (item) => {
                totalAmounts += item.fundings.reduce(
                    (sum, { amount }) => sum + amount,
                    0
                );
            }
        );

        const totalAdministrativeExpenses = administrativeCostData?.results?.reduce((acc, expense) => acc + expense.manual_import, 0);

        const finalObject = {
            executive: data.executive?.first_name,
            application_number: data.key,
            type_application: data.folio,
            available_fundings: data.fundings,
            date: data.created_datetime.substr(0, 10),
            total_operative_expenses: data.total_operative_expenses,
            total_available_final: data.total_available_KV,
            total_perceptions: data.total_perceptions,
            invoice: data.invoice,
            total_fundings_available_kv: totalFundingAvailableKV,
            total_return: tempReturn,
            total_isn: tempISN,
            total_isr: tempISR,
            return_commission: responseResults.map(item => {
                if (item.scheme === "RESGUARDO") {
                    return { ...item, base_commission: "RESGUARDO" };
                }
                return item;
            }),
            total_payers: amountPayers,
            total_commission_scheme: tempCommission,
            total_iva: tempIva,
            total_balance: tempBalance,
            total_social_cost: tempSocialCost,
            deposits: depositsMod,
            administrative_expenses: administrativeCostData?.results,
            total_administrative_expenses: totalAdministrativeExpenses,
            total_promoter_commission: promotersData?.commissionTotal,
            total_promoter_balance: promotersData?.amountTotal,
            promoters: promotersData,
            total_credit_to_debt_commission: debtsData.commissionTotal,
            total_credit_to_debt_amount: debtsData.amountTotal,
            credit_to_debt: debtsData,
            total_promoters: promoterCommissionTotal,
            total_payers_promoters: totalFundingsPromoters,
            total_commissions_credit: creditCommissionTotal,
            total_deposits: newTotalDeposits,
            total_commission_corporative: totalCommissionCorporative,
            total_import_funding: totalImportFunding,
            operative_expense: operatingExpenses,
            dec_operating_expenses: decOperatingExpenses,
            total_expenses: totalExpenses,
            available_kv_observations: data?.observations,
        }

        getPDFInternalRequest(finalObject)
            .then((response) => {
                try {
                    // Create a URL for the PDF content.
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
    
                    // Create a link element to download the PDF.
                    const link = document.createElement('a');
                    link.href = url;
    
                    // Set the 'download' attribute for the link with the PDF file name.
                    link.setAttribute('download', `${data.key}.pdf`);
    
                    // Append the link to the document body.
                    document.body.appendChild(link);
    
                    // Simulate a click on the link to initiate the download.
                    link.click();
    
                    // Display a success message after generating the report.
                    handleSuccess('Reporte generado');
                } catch (error) {
                    // Handle any errors that occur during the process and display an error message.
                    handleError(error, 'Error en reporte');
                }
            })
            .catch((error) => console.log(error));
    });
    
}