import { getSideBarStatus } from '../actions/sidebar';
import { types } from '../types/types';

const initialState = getSideBarStatus();

export const sidebarReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.setShowSideBar:
			return action.payload;

		case types.resetSideBar:
			return initialState;

		default:
			return state;
	}
};
